import { getAuth } from "firebase/auth";
import { subscriptionsTable } from "../../helpers/constants";
import { count, queryMany, queryOne } from "./all";
import {
  getFirestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
} from "firebase/firestore";

const table = subscriptionsTable;

const subscriptionModel = {
  getManyByStoryworldId: async (storyworldId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["storyworldId", "==", storyworldId], ...args],
    });

    return data;
  },
  create: async (params) => {
    const { storyworldId } = params;

    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const ref = await addDoc(collection(getFirestore(), table), {
      storyworldId,
      creator: user.uid,
      createdAt: new Date(),
    });
    const doc = await queryOne({ table, id: ref.id });
    if (!doc) throw new Error("Subscription not created");
    return doc;
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(getFirestore(), table, id));
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default subscriptionModel;
