import React from "react";
import styled from "styled-components";
import { downloadDataFile } from "../helpers/general";

export default function Data() {
  return (
    <Container>
      <DownloadButton
        onClick={async (e) => {
          await downloadDataFile();
        }}
      >
        Download Data
      </DownloadButton>
    </Container>
  );
}

const Container = styled.div`
  display: block;
`;

const DownloadButton = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
`;
