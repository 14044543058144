import React from "react";
import { Image, Modal } from "antd";
import { useState } from "react";
import styled from "styled-components";

const successImage = "/images/sparkle-success.png";

export default function SubmissionSuccessModal({ quest, setHasSeenModal }) {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <Modal
      className="modal submission-success-modal"
      footer={[]}
      open={modalOpen}
      onOk={(event) => {
        setModalOpen(false);
        setHasSeenModal(true);
        event.stopPropagation();
      }}
      onCancel={(event) => {
        setModalOpen(false);
        setHasSeenModal(true);
        event.stopPropagation();
      }}
      style={{
        margin: "auto",
        background: "#1F222A !important",
      }}
    >
      <div
        style={{
          background: "#1F222A",
          textAlign: "center",
        }}
      >
        <Image src={successImage} preview={false} />
        <Success>Success!</Success>
        <Subtext>
          Great Scott, you may have just altered the course of the future.
        </Subtext>
        <Subtext>
          Come back <Highlighted>when voting opens</Highlighted> to determine
          where the story should go!
        </Subtext>
        <BackToQuestButton
          onClick={() => {
            console.log("clicked vote button");
            setModalOpen(false);
            setHasSeenModal(true);
          }}
        >
          Back to the quest
        </BackToQuestButton>
      </div>
    </Modal>
  );
}

const Success = styled.h4`
  width: 100%;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  color: var(--navItemSelected) !important;
  font-size: 24px;
  margin-top: 4%;
`;

const Subtext = styled.p`
  margin-top: 7%;
  color: var(--color-cream);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const Highlighted = styled.span`
  color: var(--successColor);
  font-weight: 800;
`;

const BackToQuestButton = styled.button`
  border-radius: 50px;
  background: var(--lightPurple);
  font-weight: bold;
  border: none;
  padding: 8px 10px;
  height: fit-content;
  min-width: 200px;
  font-size: 12px;
  font-weight: 800;
  margin-top: 24px;
  color: white;
`;
