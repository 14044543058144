import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";
import { getRenderCount } from "../../helpers/firebase";
import { currentUser } from "../../helpers/authentication";
import { message } from "antd";
import {
  invokeGenAi,
  retrieveGraydientImagesFromFirebase,
} from "../../helpers/genAI";
import mixpanel from "mixpanel-browser";
import CreateMai from "./CreateMai";
import modelsModel from "../../lib/firebase/modelsModel";
import modelPromiseModel from "../../lib/firebase/modelPromiseModel";
import ImageGenerator from "./ImageGenerator";
import { mobileWidthThreshold } from "../../helpers/constants";

export default function ImageGeneratorSheet({ isOpen, setOpen, setImageUrl }) {
  const [hasUserModel, setHasUserModel] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [genAiError, setGenAiError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [limitReached, setLimitReached] = useState(false);
  const [genAiImages, setGenAiImages] = useState([]);
  const [imageFilenames, setImageFilenames] = useState([]);
  const [hasUploadedImages, setHasUploadedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [countdownDate, setCountdownDate] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [isModelGenerating, setModelGenerating] = useState(null);
  const [currentMai, setCurrentMai] = useState(null);
  const [checkingMai, setCheckingMai] = useState(true);
  const [loras, setLoras] = useState([]);
  const [baseImageUrl, setBaseImageUrl] = useState("");

  useEffect(() => {
    async function fetchData() {
      const modelPromiseCount = await modelPromiseModel.count([
        "creator",
        "==",
        currentUser.uid,
      ]);
      const modelCount = await modelsModel.count([
        "creator",
        "==",
        currentUser.uid,
      ]);
      if (modelPromiseCount === modelCount) setModelGenerating(false);
      else setModelGenerating(true);

      if (modelCount > 0) {
        const mai = await modelsModel.getMany(
          ["limit", "1"],
          ["creator", "==", currentUser.uid],
          ["sort", "createdAt", "desc"]
        );
        console.log(mai[0]);
        setCurrentMai(mai[0]);
      }
      setCheckingMai(false);
    }

    if (isOpen) fetchData();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) setBaseImageUrl("");
  }, [isOpen]);

  const handleChange = (event) => {
    const newPrompt = event.target.value;
    setPrompt(newPrompt);
    if (newPrompt !== "") {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  };

  const createGeneration = async () => {
    setIsGenerating(true);
    console.log(prompt);
    /*
    const canGenerate = await increaseGenerationQuota(currentUser.uid);
    if (!canGenerate) {
      setLimitReached(true);
      message.error(limitReachedText);
      setButtonEnabled(true);
      return;
    }
    */
    setSelectedImage(null);
    if (prompt === "") {
      setGenAiError("Please provide a Gen AI prompt to generate your image");
      setButtonEnabled(false);
      return;
    }
    setGenAiImages([]);
    setButtonEnabled(false);

    const renderCount = await getRenderCount();
    const newSessionId = `${currentUser.uid}-${renderCount}`;
    setSessionId(newSessionId);
    const loraStrings = [];
    loras.forEach((l) => {
      loraStrings.push(l.tag);
    });
    const loraWeights = [];
    loras.forEach((l) => {
      loraWeights.push(l.weight);
    });
    mixpanel.track("Started generating images", {
      loras: loraStrings,
    });
    const promise = await invokeGenAi({
      prompt,
      sessionId: newSessionId,
      loras: loraStrings,
      weights: loraWeights,
      facepushUrl: baseImageUrl,
    });

    if (promise) {
      const waitSeconds =
        Math.ceil(
          promise.data.attributes.estimated_render_time +
            promise.data.attributes.estimated_wait_time
        ) * 3;
      message.success(`Estimated wait time: ~${waitSeconds} seconds`, [5]);
      let newCountdownDate = new Date();
      newCountdownDate.setSeconds(newCountdownDate.getSeconds() + waitSeconds);
      setCountdownDate(newCountdownDate);
    } else {
      // message.error("Something went wrong. Please try again.");
      setIsGenerating(false);
      return;
    }

    const result = await retrieveGraydientImagesFromFirebase(newSessionId);
    console.log(result);
    const newImages = [];
    result.images.forEach((img) => {
      console.log(img);
      newImages.push(img);
    });
    setGenAiImages(newImages);
    console.log(newImages);
    /*
    if (typeof promise === "string") {
      message.error(promise);
    } else {
      setGenAiImages(promise);
    }
    */
    setIsGenerating(false);
  };

  return (
    <Container>
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[750]}
        style={{
          pointerEvents: isOpen ? "auto" : "none",
          background: isOpen ? "rgba(0,0,0,0.5)" : null,
          zIndex: 999,
        }}
        onClick={(e) => {
          if (isOpen) setOpen(false);
        }}
        tweenConfig={{ ease: "easeOut", duration: 0.25 }}
        className="contributor-sheet"
      >
        <Sheet.Container
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="contributor-sheet-container"
        >
          <Sheet.Header />
          <Sheet.Content
            className="contributor-sheet-content"
            style={{ overflow: "scroll" }}
          >
            <InnerContainer>
              {hasUserModel || genAiImages.length > 0 ? (
                <ImageGenerator
                  isGenerating={isGenerating}
                  genAiImages={genAiImages}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  buttonEnabled={buttonEnabled}
                  isOpen={isOpen}
                  setOpen={setOpen}
                  setImageUrl={setImageUrl}
                  handleChange={handleChange}
                  createGeneration={createGeneration}
                  setHasUserModel={setHasUserModel}
                  setGenAiImages={setGenAiImages}
                  countdownDate={countdownDate}
                  prompt={prompt}
                  loras={loras}
                  setLoras={setLoras}
                  baseImageUrl={baseImageUrl}
                  setBaseImageUrl={setBaseImageUrl}
                />
              ) : (
                <CreateMai
                  setImageUrl={setImageUrl}
                  hasUserModel={hasUserModel}
                  setHasUserModel={setHasUserModel}
                  imageFilenames={imageFilenames}
                  setImageFilenames={setImageFilenames}
                  hasUploadedImages={hasUploadedImages}
                  setHasUploadedImages={setHasUploadedImages}
                  isModelGenerating={isModelGenerating}
                  setModelGenerating={setModelGenerating}
                  currentMai={currentMai}
                  checkingMai={checkingMai}
                />
              )}
            </InnerContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </Container>
  );
}

const Container = styled.span`
  .contributor-sheet-container {
    background: #f5f5f5 !important;
  }

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }

  .contributor-sheet-content {
    overflow-y: scroll;
  }
`;

const InnerContainer = styled.div`
  width: 90%;
  max-width: ${mobileWidthThreshold - 200}px;
  margin: auto;
  margin-top: 0px;

  .ant-upload-drag {
    background: transparent !important;
  }
`;
