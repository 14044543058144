// ----- Components Import -----
import { styled } from "styled-components";
import { Link, NavLink } from "react-router-dom";

// ----- Assets Import -----
import SGLogo from "../../assets/home/logo.svg";
import burgerCloseIcon from "../../assets/icons/burger-close-icon.svg";
import { Container } from "../styles/CommonElements";

export default function MobileMenu({ mobileMenu, setMobileMenu }) {
  const LinkClickHandler = () => {
    setMobileMenu(false);
  };

  return (
    <MobileMenuWrapper className={mobileMenu ? "show" : ""}>
      <nav>
        <Container>
          <NavContent>
            <button onClick={() => setMobileMenu(false)}>
              <img src={burgerCloseIcon} alt="Burger" />
            </button>
            <Link className="logo" to="/" onClick={LinkClickHandler}>
              <Logo src={SGLogo} alt="Storygrounds" />
            </Link>
          </NavContent>
          <NavItems>
            <li>
              <NavLink to="/login" onClick={LinkClickHandler}>
                Log In
              </NavLink>
            </li>
            <li>
              <NavLink to="/register" onClick={LinkClickHandler}>
                Sign Up
              </NavLink>
            </li>
            <li>
              <NavLink to="/privacy" onClick={LinkClickHandler}>
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink to="/terms" onClick={LinkClickHandler}>
                Terms of Use
              </NavLink>
            </li>
          </NavItems>
        </Container>
      </nav>
    </MobileMenuWrapper>
  );
}

// ----- Styles -----
const MobileMenuWrapper = styled.div`
  background-color: white;
  padding: 1.5rem 0;
  position: fixed;
  inset: 0;
  transform: translateY(-200%);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  &.show {
    transform: translateY(0) !important;
    visibility: visible;
    opacity: 1;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: 61.9375rem) {
    justify-content: start;
  }

  .logo {
    @media (max-width: 35.9375rem) {
      max-width: 14.375rem;
    }
  }

  button {
    display: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: 61.9375rem) {
      display: block;
    }
  }
`;

const NavItems = styled.ul`
  display: flex;
  align-items: center;
  padding-top: 3rem;
  gap: 2rem;
  flex-direction: column;
  padding-left: 0;

  li {
    list-style: none;

    a {
      color: var(--color-dark);
      font-size: 1.4rem;
      font-weight: 600;
      text-decoration: none;
      transtion: 0.3s;

      &.active,
      &.hover {
        color: var(--color-primary);
        transtion: 0.3s;
      }
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;
