import React from "react";
import { Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { currentUser, firebaseAuth } from "../helpers/authentication";
import UserDisplay from "./UserDisplay";
import styled from "styled-components";

export default function HeaderBar({
  selectedStoryworld,
  setSelectedStoryworld,
  selectedQuest,
  setSelectedQuest,
  selectedPoll,
  setSelectedPoll,
  selectedEpisode,
  setSelectedEpisode,
}) {
  const location = useLocation();

  return (
    <Container>
      <UserDisplayContainer>
        {currentUser ? (
          <Button
            className="close-button"
            style={{ marginRight: 20, borderRadius: 20 }}
            onClick={async () => {
              firebaseAuth.signOut().then(
                function () {
                  window.location.href = "/login";
                },
                function (error) {
                  console.error("Sign Out Error", error);
                }
              );
            }}
          >
            Log out
          </Button>
        ) : (
          ``
        )}
        <div
          className="user-display-button-profile"
          style={{ marginTop: "0px" }}
        >
          <UserDisplay
            isCurrentUser={true}
            userId={currentUser ? currentUser.uid : null}
            showName={false}
            toLocation="login"
          />
        </div>
      </UserDisplayContainer>
    </Container>
  );
}

const Container = styled.div`
  background: var(--color-gray-500);
  width: 100%;
  height: 80px;
  box-shadow: inset 1px 0px 0px #111315;
  position: absolute;
  left: 0px;
  top: 0px;
`;

const UserDisplayContainer = styled.div`
  height: 100%;
  margin: auto;
  position: absolute;
  right: 24px;
  top: 20px;
  display: inline-flex;
`;
