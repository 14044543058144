import { paymentsTable } from "../../helpers/constants";
import { queryOne, queryMany } from "./all";

const table = paymentsTable;

const paymentModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default paymentModel;
