import React from "react";
import { useState } from "react";
import { loginUser, validateEmail } from "../helpers/authentication";
import styled from "styled-components";
import { getUserInfo } from "../helpers/firebase";
import { passwordErrorText, adminList } from "../helpers/constants";
import { isOnMobile } from "../helpers/general";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Authentication({
  email = "",
  setForgotPw,
  setGoToProfileCreation,
  setProfileCreated,
}) {
  const history = useHistory();

  const [emailAddress, setEmailAddress] = useState(email);
  const [password, setPassword] = useState("");
  const [revealPassword, setRevealPassword] = useState(false);
  const [isEmailValidated, setEmailValidated] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isSending, setIsSending] = useState(false);

  let passwordRef = React.createRef();

  const onEmailChange = (event) => {
    setEmailAddress(event.target.value);
    setEmailValidated(validateEmail(emailAddress));
    setLoginError("");
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setEmailValidated(validateEmail(emailAddress));
    setLoginError("");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);
    try {
      if (isEmailValidated) {
        setLoginError("");
        console.log("Logging in User");
        const result = await loginUser(emailAddress, password);
        if (result.user) {
          const userData = await getUserInfo(result.user.uid);
          if (result === null) {
            setLoginError(true);
            setIsSending(false);
          } else if (userData !== null && userData.acceptedTos) {
            const onMobile = isOnMobile();
            if (onMobile) history.push("/stories");
            else if (adminList.includes(emailAddress) && !onMobile)
              history.push("/editStoryworlds");
          } else if (userData !== null) {
            setGoToProfileCreation(true);
            setProfileCreated(true);
          } else {
            setGoToProfileCreation(true);
          }
        } else {
          getLoginError(result.code);
          setIsSending(false);
        }
      } else {
        console.log("Error bad email, show error to user");
      }
    } catch (error) {
      setIsSending(false);
    }
  };

  const getLoginError = (error) => {
    console.log(error);
    switch (error) {
      case "auth/wrong-password":
        setLoginError(passwordErrorText);
        break;
      case "auth/too-many-requests":
        setLoginError(
          "You have tried to log in too many times. Try again in a few minutes."
        );
        break;
      default:
        setLoginError(
          "You have encountered an error. Try again in a few minutes."
        );
        break;
    }
  };

  return (
    <Container>
      <WelcomeImage src="/images/login/welcome-image.svg" alt="" />
      <LoginHint>Enter your password to sign in</LoginHint>
      <form onSubmit={onSubmit} style={{ position: "relative", zIndex: 1 }}>
        <InputHeader>Email</InputHeader>
        <InputContainer>
          <InputFieldDisabled
            className="transition-colors"
            placeholder="email@email.com"
            value={emailAddress}
            onChange={onEmailChange}
            disabled
          />
          <Checkmark src="/icons/checkmark.svg" />
        </InputContainer>
        <PasswordInputHeader>Password</PasswordInputHeader>
        <PasswordInputContainer>
          <InputField
            className="transition-colors"
            placeholder="Enter Password"
            maxLength={40}
            type={revealPassword ? "text" : "password"}
            onChange={onPasswordChange}
            ref={passwordRef}
          />
          {loginError === passwordErrorText ? (
            <ErrorMark src="/icons/x.svg" alt="" />
          ) : (
            <span
              class="flex justify-around items-center"
              onClick={() => {
                setRevealPassword(!revealPassword);
                passwordRef.current.focus();
              }}
            >
              {revealPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          )}
        </PasswordInputContainer>
        {loginError !== "" && <LoginError>{loginError}</LoginError>}

        <SignInButtons>
          <ButtonSection>
            {!isSending ? (
              isEmailValidated && emailAddress !== "" && password !== "" ? (
                <SignInButton type="submit">Sign in</SignInButton>
              ) : (
                <SignInButtonDisabled disabled>Sign in</SignInButtonDisabled>
              )
            ) : (
              <SendingButton disabled>
                <Spinner alt="" src="/icons/loading-spheres.svg" />
              </SendingButton>
            )}
          </ButtonSection>

          <ForgotPasswordSection>
            <ForgotPasswordText onClick={() => setForgotPw(true)}>
              Forgot password?
            </ForgotPasswordText>
          </ForgotPasswordSection>
        </SignInButtons>
      </form>
      <Diamond src="/images/diamond.svg" alt="" />
      <Clouds src="/images/clouds.svg" alt="" />
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: 20px auto auto auto;
  width: 90%;
  position: relative;
`;

const WelcomeImage = styled.img`
  width: 72.5%;
  height: auto;
  margin: auto;
`;

const PasswordInputContainer = styled.div`
  position: relative;
`;

const ErrorMark = styled.img`
  position: absolute;
  right: 14px;
  top: 35%;
  height: 14px;
  width: 14px;
`;

const Checkmark = styled.img`
  right: 14px !important;
  top: 35%;
  height: 12px;
  position: absolute;
`;

const LoginError = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 12px;
  color: var(--color-red);
  letter-spacing: 0.2px;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputFieldDisabled = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--seafoam);
  margin: auto;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const LoginHint = styled.div`
  margin-bottom: 30px;
  margin-top: 16px;
  color: var(--color-cream-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const InputHeader = styled.div`
  margin-bottom: 10px;
  color: var(--color-gray-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const PasswordInputHeader = styled.div`
  color: var(--color-gray-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
  margin: 26px 0px 10px 0px;
`;

const InputField = styled.input`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  color: var(--cream);

  &:focus {
    /* Have a purple border */
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }
`;

const SignInButtons = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 0;
  text-align: left;
  margin: auto;
  text-align: center;
  margin-top: 28px;
`;

const ButtonSection = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const ForgotPasswordSection = styled.h4`
  margin-top: 28px;
`;

const ForgotPasswordText = styled.span`
  color: var(--color-cream-dark);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

const SignInButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  color: var(--cream);
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const SignInButtonDisabled = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const SendingButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const Diamond = styled.img`
  position: fixed;
  bottom: 50px;
  width: 18%;
  height: auto;
  z-index: 0;
`;

const Clouds = styled.img`
  position: fixed;
  bottom: 0px !important;
  width: 90%;
  height: auto;
  right: 0px;
  z-index: 0;
`;
