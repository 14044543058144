import React, { useEffect, useState } from "react";
import {
  getConfirmedGalleryCount,
  getFirstPageOfGallery,
  getNextPageOfGallery,
} from "../helpers/firebase";
import parse from "html-react-parser";
import { Button, Col, Row } from "antd";
import { reduceArrayToRows } from "../helpers/general";
import styled from "styled-components";

function GallerySection({
  poll,
  setActiveUsers = null,
  backLocations,
  setBackLocations,
}) {
  const [rowsOfSubmissions, setRowsOfSubmissions] = useState([]);
  const [subAmount, setSubAmount] = useState(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [documentSnapshots, setDocumentSnapshots] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const confirmedGalleryCount = await getConfirmedGalleryCount(
        poll.questId
      );
      setSubAmount(confirmedGalleryCount);

      if (confirmedGalleryCount > 0 && poll) {
        const galleryPromise = await getFirstPageOfGallery(poll.questId);
        const confirmedQuestSubmissionsPromise = galleryPromise.results;
        setDocumentSnapshots(galleryPromise.documentSnapshots);
        const confirmedQuestSubmissionsSoonestFirst =
          confirmedQuestSubmissionsPromise.sort(function (a, b) {
            return b.createdAt.toDate() - a.createdAt.toDate();
          });
        const questActiveUsers = [];
        confirmedQuestSubmissionsPromise.forEach((sub) => {
          questActiveUsers.push(sub.creator);
        });
        if (setActiveUsers) {
          setActiveUsers((activeUsers) => activeUsers.concat(questActiveUsers));
        }
        setShowSeeMore(subAmount / 2 > rowsOfSubmissions.length);
        if (confirmedQuestSubmissionsSoonestFirst.length > 0) {
          const rows = confirmedQuestSubmissionsSoonestFirst.reduce(
            reduceArrayToRows,
            []
          );
          setRowsOfSubmissions(rows);
        } else {
          setRowsOfSubmissions([]);
        }
      }
    }

    fetchData();
  }, [poll, setActiveUsers]);

  async function seeMoreEntries() {
    const nextPageGalleryPromise = await getNextPageOfGallery({
      questId: poll.questId,
      documentSnapshots,
    });
    setDocumentSnapshots(nextPageGalleryPromise.documentSnapshots);
    setShowSeeMore(subAmount / 2 > rowsOfSubmissions.length);
    const newRows = nextPageGalleryPromise.results.reduce(
      reduceArrayToRows,
      []
    );
    setRowsOfSubmissions((rows) => rows.concat(newRows));
  }

  return (
    <>
      {subAmount > 0 && poll ? (
        <Container>
          <PollTitle>{poll ? parse(poll.title) : null}</PollTitle>
          <div className="gallery-submissions-section">
            {rowsOfSubmissions.map((row, index) => (
              <Row key={index} gutter={10} style={{ marginTop: 10 }}>
                <Col span={12}></Col>
                <Col span={12}></Col>
              </Row>
            ))}
          </div>
          {showSeeMore ? (
            <>
              <div className="overlay" />
              <div style={{ textAlign: "center" }}>
                <Button onClick={seeMoreEntries} className="see-more-button">
                  See more
                </Button>
              </div>
            </>
          ) : null}
        </Container>
      ) : null}
    </>
  );
}

export default GallerySection;

const Container = styled.div`
  text-align: left;
  width: 100%;
  margin: auto;
  margin: 40px auto 80px auto;

  &.ant-image {
    width: 100%;
  }
`;

const PollTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  &p {
    margin-bottom: 6px;
  }
`;
