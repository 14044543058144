import React from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";

export default function IdeaSheet({ isOpen, setOpen, setPrompt, text }) {
  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      style={{
        pointerEvents: isOpen ? "auto" : "none",
        background: isOpen ? "rgba(0,0,0,0.5)" : null,
      }}
      onClick={(e) => {
        if (isOpen) setOpen(false);
      }}
      tweenConfig={{ ease: "easeOut", duration: 0.2 }}
      className="idea-sheet"
    >
      <Sheet.Container
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="idea-sheet-container"
      >
        <Sheet.Header />
        <Sheet.Content>
          <InnerContainer>
            <SheetText>{text}</SheetText>
            <CopyPromptButton
              onClick={async () => {
                setOpen(false);
                setPrompt(text);
              }}
            >
              Copy Prompt
            </CopyPromptButton>
          </InnerContainer>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
}

const InnerContainer = styled.div`
  margin-top: 8px;
`;

const SheetText = styled.div`
  color: var(--color-cream-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.5px;
`;

const CopyPromptButton = styled.button`
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  border: 0;
  border-radius: 100px;
  background: var(--blurple);
  color: var(--cream);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-top: 22px;
`;
