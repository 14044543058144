import React from "react";
import {
  instagramLink,
  tikTokLink,
  twitterLink,
  helpEmailAddress,
  appName,
  feedbackEmailAddress,
} from "../../helpers/constants";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const tikTokLogo = "/icons/tiktok.svg";
const instagramLogo = "/icons/instagram.svg";
const twitterLogo = "/icons/twitter.svg";

export default function PageFooter() {
  const location = useLocation();

  return (
    <Footer>
      <SocialContainer>
        <Link to="/login">
          <Logo src="/android-chrome-512x512.png" alt="" />
        </Link>
      </SocialContainer>
      <OtherContainer>
        <FooterLinks>
          <a
            href={`mailto:${feedbackEmailAddress}`}
            rel="noreferrer"
            target="_blank"
          >
            Feedback
          </a>{" "}
          |{" "}
          <a
            href={`mailto:${helpEmailAddress}`}
            rel="noreferrer"
            target="_blank"
          >
            Help
          </a>{" "}
          |{" "}
          <Link
            to="/terms"
            className={location.pathname.includes("/terms") ? "selected" : ""}
          >
            Terms
          </Link>{" "}
          |{" "}
          <Link
            to="/privacy"
            className={location.pathname.includes("/privacy") ? "selected" : ""}
          >
            Privacy
          </Link>
          {/*
          {" "}
          |{" "}
          <Link
            to="/content-and-community-guidelines"
            className={
              location.pathname.includes("/content-and-community-guidelines")
                ? "selected"
                : ""
            }
          >
            Community Guidelines
          </Link>
          */}
        </FooterLinks>
        <CopyrightText>© AllmaiFans</CopyrightText>
      </OtherContainer>
    </Footer>
  );
}

const Footer = styled.div`
  background: var(--color-main-blue);
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px 0px;
`;

const FooterLinks = styled.div`
  font-size: 10px;
  text-align: center;
  font-style: normal;
  color: white;
  font-weight: 400;
  line-height: 32.5px;
  letter-spacing: 0.4px;
  display: block;
  background: none;

  a {
    color: white !important;
  }

  .selected {
    color: black;
    font-weight: 600;
  }
`;

const Logo = styled.img`
  height: 40px;
`;

const SocialContainer = styled.div`
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  text-align: center;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: -12px;
  color: black;
`;

const OtherContainer = styled.div`
  text-align: center;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const CopyrightText = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #ede5e2;
  line-height: 32px;
  font-style: normal;
  line-height: 32.5px;
  margin-top: 0px;
  color: white;
`;
