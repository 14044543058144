import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown, Space } from "antd";
import { ReportUserSheet } from "../components/social";

export default function ProfileMenuButton({ userId }) {
  const [open, setOpen] = useState(false);

  const items = [
    {
      label: <DeleteHeader onClick={() => setOpen(true)}>Report</DeleteHeader>,
      key: "1",
    },
  ];

  return (
    <Container>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button onClick={(e) => e.preventDefault()}>
          <Space>
            <Dots src="/icons/social/ellipsis.svg" alt="" />
          </Space>
        </Button>
      </Dropdown>
      <ReportUserSheet isOpen={open} setOpen={setOpen} userId={userId} />
    </Container>
  );
}

const Container = styled.span`
  color: var(--exitColor);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.25s ease;
  position: absolute;
  top: 6px;
  right: 10px;

  &:hover {
    color: #e6544c;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 0;
  font-size: 22px;
`;

const Dots = styled.img`
  height: 26px;
  width: auto;
  padding-top: 6px;
  opacity: 0.5;
`;

const DeleteHeader = styled.div`
  color: red;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.6px */
  margin-top: 0px !important;
  width: 100%;
  height: 100%;
`;
