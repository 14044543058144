import React, { useEffect, useState } from "react";
import { Footer as _Footer } from "antd/es/layout/layout";
import { showHeaderUi, sleep } from "../../helpers/general";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Col, Row } from "antd";
import { currentUser } from "../../helpers/authentication";
import userModel from "../../lib/firebase/userModel";
import { mobileWidthThreshold } from "../../helpers/constants";

const inactiveOpacity = 0.3;

export default function NavMenu() {
  const location = useLocation();
  const history = useHistory();

  const [initials, setInitials] = useState("");
  const [pfp, setPfp] = useState("");
  const [profileOpen, setProfileOpen] = useState(false);
  const [waited, setWaited] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await sleep(1);
      setWaited(true);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/profile")) {
      setProfileOpen(true);
    } else {
      setProfileOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    async function fetchData() {
      await sleep(3);
      if (currentUser && currentUser.emailVerified) {
        const userInfo = await userModel.getOneById(currentUser.uid);
        if (userInfo) {
          if (userInfo.pfp) {
            setPfp(userInfo.pfp);
          } else {
            const matches = userInfo.name.match(/\b(\w)/g);
            setInitials(matches.join(""));
          }
        }
      }
    }
    fetchData();
  }, []);

  return (
    waited &&
    showHeaderUi(location) && (
      <>
        <Footer>
          <Row>
            <Col span={5} className="margin-auto">
              <Link to="/home">
                <HomeIcon
                  location={location.pathname}
                  src="/icons/nav-menu/home.svg"
                  alt=""
                />
              </Link>
            </Col>
            <Col span={5} className="margin-auto">
              <Link to="/notifications">
                <NotificationIcon
                  location={location.pathname}
                  src="/icons/nav-menu/bell.svg"
                  alt=""
                />
              </Link>
            </Col>
            <Col span={4} className="margin-auto">
              <Link to="/post">
                <NewIcon
                  location={location.pathname}
                  src="/icons/nav-menu/new.svg"
                  alt=""
                />
              </Link>
            </Col>
            <Col span={5} className="margin-auto">
              <Link to="/messages">
                <MessageIcon
                  location={location.pathname}
                  src="/icons/nav-menu/message.svg"
                  alt=""
                />
              </Link>
            </Col>
            <Col span={5} className="margin-auto">
              <ProfileButton
                onClick={() => {
                  history.push("/profile");
                }}
              >
                {pfp ? (
                  <ProfileImage pfp={pfp} profileOpen={profileOpen} />
                ) : (
                  <ProfileInitials profileOpen={profileOpen}>
                    {initials}
                  </ProfileInitials>
                )}
              </ProfileButton>
            </Col>
          </Row>
        </Footer>
        <Spacer />
      </>
    )
  );
}

const Footer = styled(_Footer)`
  background: white;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid lightgray;
  margin: auto;

  .margin-auto {
    margin: auto;
  }

  @media (max-width: ${mobileWidthThreshold}) {
    display: none;
  }
`;

const NewIcon = styled.img`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  height: 40px;
  width: 40px;
  opacity: ${(props) => (props.location === "/post" ? "1" : inactiveOpacity)};
`;

const HomeIcon = styled.img`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  height: 40px;
  width: 40px;
  opacity: ${(props) => (props.location === "/home" ? "1" : inactiveOpacity)};
`;

const NotificationIcon = styled.img`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  height: 40px;
  width: 40px;
  margin-left: -6px;
  opacity: ${(props) =>
    props.location === "/notifications" ? "1" : inactiveOpacity};
`;

const MessageIcon = styled.img`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-left: 10px;
  color: black;
  height: 48px;
  width: 48px;
  opacity: ${(props) =>
    props.location.includes("/messages") ? "1" : inactiveOpacity};
`;

const ProfileButton = styled.button`
  background: none;
  border: 0;
`;

const ProfileImage = styled.div`
  color: black;
  padding: 12px;
  height: 18px;
  width: 18px;
  font-weight: bold;
  font-size: 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  line-height: 18px;
  background-image: ${(props) => props.pfp && `url("${props.pfp}")`};
  background-size: cover;
  background-repeat: no-repeat;
  outline: ${(props) => (props.profileOpen ? "2px solid black" : "0px")};
`;

const ProfileInitials = styled.div`
  color: black;
  padding: 12px;
  height: 18px;
  width: 18px;
  font-weight: bold;
  font-size: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  line-height: 18px;
  outline: ${(props) => (props.profileOpen ? "2px solid black" : "0px")};
`;

const Spacer = styled.div`
  height: 70px;
  background: none;
`;
