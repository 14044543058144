import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const downArrow = "/icons/down-arrow.svg";

export default function KeepScrollingSection({
  loadNextEpisode = null,
  setIsHidden,
}) {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setIsHidden(true);
      loadNextEpisode();
    }
  }, [inView, loadNextEpisode, setIsHidden]);

  return (
    loadNextEpisode !== null && (
      <Container>
        <InnerContainer>
          <h4>Keep scrolling for next episode</h4>
          <Spacer />
          <img src={downArrow} alt="" />
        </InnerContainer>
        <ViewTrigger ref={ref} />
      </Container>
    )
  );
}

const Container = styled.div``;

const InnerContainer = styled.div`
  padding: 48px 0px 36px 0px;
  overflow-y: scroll;
`;

const Spacer = styled.div`
  margin-top: 20px;
`;

const ViewTrigger = styled.div`
  height: 140px;
`;
