import React, { useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db, getUserInfoByUserId } from "../../helpers/firebase";
import { currentUser } from "../../helpers/authentication";
import styled from "styled-components";

const SendMessage = ({ scroll, convoId }) => {
  const [message, setMessage] = useState("");

  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    const userInfo = await getUserInfoByUserId(currentUser.uid);
    const { uid } = auth.currentUser;
    await addDoc(collection(db, "messages"), {
      text: message,
      name: userInfo.username,
      avatar: userInfo.pfp,
      convoId,
      createdAt: serverTimestamp(),
      uid,
    });
    setMessage("");
    scroll.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container>
      <form onSubmit={(event) => sendMessage(event)} className="send-message">
        <input
          id="messageInput"
          name="messageInput"
          type="text"
          className="form-input__input"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <SendButton type="submit" disabled={message === ""}>
          Send
        </SendButton>
      </form>
    </Container>
  );
};

export default SendMessage;

const Container = styled.div`
  font-size: 14px;
  margin-top: 4px;
  font-weight: 500;
  border-radius: 20px !important;
  color: white !important;
  background: var(--color-main-blue);
  opacity: ${(props) => props.disabled && "0.5"};
`;

const SendButton = styled.button`
  font-size: 14px;
  margin-top: 4px;
  font-weight: 500;
  border-radius: 20px !important;
  color: white !important;
  background: var(--color-main-blue);
  opacity: ${(props) => props.disabled && "0.5"};
`;
