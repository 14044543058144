import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  appName,
  companyName,
  helpEmailAddress,
  supportEmailAddress,
  urlName,
} from "../../helpers/constants";

export default function TermsOfUseForCreators({ mustAcceptTos = false }) {
  return (
    <Container mustAcceptTos={mustAcceptTos}>
      <LogoIcon src="" alt="" mustAcceptTos={mustAcceptTos} />
      <MainHeader>Terms of Service For All Users</MainHeader>
      <SubHeader>
        By using our website you agree to these terms - please read them
        carefully
      </SubHeader>
      <List>
        <ListItem>
          These Terms of Service For All Users govern your use of {appName} and
          your agreement with us.
        </ListItem>
        <ListItem>In the Terms of Service:</ListItem>
        <List type="a">
          <ListItem>
            we refer to our website as "{appName}", including when accessed via
            the URL {urlName};
          </ListItem>
          <ListItem>
            references to "we", "our", "us" are references to {companyName}, the
            operator of {appName};
          </ListItem>
          <ListItem>
            "Content" means any material uploaded to {appName} by any User
            (whether a Creator or a Fan), including any photos, videos, audio
            (for example music and other sounds), livestream material, data,
            text (such as comments and hashtags), metadata, images, interactive
            features, emojis, GIFs, memes, and any other material whatsoever;
          </ListItem>
          <ListItem>
            "Creator" means a User who has set up their {appName} account as a
            Creator account to post Content on {appName} to be viewed by other
            Users;
          </ListItem>
          <ListItem>
            "Fan" means a User who follows a Creator and is able to view the
            Creator's Content;
          </ListItem>
          <ListItem>
            "Fan/Creator Transaction" means any transaction between a Fan and a
            Creator on {appName} by which access is granted to the Creator's
            Content including in any of the following ways: (i) a Subscription,
            (ii) payments made by a Fan to view a Creator's pay-per-view Content
            (pay-per-view media and pay-per-view live stream), and (iii) use by
            the Fan of the fan interaction function on a Creator's account;
          </ListItem>
          <ListItem>
            "Fan Payment" means any and all payments made by a Fan to a Creator
            (i) in connection with a Fan/Creator Transaction, or (ii) by way of
            a tip for a Creator;
          </ListItem>
          <ListItem>
            "Referring User" means a User who participates in the {appName}{" "}
            Referral Program;
          </ListItem>
          <ListItem>
            "Standard Contract between Fan and Creator" means the terms which
            govern each Fan/Creator Transaction, which can be found here;
          </ListItem>
          <ListItem>
            "Subscription" means a Fan's subscription to a Creator's account
            (whether paid or unpaid, and whether for one month or as part of a
            bundle comprising a subscription for more than one month);
          </ListItem>
          <ListItem>
            "Terms of Service" (also called "your agreement with us") means the
            legally binding agreement between you and us which consists of: (i)
            these Terms of Use for all Users, (ii) Terms of Use for Fans, (iii)
            Terms of Use for Creators, (iv) Privacy Policy, (v) Acceptable Use
            Policy, (vi) Referral Program Terms, (vii) Complaints Policy, (viii)
            Platform to Business Regulation Terms; and (ix) Community
            Guidelines;
          </ListItem>
          <ListItem>
            "United States" means the United States of America; and
          </ListItem>
          <ListItem>
            "User" means any user of {appName}, whether a Creator or a Fan or
            both (also referred to as "you" or "your").
          </ListItem>
        </List>
        <ListItem>
          Who we are and how to contact us: {appName} is operated by Fenix
          International Limited. We are a limited company registered in the
          United State. To contact us with any questions about {appName}, please
          email our support team at {supportEmailAddress}.
        </ListItem>
        <ListItem>
          How we may change the Terms of Service: We may change any part of the
          Terms of Service without telling you beforehand in the following
          circumstances:
        </ListItem>

        <List type="a">
          <ListItem>
            to reflect changes in laws and regulatory requirements which apply
            to {appName} and the services, features and programs of {appName}
            where such changes require {appName} to change its terms and
            conditions in a manner which does not allow us to give reasonable
            notice to you; and
          </ListItem>
          <ListItem>
            to address an unforeseen and imminent danger related to defending
            {appName}, Fans or Creators from fraud, malware, spam, data breaches
            or other cybersecurity risks.
          </ListItem>
        </List>
        <ExtraText>
          We may also make other changes to any part of the Terms of Service,
          and we will give you reasonable notice of such changes by email or
          through {appName}, and you may contact us to end your agreement with
          us before the changes take effect. Once any updated Terms of Service
          are in effect, you will be bound by them if you continue to use
          {appName}.
        </ExtraText>
        <ListItem>
          We may make changes to or suspend or withdraw {appName}: We may update
          and change {appName} from time to time for any reason, including to
          reflect changes to our services, Users' needs and our business
          practices or to improve performance, enhance functionality or address
          security issues. We will try to give you reasonable notice of any
          major changes. We do not guarantee that {appName}, or any Content on
          it, will always be available or accessible without interruption. We
          may suspend or withdraw or restrict the availability of all or any
          part of {appName} for business or operational reasons. We will try to
          give you reasonable notice of any suspension or withdrawal if it
          affects you.
        </ListItem>
        <ListItem>
          Registering with {appName}: To use {appName} you must first register
          and create a User account on {appName}. You must provide a valid email
          address, a username, and a password or authenticate using a valid
          Twitter or Google account. Your password should be unique (meaning
          that it is different to those you use for other websites) and must
          comply with the technical requirements of the {appName} site for the
          composition of passwords. To register as a User:
        </ListItem>
        <List type="a">
          <ListItem>
            you must be at least 18 years old, and you will be required to
            confirm this;
          </ListItem>
          <ListItem>
            if the laws of the country or State/province where you live provide
            that you can only be legally bound by a contract with us at an age
            which is higher than 18 years old, then you must be old enough to be
            legally bound by a contract with us under the laws of the country or
            State/province where you live; and
          </ListItem>
          <ListItem>
            you must be permitted by the laws of the country or State/province
            where you are located to join {appName} and to view any Content
            available on it and to use any functionality provided by it.{" "}
          </ListItem>
          <ListItem>
            you must provide such other information or verification records as
            we require.
          </ListItem>
        </List>
        <ExtraText>
          If you do not meet the above requirements, you must not access or use{" "}
          {appName}.
        </ExtraText>
        <ListItem>
          Your commitments to us: When you register with and use {appName}, you
          make the following commitments to us:
        </ListItem>
        <List type="a">
          <ListItem>
            If you previously had an account with {appName}, you confirm that
            your old account was not terminated or suspended by us because you
            violated any of our terms or policies.
          </ListItem>
          <ListItem>
            You will make sure that all information which you submit to us is
            truthful, accurate and complete.
          </ListItem>
          <ListItem>
            You will update promptly any of your information you have submitted
            to us as and when it changes.
          </ListItem>
          <ListItem>
            You consent to receiving communications from us electronically,
            including by emails and messages posted to your {appName} account,
            and to the processing of your personal data as more fully detailed
            in our Privacy Policy.
          </ListItem>
          <ListItem>
            You will keep your account/login details confidential and secure,
            including your user details, passwords and any other piece of
            information that forms part of our security procedures, and you will
            not disclose these to anyone else. You will contact{" "}
            {supportEmailAddress} promptly if you believe someone has used or is
            using your account without your permission or if your account has
            been subject to any other breach of security. You also agree to
            ensure that you log out of your account at the end of each session,
            and to be particularly careful when accessing your account from a
            public or shared computer so that others are not able to access,
            view or record your password or other personal information.
          </ListItem>
          <ListItem>
            You are responsible for all activity on your account even if,
            contrary to the Terms of Service, someone else uses your account.
          </ListItem>
          <ListItem>
            You will comply in full with these Terms of Use for all Users, our
            Acceptable Use Policy and all other parts of the Terms of Service
            which apply to your use of {appName}.
          </ListItem>
        </List>
        <ListItem>
          Rights we have, including to suspend or terminate your account:
        </ListItem>
        <List type="a">
          <ListItem>
            We can but we are not obligated to moderate or review any of your
            Content to verify compliance with the Terms of Service (including,
            in particular, our Acceptable Use Policy) and/or any applicable law.
          </ListItem>
          <ListItem>
            It is our policy to suspend access to any Content you post on
            {appName} which we become aware may not comply with the Terms of
            Service (including, in particular, our Acceptable Use Policy) and/or
            any applicable law whilst we investigate the suspected
            non-compliance or unlawfulness of such Content. If we suspend access
            to any of your Content, you may request a review of our decision to
            suspend access to the relevant Content by contacting us at{" "}
            {supportEmailAddress}. Following our investigation of the suspected
            non-compliance or unlawfulness of the relevant Content, we may take
            any action we consider appropriate, including to reinstate access to
            the Content or to permanently remove or disable access to the
            relevant Content without needing to obtain any consent from you and
            without giving you prior notice. You agree that you will at your own
            cost promptly provide to us all reasonable assistance (including by
            providing us with copies of any information which we request) in our
            investigation. We will not be responsible for any loss suffered by
            you arising from the suspension of access to your Content or any
            other steps which we take in good faith to investigate any suspected
            non-compliance or unlawfulness of your Content under this section.
          </ListItem>
          <ListItem>
            If we suspend access to or delete any of your Content, we will
            notify you via email or electronic message to your {appName}
            account, but we are not obligated to give you prior notice of such
            removal or suspension.
          </ListItem>
          <ListItem>
            We reserve the right in our sole discretion to terminate your
            agreement with us and your access to {appName} for any reason by
            giving you 30 days' notice by email or electronic message to your
            {appName} account. We can also suspend access to your User account
            or terminate your agreement with us and your access to {appName}
            immediately and without prior notice:
          </ListItem>
          <UnorderedList>
            <ListItem>
              if we think that you have or may have seriously or repeatedly
              breached any part of the Terms of Service (including in particular
              our Acceptable Use Policy), or if you attempt or threaten to
              breach any part of the Terms of Service in a way which has or
              could have serious consequences for us or another User; or
            </ListItem>
            <ListItem>
              if you take any action that in our opinion has caused or is
              reasonably likely to cause us to suffer a loss or that otherwise
              harms the reputation of {appName}.
            </ListItem>
          </UnorderedList>
          <ExtraText>
            If we suspend access to your User account or terminate your
            agreement with us and your access to {appName} we will let you know.
            During any period when access to your User account is suspended, any
            Fan Payments which would otherwise have fallen due during the period
            of suspension will be suspended, and we may withhold all or any part
            of the Creator Earnings due to you but not yet paid out in
            accordance with section 13 of the Terms of Use for Creators.
          </ExtraText>
          <ListItem>
            Upon termination of your account, we may deal with your Content in
            any appropriate manner in accordance with our Privacy Policy
            (including by deleting it) and you will no longer be entitled to
            access your Content. There is no technical facility on {appName}
            for you to be able to access your Content following termination of
            your account.
          </ListItem>
          <ListItem>
            We can investigate any suspected or alleged misuse, abuse, or
            unlawful use of {appName} and cooperate with law enforcement
            agencies in such investigation.
          </ListItem>
          <ListItem>
            We can disclose any information or records in our possession or
            control about your use of {appName} to law enforcement agencies in
            connection with any law enforcement investigation of any suspected
            or alleged illegal activity, to protect our rights or legal
            interests, or in response to legal process.
          </ListItem>
          <ListItem>
            We can change the third-party payment providers used to process
            payments on {appName} and if we do so, we will notify you and store
            applicable details on your {appName} account.{" "}
          </ListItem>
          <ListItem>
            Other than Content (which is owned by or licensed to Creators), all
            rights in and to {appName} and its entire contents, features,
            databases, source code and functionality, are owned by us and/or our
            licensors. Such material is protected by copyright, and may be
            protected by trademark, trade secret, and other intellectual
            property laws.{" "}
          </ListItem>
          <ListItem>
            We are the sole and exclusive owners of any and all anonymised data
            relating to your use of {appName} and such anonymised data can be
            used by us for any purpose, including for commercial, development
            and research purposes.{" "}
          </ListItem>
        </List>
        <ListItem>
          What we are not responsible for: We will use reasonable care and skill
          in providing {appName} to you, but there are certain things which we
          are not responsible for, as follows:
        </ListItem>
        <List type="a">
          <ListItem>
            We do not authorize or approve Content on {appName}, and views
            expressed by Creators or Fans on {appName} do not necessarily
            represent our views.
          </ListItem>
          <ListItem>
            We do not grant you any rights in relation to Content. Any such
            rights may only be granted to you by Creators.
          </ListItem>
          <ListItem>
            Your Content may be viewed by individuals that recognise your
            identity. We will not in any way be responsible to you if you are
            identified from your Content. While we may, from time to time and in
            our sole discretion, offer certain geofencing or geolocation
            technology on {appName}, you understand and agree that we do not
            guarantee the accuracy or effectiveness of such technology, and you
            will have no claim against us arising from your use of or reliance
            upon any geofencing or geolocation technology on
            {appName}.
          </ListItem>
          <ListItem>
            All Content is created, selected, and provided by Users and not by
            us. We are not responsible for reviewing or moderating Content, and
            we do not select or modify the Content that is stored or transmitted
            via {appName}. We are under no obligation to monitor Content or to
            detect breaches of the Terms of Service (including the Acceptable
            Use Policy).
          </ListItem>
          <ListItem>
            You agree that you have no obligation to follow any suggestions,
            comments, reviews, or instructions received from another User of
            {appName} and that if you choose to do so, you do so entirely at
            your own risk.
          </ListItem>
          <ListItem>
            We make no promises or guarantees of any kind that Creators or
            Referring Users will make a particular sum of money (or any money)
            from their use of {appName} (including the {appName} Referral
            Program).
          </ListItem>
          <ListItem>
            The materials which we make accessible on {appName} for Users are
            for general information only. We make no promises or guarantees
            about the accuracy or otherwise of such materials, or that Users
            will achieve any particular result or outcome from using such
            materials.
          </ListItem>
          <ListItem>
            We do not promise that {appName} is compatible with all devices and
            operating systems. You are responsible for configuring your
            information technology, device, and computer programs to access
            {appName}. You should use your own virus protection software.
          </ListItem>
          <ListItem>
            We are not responsible for the availability of the internet, or any
            errors in your connections, device or other equipment, or software
            that may occur in relation to your use of {appName}.
          </ListItem>
          <ListItem>
            While we try to make sure that {appName} is secure and free from
            bugs and viruses, we cannot promise that it will be and have no
            control over the Content that is supplied by Creators.
          </ListItem>
          <ListItem>
            We are not responsible for any lost, stolen, or compromised User
            accounts, passwords, email accounts, or any resulting unauthorized
            activities or resulting unauthorized payments or withdrawals of
            funds.
          </ListItem>
          <ListItem>
            You acknowledge that once your Content is posted on {appName}, we
            cannot control and will not be responsible to you for the use which
            other Users or third parties make of such Content. You can delete
            your account at any time, but you acknowledge that deleting your
            account will not of itself prevent the circulation of any of your
            Content which may have been recorded by other Users in breach of the
            Terms of Service or by third parties prior to the deletion of your
            account.
          </ListItem>
        </List>
        <ListItem>
          Intellectual property rights – ownership and licenses:
        </ListItem>
        <List type="a">
          <ListItem>
            You confirm that you own all intellectual property rights (examples
            of which are copyright and trademarks) in your Content or that you
            have obtained all necessary rights to your Content which are
            required to grant licenses in respect of your Content to us and to
            other Users. This includes any rights required to engage in the acts
            covered by sub-section 10(b) below in any territory in which{" "}
            {appName} is accessible and, in particular, in the United States of
            America, the United Kingdom and the European Union.
          </ListItem>
          <ListItem>
            You agree to grant us a license under all your Content to perform
            any act restricted by any intellectual property right (including
            copyright) in such Content, for any purpose reasonably related to
            the provision and operation of {appName}. Such acts include to
            reproduce, make available and communicate to the public, display,
            perform, distribute, translate, and create adaptations or derivative
            works of your Content, and otherwise deal in your Content.
          </ListItem>
          <ListItem>
            The license which you grant to us under sub-section 10(b) above is
            perpetual, non-exclusive, worldwide, royalty-free, sublicensable,
            assignable and transferable by us. This means that the license will
            continue even after your agreement with us ends and you stop using{" "}
            {appName}, that we do not have to pay you for the license, and that
            we can grant a sub-license of your Content to someone else or assign
            or transfer the license to someone else. This license will allow us,
            for example, to add stickers, text, and watermarks to your Content,
            to make your Content available to other Users of {appName}, as well
            as to use your Content for other normal operations of {appName}. We
            will never sell your Content to other platforms, though we may sell
            or transfer any license you grant to us in the Terms of Service in
            the event of a sale of our company or its assets to a third party.
          </ListItem>
          <ListItem>
            Whilst we do not own your Content, you grant us the limited right to
            submit notifications of infringement (including of copyright or
            trademark) on your behalf to any third-party website or service that
            hosts or is otherwise dealing in infringing copies of your Content
            without your permission. Although we are not under any obligation to
            do so, we may at any time submit or withdraw any such notification
            to any third-party website or service where we consider it
            appropriate to do so. However, we do not and are under no obligation
            to police infringements of your Content. You agree that if we
            request, you will provide us with all consents and other information
            which we reasonably need to submit notifications of infringement on
            your behalf. Please see our Complaints Policy for how to make a
            complaint about infringement of intellectual property rights.
          </ListItem>
          <ListItem>
            You waive any moral rights which you may have under any applicable
            law to object to derogatory treatment of any Content posted by you
            on {appName}. This waiver does not affect in any way your ownership
            of any intellectual property rights in your Content or the rights
            which you have to prevent your Content from being copied without
            your permission. The waiver is intended to allow us when dealing
            with your Content (as permitted by the license which you give us in
            section 10(b) above) to add watermarks, stickers or text to your
            Content.
          </ListItem>
        </List>
        <ListItem>
          Twitter: Users have the facility to connect an active Twitter account
          to their {appName} account and to share certain Content in the form of
          {appName} posts to Twitter using the share feature. If you use this
          feature, you must fully comply with Twitter's terms of service from
          time to time in respect of any Content shared in this way.
        </ListItem>
        <ListItem>Linking to and from {appName}:</ListItem>
        <List type="a">
          <ListItem>Links to {appName}:</ListItem>
          <UnorderedList>
            <ListItem>
              You may link to the {appName} homepage, provided you do so in a
              way that is fair and legal and does not damage our reputation or
              take advantage of it, but you must not establish a link in such a
              way as to suggest any form of association, approval, or
              endorsement on our part.
            </ListItem>
            <ListItem>
              If you are a Creator, when promoting your Creator account you must
              comply with our Terms of Service and the terms of service of any
              other website where you place a link to or otherwise promote your
              Creator account. When promoting your Creator account, you must not
              impersonate {appName} or give the impression that your Creator
              account is being promoted by us if this is not the case. You must
              not promote your {appName} account by using Google Ads or any
              similar advertising platform or search engine advertising service.
            </ListItem>
          </UnorderedList>
          <ListItem>
            Links from {appName}: If {appName} contains links to other sites and
            resources provided by third parties, these links are provided for
            your convenience only. Such links should not be interpreted as
            approval by us of those linked websites or information you may
            obtain from them. We have no control over the contents of those
            sites or resources and accept no responsibility for them or for any
            loss or damage that may arise from your use of them. If you decide
            to access any of the third-party websites linked to {appName}, you
            do so entirely at your own risk and subject to the terms and
            conditions of use for such websites.
          </ListItem>
          <ListItem>
            Domain Names: In some instances, {appName} may allow Creators to
            register or use domain names that contain the {appName} trademark or
            a confusingly similar term. However, you will not register such a
            domain name, unless:
            <UnorderedList>
              <ListItem>The domain name is registered by the Creator.</ListItem>
              <ListItem>
                The domain name redirects to the Creator's {appName} profile.
                Domain names containing the {appName} trademark or a confusingly
                similar term must not direct to any other website, including
                link aggregators.
              </ListItem>
              <ListItem>
                The Creator obtains prior written permission from {appName} and
                signs a licensing agreement.
              </ListItem>
            </UnorderedList>
            <ExtraText>
              If you would like to register a domain name containing the
              {appName} trademark or a confusingly similar term, please contact{" "}
              {supportEmailAddress}. Failing to comply with these provisions or
              the licensing agreement will be considered a violation of the
              licensing agreement and may result in {appName}
              filing a domain dispute against the registrant.
            </ExtraText>
          </ListItem>
        </List>
        <ListItem>
          How do I delete my account? If you want to delete your {appName}{" "}
          account then you may do so in the 'User Account' section of your
          {appName} account.
        </ListItem>
        <List type="a">
          <ListItem>
            If you are a Fan, the deletion of your account will take place
            within a reasonable time following your request.
          </ListItem>
          <ListItem>
            If you are a Creator, then once you initiate the "delete account"
            process your account will remain open until the last day of your
            Fans' paid Subscription period, following which you will receive
            your final payment and your account will be deleted.
          </ListItem>
          <ListItem>
            If you are both a Fan and a Creator then your account will be
            deleted in two stages (Fan first and then Creator).
          </ListItem>
          <ListItem>
            Once your account has been deleted you won't be charged any further
            amounts or have access to your former {appName} account or its
            Content, and any subscriptions will be deleted and cannot be
            subsequently renewed. You will receive an email confirmation upon
            the successful deletion of your account. Once your account has been
            deleted, we may deal with your Content in any appropriate manner in
            accordance with our Privacy Policy (including by deleting it) and
            you will no longer be entitled to access your Content. There is no
            technical facility on {appName} for you to be able to access your
            Content following termination of your account.{" "}
          </ListItem>
        </List>
        <ListItem>
          Who is responsible for any loss or damage suffered by you?
        </ListItem>
        <List type="a">
          <ListItem>
            Whether you are a consumer or business User: We do not exclude or
            limit in any way our liability to you where it would be unlawful to
            do so. This includes (i) liability for death or personal injury
            caused by our negligence or the negligence of our employees, agents
            or subcontractors, and (ii) fraud or fraudulent misrepresentation.
          </ListItem>
          <ListItem>
            If you are a consumer User: If you are a consumer User, you agree
            that:
          </ListItem>
          <UnorderedList>
            <ListItem>
              We and our subsidiary companies, employees, owners,
              representatives, and agents will not be liable to you for any loss
              of profit, loss of business or revenue, business interruption,
              loss of business opportunity, or loss of anticipated savings
              suffered by you arising from or in connection with your use of
              {appName}.
            </ListItem>
            <ListItem>
              If you are a consumer User and reside in the United States of
              America, our total liability to you for claims arising out of or
              related to your agreement with us shall be limited to USD 250 per
              claim.{" "}
            </ListItem>
          </UnorderedList>
          <ListItem>
            If you are a business User: If you are a business User, you agree
            that:
          </ListItem>
          <UnorderedList>
            <ListItem>
              We and our subsidiary companies, employees, owners,
              representatives, and agents:
            </ListItem>
            <UnorderedList>
              <ListItem>
                exclude (to the extent permitted by law) all implied conditions,
                warranties, representations, or other terms that may apply to
                {appName} or any content on it. This means that if the Terms of
                Service do not expressly include a promise or commitment by us,
                then one cannot be implied by law;
              </ListItem>
              <ListItem>
                are not responsible to you for any loss or damage suffered by
                you that is not a foreseeable result of our breaching the Terms
                of Service or our failing to use reasonable care and skill. Loss
                or damage is foreseeable if either it is obvious that it will
                happen or if, at the time you agreed to the Terms of Service,
                both we and you knew it might happen;
              </ListItem>
              <ListItem>
                won't be liable to you for any loss or damage, whether in
                contract, tort (including negligence), breach of statutory duty,
                or otherwise, even if foreseeable, arising out of or in
                connection with:
              </ListItem>
              <UnorderedList>
                <ListItem>
                  your inability to use {appName} or any of its services,
                  features or programs; or
                </ListItem>
                <ListItem>
                  your use of or reliance on any content (including Content)
                  stored on {appName};
                </ListItem>
              </UnorderedList>
              <ListItem>won't be liable to you for any:</ListItem>
              <List type="a">
                <ListItem>loss of profits;</ListItem>
                <ListItem>loss of sales, business, or revenue;</ListItem>
                <ListItem>business interruption;</ListItem>
                <ListItem>loss of anticipated savings;</ListItem>
                <ListItem>
                  loss of business opportunity, goodwill or reputation;
                </ListItem>
                <ListItem>
                  loss of data or information, including any Content; or
                </ListItem>
                <ListItem>indirect or consequential loss or damage; </ListItem>
              </List>
              <ListItem>
                won't be liable to you for any loss or damage caused by a
                distributed denial-of-service attack, virus, malware,
                ransomware, or other technologically harmful material that may
                infect your computer equipment, computer programs, data, or
                other proprietary material due to your use of {appName} or any
                of its services, features or programs, or due to your
                downloading of any material posted on it, or on any website
                linked to it;
              </ListItem>
              <ListItem>
                won't be liable to you if your Content is copied, distributed,
                reposted elsewhere or its copyright is infringed by another User
                or any third party;
              </ListItem>
              <ListItem>
                won't be liable to you for any disclosure of your identity, or
                any disclosure or publication of your personal information by
                other Users or third parties without your consent (also known as
                "doxing");
              </ListItem>
              <ListItem>
                won't be liable to you for any failure or delay by us in
                complying with any part of the Terms of Service arising from
                events outside our reasonable control. If there is any failure
                or delay by us in complying with any part of the Terms of
                Service arising from an event outside our reasonable control
                then we will contact you as soon as possible to let you know and
                we will take steps to minimise the effect of the delay.
              </ListItem>
            </UnorderedList>
            <ListItem>
              Our total liability to you for any and all claims arising out of
              or related to your agreement with us, whether in contract, tort
              (including negligence), breach of statutory duty, or otherwise
              shall be limited to the greater of:
            </ListItem>
            <UnorderedList>
              <ListItem>
                100% of the total fees paid by you to us in connection with your
                use of {appName}; and
              </ListItem>
              <ListItem>$1,000 USD.</ListItem>
            </UnorderedList>
          </UnorderedList>
        </List>
        <ListItem>General: You agree that:</ListItem>
        <List type="a">
          <ListItem>
            If any aspect of your agreement with us is unenforceable, the rest
            will remain in effect.
          </ListItem>
          <ListItem>
            If we fail to enforce any aspect of your agreement with us, it will
            not be a waiver;
          </ListItem>
          <ListItem>
            We reserve all rights not expressly granted to you.
          </ListItem>
          <ListItem>
            No implied licenses or other rights are granted to you in relation
            to any part of {appName}, save as expressly set out in the Terms of
            Service.
          </ListItem>
          <ListItem>
            Your agreement with us does not give rights to any third parties,
            except that the exclusions and limitations of liability in section
            14 (Who is responsible for any loss or damage suffered by you?) and
            the terms in section 16 ( Terms relating to disputes) may be
            enforced by our subsidiary companies, employees, owners,
            representatives and agents.
          </ListItem>
          <ListItem>
            You cannot transfer your rights or obligations under your agreement
            with us without our prior written consent.
          </ListItem>
          <ListItem>
            Our rights and obligations under your agreement with us can be
            assigned or transferred by us to others. For example, this could
            occur if our ownership changes (as in a merger, acquisition, or sale
            of assets) or by law. In addition, we may choose to delegate the
            performance of any of our obligations under your agreement with us
            to any third party, but we will remain responsible to you for the
            performance of such obligations.
          </ListItem>
          <ListItem>
            The Terms of Service form the entire agreement between us and you
            regarding your access to and use of {appName}, and supersede any and
            all prior oral or written understandings or agreements between us
            and you.
          </ListItem>
        </List>
        <ListItem>
          Terms relating to disputes - the law which applies to your agreement
          with us and where disputes and claims concerning your use of {appName}
          (including those arising from or relating to your agreement with us)
          can be brought:
        </ListItem>
        <List type="a">
          <ListItem>For consumers (Fans):</ListItem>
          <UnorderedList>
            <ListItem>Consumers - Law:</ListItem>
            <UnorderedList>
              <ListItem>
                If you are a consumer, your agreement with us is governed by
                English law and English law will apply to (i) any claim that you
                have arising out of or in connection with your agreement with us
                or your use of {appName}, and (ii) any claim that we have
                against you that arises out of or in connection with your
                agreement with us or your use of {appName} (including, in both
                cases, non-contractual disputes or claims). You will also be
                able to rely on mandatory rules of the law of the country where
                you live.
              </ListItem>
            </UnorderedList>
            <ListItem>Consumers - where claims must be brought:</ListItem>
            <UnorderedList>
              <ListItem>
                If you are a consumer resident in the United States, any claim
                which you have or which we have arising out of or in connection
                with your agreement with us or your use of {appName} (including,
                in both cases, non-contractual disputes or claims) may be
                brought in the courts of the United States or the courts of the
                country where you live.
              </ListItem>
              <ListItem>
                If you are a consumer resident outside of the United States or ,
                any claim which you have or which we have arising out of or in
                connection with your agreement with us or your use of {appName}
                (including, in both cases, non-contractual disputes or claims)
                must be brought in the courts of the United States.
              </ListItem>
            </UnorderedList>
          </UnorderedList>
        </List>
      </List>
    </Container>
  );
}

const Container = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 100%;
  margin: auto;
  margin-bottom: ${(props) => (props.mustAcceptTos ? "0" : "40")}px;
`;

const LogoIcon = styled.img`
  height: 40px;
  width: auto;
  margin: auto auto 10px auto;
  display: block;
  margin-top: ${(props) => (props.mustAcceptTos ? "0" : "30")}px;
`;

const TextContainer = styled.div`
  overflow-y: ${(props) => (props.mustAcceptTos ? "auto" : "unset")};
  overflow-x: ${(props) => (props.mustAcceptTos ? "hidden" : "unset")};
  height: ${(props) => (props.mustAcceptTos ? "500px" : "auto")};
  max-height: ${(props) => (props.mustAcceptTos ? "500px" : "auto")};
  box-shadow: ${(props) =>
    props.mustAcceptTos ? "0px 4px 0px 0px rgba(0, 0, 0, 0.1)" : "none"};
  padding-right: 5%;
  padding-bottom: 32px;

  @media screen and (max-height: 825px) {
    height: ${(props) => (props.mustAcceptTos ? "400px" : "auto")};
    max-height: ${(props) => (props.mustAcceptTos ? "400px" : "auto")};
  }
`;

const Header = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 24px;
`;

const MainHeader = styled.div`
  color: var(--color-normal-text);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35.2px;
  margin-bottom: 18px;
`;

const SubHeader = styled.div`
  color: var(--color-normal-text);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 18px;
  text-transform: uppercase;
`;

const List = styled.ol`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const UnorderedList = styled.ul`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const ListItem = styled.li`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const ExtraText = styled.div`
  margin-top: 20px;
`;

const TextSection = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;
