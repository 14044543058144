import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getUserInfoByUserId } from "../../helpers/firebase";
import { useQuery } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

const Message = ({ message }) => {
  const [user] = useAuthState(auth);
  const history = useHistory();

  const profileQuery = useQuery({
    queryKey: [`message-user-info-${message.uid}`],
    queryFn: () => {
      const currentUser = getAuth().currentUser;
      if (!currentUser) return null;
      return getUserInfoByUserId(message.uid);
    },
  });

  return (
    <div
      className={`chat-bubble ${message.uid === user.uid ? "right" : "left"}`}
    >
      <ChatBubbleRight>
        {message.uid !== user.uid && (
          <ChatProfileIcon
            onClick={() =>
              history.push(
                `/user/${
                  profileQuery.status === "success" &&
                  profileQuery.data &&
                  profileQuery.data.username
                }`
              )
            }
            src={
              profileQuery.status === "success" &&
              profileQuery.data &&
              profileQuery.data.pfp
            }
            alt=""
          />
        )}
        {/*
        {message.uid !== user.uid && (
          <UserName>{message.name}</UserName>
        )}
        */}
        <UserMessage>{message.text}</UserMessage>
      </ChatBubbleRight>
    </div>
  );
};

export default Message;

const ChatBubbleRight = styled.div`
  display: flex;
`;

const ChatProfileIcon = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 6px;
  margin-left: -8px;
  background: white;
`;

const UserMessage = styled.p`
  word-break: auto-phrase;
`;

const UserName = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #1c2c4c;
`;
