import React from "react";
import styled from "styled-components";

export default function CreateVoteConnect() {
  return (
    <>
      <ImageContainer>
        <HeaderImage src="/images/nux/bunny-painting.svg" alt="" />
        <Trophy src="/images/nux/trophy.svg" alt="" />
        <X src="/images/nux/x-bordered-purple.svg" alt="" />
        <Star src="/images/nux/star-white-bordered.svg" alt="" />
        <Dot src="/images/nux/dot.svg" alt="" />
        <Pencil src="/images/nux/pencil.png" alt="" />
        <HollowCirclePurple src="/images/nux/hollow-circle-purple.svg" alt="" />
        <HollowCirclePink src="/images/nux/hollow-circle-pink.svg" alt="" />
      </ImageContainer>
      <Container>
        <Header>Create, vote and connect</Header>
        <Subheader>
          Everyone can create, vote and help influence the direction of the
          story!
        </Subheader>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin: auto;
  width: 90%;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const HeaderImage = styled.img`
  width: 52%;
  height: auto;
  margin: 50px auto 30px auto;

  @media screen and (max-height: 825px) {
    width: 45%;
    margin: 50px auto -20px auto;
  }
`;

const Trophy = styled.img`
  position: absolute;
  width: 30%;
  height: auto;
  right: -6.5%;
  bottom: -18%;
`;

const Star = styled.img`
  position: absolute;
  width: 5%;
  height: auto;
  left: 31%;
  bottom: -14%;
`;

const Pencil = styled.img`
  position: absolute;
  width: 20%;
  height: auto;
  left: -3%;
  top: 21%;
`;

const X = styled.img`
  position: absolute;
  width: 7%;
  height: auto;
  left: 6%;
  top: -14%;
`;

const Dot = styled.img`
  position: absolute;
  width: 4%;
  height: auto;
  left: 13%;
  top: 12%;
`;

const HollowCirclePink = styled.img`
  position: absolute;
  width: 4%;
  height: auto;
  left: 27%;
  top: -42%;
`;

const HollowCirclePurple = styled.img`
  position: absolute;
  width: 8%;
  height: auto;
  right: 5%;
  bottom: 46%;
`;

const Header = styled.div`
  color: white;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-top: 64px;
`;

const Subheader = styled.div`
  color: white;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: 0.2px;
  margin-top: 24px;
`;
