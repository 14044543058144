import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../helpers/authentication";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "antd";
import userModel from "../../lib/firebase/userModel";
import { Avatar } from "antd";
import messageModel from "../../lib/firebase/messageModel";

export default function Convo({ convo }) {
  const history = useHistory();

  const convoPartnerInfoQuery = useQuery({
    queryKey: [`partner-info-${convo.id}`],
    queryFn: async () => {
      if (!currentUser) return null;
      let convoPartnerId;
      if (convo.creator === currentUser.uid) convoPartnerId = convo.subscriber;
      else convoPartnerId = convo.creator;
      const convoPartnerInfo = await userModel.getOneById(convoPartnerId);
      return convoPartnerInfo;
    },
  });

  const lastMessageQuery = useQuery({
    queryKey: [`last-message-${convo.id}`],
    queryFn: async () => {
      if (!currentUser) return null;
      const lastMessage = await messageModel.getMany(
        ["limit", "1"],
        ["convoId", "==", convo.id],
        ["sort", "createdAt", "desc"]
      );
      return lastMessage.length > 0 ? lastMessage[0] : null;
    },
  });

  if (convoPartnerInfoQuery.status !== "success") return;

  return (
    <Container
      onClick={() => {
        console.log("tapped on a conversation");
        history.push(`/messages/${convo.id}`);
      }}
    >
      <Row className="convo-row">
        <Col span={4}>
          <Avatar src={convoPartnerInfoQuery.data.pfp} size={42} />
        </Col>
        <Col span={18}>
          <UsernameSection>
            <Name>{convoPartnerInfoQuery.data.name}</Name>
            <Username>@{convoPartnerInfoQuery.data.username}</Username>
          </UsernameSection>
          <MessageSection>
            <LastMessage>
              {lastMessageQuery.data && lastMessageQuery.data.text}
            </LastMessage>
          </MessageSection>
        </Col>
        <Col span={2}></Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 0.5px solid var(--color-gray-50);
  background: white;

  .convo-row {
    padding: 8px 10px 0px 10px;
  }
`;

const UsernameSection = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MessageSection = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 4px;
`;

const Name = styled.span`
  font-weight: 800;
  font-size: 13px;
`;

const Username = styled.span`
  font-weight: 400;
  color: var(--color-gray-100);
  margin-left: 4px;
  font-size: 13px;
`;

const LastMessage = styled.div`
  font-weight: 500;
  width: 95%;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
