import { QueryClient } from "@tanstack/react-query";

export const _queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15, // 15 minutes
      refetchOnMount: "always",
    },
  },
});
