import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { validateEmail } from "../../helpers/authentication";
import styled from "styled-components";

export default function ForgotPassword({ email = "" }) {
  const [emailSent, setEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const auth = getAuth();

  const sendPasswordReset = async () => {
    setIsSending(true);
    await sendPasswordResetEmail(auth, email);
    setIsSending(false);
    setEmailSent(true);
  };

  return (
    <Container>
      {!emailSent ? (
        <div>
          <WelcomeHeader>Reset your password</WelcomeHeader>
          <LoginHint>
            Enter the email you used to sign in and we will send you a link to
            reset your password
          </LoginHint>
          <div>
            <InputHeader>Email</InputHeader>
            <InputContainer>
              <InputFieldDisabled
                className="transition-colors"
                placeholder="email@email.com"
                value={email}
                disabled
              />
              <Checkmark src="/icons/checkmark.svg" />
            </InputContainer>
            <SubmitButtons>
              {!isSending ? (
                validateEmail(email) && email !== "" ? (
                  <SubmitButton onClick={sendPasswordReset}>
                    Submit
                  </SubmitButton>
                ) : (
                  <SubmitButtonDisabled disabled>Submit</SubmitButtonDisabled>
                )
              ) : (
                <SendingButton disabled>
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                </SendingButton>
              )}
            </SubmitButtons>
          </div>
        </div>
      ) : (
        <>
          <LoginHint>Thanks! We've sent an email to {email}.</LoginHint>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 30px;
  width: 90%;
`;

const Checkmark = styled.img`
  right: 14px !important;
  top: 35%;
  height: 12px;
  position: absolute;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputFieldDisabled = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--seafoam);
  margin: auto;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const WelcomeHeader = styled.div`
  font-size: 28px;
  font-weight: 800;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const LoginHint = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 30px;
`;

const InputHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-align: left;
  color: var(--color-gray-50);
  margin-bottom: 12px;
`;

const SubmitButtons = styled.h4`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 0;
  text-align: left;
  margin: auto;
  text-align: center;
  margin-top: 36px;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  color: var(--cream);
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const SubmitButtonDisabled = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  line-height: 16px;
  letter-spacing: 0em;
`;

const SendingButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  line-height: 16px;
  letter-spacing: 0em;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;
