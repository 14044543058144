import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { queryMany, queryOne } from "./all";
import { messageTable } from "../../helpers/constants";

const table = messageTable;

const messageModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });
    return data;
  },
  update: async ({ id, data }) => {
    const userRef = doc(getFirestore(), table, id);
    await updateDoc(userRef, data);
    return await queryOne({ table, id });
  },
};

export default messageModel;
