// ----- Components Import -----
import { styled } from "styled-components";
import { Col, Row } from "antd";
import { Container, SectionHeading } from "../styles/CommonElements";

// ----- Assets Import -----
import featuresData from "../../data/featuresData";

export default function Features() {
  return (
    <FeaturesWrapper>
      <Container>
        <SectionHeading secondary>
          Build your perfect content creator
        </SectionHeading>
        <Row
          gutter={[
            { xs: 30, md: 30 },
            { xs: 40, sm: 40 },
          ]}
        >
          {featuresData.map((item) => (
            <Col sm={24} md={8} key={item.id}>
              <FeatureItem>
                <ItemIcon className="feature-icon">
                  <img src={item.icon} alt={item.title} />
                </ItemIcon>
                <ItemInfo>
                  <h4>{item.title}</h4>
                  <p>{item.desc}</p>
                </ItemInfo>
              </FeatureItem>
            </Col>
          ))}
        </Row>
      </Container>
    </FeaturesWrapper>
  );
}

// ----- Styles -----
const FeaturesWrapper = styled.section`
  padding: 4rem 0;
  background: var(--color-primary-light);

  h2 {
    max-width: 61.5rem;
    text-align: center;
    margin: 0 auto 3rem auto;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;

  @media (max-width: 35.9375rem) {
    max-width: 19.5rem;
  }

  &.ant-col:last-child {
    display: none;
  }
`;

const ItemIcon = styled.div`
  flex: 2;
  /* width: 150px;
  @media (max-width: 47.9375rem) {
    min-width: 70px;
    max-width: 70px;
  } */

  img {
    width: 100%;
  }
`;

const ItemInfo = styled.div`
  flex: 5;
  h4 {
    font-size: 2rem;
    color: black;
    margin-bottom: 0.5rem;
    font-weight: 800;

    @media (max-width: 35.9375rem) {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.37rem;
    color: black;
  }
`;
