import React, { useState } from "react";
import { styled } from "styled-components";
import SinglePost from "../components/posts/SinglePost";

function SelfEmptyState() {
  return (
    <EmptyWrapper>
      <EmptyText>No Posts</EmptyText>
    </EmptyWrapper>
  );
}

export default function ProfileArtTab({
  query,
  isSelf,
  isSubscribed,
  setIsSubscribed,
}) {
  return (
    <Container>
      {query.status === "success" && query.data
        ? query.data
            .filter(function (e) {
              return e.text !== "";
            })
            .map((post) => (
              <SubmissionCard
                key={post.id}
                submission={post}
                isSelf={isSelf}
                isSubscribed={isSubscribed}
                setIsSubscribed={setIsSubscribed}
              />
            ))
        : Array.from({ length: 8 }).map((_, i) => (
            <Submission key={i} className="animate-pulse" />
          ))}

      {query.status === "success" && query.data.length === 0 && (
        <div style={{ gridColumn: "1 / -1", textAlign: "center" }}>
          <EmptyText>{isSelf ? <SelfEmptyState /> : "No posts yet"}</EmptyText>
        </div>
      )}
    </Container>
  );
}

function SubmissionCard({ submission, isSubscribed, setIsSubscribed, isSelf }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onLikeClick = (event) => {
    event.stopPropagation();
  };

  return (
    <SinglePost
      post={submission}
      isSubscribed={isSubscribed || isSelf}
      setIsSubscribed={setIsSubscribed}
    />
  );
}

const LikeButtonContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  border-radius: 50%;
`;

// grid-template-columns: repeat(2, minmax(0, 1fr));
// gap: 14px;

const Container = styled.div`
  display: grid;
  padding-bottom: 20px;
`;

// clip-path: inset(0 round 8px);

const Submission = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(--color-gray-200);
  position: relative;
  margin-bottom: 30px;
`;

const SubmissionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
`;

const EmptyText = styled.p`
  color: var(--color-gray-300);
  font-size: 16px;
  line-height: 160%;
  margin-top: 16px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 7px;

  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
`;

const LinkText = styled.span`
  color: #f5a2ff;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: #f385ff;
    text-decoration: underline;
  }
`;
