import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getLikesForSubmission,
  likeSubmission,
  unlikeSubmission,
} from "../helpers/firebase";
import { currentUser } from "../helpers/authentication";
import party from "party-js";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { employeeIds } from "../helpers/employees";

export default function LikeButton({
  likeSubject = null,
  onClick = null,
  location = null,
}) {
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [likeId, setLikeId] = useState(null);

  let heartRef = React.createRef();
  let disabledButtonRef = React.createRef();

  party.resolvableShapes["heart"] = `<img src="/icons/heart-pink.svg" />`;

  useEffect(() => {
    async function fetchData() {
      const likeDataPromise = await getLikesForSubmission(likeSubject);
      setLikes(likeDataPromise.length);
      const myLike = likeDataPromise.filter(function (e) {
        return e.creator === currentUser.uid;
      });
      if (myLike.length > 0) {
        setIsLiked(true);
        setLikeId(myLike[0].id);
      } else {
        setIsLiked(false);
        setLikeId(null);
      }
    }
    if (likeSubject) fetchData();
  }, [likeSubject]);

  const likeOrUnlike = async (event) => {
    onClick && onClick(event);
    if (isLiked) {
      setIsLiked(false);
      setLikes(likes - 1);
      await unlikeSubmission({ likeId });
      setLikeId(null);
      mixpanel.track("Unliked User Submission", {
        submission_id: likeSubject.id,
        location,
      });
    } else if (!isLiked) {
      releaseParticles(heartRef);
      setIsLiked(true);
      setLikes(likes + 1);
      const id = await likeSubmission(likeSubject);
      setLikeId(id);
      if (likeSubject) {
        if (currentUser && !employeeIds.includes(currentUser.uid)) {
          mixpanel.track("Liked User Submission", {
            submission_id: likeSubject.id,
            location,
          });
        }
      }
    }
  };

  const releaseParticles = (ref) => {
    party.sparkles(ref.current, {
      count: party.variation.range(10, 15),
      size: 0.7,
      speed: 70,
      lifetime: 1,
      shapes: ["heart"],
    });
  };

  return (
    <Container>
      {!likeSubject ? (
        <ClickButton
          ref={disabledButtonRef}
          onClick={() => {
            releaseParticles(disabledButtonRef);
          }}
        >
          <HeartFilled src="/icons/heart-pink.svg" alt="" />
        </ClickButton>
      ) : (
        <ClickButton
          onClick={likeOrUnlike}
          className={`${isLiked ? "like-button-liked" : ""} ${
            likes === 0 ? "like-button-no-likes" : ""
          }`}
          ref={heartRef}
        >
          {isLiked ? (
            <HeartFilled src={"/icons/heart-pink.svg"} alt="" />
          ) : (
            <HeartOutlined src={"/icons/heart-outlined.svg"} alt="" />
          )}
          {likes > 0 && <LikeAmount isLiked={isLiked}>{likes}</LikeAmount>}
        </ClickButton>
      )}
    </Container>
  );
}

const Container = styled.span`
  .like-button-liked {
    color: var(--color-red) !important;
    height: 32px;
    border: 0px;
    background: transparent;
  }

  .like-button-liked:hover {
    color: var(--color-red) !important;
  }

  .like-button-no-likes {
    color: white !important;
  }

  .like-button-no-likes:hover {
    color: white !important;
  }

  .like-button-no-likes:focus {
    color: white !important;
  }
`;

const ClickButton = styled.button`
  width: auto;
  height: 32px;
  border-radius: 65px;
  padding: 0px 10px;
  background: transparent;
  color: var(--color-cream-base);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.895px;
  border: 0;

  &:disabled {
    background: transparent;
    color: var(--hoverColor);
  }

  &:active {
    background: transparent;
    color: var(--color-cream-base);
  }

  &:hover {
    background: transparent;
    color: var(--color-cream-base);
  }

  &::selection {
    background: transparent;
    color: var(--color-cream-base);
  }

  &:focus {
    background: transparent;
    color: var(--color-cream-base);
  }
`;

const HeartFilled = styled.img`
  height: 14px;
  vertical-align: middle;
  width: auto;
  margin: auto;
  margin-top: -2px;
  margin-left: -1px;
`;

const HeartOutlined = styled.img`
  height: 17px;
  width: auto;
  vertical-align: middle;
  margin: auto;
  margin-top: -2px;
`;

const LikeAmount = styled.span`
  margin-left: 4px;
  padding-bottom: 4px;
  font-weight: ${(props) => (props.isLiked ? 600 : 400)};
  color: ${(props) =>
    props.isLiked ? "var(--color-red)" : "var(--color-gray-100)"};
`;
