import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import ProfileMenuButton from "./ProfileMenuButton";
import ProfileLikedTab from "./ProfileLikedTab";
import { TabItem } from "../components/TabItem";
import { useQuery } from "@tanstack/react-query";
import submissionModel from "../lib/firebase/submissionModel";
import userModel from "../lib/firebase/userModel";
import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUsernameFromUrl, sleep } from "../helpers/general";
import pluralize from "pluralize";
import {
  getPostCountByUserId,
  getUserInfoByUsername,
} from "../helpers/firebase";
import { ReadMore } from "../components/general";
import { SubscriptionSection } from "../components/profile";
import ProfileArtTab from "./ProfileArtTab";
import { getAuth } from "firebase/auth";
import postsModel from "../lib/firebase/postsModel";
import subscriptionModel from "../lib/firebase/subscriptionModel";
import { currentUser } from "../helpers/authentication";

export default function UserProfile() {
  const { id: userId } = useParams();
  const [tab, setTab] = useState("posts");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const userQuery = useQuery({
    queryKey: ["user", getUsernameFromUrl()],
    queryFn: () => getUserInfoByUsername(getUsernameFromUrl()),
  });

  const likedQuery = useQuery({
    queryKey: ["user-liked", userId],
    queryFn: () => submissionModel.getManyLikedByUserId(userId),
  });

  const postCountQuery = useQuery({
    queryKey: ["user-posts-count", userId],
    queryFn: async () => {
      const userData = await getUserInfoByUsername(getUsernameFromUrl());
      return getPostCountByUserId(userData.id);
    },
  });

  const postQuery = useQuery({
    queryKey: ["user-posts", userId],
    queryFn: async () => {
      const myUser = getAuth().currentUser;
      console.log("waiting");
      await sleep(2);
      if (!myUser) return null;
      console.log("user query data");
      const userData = await getUserInfoByUsername(getUsernameFromUrl());
      return postsModel.getManyByUserId(userData.id, [
        "sort",
        "createdAt",
        "desc",
      ]);
    },
  });

  const subscriptionQuery = useQuery({
    queryKey: [`subscription-${getUsernameFromUrl()}`],
    queryFn: async () => {
      const currentUser = getAuth().currentUser;
      if (!currentUser) return null;
      const userData = await getUserInfoByUsername(getUsernameFromUrl());
      return subscriptionModel.getMany(
        ["limit", "1"],
        ["creator", "==", userData.id],
        ["subscriber", "==", currentUser.uid]
      );
    },
  });

  useEffect(() => {
    if (subscriptionQuery.status === "success") {
      if (subscriptionQuery.data.length > 0) setIsSubscribed(true);
      else setIsSubscribed(false);
    }
  }, [subscriptionQuery.status, subscriptionQuery.data]);

  useEffect(() => {
    if (currentUser) mixpanel.track("Visited user profile");
  }, []);

  if (userQuery.status !== "success") return;

  return (
    <Container>
      <InfoContainer>
        <ActionsOverlay></ActionsOverlay>
        <ProfileImageSection>
          {userQuery.status === "success" ? (
            <ProfileImg
              alt=""
              src={
                userQuery.data && userQuery.data.pfp ? userQuery.data.pfp : null
              }
            />
          ) : (
            <LoadingImg className="animate-pulse" />
          )}
        </ProfileImageSection>

        {userQuery.status === "success" && userQuery.data.name ? (
          <ProfileTitle>{userQuery.data.name}</ProfileTitle>
        ) : (
          <LoadingTitle className="animate-pulse" />
        )}

        {userQuery.status === "success" ? (
          <UsernameTitle>@{userQuery.data.username}</UsernameTitle>
        ) : (
          <LoadingTitle className="animate-pulse" />
        )}

        {userQuery.status === "success" && userQuery.data.bio && (
          <ProfileBio>
            <ReadMore text={userQuery.data.bio} threshold={50} />
          </ProfileBio>
        )}

        <ProfileMenuButton userId={userQuery.data.id} />
      </InfoContainer>

      {userQuery.status === "success" ? (
        <SubscriptionSection
          creator={userQuery.data}
          setIsSubscribed={setIsSubscribed}
          isSubscribed={isSubscribed}
          subscriptionQuery={subscriptionQuery}
        />
      ) : (
        <LoadingTitle className="animate-pulse" />
      )}

      <TabContainer>
        <TabItem
          layoutId="profile"
          isActive={tab === "posts"}
          onClick={() => setTab("posts")}
        >
          {postQuery.status === "success"
            ? pluralize(
                "Post",
                postQuery.status === "success" &&
                  postQuery.data &&
                  postQuery.data.filter(function (e) {
                    return e.text !== "";
                  }).length,
                true
              )
            : "Posts"}
        </TabItem>

        <TabItem
          layoutId="profile"
          isActive={tab === "media"}
          onClick={() => setTab("media")}
        >
          {postQuery.status === "success" &&
            postQuery.data &&
            postQuery.data.filter(function (e) {
              return e.text === "";
            }).length}{" "}
          Media
        </TabItem>
      </TabContainer>

      <MainContainer>
        {tab === "posts" && (
          <ProfileArtTab
            query={postQuery}
            isSubscribed={isSubscribed}
            setIsSubscribed={setIsSubscribed}
          />
        )}
        {tab === "media" && (
          <ProfileLikedTab
            query={postQuery}
            isSubscribed={isSubscribed}
            setIsSubscribed={setIsSubscribed}
          />
        )}
      </MainContainer>
    </Container>
  );
}

const Container = styled.div``;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 230px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  text-align: left;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: white;
  object-fit: cover;
`;

const ProfileImageSection = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: white;
`;

const LoadingImg = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: var(--color-gray-50);
`;

const ProfileTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 18px;
  margin-bottom: 0px;
  color: black;
  margin-left: 6px;
`;

const UsernameTitle = styled.p`
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0px;
  color: gray;
  margin-left: 6px;
`;

const LoadingTitle = styled.div`
  width: 20ch;
  height: 20px;
  background-color: var(--color-gray-50);
  margin-top: 16px;
  border-radius: 10px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileBio = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: 0px;
`;

const ActionsOverlay = styled.div`
  position: absolute;
  top: 8px;
  left: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
  width: fit-content;
  margin: auto;
`;
