// ----- Components Import -----
import { styled } from "styled-components";
import { Button, Container, SectionHeading } from "../styles/CommonElements";

export default function Stories() {
  return (
    <StoriesWrapper>
      <Container>
        <StoriesContent>
          <SectionHeading primary>
            Photorealistic models,
            <br />
            Incredible art.
          </SectionHeading>
          <StoriesAction></StoriesAction>
        </StoriesContent>
      </Container>
    </StoriesWrapper>
  );
}

// ----- Styles -----
const StoriesWrapper = styled.section`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 6rem 0;

  @media (min-width: 106.25rem) {
    padding: 10rem 0;
  }

  @media (max-width: 35.9375rem) {
    padding-top: 18rem;
  }

  @media (max-width: 24.375rem) {
    padding-top: 10rem;
  }
`;

const StoriesContent = styled.div`
  h2 {
    margin-bottom: 1.5rem;
    color: #574d9b;

    @media (max-width: 47.9375rem) {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
`;

const StoriesAction = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 47.9375rem) {
    justify-content: center;
  }

  img {
    max-width: 5.62rem;
    display: inline-block;
    margin-right: -1.5rem;
    position: relative;
    z-index: 10;
  }

  a {
    background-color: #796ec4;
  }
`;
