import {
  collection,
  getDocs,
  getFirestore,
  where,
  query,
  doc,
  getDoc,
  limit,
  orderBy,
  documentId,
  getCountFromServer,
} from "firebase/firestore";

export function timestampToDate(timestamp) {
  return new Date(timestamp.seconds * 1000);
}

export function parseDoc(docSnapshot) {
  const data = docSnapshot.data();
  const createdAt = timestampToDate(docSnapshot._document.createTime.timestamp);
  if (data.createdAt) delete data.createdAt;

  return {
    id: docSnapshot.id,
    createdAt,
    ...data,
  };
}

export async function queryMany({ table, conditions = [] }) {
  let q = collection(getFirestore(), table);

  for (const condition of conditions) {
    if (condition[0] === "limit") {
      q = query(q, limit(condition[1]));
    } else if (condition[0] === "sort") {
      q = query(q, orderBy(condition[1], condition[2]));
    } else {
      q = query(q, where(...condition));
    }
  }

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(parseDoc);
}

export async function count({ table, conditions = [] }) {
  let q = collection(getFirestore(), table);

  for (const condition of conditions) {
    q = query(q, where(...condition));
  }

  const querySnapshot = await getCountFromServer(q);
  return querySnapshot.data().count;
}

export async function queryOne({ table, id }) {
  if (!id || !table) throw new Error("Missing table or id - queryMany");

  const q = doc(getFirestore(), table, id);
  const docSnapshot = await getDoc(q);
  if (!docSnapshot.exists()) return null;

  return parseDoc(docSnapshot);
}

export async function batchQuery({ table, fieldName = documentId(), ids }) {
  const BATCH_SIZE = 30;
  const allResults = [];

  for (let i = 0; i < ids.length; i += BATCH_SIZE) {
    const batchIds = ids.slice(i, i + BATCH_SIZE);
    if (batchIds.length === 0) break;
    const q = query(
      collection(getFirestore(), table),
      where(fieldName, "in", batchIds)
    );

    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map(parseDoc);
    allResults.push(...results);
  }

  return allResults;
}
