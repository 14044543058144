import React, { useState } from "react";
import styled from "styled-components";
import { PostEditor } from "../components";
import ImageGeneratorSheet from "../components/imageGenerator/ImageGeneratorSheet";
import { createPost, uploadImage } from "../helpers/firebase";
import { message } from "antd";
import mixpanel from "mixpanel-browser";
import { getFileFromUrl, isOnMobile } from "../helpers/general";
import ImageGeneratorModal from "../components/imageGenerator/ImageGeneratorModal";
import { ConsistencyModal } from "../components/imageGenerator";

export default function Post() {
  const [isOpen, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [isPublic, setPublic] = useState(false);
  const [isInfoOpen, setInfoOpen] = useState(false);

  let editorRef = React.createRef();

  const startCreatingPost = async () => {
    setSubmitButtonDisabled(true);
    const blob = await getFileFromUrl(imageUrl);
    const newImageUrl = await uploadImage("content", blob);
    const result = await createPost({
      text,
      imageUrl: newImageUrl,
      isPublic,
    });
    if (result) {
      message.success("Content posted successfully");
      setText("");
      setImageUrl("");
      mixpanel.track("Posted content", { hasText: text !== "" });
    } else {
      message.error("Please try again later");
      mixpanel.track("Failed to post content", { hasText: text !== "" });
    }
    setSubmitButtonDisabled(false);
  };

  return (
    <Container>
      <PostEditor
        setOpen={setOpen}
        imageUrl={imageUrl}
        text={text}
        setText={setText}
        editorRef={editorRef}
        isPublic={isPublic}
        setPublic={setPublic}
      />
      <SubmitButtonContainer>
        <SubmitButton
          disabled={(text === "" && imageUrl === "") || submitButtonDisabled}
          onClick={(e) => {
            startCreatingPost();
          }}
        >
          Create Post
        </SubmitButton>
      </SubmitButtonContainer>
      <KeepConsistency onClick={() => setInfoOpen(true)}>
        How can I create consistent content?
      </KeepConsistency>
      <ConsistencyModal isOpen={isInfoOpen} setOpen={setInfoOpen} />
      {isOnMobile() ? (
        <ImageGeneratorSheet
          setImageUrl={setImageUrl}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      ) : (
        <ImageGeneratorModal
          setImageUrl={setImageUrl}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 100px;
  border-right: none !important;
  text-align: left;
`;

const SubmitButtonContainer = styled.div`
  height: 100%;
  width: 90%;
  margin: auto;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: var(--blueHeader);
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #fef6f3;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }
`;

const KeepConsistency = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  margin-top: 22px;
  text-align: center;
  margin-bottom: 80px;
  color: var(--blueHeader) !important;
`;
