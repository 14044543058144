import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ImageBanner = ({ images, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div style={{ width: "auto", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <GridImage src={image} alt="" />
        ))}
      </div>
    </div>
  );
};

export default ImageBanner;

const GridImage = styled.img`
  width: 100%;
  height: auto;
`;
