import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";
import { containsProfanity, getComments, isOniOS } from "../../helpers/general";
import { submitComment, editPostedComment } from "../../helpers/firebase";
import { message } from "antd";
import CommentSection from "./CommentSection";
import TextareaAutosize from "react-textarea-autosize";
import userModel from "../../lib/firebase/userModel";
import likeModel from "../../lib/firebase/likeModel";
import {
  commentLengthMax,
  commentLengthThreshold,
} from "../../helpers/constants";

const postButtonWidth = 70;
const postButtonLeftMargin = 4;

export default function CommentSheet({
  isOpen,
  setOpen,
  comments,
  setComments,
  backLocations,
  setBackLocations,
  subjectId,
}) {
  let containerRef = React.createRef();
  let commentInputRef = React.createRef();
  let footerRef = React.createRef();

  const [isFocused, setIsFocused] = useState(false);
  const [comment, setComment] = useState("");
  const [commentOk, setCommentOk] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [replyToUsername, setReplyToUsername] = useState("");
  const [editComment, setEditComment] = useState(null);

  useEffect(() => {
    if (editComment) {
      console.log(editComment);
      setComment(editComment.comment);
      setReplyTo(null);
      setReplyToUsername("");
    }
  }, [editComment]);

  useEffect(() => {
    setEditComment(null);
    setComment("");
  }, [comments]);

  useEffect(() => {
    async function fetchData() {
      await Promise.all(
        comments.map(async (c) => {
          const likeCount = await likeModel.count(["submissionId", "==", c.id]);
          c.likeCount = likeCount;
        })
      );
      const sortedComments = comments.sort(function (a, b) {
        return b.likeCount - a.likeCount || b.createdAt - a.createdAt;
      });

      setComments(sortedComments);
    }

    fetchData();
  }, [comments, setComments]);

  useEffect(() => {
    if (isOpen) {
      setEditComment(null);
      setComment("");
      setReplyTo(null);
      setReplyToUsername("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (comment.length >= commentLengthThreshold && !containsProfanity(comment))
      setCommentOk(true);
    else setCommentOk(false);
  }, [comment]);

  useEffect(() => {
    async function fetchData() {
      // console.log(replyTo);
      if (replyTo) {
        const replyToUser = await userModel.getOneById(replyTo.creator);
        console.log(replyToUser);
        setReplyToUsername(replyToUser.username);
        setComment("");
      }
    }

    fetchData();
  }, [replyTo, comment.creator]);

  const startCommentSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsSending(true);
    let result;
    if (replyTo) {
      const newComment = comment.replace(`@${replyToUsername} `, "");
      result = await submitComment({ comment: newComment, subjectId, replyTo });
      setReplyTo(null);
      setReplyToUsername("");
      setComment("");
    } else {
      result = await submitComment({ comment, subjectId, replyTo });
    }
    if (result) {
      message.success("Comment submitted. Thank you!");
      setReplyTo(null);
      setReplyToUsername("");
      setComment("");
      console.log(result);
      setComments(await getComments(subjectId));
    } else message.error("Comment was not submitted. Please try again.");
    setIsSending(false);
  };

  const startEditCommentSubmit = async () => {
    setIsSending(true);
    console.log(editComment);
    const result = await editPostedComment({
      editedComment: editComment,
      comment,
    });
    if (result) {
      message.success("Edited comment successfully");
      setComment("");
      console.log(result);
      setComments(await getComments(subjectId));
    } else message.error("Comment was not edited. Please try again.");
    setIsSending(false);
  };

  return (
    <Container>
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[675]}
        style={{
          pointerEvents: isOpen ? "auto" : "none",
          background: isOpen ? "rgba(0,0,0,0.5)" : null,
          zIndex: 999,
        }}
        onClick={(e) => {
          if (isOpen) setOpen(false);
        }}
        tweenConfig={{ ease: "easeOut", duration: 0.25 }}
        className="contributor-sheet"
      >
        <Sheet.Container
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="contributor-sheet-container"
        >
          <Sheet.Header></Sheet.Header>
          <Sheet.Content
            className="contributor-sheet-content"
            style={{ overflow: "scroll" }}
          >
            <Footer
              isFocused={isFocused}
              ref={footerRef}
              className="the_footer"
              editComment={editComment}
            >
              {editComment && (
                <EditCommentSection>
                  <StopEditButton
                    src="/icons/x-icon-black.svg"
                    alt=""
                    onClick={(e) => {
                      setEditComment(null);
                      setComment("");
                    }}
                  />
                  Editing comment
                </EditCommentSection>
              )}
              <AddCommentSection>
                <TextareaAutosize
                  maxRows="4"
                  className="autosize-textarea"
                  placeholder="Add a comment"
                  maxLength={commentLengthMax}
                  ref={commentInputRef}
                  value={`${
                    replyTo !== null && replyToUsername !== ""
                      ? `@${replyToUsername} `
                      : ""
                  }${comment}`}
                  onChange={(e) => {
                    let newComment = e.target.value;
                    newComment = newComment.replace(`@${replyToUsername} `, "");
                    setComment(newComment);
                  }}
                  onFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsFocused(true);
                  }}
                  onBlur={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsFocused(false);
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <PostButton
                  disabled={!commentOk || isSending}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (editComment) {
                      startEditCommentSubmit();
                    } else {
                      startCommentSubmit(e);
                    }
                  }}
                  className="post-button"
                >
                  Post
                </PostButton>
              </AddCommentSection>
            </Footer>
            <InnerContainer>
              {comments.length > 0 ? (
                <CommentsContainer>
                  <CommentSection
                    comments={comments}
                    setComments={setComments}
                    backLocations={backLocations}
                    setBackLocations={setBackLocations}
                    setEditComment={setEditComment}
                    commentInputRef={commentInputRef}
                    setReplyTo={setReplyTo}
                  />
                </CommentsContainer>
              ) : (
                <InnerContainer>
                  <EmptyHeader>No comments yet</EmptyHeader>
                </InnerContainer>
              )}
            </InnerContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </Container>
  );
}

const Container = styled.span`
  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }

  .comment-sheet-content {
    z-index: 100;
  }
`;

const CommentsContainer = styled.div`
  margin-top: 0px;
  overflow: auto;
`;

const InnerContainer = styled.div`
  margin-top: 14px;
`;

const HeaderContainer = styled.div`
  position: relative;
  border-bottom: 0.5px solid #35383f;
  padding-bottom: 16px;
`;

const Footer = styled.div`
  width: 95%;
  margin: 0px auto;
  padding-bottom: 10px;
  text-align: center;
  bottom: 0px;
  bottom: ${(props) => (props.isFocused && isOniOS() ? "295" : "0")}px;
`;

const CloseButton = styled.img`
  width: 16px;
  height: 16.597px;
  flex-shrink: 0;
  display: inline-block;
  margin: auto;
  right: 10px;
  opacity: 0.4;
  position: absolute;
  top: 6px;
`;

const EmptyHeader = styled.div`
  color: var(--color-gray-200);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 24px;
`;

const EmptySubheader = styled.div`
  color: var(--color-gray);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 6px;
`;

const StopEditButton = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

const EditCommentSection = styled.div`
  margin: auto;
  text-align: left;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 4px 20px;
`;

const AddCommentSection = styled.div`
  text-align: center;
  width: 100%;
  display: inline-flex;
  position: relative;
  padding: 12px 4px 0px 4px;

  .autosize-textarea {
    width: calc(87.5% - ${postButtonWidth}px - ${postButtonLeftMargin}px);
    border-radius: 20px;
    padding: 10px 20px;
    outline: 0px;
    resize: none;
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    border: 1px solid var(--color-gray-50);

    &::placeholder {
      color: var(--color-gray);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.2px;
    }

    &::focus-visible {
      outline: 0px;
      margin-bottom: calc(
        100% + 500px
      ) !important; /* add padding for keyboard */
    }

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    &::placeholder {
      opacity: 0.5;
      color: var(--color-gray-100);
    }
  }
`;

const PostButton = styled.button`
  width: ${postButtonWidth}px;
  height: 38px;
  border-radius: 20px;
  border: 0px !important;
  outline: 0px;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? "var(--color-gray-300)" : "var(--blurple)"};
  color: ${(props) =>
    props.disabled ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  margin-left: ${postButtonLeftMargin}px};

  &::placeholder {
    color: var(--color-gray);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  &::focus-visible {
    outline: 0px;
  }
`;
