import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDebounce } from "../helpers/useDebounce";
import styled from "styled-components";
import { generateRandomId, usernameExists } from "../helpers/firebase";
import { getAuth } from "firebase/auth";
import InputField from "../components/InputField";
import { DeleteUserModal } from "../components/profile";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { containsProfanity, removeIllegalCharacters } from "../helpers/general";
import { forbiddenUsernames, profanityErrorText } from "../helpers/constants";
// import { getUserById, updateUser } from "../lib/firebase/user";
import userModel from "../lib/firebase/userModel";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export default function EditProfile() {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const userQuery = useQuery({
    queryKey: ["user-self"],
    queryFn: () => {
      const user = getAuth().currentUser;
      if (!user) return null;
      return userModel.getOneById(user.uid);
    },
  });

  if (!userQuery.data || userQuery.status !== "success") return null;

  return (
    <>
      <DeleteUserModal
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
        userName={userQuery.data.username}
      />

      <Content
        user={userQuery.data}
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
      />

      <DeleteUserButton
        onClick={(e) => {
          setIsDeleteOpen(true);
        }}
      >
        Delete account
      </DeleteUserButton>
    </>
  );
}

const validateUsername = async (name) => {
  if (!/^[a-z0-9._-]+$/.test(name))
    throw new Error("Username must contain only lowercase letters and numbers");
  if (
    containsProfanity(name) ||
    forbiddenUsernames.includes(name.toLowerCase())
  )
    throw new Error(profanityErrorText);
  if (await usernameExists(name))
    throw new Error("That username is taken. Try a different one.");
};

function Content({ user }) {
  const [name, setName] = useState(user.name || "");
  const [bio, setBio] = useState(user.bio || "");
  const [profilePic, setProfilePic] = useState(user.pfp);
  const history = useHistory();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const [username, setUsername] = useState(user.username);
  const usernameDebounced = useDebounce(username, 500);

  const usernameQuery = useQuery({
    queryKey: ["username", usernameDebounced],
    queryFn: async () => {
      const name = usernameDebounced;
      console.log("Checking for validity");
      if (name.length < 3) return null;
      if (name === user.username) return null;
      await validateUsername(name);
      return true;
    },
    retry: false,
    retryDelay: 0,
  });

  const bioQuery = useQuery({
    queryKey: ["bio", bio],
    queryFn: async () => {
      if (bio.length > 100)
        throw new Error("Bio cannot be more than 100 characters");
      if (containsProfanity(bio)) throw new Error(profanityErrorText);
      return true;
    },
    retry: false,
    retryDelay: 0,
  });

  const nameQuery = useQuery({
    queryKey: ["name", name],
    queryFn: async () => {
      if (name.length > 100)
        throw new Error("Name cannot be more than 100 characters");
      if (containsProfanity(name)) throw new Error(profanityErrorText);
      return true;
    },
    retry: false,
    retryDelay: 0,
  });

  const editMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        name,
        username,
        bio: bio.length ? bio : null,
      };

      if (typeof profilePic !== "string" && profilePic) {
        const storageRef = ref(
          getStorage(),
          `profileImage/${user.id}/${generateRandomId()}`
        );
        const snapshot = await uploadBytes(storageRef, profilePic);
        const url = await getDownloadURL(snapshot.ref);
        payload.pfp = url;
      }

      return await userModel.update({ id: user.id, data: payload });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["user-self"]);
      history.push("/profile");
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (editMutation.status === "loading") return;
    editMutation.mutate();
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    history.push("/profile/change-password");
  };

  const onCancel = () => {
    history.goBack();
  };

  const onImageClick = () => {
    inputRef.current.click();
  };

  const onImageChange = async (e) => {
    const [file] = e.target.files;
    file && setProfilePic(file);
  };

  const onChangeUsername = (e) => {
    const value = removeIllegalCharacters(e.target.value.toLowerCase());
    setUsername(value);
  };

  const disableSubmit = useMemo(() => {
    if (username !== usernameDebounced) return true;
    if (usernameQuery.status === "error" || usernameQuery.status === "loading")
      return true;
    if (bioQuery.status === "error" || bioQuery.status === "loading")
      return true;
    if (nameQuery.status === "error" || nameQuery.status === "loading")
      return true;
    if (editMutation.status === "loading") return true;
    if (username.length < 3) return true;

    const nameChanged = name !== (user.name ?? "");
    const bioChanged = bio !== (user.bio ?? "");
    const usernameChanged = username !== user.username;
    const profilePicChanged = profilePic !== (user.pfp ?? "");
    if (!bioChanged && !usernameChanged && !profilePicChanged && !nameChanged)
      return true;

    return false;
  }, [
    username,
    usernameDebounced,
    bio,
    profilePic,
    name,
    usernameQuery.status,
    nameQuery.status,
    bioQuery.status,
    editMutation.status,
    user.username,
    user.bio,
    user.pfp,
    user.name,
  ]);

  return (
    <>
      <InfoContainer>
        <CancelContainer>
          <CancelButton onClick={onCancel}>
            <LeftCarat src="/icons/chevron-left.svg" />
            <CancelText>Cancel</CancelText>
          </CancelButton>
        </CancelContainer>

        <ProfileContainer onClick={onImageClick}>
          <input ref={inputRef} type="file" onChange={onImageChange} hidden />
          <ProfileImg
            src={
              typeof profilePic === "string"
                ? profilePic
                : URL.createObjectURL(profilePic)
            }
          />

          <ProfileImgOverlay>
            <CameraIcon src="/icons/camera.svg" alt="" />
          </ProfileImgOverlay>
        </ProfileContainer>
      </InfoContainer>

      <FormContainer onSubmit={onSubmit}>
        <FieldContainer>
          <FieldLabel>Name</FieldLabel>
          <InputField
            className="transition-colors"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {bioQuery.status === "error" && (
            <ErrorText>{bioQuery.error.message}</ErrorText>
          )}
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Username</FieldLabel>
          <div style={{ position: "relative" }}>
            <InputField
              value={username}
              onChange={onChangeUsername}
              className="transition-colors"
            />
            {usernameQuery.status === "error" && (
              <Icon src="/icons/error.svg" alt="" style={{ height: 18 }} />
            )}
            {usernameQuery.status === "success" && usernameQuery.data && (
              <Icon
                src="/icons/correct-checkmark.svg"
                alt=""
                style={{ height: 17 }}
              />
            )}
          </div>

          {usernameQuery.status === "error" && (
            <ErrorText>{usernameQuery.error.message}</ErrorText>
          )}
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Email</FieldLabel>
          <InputField value={user.email} onChange={(e) => {}} disabled />
        </FieldContainer>

        <ChangePasswordButton onClick={onChangePassword}>
          Change password
        </ChangePasswordButton>

        <FieldContainer>
          <FieldLabel>Bio</FieldLabel>
          <InputArea
            className="transition-colors"
            placeholder="Tell your fans who you are..."
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
          {bioQuery.status === "error" && (
            <ErrorText>{bioQuery.error.message}</ErrorText>
          )}
        </FieldContainer>

        <SubmitButton disabled={disableSubmit}>
          {editMutation.status === "loading" ? "Saving..." : "Save"}
        </SubmitButton>
      </FormContainer>
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const CancelButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: black;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

const LeftCarat = styled.img`
  height: 24px;
  margin: 6px 10px 6px auto;
`;

const CancelText = styled.span`
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 22.4px */
`;

const ProfileContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 24px;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
`;

const ProfileImgOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;
  background: var(--blueHeader);
  border-radius: 32px;
`;

const CameraIcon = styled.img`
  padding: 10px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-gray-300);
  padding-left: 0px;
  text-align: left;
`;

const InputArea = styled.textarea`
  font-size: 14px;
  width: 100%;
  border: 1px solid;
  border-color: transparent;
  background: white;
  border: 1px solid black;
  padding: 14px;
  border-radius: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-align: left;
  color: black;
  min-height: 100px;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  &:disabled {
    background: var(--color-gray-450);
    pointer-events: none;
    user-select: none;
    color: #65676d;
  }
`;

const ChangePasswordButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 8px;
  color: var(--color-gray-100);
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  font-style: normal;
  letter-spacing: 0.2px;
  margin-left: 1%;
  width: fit-content;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: var(--blueHeader);
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #fef6f3;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }
`;

const DeleteUserButton = styled.button`
  color: var(--exitColor);
  line-height: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: auto;
  margin: 0px 7px 3px 0px;
`;

const Icon = styled.img`
  width: auto;
  position: absolute;
  right: 14px;
  top: 17px;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 160%;
  color: var(--color-red);
  margin-bottom: 0px;
  margin-top: 14px;
  text-align: left;
`;
