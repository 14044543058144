import { message, Upload } from "antd";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { generateRandomId } from "../../helpers/firebase";
import { useState } from "react";
import styled from "styled-components";

export default function UploadProfilePictureButton({
  setProfilePic,
  setFilename,
  maxFileSizeInMb = 10,
  maxWidth,
  maxHeight,
  minWidth,
  fileBucket,
  byPassRes = true,
  setOpen,
}) {
  const storage = getStorage();

  const [isUploading, setIsUploading] = useState(false);

  const beforeUpload = async (file) => {
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeInMb;
    if (!isLt2M) {
      message.error(`Image must be smaller than ${maxFileSizeInMb}MB`);
    }
    const isRightResolution = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          var goodRes;
          if (minWidth) {
            goodRes = width >= minWidth;
          } else if (maxWidth && maxHeight) {
            goodRes = width === maxWidth && height === maxHeight;
          } else if (byPassRes) {
            goodRes = true;
          }
          if (!goodRes) {
            message.error(`Image must be the correct resolution`);
            resolve(false);
          }
          resolve(true);
        });
      });
    });
    return isLt2M && isRightResolution;
  };

  const props = {
    name: "image",
    multiple: false,
    accept: ".jpeg,.png,.svg", // handled by beforeUpload
    beforeUpload,
    action: async (file) => {
      setIsUploading(true);
      setOpen(false);

      const storageRef = ref(
        storage,
        `${fileBucket}/${new Date()} -- ${generateRandomId()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // handle progress state changes here
        },
        (error) => {
          console.error(error);
          message.error(`${file.name} file upload failed.`);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            file.url = downloadURL;
            setProfilePic(downloadURL);
            if (setFilename) setFilename(file.name);
            message.success(`${file.name} file uploaded successfully`);
            return file;
          } catch (error) {
            console.error(error);
            message.error(`${file.name} file upload failed.`);
          }
          setIsUploading(false);
        }
      );
    },
  };

  return (
    <Container>
      <Upload {...props}>
        <ChooseFromLibraryButton
          onClick={async () => {
            console.log("upload images");
          }}
        >
          <ChooseFromLibraryImage src="/icons/image-white.svg" />
          <ChooseFromLibraryButtonText>
            Choose from library
          </ChooseFromLibraryButtonText>
        </ChooseFromLibraryButton>
      </Upload>
    </Container>
  );
}

const Container = styled.span`
  .ant-upload {
    width: 100%;
  }

  .ant-upload-list-item-container {
    display: none;
  }
`;

const ChooseFromLibraryButton = styled.button`
  width: 100%;
  background: var(--color-gray-300);
  border: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: center;
  height: 55px;
  padding: 12px 0px;
  border-radius: 8px;
  margin-top: 10px;
  color: var(--cream);
`;

const ChooseFromLibraryImage = styled.img`
  height: 28px;
  margin-right: 8px;
`;

const ChooseFromLibraryButtonText = styled.span`
  vertical-align: text-top;
  color: white;
`;
