import { votesTable } from "../../helpers/constants";
import { count, queryMany, queryOne } from "./all";

const table = votesTable;

const voteModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getManyByUserId: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["creator", "==", userId], ...args],
    });

    return data;
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default voteModel;
