import React from "react";
import styled from "styled-components";
import {
  appName,
  companyName,
  helpEmailAddress,
  privacyEmailAddress,
  urlName,
} from "../../helpers/constants";
import {
  PrivacyPolicyPersonalDataTable,
  PrivacyPolicyPersonalDataUseTable,
} from "../privacy";

export default function PrivacyPolicy() {
  return (
    <Container>
      <LogoIcon src="" alt="" />
      <MainHeader>Privacy Policy</MainHeader>

      <List>
        <ListHeader>Who we are</ListHeader>
        <ExtraText>
          {companyName} and its subsidiaries ("{appName}", "we", "us", "our")
          respect your privacy and we are committed to protecting the personal
          data we process about you. {appName} is a social network which
          enables: (i) "Creators" to share and monetise their own content (as
          well as subscribe to, and view, the content of other Creators); and
          (ii) "Fans" to subscribe to, and view, the content of Creators.{" "}
        </ExtraText>
        <ListHeader>About this Policy</ListHeader>
        <ExtraText>
          We process your personal data when you use our website located at{" "}
          {urlName} ("Website") and for the provision of the services that we
          offer from time to time via our Website. We also process your personal
          data when you interact with us through our social media pages on
          third-party websites (e.g. Twitter and Instagram), or otherwise. We
          refer to these activities collectively as the "Services" in this
          Policy.
        </ExtraText>
        <ExtraText>
          We are a "data controller" of the personal data that we process in
          connection with the Services. This means that we decide the reasons
          why we process personal data about you and how we do so.
        </ExtraText>
        <ExtraText>
          Please review this Policy to understand how we process your personal
          data in connection with the Services. By using our Services, you
          acknowledge that you have read and understand the information in this
          Policy.
        </ExtraText>
        <ExtraText>
          If you have any questions about this Policy or our processing of your
          personal data, please see Section 19 (assistance and contact
          information) for information about how to contact us.
        </ExtraText>
        <ListHeader>What is personal data?</ListHeader>
        <ExtraText>
          "Personal data" means information that identifies, relates to,
          describes, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular person
          or household.
        </ExtraText>
        <ExtraText>
          In addition, we may collect data that is not capable of identifying
          you or is otherwise not associated or linked with you, such as
          deidentified, aggregated or anonymised information. This type of data
          is not personal data and our use of such data is not subject to this
          Policy.
        </ExtraText>
        <ListHeader>Informing us of changes</ListHeader>
        <ExtraText>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes at
          any point during your relationship with us. Updates or corrections can
          be made through your account settings on our Website.
        </ExtraText>
        <ListHeader>Applicability of this Policy (18+)</ListHeader>
        <ExtraText>
          This Policy is provided in addition to, but does not form part of, our
          Terms of Service (which includes our Acceptable Use Policy) that
          govern your use of our Website and the Services.
        </ExtraText>
        <ExtraText>
          Our Services are strictly intended for individuals 18 years of age or
          older. Anyone under 18 years of age is not permitted to use the
          Services. By using the Services, you represent that you are 18 years
          of age or older.
        </ExtraText>
        <ListHeader>Third-party links</ListHeader>
        <ExtraText>
          Our Website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share personal data about you.
        </ExtraText>
        <ExtraText>
          We are not responsible for, and this Policy does not apply to, the
          content, security or privacy practices of those other websites,
          plug-ins or applications. We encourage you to view the privacy and
          cookie policies / notices of those third parties to find out how your
          personal data may be used.
        </ExtraText>
        <ListHeader>If you do not wish to provide personal data</ListHeader>
        <ExtraText>
          We need to collect certain personal data from you in order to provide
          you with access to the Services or specific features and
          functionalities of the Services in accordance with our contract with
          you (i.e. our Terms of Service). We are also required to process
          certain personal data in accordance with applicable laws. Please note
          that if you do not wish to provide personal data where requested, we
          may not be able to provide you with access to the Services or specific
          features and functionalities of the Services.
        </ExtraText>
        <ListHeader>Updates to this Policy</ListHeader>
        <ExtraText>
          We may update this Policy from time to time, and any updates will be
          effective upon our posting of the revised Policy on our Website. We
          will use reasonable efforts to notify you in the event that material
          updates are made to this Policy, such as sending you a feed
          notification or a chat message via your account on our Website.
        </ExtraText>
        <ListHeader>Categories of personal data</ListHeader>
        <ExtraText>
          We process, or our third-party providers process on our behalf,
          different kinds of personal data about Creators and Fans, which we
          have grouped together as follows:
        </ExtraText>
        <PrivacyPolicyPersonalDataTable />
        <ListHeader>Our onboarding processes</ListHeader>
        <ExtraText>CREATORS</ExtraText>
        <ExtraText>
          We have processes in place that are intended to ensure that all
          Creators on the Website: (i) are at least 18 years of age; and (ii)
          verify their identity. Before you can start a Creator account, we
          will:
        </ExtraText>
        <UnorderedList>
          <ListItem>
            Ask you to provide Creator User Data, as set out at Section 9
            (categories of personal data).
          </ListItem>
          <ListItem>
            Check your country of residence. This check is intended to ensure
            lawful access to the Website and the Services.
          </ListItem>
          <ListItem>
            Ask you to provide Financial Data, as set out at Section 9
            (categories of personal data). This is necessary so that payments
            can be made to Creators for content, and so that Creators can access
            their earnings via the Website. Financial Data is also collected as
            a verification and anti-fraud measure.
          </ListItem>
          <ListItem>
            Ask you to go through a third-party age and identity verification
            process:
            <UnorderedList>
              <ListItem>
                This process involves our third-party provider collecting a
                short .gif, taken from a "selfie" and photo from a government
                identity document (in both cases, that you provide to the
                third-party provider). The third-party provider then uses Face
                Recognition Data to match the two images so they can digitally
                verify your age and identity.
              </ListItem>
              <ListItem>
                As described at Section 9 (categories of personal data), we do
                not collect, receive, possess, or have access to any Face
                Recognition Data collected or processed by our third-party
                providers through this process.
              </ListItem>
              <ListItem>
                Where permitted by applicable law, we receive from our
                third-party providers Third-Party Onboarding Data, and certain
                Technical Data, as set out at Section 9 (categories of personal
                data), to maintain a record of the age and identity verification
                process.{" "}
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            Check that you have not previously been banned from using the
            Website and our Services (e.g. as a result of violating our Terms of
            Service).
          </ListItem>
        </UnorderedList>
        <ListHeader>
          How / why your personal data is used and lawful bases for processing
        </ListHeader>
        <ExtraText>
          We process personal data for, or based on, one or more of the
          following legal bases:
        </ExtraText>
        <UnorderedList>
          <ListItem>
            Consent: Your consent is requested only in specific circumstances
            which includes, for example, the processing of: (i) Face Recognition
            Data by our third-party providers as part of the age and identity
            verification process for all Creators (and for Fans in certain
            locations); and (ii) age estimation captures (which may involve the
            use of Face Recognition Data) by our third-party providers for Fans
            in certain locations. Please see Section 10 (our onboarding
            processes), above, for further information.
          </ListItem>
          <ListItem>
            Performance of a contract: By using the Services, you have
            contracted with us through our Terms of Service, and we will process
            personal data to perform that contract (e.g. to fulfil transactions
            between Fans and Creators and process Creator earnings) and to
            enforce the terms of that contract.
          </ListItem>
          <ListItem>
            Legitimate interests: We may process personal data if it is in our,
            or a third-party's, legitimate interests (as detailed in the table
            below). This includes, for example, investigating and responding to
            a report made through our DMCA takedown procedure to protect a
            Creator's intellectual property rights.
          </ListItem>
          <ListItem>
            Compliance with legal obligations: As a global business, we may
            process personal data to comply with applicable law, rules and
            regulations in the locations where we operate.{" "}
          </ListItem>
          <ListItem>
            Task carried out in the public interest: We may process personal
            data as necessary for a task carried out in the public interest.
            This may include, for example, reporting illegal activity to
            relevant law enforcement authorities, other governmental agencies
            and non- governmental organisations.{" "}
          </ListItem>
        </UnorderedList>
        <ExtraText>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose.
        </ExtraText>
        <ExtraText>
          The table below indicates the purposes for which your personal data is
          processed and the legal justification for the processing. Some of the
          above grounds for processing will overlap and there may be several
          grounds which justify the processing:
        </ExtraText>
        <PrivacyPolicyPersonalDataUseTable />
        <ListHeader>Obtaining your personal data</ListHeader>
        <ExtraText>
          We collect your personal data from the following categories of
          sources:
        </ExtraText>
        <UnorderedList>
          <ListItem>
            Directly from you: When you provide it to us directly to open an
            account and use the Services, when you update your personal data in
            your account, or by corresponding with us (e.g. User Data, Account
            Data).
          </ListItem>
          <ListItem>
            Automatically or indirectly from you: For example, through and as a
            result of your use of the Services (e.g. Transaction Data, Technical
            Data, Usage Data).
          </ListItem>
          <ListItem>
            From our service providers: For example, where permitted by
            applicable law, we receive Third-Party Onboarding Data and certain
            Technical Data from our third-party age and identity verification
            providers.
          </ListItem>
        </UnorderedList>
        <ListHeader>Sharing your personal data</ListHeader>
        <ExtraText>
          We share personal data with the following categories of third parties:
        </ExtraText>
        <UnorderedList>
          <ListItem>
            Our third-party service providers: Such as our IT, payment
            processing, customer support, content and text moderation, and age
            and identity verification / age estimation service providers. The
            lawful basis we rely on for sharing personal data with these
            recipients is that it is necessary for our legitimate interests
            (namely the receipt of services to support business functionality).
          </ListItem>
          <ListItem>
            Our professional advisers: Such as our legal advisors, bankers,
            auditors, accountants, consultants, and insurers. Our professional
            advisors will process personal data as necessary to provide their
            services to us. The lawful basis we rely on for sharing personal
            data with these recipients is that it is necessary for our
            legitimate interests (namely the receipt of professional services).
          </ListItem>
          <ListItem>
            Corporate: Relevant third parties in the event of a possible sale,
            merger, acquisition, business reorganisation or group restructuring
            exercise. The lawful basis we rely on for sharing personal data with
            these recipients is that it is necessary for our and the relevant
            third parties' legitimate interests (namely assessing and putting
            into effect potential transactions).
          </ListItem>
          <ListItem>
            Our group companies: For the centralised coordination and management
            of our business, in accordance with the purposes set out at Section
            9 (categories of personal data). These recipients will process
            personal data in the same way as set out in this Policy. The lawful
            basis we rely on for sharing personal data with these recipients is
            that it is necessary for our legitimate interests (namely
            coordinating the global operations of our business).
          </ListItem>
          <ListItem>
            Relevant authorities, regulators and organisations: In response to
            requests from governmental authorities (including law enforcement
            and tax authorities), regulators, and certain non-governmental
            organisations (such as the National Center for Missing & Exploited
            Children (NCMEC)). These recipients will use your personal data in
            the performance of their regulatory, law enforcement or otherwise
            charitable or not-for-profit role. The lawful basis we rely on for
            sharing personal data with these recipients is that the processing
            is either necessary to comply with a legal obligation to which we
            are subject, or necessary for our, or a third-party's, legitimate
            interests, or where it is in the interests of the wider public to do
            so (namely reporting illegal content to, and assisting with requests
            from, such authorities, regulators and organisations, to protect the
            safety of our users and third parties).
          </ListItem>
        </UnorderedList>
        <ListHeader>International data transfers</ListHeader>
        <ExtraText>
          We share your personal data within our group companies and to our
          third parties, as set out at Section 13 (sharing your personal data).
        </ExtraText>
        <ExtraText>
          In some circumstances, this will involve transferring your data
          outside the UK, the EEA and Switzerland. We make those transfers: (i)
          to countries that have been deemed to provide an adequate level of
          protection for personal data; (ii) using appropriate safeguards; or
          (iii) where otherwise authorised by applicable law.
        </ExtraText>
        <ExtraText>
          Please contact us using the contact details set out at Section 19
          (assistance and contact information) if you would like further
          information on the specific mechanism used by us when transferring
          your personal data outside the UK, the EEA and Switzerland.
        </ExtraText>
        <ListHeader>Your rights regarding personal data</ListHeader>
        <ExtraText>
          You have certain rights regarding the collection and processing of
          personal data. You may exercise these rights by contacting us using
          the contact details set out at Section 19 (assistance and contact
          information).
        </ExtraText>
        <ExtraText>
          Under certain circumstances and subject to certain exemptions, you
          have the right to:
        </ExtraText>
        <UnorderedList>
          <ListItem>
            Withdraw your consent to the processing of your personal data:
            Please note that withdrawing your consent will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent.
          </ListItem>
          <ListItem>
            Request to know or access to your personal data: You may receive a
            copy of the personal data we hold about you and to check that we are
            lawfully processing it.
          </ListItem>
          <ListItem>
            Request correction of the personal data that we hold about you: You
            may correct any incomplete or inaccurate personal data we hold about
            you.
          </ListItem>
          <ListItem>
            Request deletion / erasure of your personal data: You may ask us to
            delete or remove personal data where there is no legitimate reason
            for us continuing to process it. You also may ask us to delete or
            remove your personal data where you have exercised your right to
            object to processing (see below). Please note that we may not always
            be able to comply with your request of deletion / erasure for
            specific legal reasons.
          </ListItem>
          <ListItem>
            Request the restriction of processing of your personal data: You may
            ask us to suspend the processing of personal data about you, for
            example if you want us to establish its accuracy or the reason for
            processing it.
          </ListItem>
          <ListItem>
            Request the transfer of some sections of your personal data to
            another party.
          </ListItem>
        </UnorderedList>
        <ExtraText>
          You also have the right to object to processing of your personal data
          where we are relying on a legitimate interest for the processing and
          there is something about your particular situation which makes you
          want to object to processing on this ground.
        </ExtraText>
        <ExtraText>
          We do not process personal data that is subject to solely automated
          decision-making, where that decision-making is likely to have a legal
          or similarly significant effect on you.
        </ExtraText>
        <ExtraText>You also have the right:</ExtraText>
        <UnorderedList>
          <ListItem>
            To lodge a complaint with a data protection regulator. For example,
            in the UK, this is the Information Commissioner's Office (ICO) and
            in Switzerland, this is the Federal Data Protection and Information
            Commissioner (FDPIC). If you are resident in the EEA, you may wish
            to contact your local country or state-specific data protection
            regulator.
          </ListItem>
          <ListItem>
            Depending on your location, to not receive retaliatory or
            discriminatory treatment in connection with a request to exercise
            the above rights, or appeal a decision we have made in connection
            with your privacy rights request. All appeal requests should be
            submitted by contacting us using the contact details set out at
            Section 19 (assistance and contact information).
          </ListItem>
        </UnorderedList>
        <ListHeader>Exercising your rights</ListHeader>
        <ExtraText>
          If you would like to exercise your rights set out at Section 15 (your
          rights regarding personal data), you may do so by contacting us using
          the contact details set out at Section 19 (assistance and contact
          information).
        </ExtraText>
        <ExtraText>
          If you submit the request yourself, please ensure that your request
          contains sufficient information to allow us to confirm your identity
          and properly understand, evaluate, and respond to it.{" "}
        </ExtraText>
        <ExtraText>
          In order to verify your identity, we may at times need to request
          additional personal data from you, taking into consideration our
          relationship with you and the sensitivity of your request. In certain
          circumstances, we may decline a privacy rights request, particularly
          where we are unable to verify your identity.
        </ExtraText>
        <ExtraText>
          If your request is made by a third-party authorised by you, we will
          also require proof that the third-party has permission to submit the
          request on your behalf (such as a signed document evidencing that the
          third-party has authority to make the request).
        </ExtraText>
        <ListHeader>Choices and control over your personal data</ListHeader>
        <ExtraText>
          Modifying and deleting your personal data: If you have an account with
          us, you may update your account settings on our Website. Please note
          that changes to your settings may require some time to take effect.
        </ExtraText>
        <ExtraText>
          Access to device information: You may control the Services' access to
          your Technical Data through your "Settings" app on your device. For
          instance, you can withdraw permission for the Services to access your
          network devices and geolocation.
        </ExtraText>
        <ExtraText>
          Email notification opt-out: We currently do not send emails for direct
          marketing purposes. However, we do send email notifications which are
          related to your account (e.g. for Creators, where you have a new
          subscriber, you have received a new tip, or somebody has renewed their
          subscription with you). You may opt-out of receiving certain types of
          email communications from us by changing your notification preferences
          on our Website. You may also email us using the contact details set
          out at Section 19 (assistance and contact information). Please include
          "E-mail notification opt-out" in the email's subject line and include
          your name and your account email address in the body of the email.
        </ExtraText>
        <ExtraText>
          Please note that you cannot opt-out of certain automated email
          notifications that are necessary to provide the Services or are
          otherwise required in accordance with applicable law (e.g. account
          verification, transactional communications, changes / updates to
          features of the service, technical and security notices).
        </ExtraText>
        <ListHeader>Retention of personal data</ListHeader>
        <ExtraText>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes for which it was collected, as set
          out in this Policy. Subject to the below, we retain personal data for
          a period of 6 months after the deletion or deactivation of your
          account on the Website.
        </ExtraText>
        <ExtraText>Please note that:</ExtraText>
        <UnorderedList>
          <ListItem>
            We will delete your personal data sooner where a shorter retention
            period is required by applicable law.
          </ListItem>
          <ListItem>
            We will retain your personal data for a longer period to the extent
            we deem necessary to carry out the processing activities set out in
            this Policy, for example:
          </ListItem>
          <UnorderedList>
            <ListItem>
              where it is necessary to comply with laws and regulatory
              obligations that are applicable to us (e.g. adhering to record
              keeping / maintenance requirements in certain locations and
              financial / tax reporting requirements, which in some cases is up
              to 7 years, and if we receive a valid legal request, such as a
              preservation order or search warrant, related to your account);
            </ListItem>
            <ListItem>
              for the purposes of identifying and reporting illegal activity,
              protecting the safety of our users and third parties, or otherwise
              protecting the rights and property of our users, us, and other
              third parties (e.g. where you have, or we have reason to believe
              that you have, violated our Terms of Service, and in circumstances
              where users are banned from further access to the Website);
            </ListItem>
            <ListItem>
              for purposes of legal proceedings (e.g. to defend ourselves in
              litigation about a claim related to you); and
            </ListItem>
            <ListItem>
              for the purposes of responding to requests from third parties in
              relation to your account, such as requests received from, or
              investigations by, law enforcement authorities, relevant
              governmental authorities (e.g. tax authorities and regulatory
              authorities) and non-governmental organisations (e.g. NCMEC).
            </ListItem>
          </UnorderedList>
        </UnorderedList>
        <ExtraText>
          The personal data that we retain and the length of time for which it
          is retained will be determined on a case-by-case basis, depending on
          the particular circumstances.{" "}
        </ExtraText>
        <ListHeader>Assistance and contact information </ListHeader>
        <ExtraText>
          We have appointed a Data Protection Officer ("DPO") whose
          responsibilities include, together with our team of privacy
          specialists, responding to questions, requests, and concerns in
          relation to this Policy and our use of personal data.
        </ExtraText>
        <ExtraText>
          If you have questions about this Policy, or how we process your
          personal data, please submit a ticket through your account or email us
          at <a href={`mailto:${privacyEmailAddress}`}>{privacyEmailAddress}</a>
          .
        </ExtraText>
        <ListHeader>Additional U.S. state privacy disclosures</ListHeader>
        <ExtraText>
          Residents of the States of California, Colorado, Connecticut, Nevada,
          Utah, and Virginia: These additional U.S. State Privacy Disclosures
          ("U.S. Disclosures") supplement the information contained in the
          Policy by providing additional information about our personal data
          processing practices relating to individual residents of these states.
          Unless otherwise expressly stated, all terms defined in this Policy
          retain the same meaning in these U.S. Disclosures.
        </ExtraText>
        <ExtraText>
          For the purposes of these U.S. Disclosures, personal data does not
          include publicly available information or deidentified, aggregated or
          anonymised information that is maintained in a form that is not
          capable of being associated with or linked to you.
        </ExtraText>
        <ExtraText>No sales for targeted advertising</ExtraText>
        <ExtraText>
          We do not sell or share personal data for the purpose of displaying
          advertisements that are selected based on personal data obtained or
          inferred over time from an individual's activities across businesses
          or distinctly-branded websites, applications, or other services
          (otherwise known as "targeted advertising").
        </ExtraText>
        <ExtraText>Sensitive information</ExtraText>
        <ExtraText>
          Although sensitive information may be disclosed for a business purpose
          as described below, we do not sell or share sensitive information for
          the purpose of targeted advertising.
        </ExtraText>
        <ExtraText>
          The following personal data elements we, or our service providers,
          collect may be classified as "sensitive" under certain privacy laws
          ("sensitive information") including:
        </ExtraText>
        <UnorderedList>
          <ListItem>username and password;</ListItem>
          <ListItem>
            social security number, driver's licence number, and passport
            number;
          </ListItem>
          <ListItem>
            government identifiers (such as driver's licence numbers);
          </ListItem>
          <ListItem>
            partial payment card number and the name registered with your
            payment card; and
          </ListItem>
          <ListItem>
            Face Recognition Data (biometric information which is collected and
            processed by our third-party providers).
          </ListItem>
        </UnorderedList>
        <ExtraText>
          We use this sensitive information for the purposes set out at Section
          11 (how / why we use your personal data and lawful bases for
          processing), to the extent necessary for the operation of our
          Services, to enter into and perform a contract with you, to comply
          with legal and regulatory requirements, to protect the safety of ours
          users and third parties or as otherwise permissible for our own
          internal purposes consistent with applicable laws.
        </ExtraText>
        <ExtraText>Deidentified information</ExtraText>
        <ExtraText>
          We may at times receive, or process personal data, to create
          deidentified information that can no longer reasonably be used to
          infer information about, or otherwise be linked to, a particular
          individual or household. Where we maintain deidentified information,
          we will maintain and use the information in deidentified form and not
          attempt to reidentify the information except as required or permitted
          by applicable law.
        </ExtraText>
        <ExtraText>Minors</ExtraText>
        <ExtraText>
          Our Services are strictly intended for individuals 18 years of age or
          older. Anyone under 18 years of age is not permitted to use the
          Services. By using the Services, you represent that you are 18 years
          of age or older.
        </ExtraText>{" "}
        <ExtraText>CALIFORNIA SPECIFIC DISCLOSURES</ExtraText>
        <ExtraText>
          The following disclosures only apply to residents of the State of
          California:
        </ExtraText>
        <ExtraText>Personal data collection</ExtraText>
        <ExtraText>
          California law requires that we provide disclosures to you about what
          personal data we collect by reference to the categories of personal
          data set forth within California law. To address this obligation, we
          have identified the relevant California personal data category for the
          personal data set out in more detail at Section 9 (categories of
          personal data):
        </ExtraText>
        <UnorderedList>
          <ListItem>
            Identifiers: Such as your name, address, phone number, email
            address, passport or other government identity information including
            driver's licence information, account information, or other similar
            identifiers.
          </ListItem>
          <ListItem>
            Customer Records: Such as your driver's licence number, passport
            number, partial debit card information, partial credit card
            information, bank account information or other payment or financial
            information.
          </ListItem>
          <ListItem>
            Protected Classification Characteristics: Such as age, date of
            birth, and gender.
          </ListItem>
          <ListItem>
            Commercial Information: Such as information about products or
            services purchased and your use of our Services.
          </ListItem>
          <ListItem>
            Biometric Information: Which is limited to Face Recognition Data,
            used by our third-party providers for age and identity verification
            purposes. Face Recognition Data remains with our third-party
            providers and we do not ourselves collect, receive, possess, or have
            access to this data.
          </ListItem>
          <ListItem>
            Internet / Network Information: Such as device information, log, and
            analytics data.
          </ListItem>
          <ListItem>
            Sensory Information: Such as pictures and videos (content) you
            upload to the Website.
          </ListItem>
          <ListItem>
            Professional / Employment Information: Such as the business or
            organisation you are associated with and, where applicable, your
            title with that business or organisation and information relating to
            your role with the business or organisation.
          </ListItem>
          <ListItem>
            Other Personal Data: Such as communication preferences, customer
            service and communication history, personal data an individual
            permits us to see when interacting with us through social media,
            personal data an individual provides us in relation to a question or
            request, and messages you send us through our Services or make
            available to us on social media.
          </ListItem>
          <ListItem>
            Inferences: Such as information generated from your use of our
            Services.
          </ListItem>
        </UnorderedList>
        <ExtraText>
          We may disclose all of these categories of personal data for a
          business purpose to service providers or other third parties, as
          outlined in this Policy.
        </ExtraText>
        <ExtraText>Disclosure of personal data</ExtraText>
        <ExtraText>
          As set out at Section 13 (sharing your personal data), we may disclose
          the categories of personal data identified above to the following
          categories of third parties for various business purposes: our group
          and affiliated companies, service providers, our professional
          advisers, business partners, other businesses as needed to provide our
          Services, and certain third parties where you have provided consent,
          where it is in connection with a corporate transaction, or where we
          are required by law or in connection with other legal process.{" "}
        </ExtraText>
        <ExtraText>Sources of personal data</ExtraText>
        <ExtraText>
          We collect personal data directly from you, from your browser or
          device when you interact with our Services, and from our business
          partners and affiliates, third parties you direct to share information
          with us, and other third-party providers. For more information, please
          see Section 12 (obtaining your personal data).
        </ExtraText>
        <ExtraText>Purpose for collection</ExtraText>
        <ExtraText>
          We collect personal data about you for the purposes set out at Section
          11 (how / why we use your personal data and lawful bases for
          processing).
        </ExtraText>
        <ExtraText>Notice of financial incentives</ExtraText>
        <ExtraText>
          As discussed above, we currently do not use any cross-site tracking
          technologies and we do not sell personal data collected about you, or
          share personal data collected about you for cross-context behavioural
          advertising. We do not currently send emails for direct marketing
          purposes.
        </ExtraText>
        <ExtraText>
          We currently offer a referral program, whereby existing Creators of
          the Website can use their unique referral code to introduce people who
          are interested in becoming Creators on the Website, and the referring
          Creator will receive referral payments, based on the referred
          Creator's earnings. The referral program is subject to our Terms of
          Service and any such referral payments are calculated, and limited, as
          described in the Referral Program Terms in our Terms of Service.
        </ExtraText>
        <ExtraText>
          Any personal data associated with the referring Creator, or the
          referred Creator, is processed in accordance with this Policy.
        </ExtraText>
        <ExtraText>
          We have determined that the value of the referral program is
          reasonably related to the value of the personal data we process in
          connection with the referral program (based on our reasonable but sole
          determination). We estimate the value of the personal data we receive
          and otherwise process in connection with the referral program by
          considering the expense we incur in collecting and processing the
          personal data, as well as the expenses related to facilitating the
          referral program.
        </ExtraText>
        <ExtraText>
          You may exercise your rights in relation to your personal data as
          outlined in this Policy, and as applicable, by submitting a ticket
          through your account or by emailing{" "}
          <a href={`mailto:${privacyEmailAddress}`}>{privacyEmailAddress}</a>.
        </ExtraText>
      </List>
    </Container>
  );
}

const Container = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 90%;
  margin: auto;
  margin-bottom: ${(props) => (props.mustAcceptTos ? "0" : "40")}px;
`;

const LogoIcon = styled.img`
  height: 40px;
  width: auto;
  margin: auto auto 10px auto;
  display: block;
  margin-top: ${(props) => (props.mustAcceptTos ? "0" : "30")}px;
`;

const TextContainer = styled.div`
  overflow-y: ${(props) => (props.mustAcceptTos ? "auto" : "unset")};
  overflow-x: ${(props) => (props.mustAcceptTos ? "hidden" : "unset")};
  height: ${(props) => (props.mustAcceptTos ? "500px" : "auto")};
  max-height: ${(props) => (props.mustAcceptTos ? "500px" : "auto")};
  box-shadow: ${(props) =>
    props.mustAcceptTos ? "0px 4px 0px 0px rgba(0, 0, 0, 0.1)" : "none"};
  padding-right: 5%;
  padding-bottom: 32px;

  @media screen and (max-height: 825px) {
    height: ${(props) => (props.mustAcceptTos ? "400px" : "auto")};
    max-height: ${(props) => (props.mustAcceptTos ? "400px" : "auto")};
  }
`;

const MainHeader = styled.div`
  color: var(--color-normal-text);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35.2px;
  margin-bottom: 18px;
`;

const List = styled.ol`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const UnorderedList = styled.ul`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const ListHeader = styled.li`
  color: var(--grayscale);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const ListItem = styled.li`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const ExtraText = styled.div`
  margin-top: 20px;
`;
