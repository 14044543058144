import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { currentUser } from "../../helpers/authentication";
import postsModel from "../../lib/firebase/postsModel";
import { getNextPageOfBaseImages } from "../../helpers/firebase";

export default function BaseImageSelector({ baseImageUrl, setBaseImageUrl }) {
  const [postsCount, setPostsCount] = useState(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [documentSnapshots, setDocumentSnapshots] = useState(null);

  const postsQuery = useQuery({
    queryKey: [`base-images-${currentUser && currentUser.uid}`],
    queryFn: async () => {
      if (!currentUser) return null;
      const posts = await postsModel.getMany(
        ["sort", "createdAt", "desc"],
        ["creator", "==", currentUser.uid]
      );
      return posts;
    },
  });

  const postsCountQuery = useQuery({
    queryKey: [`base-imagecount-${currentUser && currentUser.uid}`],
    queryFn: async () => {
      if (!currentUser) return null;
      const postCount = await postsModel.count([
        "creator",
        "==",
        currentUser.uid,
      ]);
      return postCount;
    },
  });

  useEffect(() => {
    if (postsCountQuery.status === "success" && postsCountQuery.data) {
      setPostsCount(postsCountQuery.data);
    }
  }, [postsCountQuery]);

  /*
  async function seeMoreEntries() {
    const nextPageGalleryPromise = await getNextPageOfBaseImages({
      questId: poll.questId,
      documentSnapshots,
    });
    setDocumentSnapshots(nextPageGalleryPromise.documentSnapshots);
    setShowSeeMore(subAmount > postsQuery.data.length);
    const newRows = nextPageGalleryPromise.results.reduce(
      reduceArrayToRows,
      []
    );
    setRowsOfSubmissions((rows) => rows.concat(newRows));
  }
  */

  return (
    <Container>
      <Header>
        Select one of your previous pieces of content to create more of the same
        person with a similar description below
      </Header>
      {postsQuery.status === "success" &&
        postsQuery.data.map((image, index) => (
          <BaseImage
            src={image.imageUrl}
            className="base-image"
            baseImageUrl={baseImageUrl}
            active={baseImageUrl === image.imageUrl}
            onClick={() => {
              console.log("set base image url");
              if (baseImageUrl === image.imageUrl) setBaseImageUrl("");
              else setBaseImageUrl(image.imageUrl);
            }}
          />
        ))}
      {showSeeMore ? (
        <>
          <div className="overlay" />
          <div style={{ textAlign: "center" }}>
            <SeeMoreButton
              onClick={() => {
                console.log("clicked on see more");
              }}
              className="see-more-button"
            >
              See more
            </SeeMoreButton>
          </div>
        </>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: block;
  margin-bottom: 10px;
  margin-top: -16px;
  text-align: center;

  .base-image {
    margin-right: 4px;
  }
`;

const BaseImage = styled.img`
  width: 30%;
  height: auto;
  border-radius: 3px;
  box-sizing: border-box;
  opacity: ${(props) => !props.active && props.baseImageUrl !== "" && "40%"};
  border: ${(props) => props.active && "3px solid var(--color-main-blue)"};
`;

const Header = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 18px;
  color: var(--color-gray-300);
`;

const SeeMoreButton = styled.button`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  background: var(--color-main-blue);
  color: white;
  border: 0px;
  height: 38px;
`;
