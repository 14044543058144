import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../helpers/authentication";
import subscriptionModel from "../../lib/firebase/subscriptionModel";
import SubscriberEntry from "./SubscriberEntry";

export default function Subscribers() {
  const [mySubscriptions, setMySubscriptions] = useState([]);

  const subscriptionQuery = useQuery({
    queryKey: ["subscribers"],
    queryFn: () =>
      subscriptionModel.getMany(
        ["creator", "==", currentUser.uid],
        ["expiresAt", ">", new Date()]
      ),
  });

  useEffect(() => {
    if (subscriptionQuery.status === "success" && subscriptionQuery.data) {
      setMySubscriptions(subscriptionQuery.data);
    } else {
      setMySubscriptions([]);
    }
  }, [subscriptionQuery.status, subscriptionQuery.data]);

  return (
    <Container>
      {mySubscriptions.length > 0 &&
        mySubscriptions.map((subscription) => (
          <SubscriberEntry subscription={subscription} />
        ))}
      {mySubscriptions.length === 0 && (
        <NoSubscribers>
          Attract fans on social accounts to get subscribers
        </NoSubscribers>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 34px;
`;

const NoSubscribers = styled.div`
  width: 100%;
  background: white;
  padding: 20px 0px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid var(--color-light-gray);
`;
