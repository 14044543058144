import { Image, Menu, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import {
  Profile,
  Logout,
  UserProfile,
  Post,
  Notifications,
  ProfileStatistics,
  Featured,
} from ".";
import { currentUser } from "../helpers/authentication";
import { getBookmarkByStoryworldId } from "../helpers/firebase";
import {
  showHeaderUi,
  getConvoIdFromUrl,
  showDesktopWidth,
} from "../helpers/general";
import { adminList, mobileWidthThreshold } from "../helpers/constants";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { PrivacyPolicy, ResetPassword, TermsOfUse } from "../components/login";
import { Login, Register } from "./login";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import styled from "styled-components";
import { Conversations } from "../components/chat";
import ChatBox from "../components/chat/ChatBox";
import { PageFooter } from "../components/footer";
import Home from "../components/pages/Home";
import { Footer, Header } from "../components/layouts";

const { Sider } = Layout;

export default function Main({
  storyworldId,
  setNavItems,
  navItems,
  selectedQuest,
  setSelectedQuest,
  setSelectedStoryworld,
  selectedStoryworld,
  selectedEpisode,
  selectedPoll,
  setLocationTitle,
}) {
  const history = useHistory();
  const location = useLocation();

  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isGeneralUser, setIsGeneralUser] = useState(false);
  const [storyworldQuests, setStoryworldQuests] = useState([]);
  const [storyworlds, setStoryworlds] = useState([]);
  const [questNavItems, setQuestNavItems] = useState([]);
  const [bookmark, setBookmark] = useState(null);
  const [backLocations, setBackLocations] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  useEffect(() => {
    async function fetchData() {
      const bookmarkPromise = await getBookmarkByStoryworldId(storyworldId);
      setBookmark(bookmarkPromise);
    }
    if (location.pathname === "/stories") {
      fetchData();
    }
  }, [selectedStoryworld, location, storyworldId]);

  const onClick = (e) => {
    const storyworld = storyworlds.filter(function (el) {
      return e.key === el.id;
    });
    setSelectedStoryworld(storyworld[0]);
  };

  const onQuestClick = (e) => {
    const quests = storyworldQuests.filter(function (el) {
      return e.key === el.id;
    });
    if (quests.length > 0) {
      setSelectedQuest(quests[0]);
    }
  };

  return (
    <Container location={location}>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        {selectedQuest === null &&
          selectedPoll === null &&
          selectedEpisode === null &&
          showHeaderUi(location) &&
          adminList.includes(currentUser.email) && (
            <Sider className="menu-sider">
              <MenuSidebar>
                <Image
                  src="/images/footer-hero.svg"
                  style={{
                    margin: "10px 0px 20px 10px",
                    width: "150px",
                  }}
                  preview={false}
                />
                {isGeneralUser ? (
                  <Menu selectedKeys={[location.pathname]} mode="vertical">
                    <Menu.Item key="/quests">
                      <Link to="/quests">
                        <span className="menu-text">Quests</span>
                      </Link>
                    </Menu.Item>
                  </Menu>
                ) : (
                  ``
                )}
                {isAdminUser && !selectedStoryworld ? (
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["1"]}
                    items={navItems}
                    onClick={onClick}
                  />
                ) : (
                  ``
                )}

                {isAdminUser && selectedStoryworld && (
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["1"]}
                    items={questNavItems}
                    onClick={onQuestClick}
                  />
                )}
              </MenuSidebar>
            </Sider>
          )}

        <Layout>
          <Switch>
            <Route exact path="/">
              <Header />
              <Home />
              <Footer />
            </Route>

            <Route exact path="/home">
              <Featured />
            </Route>

            <Route exact path="/login">
              <Login />
              <PageFooter />
            </Route>

            <Route exact path="/register">
              <Register />
              <PageFooter />
            </Route>

            <Route path="/terms">
              <TermsOfUse />
              <PageFooter />
            </Route>

            <Route path="/privacy">
              <PrivacyPolicy />
              <PageFooter />
            </Route>

            <Route path="/reset-password">
              <ResetPassword />
            </Route>

            <Route exact path="/notifications">
              <Notifications />
            </Route>

            <Route exact path="/messages">
              <Conversations />
            </Route>

            <Route exact path="/messages/:id">
              <ChatBox convoId={getConvoIdFromUrl()} />
            </Route>

            <Route exact path="/post">
              <Post />
            </Route>

            <Route exact path="/profile">
              {currentUser ? (
                <Profile
                  backLocations={backLocations}
                  setBackLocations={setBackLocations}
                />
              ) : (
                <Login message={""} />
              )}
            </Route>

            <Route exact path="/profile/edit">
              {currentUser ? <EditProfile /> : <Login message={""} />}
            </Route>

            <Route exact path="/profile/stats">
              {currentUser ? <ProfileStatistics /> : <Login message={""} />}
            </Route>

            <Route exact path="/profile/change-password">
              {currentUser ? <ChangePassword /> : <Login message={""} />}
            </Route>

            <Route exact path="/user/:id">
              <UserProfile />
            </Route>

            <Route exact path="/logout">
              <Logout />
            </Route>
          </Switch>
        </Layout>
      </Layout>
    </Container>
  );
}

const Container = styled.div`
  margin: auto;

  @media (min-width: ${mobileWidthThreshold}px) {
    border-left: 0.1px solid var(--color-gray-50);
    border-right: 0.1px solid var(--color-gray-50);
    max-width: ${(props) =>
      !showDesktopWidth(props.location) ? mobileWidthThreshold : 3000}px;
  }
`;

const MenuSidebar = styled.div`
  background: var(--color-gray-500);
  padding: 20px !important;
  height: 100%;
  min-width: 225px !important;
  border-right: none !important;
  border-right: 2px solid var(--color-gray-800) !important;
  text-align: left;
`;
