import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import _InputField from "../components/InputField";
import { useMutation } from "@tanstack/react-query";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

export default function ChangePassword() {
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [revealCurrentPassword, setRevealCurrentPassword] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [reavealConfirmedPassword, setReavealConfirmedPassword] = useState(
    false
  );

  let password1Ref = React.createRef();
  let password2Ref = React.createRef();
  let password3Ref = React.createRef();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const editMutation = useMutation({
    mutationFn: async () => {
      const auth = getAuth();
      const credentials = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credentials);
      await updatePassword(auth.currentUser, newPassword);
    },
    onSuccess: () => {
      history.push("/profile");
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (editMutation.status === "loading") return;
    editMutation.mutate();
  };

  const disableSubmit = useMemo(() => {
    return (
      currentPassword.length < 6 ||
      newPassword.length < 6 ||
      newPasswordConfirm.length < 6 ||
      newPassword !== newPasswordConfirm
    );
  }, [currentPassword, newPassword, newPasswordConfirm]);

  const onCancel = () => {
    history.goBack();
  };

  return (
    <InfoContainer>
      <CancelContainer>
        <CancelButton onClick={onCancel}>
          <LeftCarat src="/icons/chevron-left.svg" />
          <CancelText>Cancel</CancelText>
        </CancelButton>
      </CancelContainer>
      <FormContainer onSubmit={onSubmit}>
        <Title>Change Password</Title>
        <FieldContainer>
          <FieldLabel>Current Password</FieldLabel>
          <InputContainer>
            <InputField
              type={revealCurrentPassword ? "text" : "password"}
              value={currentPassword}
              maxLength={40}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Type your current password"
              ref={password1Ref}
            />
            <span
              class="flex justify-around items-center"
              onClick={() => {
                setRevealCurrentPassword(!revealCurrentPassword);
                password1Ref.current.focus();
              }}
            >
              {revealCurrentPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          </InputContainer>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>New password</FieldLabel>
          <InputContainer>
            <InputField
              type={revealPassword ? "text" : "password"}
              value={newPassword}
              maxLength={40}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Must be greater than 6 characters"
              ref={password2Ref}
            />
            <span
              class="flex justify-around items-center"
              onClick={() => {
                setRevealPassword(!revealPassword);
                password2Ref.current.focus();
              }}
            >
              {revealPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          </InputContainer>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Confirm new password</FieldLabel>
          <InputContainer>
            <InputField
              type={reavealConfirmedPassword ? "text" : "password"}
              value={newPasswordConfirm}
              maxLength={40}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              placeholder="Type your new password again"
              ref={password3Ref}
            />
            <span
              class="flex justify-around items-center"
              onClick={() => {
                setReavealConfirmedPassword(!reavealConfirmedPassword);
                password3Ref.current.focus();
              }}
            >
              {reavealConfirmedPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          </InputContainer>
        </FieldContainer>
        {editMutation.error && (
          <ErrorText>{editMutation.error.message}</ErrorText>
        )}
        <SubmitButton disabled={disableSubmit}>
          {editMutation.status === "loading" ? "Loading..." : "Save"}
        </SubmitButton>
      </FormContainer>
    </InfoContainer>
  );
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding-top: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-gray-300);
  padding-left: 0px;
  text-align: left;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  margin-bottom: 0px;
  margin-top: 20px;
  font-weight: 800;
  font-size: 28px;
  line-height: 160%;
  color: black;
  text-align: left;
  padding-left: 4px;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: var(--blueHeader);
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #fef6f3;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 160%;
  color: var(--color-red);
  margin-bottom: 0px;
  margin-top: 14px;
  text-align: left;
`;

const InputField = styled(_InputField)`
  &::placeholder {
    font-style: normal;
  }
`;

const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
`;

const CancelButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: black;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

const LeftCarat = styled.img`
  height: 24px;
  margin: 6px 10px 6px auto;
`;

const CancelText = styled.span`
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 22.4px */
`;

const InputContainer = styled.div`
  position: relative;
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;
