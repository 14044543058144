import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../helpers/authentication";
import SinglePost from "../components/posts/SinglePost";
import postsModel from "../lib/firebase/postsModel";
import subscriptionModel from "../lib/firebase/subscriptionModel";

export default function SubscriptionPosts() {
  const postsQuery = useQuery({
    queryKey: [`subscription-posts-${currentUser && currentUser.uid}`],
    queryFn: async () => {
      if (!currentUser) return null;
      const subscriptions = await subscriptionModel.getMany([
        "subscriber",
        "==",
        currentUser.uid,
      ]);
      const posts = [];
      for (let i = 0; i < subscriptions.length; i++) {
        const sub = subscriptions[i];
        const newPosts = await postsModel.getManyByUserId(sub.creator, [
          "sort",
          "createdAt",
          "desc",
        ]);
        newPosts.forEach((post) => {
          posts.push(post);
        });
      }
      const finalPosts = posts.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
      return finalPosts;
    },
  });

  return (
    <Container>
      {postsQuery.status === "success" &&
        postsQuery.data &&
        postsQuery.data.map((post, key) => (
          <SinglePost post={post} key={key} isSubscribed={true} />
        ))}
      {postsQuery.status === "success" &&
        postsQuery.data &&
        postsQuery.data.length === 0 && (
          <NoPostsHeader>Subscribe to creators to see posts</NoPostsHeader>
        )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-right: none !important;
  text-align: left;
`;

const NoPostsHeader = styled.div`
  height: 100%;
  width: 100%;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  color: var(--color-gray-100);
`;
