import { Row, Col, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  currentUser,
  firebaseAuth,
  getLoginError,
  loginUser,
  validateEmail,
} from "../../helpers/authentication";

import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { getUserInfo, isEmailOnAllowList } from "../../helpers/firebase";
import { appName, mobileWidthThreshold } from "../../helpers/constants";
import { sendEmailVerification } from "@firebase/auth";
import { sleep } from "../../helpers/general";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const cta = "Create your perfect AI influencer and content";

export default function Login({}) {
  const location = useLocation();
  const history = useHistory();

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);

  useEffect(() => {
    async function waitForLogin() {
      await sleep(1);
      if (currentUser && currentUser.emailVerified) history.push("/home");
    }
    waitForLogin();
  }, [history]);

  useEffect(() => {
    if (validateEmail(emailAddress) && password.length > 0) {
      setLoginButtonDisabled(false);
    } else setLoginButtonDisabled(true);
  }, [emailAddress, password]);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      // setLoginError("");
      /*
      const onAllowList = await isEmailOnAllowList(emailAddress);
      if (!onAllowList) {
        await firebaseAuth.signOut();
        message.error(
          "AllmaiFans is in closed beta, please email contact@junoware.com to join the waitlist",
          [5]
        );
        return;
      }
      */
      console.log("Logging in User");
      const result = await loginUser(emailAddress, password);
      if (result.user && result.user.emailVerified) {
        // const userData = await getUserInfo(result.user.uid);
        // console.log(userData);
        history.push("/home");
        mixpanel.track("User logged in");
      } else if (result.user && !result.user.emailVerified) {
        message.error("Check your email for verification message");
        await sendEmailVerification(result.user);
      } else {
        const errorMessage = getLoginError(result.code);
        message.error(errorMessage);
      }
    } catch (error) {
      // setIsSending(false);
    }
  };

  return (
    <Container>
      <form onSubmit={onSubmit} style={{ position: "relative", zIndex: 1 }}>
        <MobileContainer>
          <InnerHeader onClick={() => history.push("/")}>
            <Logo src="/android-chrome-512x512.png" alt="" />
            <HeaderText>{appName}</HeaderText>
          </InnerHeader>
          <InnerSubheader>{cta}</InnerSubheader>
          <Row style={{ marginBottom: "-3px" }}>
            <Col span={12}>
              <Image src="/images/models/00.png" alt="" />
            </Col>
            <Col span={12}>
              <Image src="/images/models/02.png" alt="" />
            </Col>
          </Row>
          <Row style={{ marginBottom: "0px" }}>
            <Col span={12}>
              <Image src="/images/models/04.png" alt="" />
            </Col>
            <Col span={12}>
              <Image src="/images/models/03.png" alt="" />
            </Col>
          </Row>
          <InputHeader style={{ marginTop: 16 }}>Email</InputHeader>
          <Input
            placeholder="Enter Email"
            maxLength={40}
            type={"texts"}
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
          />

          <InputHeader>Password</InputHeader>
          <Input
            placeholder="Enter Password"
            maxLength={40}
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <SubmitButton type="submit" disabled={loginButtonDisabled}>
            LOG IN
          </SubmitButton>
          <GoToRegister>
            <Link to="/register">Sign up for {appName}</Link>
          </GoToRegister>
        </MobileContainer>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-width: 250px !important;
  text-align: left;
`;

const MobileContainer = styled.div`
  width: 90%;
  text-align: left;
  margin: auto;
  margin-top: 50px;
`;

const InnerSubheader = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

const LeftContainer = styled.div`
  background: lightblue;
`;

const Subheader = styled.h4`
  font-size: 12px;
  color: white;
`;

const InputHeader = styled.div`
  font-size: 12px;
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 4px;
`;

const GoToRegister = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
  width: 100%;
  margin-top: 22px;
  text-align: center;
  margin-bottom: 80px;
`;

const SubmitButton = styled.button`
  font-size: 12px;
  display: block;
  font-weight: bold;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  border-radius: 24px;
  border: 0;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  color: ${(props) => (props.disabled ? "black" : "white")};
  background: ${(props) =>
    props.disabled ? "var(--color-gray-50)" : "var(--lightPurple)"};
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
`;

const InnerHeader = styled.span`
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;
  vertical-align: super;
  display: flex;
  cursor: pointer;
  width: fit-content;
`;

const HeaderText = styled.div`
  font-size: 34px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 12px;
  vertical-align: super;
`;

const Image = styled.img`
  height: auto;
  width: 100%;
`;
