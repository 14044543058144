import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getUserInfo } from "../../helpers/firebase";
import { Col, Row } from "antd";
import { getDaysLeft } from "../../helpers/general";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../helpers/authentication";
import conversationsModel from "../../lib/firebase/conversationsModel";

export default function SubscriberEntry({ subscription }) {
  const history = useHistory();

  const [subscriber, setSubscriber] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const result = await getUserInfo(subscription.subscriber);
      setSubscriber(result);
    }

    if (subscription) fetchData();
  }, [subscription]);

  const subscriptionConvoQuery = useQuery({
    queryKey: [`subscription-convo-${subscription && subscription.id}`],
    queryFn: async () => {
      if (!currentUser) return;
      const subscriberConversations = await conversationsModel.getMany(
        ["creator", "==", currentUser.uid],
        ["subscriber", "==", subscription.subscriber]
      );
      return subscriberConversations.length > 0
        ? subscriberConversations[0]
        : null;
    },
  });

  if (!subscriber) return;
  if (subscriptionConvoQuery.status !== "success") return;

  return (
    <Container>
      <Row>
        <Col span={12}>
          <Name>{subscriber.name}</Name>
        </Col>
        <Col span={8}>
          <Expiration>
            Expires in {getDaysLeft(subscription.expiresAt)} days
          </Expiration>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Message
            onClick={() => {
              history.push(`/messages/${subscriptionConvoQuery.data.id}`);
            }}
            src="/icons/nav-menu/message.svg"
            alt=""
          />
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 24px;
  border-bottom: 1px solid var(--color-gray-50);
  background: white;
  text-align: left;
`;

const Message = styled.img`
  height: 18px;
  weight: auto;
  margin-top: 2px;
`;

const Name = styled.div`
  font-weight: 800;
  font-size: 14px;
  margin-top: 2px;
`;

const Expiration = styled.div`
  font-weight: 400;
  font-size: 11px;
  margin-top: 4px;
`;
