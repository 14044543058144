import React, { useEffect } from "react";
import { Divider, Image } from "antd";
import { useState } from "react";
import EpisodeListEntry from "./EpisodeListEntry";
import pluralize from "pluralize";

function EpisodeList({ episodes = [], storyworldId, noEpisodes }) {
  const [isReversed, setIsReversed] = useState(false);
  const [sortedEpisodes, setSortedEpisodes] = useState(episodes);

  const switchSorting = () => {
    if (isReversed) {
      episodes = episodes.sort(function (a, b) {
        return b.publishedAt - a.publishedAt;
      });
      setIsReversed(false);
    } else {
      episodes = episodes.sort(function (a, b) {
        return a.publishedAt - b.publishedAt;
      });
      setIsReversed(true);
    }
    setSortedEpisodes(episodes);
  };

  useEffect(() => {
    if (episodes !== null) {
      let eps;
      if (isReversed) {
        eps = episodes.sort(function (a, b) {
          return a.publishedAt - b.publishedAt;
        });
      } else {
        eps = episodes.sort(function (a, b) {
          return b.publishedAt - a.publishedAt;
        });
      }
      setSortedEpisodes(eps);
    }
  }, [episodes, isReversed]);

  return (
    <div style={{ marginBottom: 100 }}>
      <div className="right episode-list-amount">
        {pluralize("Episode", episodes !== null ? episodes.length : 0, true)}
        {!isReversed ? (
          <Image
            onClick={switchSorting}
            preview={false}
            style={{ margin: "auto 14px -4px 8px", height: 16 }}
            src={"/icons/sort.svg"}
          />
        ) : (
          <Image
            onClick={switchSorting}
            preview={false}
            className="flipped-y"
            style={{ margin: "auto 14px -4px 8px", height: 16 }}
            src={"/icons/sort.svg"}
          />
        )}
      </div>
      <Divider plain style={{ marginTop: 10, marginBottom: 10 }} />

      {noEpisodes === true ? (
        <div>No episodes yet</div>
      ) : noEpisodes === false ? (
        sortedEpisodes.map((ep, index) => (
          <span key={index}>
            <div className="episode-section">
              <EpisodeListEntry
                episode={ep}
                storyworldId={storyworldId}
                bookmarked={ep.bookmarked}
                read={ep.read}
              />
            </div>
            {index !== episodes.length - 1 ? (
              <Divider plain style={{ marginTop: 10, marginBottom: 10 }} />
            ) : null}
          </span>
        ))
      ) : (
        <Image
          className="spinner"
          style={{ width: "50px", margin: "10px auto" }}
          preview={false}
          src={"/icons/spinner.svg"}
        />
      )}
    </div>
  );
}

export default EpisodeList;
