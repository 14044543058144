import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HomePosts, Post } from ".";
import { sleep } from "../helpers/general";

export default function Featured() {
  const [waited, setWaited] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await sleep(1);
      setWaited(true);
    }
    fetchData();
  }, []);

  return (
    <Container>
      <HeaderSection>
        <Header>
          <Stars src="/images/general/stars.svg" alt="" />
          New Posts
        </Header>
        <Subheader>Discover your favorite creator</Subheader>
      </HeaderSection>
      {waited ? (
        <HomePostsContainer>
          <HomePosts />
        </HomePostsContainer>
      ) : (
        <SpinnerContainer>
          <Spinner src="/icons/general/loading-black.svg" alt="" />
        </SpinnerContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-right: none !important;
  text-align: left;
`;

const HeaderSection = styled.div`
  margin-left: 24px;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-top: 30px;
  padding-bottom: 10px;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
`;

const HomePostsContainer = styled.div`
  margin-top: 40px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
`;

const Spinner = styled.img`
  width: 36px;
  height: auto;
  animation: rotation 2s infinite linear;
`;

const Stars = styled.img`
  width: 20px;
  height: auto;
  margin-right: 6px;
`;
