import { likesTable, submissionsTable } from "../../helpers/constants";
import { queryMany, batchQuery } from "./all";

const table = submissionsTable;

const submissionModel = {
  getManyByUserId: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["creator", "==", userId], ...args],
    });

    return data;
  },
  getManyLikedByUserId: async (userId) => {
    const likes = await queryMany({
      table: likesTable,
      conditions: [["creator", "==", userId]],
    });

    const subSetId = new Set();
    likes.forEach((l) => subSetId.add(l.submissionId));

    if (subSetId.size === 0) return [];

    const submissions = await batchQuery({
      table,
      ids: Array.from(subSetId),
    });

    return submissions;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default submissionModel;
