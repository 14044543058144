import React from "react";
import styled from "styled-components";

export default function ConsistencyCarouselThree({ email }) {
  return (
    <Container>
      <PromptImage src={"/images/consistency/face.svg"} alt="" />
      <Subheader>
        After posting your first content, go back into the content creation menu
        and select your previous post. Then, enter a new prompt that you want to
        see with your model's likeness.
      </Subheader>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 0px;
`;

const PromptImage = styled.img`
  max-width: 330px;
  height: auto;
  text-align: center;
  margin: auto;
  box-shadow: inse 1px 0px 0px #111315;
  border: 1px solid var(--color-gray-50);
  border-radius: 5px;
`;
