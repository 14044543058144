import React, { useEffect } from "react";
import styled from "styled-components";

export default function SubmitGenerationSuccess({
  setShowBackground,
  setIsSubmittingArt,
  setIsSubmitting,
}) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <SubmittingSection>
      <PaintImage src={"/images/gen-ai/paint-image.svg"} alt="" />
      <Success>Success!</Success>
      <Subtext>
        Great Scott, you may have just altered the course of the future.
      </Subtext>
      <Subtext>
        Come back <Highlighted>when voting opens</Highlighted> to determine
        where the story should go!
      </Subtext>
      <BackToQuestButton
        onClick={() => {
          setShowBackground(false);
          setIsSubmittingArt(false);
          setIsSubmitting(false);
        }}
      >
        Back to the quest
      </BackToQuestButton>
    </SubmittingSection>
  );
}

const SubmittingSection = styled.div`
  margin: auto;
  margin-top: 0%;
  text-align: center !important;
  width: 90%;
  position: relative;
`;

const PaintImage = styled.img`
  width: 55%;
  height: auto;
  margin-top: 18%;
`;

const Success = styled.h4`
  width: 100%;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  margin-top: 10%;
  margin-bottom: -2%;
  color: var(--color-mint);
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.058px;
`;

const Subtext = styled.p`
  margin-top: 7%;
  color: var(--color-cream);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const Highlighted = styled.span`
  color: var(--color-pink);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.2px;
`;

const BackToQuestButton = styled.button`
  border-radius: 50px;
  background: var(--lightPurple);
  border: none;
  padding: 8px 10px;
  width: 100%;
  margin-top: 34px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--color-cream);
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;
