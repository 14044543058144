import React, { useEffect, useState } from "react";
import styled from "styled-components";

const defaultReadMoreThreshold = 50;
const lineHeight = 22;

const Content = ({ text, threshold }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    if (text && text.length < threshold) setIsReadMore(false);
  }, [text, threshold]);

  return (
    <TextContainer>
      {isReadMore && text ? `${text.slice(0, threshold)} ...` : text}
      {text && text.length > threshold && (
        <MoreText onClick={toggleReadMore}>
          {isReadMore ? " More" : " Less"}
        </MoreText>
      )}
    </TextContainer>
  );
};

const ReadMore = ({ text, threshold = defaultReadMoreThreshold }) => {
  return (
    <Container
      onScroll={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Content text={text} threshold={threshold} />
    </Container>
  );
};

export default ReadMore;

const Container = styled.div`
  overflow-y: scroll;
  max-height: fit-content;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const TextContainer = styled.p`
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight}px;
  letter-spacing: -0.14px;
  text-align: left;
`;

const MoreText = styled.span`
  color: var(--blueHeader);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: ${lineHeight}px;
  letter-spacing: -0.14px;
  white-space: nowrap;
`;
