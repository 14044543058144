import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../helpers/authentication";
import subscriptionModel from "../../lib/firebase/subscriptionModel";

const minimumPayout = 100;

export default function Statements({
  totalUnpaidRevenue,
  currentSubscriberCount,
}) {
  const totalSubscriptionQuery = useQuery({
    queryKey: ["global-total-subscriptions"],
    queryFn: async () => {
      if (!currentUser) return null;
      const posts = await subscriptionModel.count();
      return posts;
    },
  });

  return (
    <>
      <PercentHeader>
        <Star src="/icons/gen-ai/star-blue.svg" alt="" />
        You are in the top{" "}
        {100 -
          (
            (currentSubscriberCount /
              (totalSubscriptionQuery.status === "success" &&
                totalSubscriptionQuery.data)) *
            100
          ).toFixed(2)}
        % of all creators!
      </PercentHeader>
      <RevenueSection>
        <Row>
          <Col span={12} className="row-col row-col-left">
            <MoneyHeader>${totalUnpaidRevenue}</MoneyHeader>
            <MoneySubheader>Current balance</MoneySubheader>
          </Col>
          <Col span={12} className="row-col row-col-right">
            <MoneyHeader>$0.00</MoneyHeader>
            <MoneySubheader>Pending balance</MoneySubheader>
          </Col>
        </Row>
      </RevenueSection>
      <ManualPayoutSection>
        <PayoutHeader>Manual payouts</PayoutHeader>
        <PayoutSubheader>
          Minimum withdrawal amount is ${minimumPayout}
        </PayoutSubheader>
        <WithdrawalButton
          disabled={totalUnpaidRevenue < minimumPayout}
          onClick={() => {
            console.log("request withdrawal");
          }}
        >
          Request Withdrawal
        </WithdrawalButton>
      </ManualPayoutSection>
    </>
  );
}

const PercentHeader = styled.div`
  width: 100%;
  text-align: left;
  padding: 14px 26px;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  background: white;
`;

const Star = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 8px;
`;

const RevenueSection = styled.div`
  text-align: left;
  background: white;

  .row-col {
    border-top: 1.5px solid var(--color-light-gray);
    border-bottom: 1.5px solid var(--color-light-gray);
    padding: 18px;
  }

  .row-col-right {
    border-left: 0.75px solid var(--color-light-gray);
  }

  .row-col-left {
    border-right: 0.75px solid var(--color-light-gray);
  }
`;

const MoneyHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const MoneySubheader = styled.div`
  font-size: 15px;
  margin-top: 4px;
  font-weight: 500;
`;

const ManualPayoutSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1.5px solid var(--color-light-gray);
  background: white;
  padding: 18px;
  text-align: left;
  position: relative;
`;

const PayoutHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const PayoutSubheader = styled.div`
  font-size: 12px;
  margin-top: 4px;
  font-weight: 500;
  color: var(--color-gray-100);
  margin-bottom: 42px;
`;

const WithdrawalButton = styled.button`
  font-size: 12px;
  margin-top: 4px;
  font-weight: 600;
  color: white;
  position: absolute;
  background: var(--color-main-blue);
  border: 0;
  border-radius: 20px;
  padding: 10px 12px;
  right: 10px;
  bottom: 10px;
  text-transform: uppercase;
  opacity: ${(props) => props.disabled && "0.5"};
`;
