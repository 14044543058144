import React from "react";
import UserDisplay from "../components/UserDisplay";
import { discordLink } from "../helpers/constants";
import { styled } from "styled-components";

export default function SubmissionsDisplayEmpty({
  currentUserSubmission,
  pollCompleted,
  currentUser,
  pollOpen,
  questPollState,
  confirmedSubmissions,
  runnerUpSubmissions,
}) {
  return (
    <Container>
      {currentUserSubmission === null || pollCompleted ? null : (
        <>
          <SuccessTitle>✨ Success ✨</SuccessTitle>
          <h4 className="submission-ready-text" style={{ marginBottom: 14 }}>
            Nice work! Come hang out with the quest community in the{" "}
            <DiscordLink href={discordLink} rel="noreferrer" target="_blank">
              Storygrounds Discord
            </DiscordLink>{" "}
            ✌️
          </h4>
        </>
      )}
      {pollCompleted ? null : (
        <SubmissionsContainerMaster>
          {currentUserSubmission === null ? (
            <div
              className="quest-submission-preview-container"
              style={{
                border: "1px dashed #D3E349",
                height: "98px",
              }}
            >
              <div className="profile-bug-overlay">
                <UserDisplay userId={currentUser.uid} size={30} />
              </div>
              <div className="quest-submission-preview" />
            </div>
          ) : (
            <div
              className="quest-submission-preview-container"
              style={{
                border: "1px solid #D3E349",
                height: "98px",
              }}
            >
              <div className="profile-bug-overlay">
                <UserDisplay userId={currentUser.uid} size={30} />
              </div>
              <div
                className={
                  !pollOpen || (pollOpen && !questPollState.poll.confirmed)
                    ? "quest-submission-preview quest-submission-preview-blurred"
                    : "quest-submission-preview"
                }
                style={{
                  backgroundImage: `url("${currentUserSubmission.imageUrl}")`,
                }}
              />
            </div>
          )}
          {confirmedSubmissions.map((submission, index) => (
            <div className="quest-submission-preview-container">
              <div className="profile-bug-overlay">
                <UserDisplay userId={submission.creator} size={30} />
              </div>
              <div
                className={
                  !pollOpen || (pollOpen && !questPollState.poll.confirmed)
                    ? "quest-submission-preview quest-submission-preview-blurred"
                    : "quest-submission-preview"
                }
                style={{
                  backgroundImage: `url("${submission.imageUrl}")`,
                }}
              />
            </div>
          ))}
        </SubmissionsContainerMaster>
      )}
      {pollCompleted
        ? runnerUpSubmissions.map((submission, index) => (
            <SubmissionContainer>
              <div className="user-display-result">
                <UserDisplay
                  size={18}
                  userId={submission.creator}
                  showName={true}
                />
              </div>
            </SubmissionContainer>
          ))
        : null}
    </Container>
  );
}

const Container = styled.h3`
  margin-top: 30px;
`;

const SubmissionContainer = styled.div`
  margin-top: 28px;
`;

const SuccessTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: white;
`;

const DiscordLink = styled.a`
  font-weight: bold;
`;

const SubmissionsContainerMaster = styled.div`
  display: contents;
  margin: auto;
  text-align: center;
`;
