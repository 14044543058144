import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Collapse, Row, Tooltip } from "antd";
import moment from "moment";
import { Comment } from "@ant-design/compatible";
import { useQuery } from "@tanstack/react-query";
import userModel from "../../lib/firebase/userModel";
import UserDisplay from "../UserDisplay";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LikeButton from "./../LikeButton";
import CommentDropdownButton from "./CommentDropdownButton";
import { currentUser } from "../../helpers/authentication";
import pluralize from "pluralize";
import { ReadMore } from "../general";

const Panel = Collapse.Panel;

export default function PostedComment({
  comment,
  backLocations,
  setBackLocations,
  showReply = true,
  setEditComment,
  setReplyTo,
  setComments,
  commentInputRef,
  children,
  replyToSubject = null,
}) {
  const location = useLocation();

  const [sortedChildren, setSortedChildren] = useState(false);

  useEffect(() => {
    if (children && children.length > 0) {
      const sorted = children.sort(function (a, b) {
        return a.createdAt - b.createdAt;
      });
      setSortedChildren(sorted);
    }
  }, [children]);

  const userQuery = useQuery({
    queryKey: ["user", comment.creator],
    queryFn: () => userModel.getOneById(comment.creator),
  });

  return (
    <Container>
      <Comment
        style={{ marginBottom: children && children.length > 0 && "-6px" }}
        actions={[
          <Row>
            <Col span={8} style={{ marginTop: 4 }}>
              {/*
              {showReply && (
                <ReplySpan
                  onClick={() => {
                    setEditComment(null);
                    setReplyTo(replyToSubject ? replyToSubject : comment);
                    commentInputRef.current.focus();
                  }}
                >
                  Reply
                </ReplySpan>
              )}
              */}
            </Col>
            <Col span={16} style={{ textAlign: "right" }}>
              {showReply && (
                <CommentDropdownButton
                  comment={comment}
                  children={children}
                  setEditComment={setEditComment}
                  isCurrentUser={comment.creator === currentUser.uid}
                  commentInputRef={commentInputRef}
                  setComments={setComments}
                />
              )}
              <HorizontalSpacer />
              <LikeButton likeSubject={comment} />
            </Col>
          </Row>,
        ]}
        author={userQuery.status === "success" && userQuery.data.username}
        avatar={
          <UserDisplay
            userId={comment.creator}
            showName={false}
            size={comment.replyTo === null ? 38 : 22}
            isWinner={true}
            backLocation={location.pathname}
            backLocations={backLocations}
            setBackLocations={setBackLocations}
          />
        }
        content={
          <CommentText>
            <ReadMore
              text={comment.comment}
              threshold={100}
              showEdited={comment.edited}
              showLess={false}
            />
          </CommentText>
        }
        datetime={
          <Tooltip
            title={moment(comment.createdAt).format("MMMM D YYYY, h:mm:ss a")}
          >
            <MomentSpan>• {moment(comment.createdAt).fromNow(true)}</MomentSpan>
          </Tooltip>
        }
      />
      {children && children.length > 0 && (
        <ChildrenContainer>
          <Collapse expandIconPosition="end">
            <Panel
              header={
                <>
                  <Line /> {pluralize("Reply", children.length, true)}
                </>
              }
              key="1"
            >
              {sortedChildren &&
                sortedChildren.length > 0 &&
                sortedChildren.map((c, index) => (
                  <PostedComment
                    comment={c}
                    key={index}
                    backLocations={backLocations}
                    setBackLocations={setBackLocations}
                    setEditComment={setEditComment}
                    commentInputRef={commentInputRef}
                    setComments={setComments}
                    setReplyTo={setReplyTo}
                    replyToSubject={comment}
                    children={[]}
                  />
                ))}
            </Panel>
          </Collapse>
        </ChildrenContainer>
      )}
    </Container>
  );
}

const Container = styled.span`
  .ant-comment-content-author-name {
    color: black;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }

  .ant-avatar-circle img {
    width: 100%;
    height: 100%;
  }

  .user-display-button {
    margin: 0px !important;
  }

  .ant-comment-content-author-time {
    color: var(--disabled-button-text);
    leading-trim: both;
    text-edge: cap;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
  }

  .ant-comment-actions {
    margin-top: 4px;
  }

  .ant-comment-actions li {
    width: 100%;
  }

  .like-button-no-likes {
    height: 30px !important;
  }

  .like-button-liked {
    height: 30px !important;
  }

  .ant-comment-avatar {
    height: fit-content;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: 48px;
  margin-bottom: 10px;

  .ant-collapse {
    background: transparent;
    border: 0;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content {
    border: 0;
    background: transparent;
    outline: 0;
    margin-bottom: -30px;
  }

  .ant-comment-inner {
    padding: 0;
  }

  .ant-collapse-header-text {
    color: var(--disabled-button-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px; /* 189.286% */
  }

  .ant-collapse-arrow {
    left: 105px !important;
    scale: -1;
  }

  .ant-collapse-item > .ant-collapse-header .anticon {
    transform: rotate(-90deg) !important;
    transition: transform 0.5s ease-in-out;
    top: 30%;
    color: var(--disabled-button-text);
    font-weight: bold;
  }

  .ant-collapse-item {
    border: 0;
  }

  .ant-collapse-item-active > .ant-collapse-header .anticon {
    transform: rotate(0deg) !important;
  }

  .ant-collapse-content-box {
    padding: 10px 0px;
  }

  .ant-collapse-content-box {
    padding: 16px 0px !important;
  }

  .ant-comment {
    margin-bottom: 20px;
  }
`;

const MomentSpan = styled.span`
  margin-left: -3px;
`;

const Line = styled.img`
  width: 20px;
  height: 1px;
  border: 0.5px solid #35383f;
  margin-right: 4px;
`;

const ReplySpan = styled.span`
  color: var(--disabled-button-text) !important;
  /* Small Body Text (Default) */
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
`;

const CommentText = styled.p`
  color: var(--color-cream-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .read-more-content {
    color: var(--color-cream-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const HorizontalSpacer = styled.span`
  margin-right: 6px;
`;
