import React from "react";
import { Col, Row } from "antd";
import GeneratedImage from "../views/GeneratedImage";
import styled from "styled-components";

function ImageGrid({
  selectedImage,
  genAiImages,
  isButtonEnabled,
  setSelectedImage,
  setImageUrl,
  setOpen,
}) {
  return (
    <Images>
      <Row gutter={14}>
        <Col span={12}>
          <GeneratedImage
            genImage={genAiImages[0].url}
            isGenerating={!isButtonEnabled}
            hasResults={genAiImages.length > 0}
            setSelectedImage={setSelectedImage}
            setImageUrl={setImageUrl}
            selectedImage={selectedImage}
            setOpen={setOpen}
          />
        </Col>
        <Col span={12}>
          <GeneratedImage
            genImage={genAiImages[1].url}
            isGenerating={!isButtonEnabled}
            hasResults={genAiImages.length > 0}
            setSelectedImage={setSelectedImage}
            setImageUrl={setImageUrl}
            selectedImage={selectedImage}
            setOpen={setOpen}
          />
        </Col>
      </Row>
      <Row gutter={14} style={{ marginTop: 12 }}>
        <Col span={12}>
          <GeneratedImage
            genImage={genAiImages[2].url}
            isGenerating={!isButtonEnabled}
            hasResults={genAiImages.length > 0}
            setSelectedImage={setSelectedImage}
            setImageUrl={setImageUrl}
            selectedImage={selectedImage}
            setOpen={setOpen}
          />
        </Col>
        <Col span={12}>
          <GeneratedImage
            genImage={genAiImages[3].url}
            isGenerating={!isButtonEnabled}
            hasResults={genAiImages.length > 0}
            setSelectedImage={setSelectedImage}
            setImageUrl={setImageUrl}
            selectedImage={selectedImage}
            setOpen={setOpen}
          />
        </Col>
      </Row>
    </Images>
  );
}

export default ImageGrid;

const Images = styled.div`
  margin: auto !important;
  display: block;
`;
