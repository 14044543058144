import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../helpers/authentication";
import SinglePost from "../components/posts/SinglePost";
import postsModel from "../lib/firebase/postsModel";

const postRetrievalCount = 10;

export default function HomePosts() {
  const postsQuery = useQuery({
    queryKey: [`home-posts-${currentUser && currentUser.uid}`],
    queryFn: async () => {
      if (!currentUser) return null;
      const newPosts = await postsModel.getMany(
        ["sort", "createdAt", "desc"],
        ["public", "==", true],
        ["limit", postRetrievalCount]
      );
      return newPosts;
    },
  });

  return (
    <Container>
      {postsQuery.status === "success" &&
        postsQuery.data &&
        postsQuery.data.map((post, key) => (
          <SinglePost post={post} key={key} isSubscribed={true} />
        ))}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-right: none !important;
  text-align: left;
`;
