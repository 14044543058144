import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Table, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../helpers/authentication";
import subscriptionModel from "../../lib/firebase/subscriptionModel";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <RowHeader>{text}</RowHeader>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
];

export default function Overview({
  payments,
  unpaidPayments,
  totalRevenue,
  totalUnpaidRevenue,
  currentSubscriberCount,
}) {
  const history = useHistory();

  const onCancel = () => {
    history.goBack();
  };

  const data = [
    {
      key: "1",
      name: (
        <span>
          <RowTitle>Current Subscribers</RowTitle>
          <Tooltip
            title={`Number of users currently subscribed to your content`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      amount: <DataField>{currentSubscriberCount}</DataField>,
    },
    {
      key: "2",
      name: (
        <span>
          <RowTitle>Lifetime Payments</RowTitle>
          <Tooltip
            title={`Total payments made to you across the lifetime of your account`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      amount: <DataField>{payments.length}</DataField>,
    },
    {
      key: "3",
      name: (
        <span>
          <RowTitle>Lifetime Revenue</RowTitle>
          <Tooltip
            title={`Total revenue for payments made across the lifetime of your account`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      amount: <DataField>${totalRevenue}</DataField>,
    },
  ];

  return (
    <>
      <FieldContainer style={{ marginTop: 24 }}>
        <BalanceHeader>
          Available Balance{" "}
          <Tooltip
            title={`Funds available for transfer from your profile to your bank account`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </BalanceHeader>
        <RevenueValue>${totalUnpaidRevenue}</RevenueValue>
        <RevenueText>From {unpaidPayments.length} Subscribers</RevenueText>
      </FieldContainer>

      <FieldContainer>
        <Table
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
        />
      </FieldContainer>
    </>
  );
}

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 34px;
`;

const BalanceHeader = styled.div`
  color: black;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 4px;
`;

const RevenueValue = styled.div`
  color: black;
  font-size: 34px;
  font-style: normal;
  text-align: center;
  font-weight: 800;
  line-height: 160%; /* 22.4px */
`;

const RevenueText = styled.div`
  color: black;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 8px;
`;

const RowHeader = styled.span`
  color: black;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
`;

const DataField = styled.div`
  color: black;
  font-size: 14px;
  font-style: normal;
  text-align: right;
  font-weight: 400;
`;

const RowTitle = styled.span`
  margin-right: 4px;
`;
