import React from "react";
import styled from "styled-components";

export default function ConsistencyCarouselTwo({ email }) {
  return (
    <Container>
      <PostImage src={"/images/consistency/post-ui.svg"} alt="" />
      <Subheader>
        To get started, enter our content creation suite and enter a prompt for
        your perfect model.
      </Subheader>
      <PromptImage src={"/images/consistency/prompt-ui.svg"} alt="" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 12px;
`;

const PostImage = styled.img`
  max-width: 300px;
  height: auto;
  text-align: center;
  margin: auto;
  box-shadow: inset 1px 0px 0px #111315;
  border: 1px solid var(--color-gray-50);
  border-radius: 5px;
`;

const PromptImage = styled.img`
  max-width: 320px;
  height: auto;
  text-align: center;
  margin: auto;
  box-shadow: inse 1px 0px 0px #111315;
`;
