import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";
import { getUserInfo } from "../../helpers/firebase";
import { currentUser } from "../../helpers/authentication";
import { sendReport } from "../../helpers/report";
import { Col, Row, message, notification } from "antd";
import reportModel from "../../lib/firebase/reportModel";
import { getComments } from "../../helpers/general";

export default function CommentReportSheet({
  isOpen,
  setOpen,
  comment,
  setComments,
}) {
  const [api, contextHolder] = notification.useNotification();
  const [isSending, setIsSending] = useState(false);

  const openNotification = () => {
    message.success("We have received your report. Thank you!", [10]);
    /*
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col span={22}>We have received your report. Thank you!</Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "report-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
    });
    */
  };

  useEffect(() => {
    if (isOpen) setIsSending(false);
  }, [isOpen]);

  const startSendingReport = async () => {
    setIsSending(true);
    const reportedByUser = await getUserInfo(currentUser.uid);
    const reportedUser = await getUserInfo(comment.creator);
    const data = {
      type: "comment",
      comment: comment.comment,
      profileLink: window.location.toString(),
      reportedBy: {
        username: reportedByUser.username,
        email: reportedByUser.email,
        id: reportedByUser.id,
      },
      reportedUser: {
        username: reportedUser.username,
        email: reportedUser.email,
        id: reportedUser.id,
      },
    };
    const result = await sendReport(data);
    if (result.success) {
      openNotification();
      await reportModel.create({ subjectId: comment.id });
      setComments(await getComments(comment.subjectId));
      setIsSending(false);
      setOpen(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[250]}
        style={{
          pointerEvents: isOpen ? "auto" : "none",
          background: isOpen ? "rgba(0,0,0,0.5)" : null,
        }}
        onClick={(e) => {
          if (isOpen) setOpen(false);
        }}
        tweenConfig={{ ease: "easeOut", duration: 0.3 }}
        className="contributor-sheet"
      >
        <Sheet.Container
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="comment-sheet-container"
        >
          <Sheet.Header />
          <Sheet.Content className="comment-sheet-content">
            <InnerContainer>
              <Header>Report this comment?</Header>
              <Content>
                Please submit a report if you consider this comment to be
                violating our{" "}
                <HighlightText
                  onClick={() => {
                    window.open("/content-and-community-guidelines");
                  }}
                >
                  community guidelines
                </HighlightText>
                .
              </Content>
              {!isSending ? (
                <SendReportButton
                  onClick={async () => await startSendingReport()}
                >
                  Report
                </SendReportButton>
              ) : (
                <SendReportButton disabled>
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                </SendReportButton>
              )}
            </InnerContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

const Header = styled.div`
  color: black;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 14px;
`;

const Content = styled.div`
  color: black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  margin-top: 12px;
`;

const InnerContainer = styled.div`
  width: 85%;
  margin: 0px auto;
  text-align: center;
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const SendReportButton = styled.button`
  width: 100%;
  height: 50px;
  background: var(--color-main-blue);
  border: 0;
  border-radius: 90px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: stretch;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;

const HighlightText = styled.span`
  color: var(--color-main-blue);
`;

const ProfileImage = styled.img`
  height: 82px;
  width: 82px;
  text-align: center;
  margin-top: 14px;
`;
