import { message, Upload } from "antd";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { generateRandomId } from "../helpers/firebase";
import { useState } from "react";
import styled from "styled-components";
import { currentUser } from "../helpers/authentication";

const fileBucket = "modelTrainingImages";

export default function UploadModelImages({
  imageUrls,
  setImageUrls,
  setHasUploadedImages,
  maxFileSizeInMb,
  maxWidth,
  maxHeight,
  minWidth,
  byPassRes = false,
  isDisabled = false,
  imageBlobs,
}) {
  const { Dragger } = Upload;
  const storage = getStorage();

  const [isUploading, setIsUploading] = useState(false);

  const beforeUpload = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("Please upload JPG/JPEG/PNG images only");
    }
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeInMb;
    if (!isLt2M) {
      message.error(`Image must be smaller than ${maxFileSizeInMb}MB`);
    }
    const isRightResolution = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          var goodRes;
          if (minWidth) {
            goodRes = width >= minWidth;
          } else if (maxWidth && maxHeight) {
            goodRes = width === maxWidth && height === maxHeight;
          } else if (byPassRes) {
            goodRes = true;
          }
          if (!goodRes) {
            message.error(`Image must be the correct resolution`);
            resolve(false);
          }
          resolve(true);
        });
      });
    });
    return isJpgOrPng && isLt2M && isRightResolution;
  };

  const props = {
    name: "image",
    multiple: true,
    accept: ".jpeg,.jpg,.png",
    beforeUpload,
    action: async (files) => {
      console.log(files);
      setIsUploading(true);
      setHasUploadedImages(true);
      console.log(files);
      const file = files;

      if (file) {
        const storageRef = ref(
          storage,
          `${fileBucket}/${
            currentUser.uid
          }/${new Date()} -- ${generateRandomId()}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // handle progress state changes here
          },
          (error) => {
            console.error(error);
            message.error(`${file.name} file upload failed.`);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // setImageBlobs([file]);
              console.log(downloadURL);
              // setImageUrls([downloadURL]);
              // setImageFilenames(file.name);
              // const newUrls = [...imageUrls];
              const newObj = {
                url: downloadURL,
                caption: "",
              };
              // newUrls.push(downloadURL);
              // setImageUrls(newUrls);
              setImageUrls((imageUrls) => [...imageUrls, newObj]);
              console.log(imageUrls);
              return;
            } catch (error) {
              console.error(error);
            }
          }
        );
      }
      setIsUploading(false);
    },
  };

  /*
  const newProps = {
    name: "file",
    multiple: true,
    action: null,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      setIsUploading(true);
      setHasUploadedImages(true);
      console.log(e.dataTransfer.files);
      const fileUrls = [];
      const fileBlobs = [];

      e.dataTransfer.files.forEach((file) => {
        const storageRef = ref(
          storage,
          `${fileBucket}/${
            currentUser.uid
          }/${new Date()} -- ${generateRandomId()}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // handle progress state changes here
          },
          (error) => {
            console.error(error);
            message.error(`${file.name} file upload failed.`);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // setImageBlobs([file]);
              fileBlobs.push(file);
              console.log(downloadURL);
              fileUrls.push(downloadURL);
              setImageUrls(fileUrls);
              // setImageFilenames(file.name);
              return;
            } catch (error) {
              console.error(error);
            }
          }
        );
      });
      setIsUploading(false);
      setImageBlobs(fileBlobs);
      setImageUrls(fileUrls);
    },
  };
  */

  return (
    <Container>
      {isUploading ? (
        <LoadingContainer>
          <Spinner src="/icons/loading-spheres.svg" />
        </LoadingContainer>
      ) : (
        <InnerContainer>
          <Dragger {...props} disabled={isDisabled}>
            <span
              className="ant-upload-drag-icon"
              style={{ marginBottom: 8, marginRight: 4 }}
            >
              <UploadIcon src="/icons/gen-ai/upload.svg" alt="" />
            </span>
            {/*
            <UploadHint style={{ marginLeft: 6, marginTop: 6 }}>
              Tap to upload an image
            </UploadHint>
            */}
          </Dragger>
        </InnerContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  min-height: 100px;
  width: 100%;

  .ant-upload {
    text-align: center !important;
    padding: 0px !important;
  }

  .ant-upload-drag-container {
    border: 1px dashed var(--color-gray-50);
    border-radius: 8px;
  }

  .ant-upload-list-item-container {
    display: none;
  }
`;

const InnerContainer = styled.div`
  border-radius: 8px;
  border-radius: 8px;
  background: none;
  margin: auto;
  margin-top: 6px;
  text-align: center;

  .ant-upload {
    padding: 0px !important;
    text-align: left;
  }

  .ant-upload.ant-upload-drag {
    border: 0 !important;
  }
`;

const UploadHint = styled.span`
  color: #747887;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px 
  letter-spacing: 0.2px;
`;

const UploadIcon = styled.img`
  width: auto;
  height: 70px;
  padding: 0px 0px 0px 1px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  opacity: 0.2;
`;

const LoadingContainer = styled.div`
  display: block;
  text-align: center;
`;

const Spinner = styled.img`
  height: 50px;
  width: auto;
  margin: auto;
  display: inline-flex;
  margintop: 40px;
  animation: rotation 2s infinite linear;
`;
