import React from "react";
import { Button } from "antd";
import { firebaseAuth } from "../helpers/authentication";

function Authentication() {
  return (
    <div>
      <div
        style={{
          textAlign: "left",
          padding: 16,
          width: "80%",
          margin: "auto",
          marginTop: 64,
        }}
      >
        <Button
          style={{ margin: "auto", marginTop: 10, maxWidth: 200 }}
          onClick={async () => {
            firebaseAuth.signOut().then(
              function () {
                console.log("Signed Out");
                window.location.href = "/";
              },
              function (error) {
                console.error("Sign Out Error", error);
              }
            );
          }}
          size="large"
          shape="round"
        >
          Log Out
        </Button>
      </div>
    </div>
  );
}

export default Authentication;
