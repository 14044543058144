import React, { useEffect, useState } from "react";
import { currentUser, firebaseAuth } from "../helpers/authentication";
import styled from "styled-components";
import { TabItem } from "../components/TabItem";
import { useQuery } from "@tanstack/react-query";
import userModel from "../lib/firebase/userModel";
import postsModel from "../lib/firebase/postsModel";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import ProfileArtTab from "./ProfileArtTab";
import ProfileLikedTab from "./ProfileLikedTab";
import mixpanel from "mixpanel-browser";
import { employeeIds } from "../helpers/employees";
import { ReadMore } from "../components/general";
import pluralize from "pluralize";

export default function UserProfile({ backLocations, setBackLocations }) {
  const [tab, setTab] = useState("posts");

  const userQuery = useQuery({
    queryKey: ["user-self"],
    queryFn: () => {
      const user = getAuth().currentUser;
      if (!user) return null;
      return userModel.getOneById(user.uid);
    },
  });

  const postQuery = useQuery({
    queryKey: ["user-self-art"],
    queryFn: () => {
      const user = getAuth().currentUser;
      if (!user) return null;
      return postsModel.getManyByUserId(user.uid, [
        "sort",
        "createdAt",
        "desc",
      ]);
    },
  });

  const onLogout = async () => {
    try {
      await firebaseAuth.signOut();
      window.location = "/login";
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  };

  useEffect(() => {
    if (currentUser && !employeeIds.includes(currentUser.uid)) {
      mixpanel.track("Visited own profile");
    }
  }, []);

  return (
    <div>
      <InfoContainer>
        {userQuery.status === "success" ? (
          <ProfileImg
            alt="the user's profile image"
            src={
              userQuery.data && userQuery.data.pfp ? userQuery.data.pfp : null
            }
          />
        ) : (
          <LoadingImg className="animate-pulse" />
        )}

        {userQuery.status === "success" &&
        userQuery.data &&
        userQuery.data.name ? (
          <UsernameSection>
            <ProfileTitle>{userQuery.data.name}</ProfileTitle>
            <Link to="/profile/edit">
              <EditButton className="transition-colors">
                <EditIcon src="/icons/general/edit.svg" alt="" />
              </EditButton>
            </Link>
          </UsernameSection>
        ) : (
          <LoadingTitle className="animate-pulse" />
        )}

        {userQuery.status === "success" ? (
          <UsernameTitle>
            @
            {userQuery.data && userQuery.data.username
              ? userQuery.data.username
              : ""}
          </UsernameTitle>
        ) : (
          <LoadingTitle className="animate-pulse" />
        )}

        {userQuery.status === "success" &&
          userQuery.data &&
          userQuery.data.bio && (
            <ProfileBio>
              <ReadMore text={userQuery.data.bio} threshold={50} />
            </ProfileBio>
          )}

        <Link to="/profile/stats">
          <StatisticsButton>
            <StatisticsIcon src="/icons/profile/stats.svg" alt="" />
          </StatisticsButton>
        </Link>

        <LogoutButton onClick={onLogout}>
          <LogoutIcon src="/icons/general/logout.svg" alt="" />
        </LogoutButton>
      </InfoContainer>

      <TabContainer>
        <TabItem
          layoutId="profile"
          isActive={tab === "posts"}
          onClick={() => setTab("posts")}
        >
          {postQuery.status === "success"
            ? pluralize(
                "Post",
                postQuery.status === "success" &&
                  postQuery.data &&
                  postQuery.data.filter(function (e) {
                    return e.text !== "";
                  }).length,
                true
              )
            : "Posts"}
        </TabItem>

        <TabItem
          layoutId="profile"
          isActive={tab === "media"}
          onClick={() => setTab("media")}
        >
          {postQuery.status === "success" &&
            postQuery.data &&
            postQuery.data.filter(function (e) {
              return e.text === "";
            }).length}{" "}
          Media
        </TabItem>
      </TabContainer>

      <MainContainer>
        {tab === "posts" && (
          <ProfileArtTab
            query={postQuery}
            userQuery={userQuery}
            isSelf={true}
          />
        )}
        {tab === "media" && (
          <ProfileLikedTab
            query={postQuery}
            userQuery={userQuery}
            isSelf={true}
          />
        )}
      </MainContainer>
    </div>
  );
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 230px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  text-align: left;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
`;

const LoadingImg = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: var(--color-gray-200);
`;

const ProfileTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 18px;
  margin-bottom: 0px;
  color: black;
`;

const LoadingTitle = styled.div`
  width: 20ch;
  height: 20px;
  background-color: var(--color-gray-200);
  margin-top: 16px;
  border-radius: 10px;
`;

const ProfileTenure = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
  color: var(--color-gray-150);
`;

const LoadingTenure = styled.div`
  width: 10ch;
  height: 12px;
  background-color: var(--color-gray-200);
  margin-top: 4px;
  border-radius: 6px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoutButton = styled.button`
  color: var(--exitColor);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.25s ease;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    color: #e6544c;
  }
`;

const LogoutIcon = styled.img`
  height: 24px;
  width: auto;
  opacity: 0.5;
`;

const StatisticsButton = styled.button`
  color: var(--exitColor);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.25s ease;
  position: absolute;
  top: 20px;
  right: 62px;

  &:hover {
    color: #e6544c;
  }
`;

const StatisticsIcon = styled.img`
  height: 24px;
  width: auto;
  opacity: 0.5;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-align: center;
  height: 35px;
  margin-left: 2px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: black;
  background: none;
  border-radius: 18px;
  cursor: pointer;
  margin-top: 18px;

  &:hover {
    border-color: black;
  }
`;

const EditIcon = styled.img`
  width: 16px;
  height: auto;
  opacity: 0.5;
`;

const ProfileBio = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: 0px;
`;

const UsernameTitle = styled.p`
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0px;
  color: gray;
  margin-left: 6px;
`;

const UsernameSection = styled.div`
  display: inline-flex;
  margin-left: 6px;
`;
