import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { currentUser, getRandomProfilePic } from "../../helpers/authentication";
import { usernameExists, writeUserProfileData } from "../../helpers/firebase";
import {
  containsProfanity,
  removeIllegalCharacters,
} from "../../helpers/general";
import {
  profanityErrorText,
  usernameTakenErrorText,
} from "../../helpers/constants";
import { message } from "antd";
import NewUserCarousel from "./NewUserCarousel";
import mixpanel from "mixpanel-browser";

const usernameLengthThreshold = 3;
const checkUsernameWaitSeconds = 0.5;

export default function CreateProfile({
  emailAddress,
  setShowBackButton,
  setProfileCreated,
  profileCreated,
}) {
  const [username, setUsername] = useState("");
  const [usernameOk, setUsernameOk] = useState(null);
  const [bio, setBio] = useState("");
  const [bioOk, setBioOk] = useState(true);
  const [profilePic, setProfilePic] = useState(getRandomProfilePic());
  const [isImageSheetOpen, setIsImageSheetOpen] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [usernameError, setUsernameError] = useState("");

  const [isRegistering, setIsRegistering] = useState(false);

  const onUsernameChange = async (event) => {
    let desiredUsername = event.target.value.toLowerCase();
    desiredUsername = removeIllegalCharacters(desiredUsername);
    setShowFeedback(false);
    setUsernameOk(false);
    setUsername(desiredUsername);
  };

  useEffect(() => {
    if (username.length >= usernameLengthThreshold) {
      const timer = setTimeout(async () => {
        const userExists = await usernameExists(username);
        setUsernameOk(!userExists && !containsProfanity(username));
        setShowFeedback(true);
        if (userExists) {
          setUsernameError(usernameTakenErrorText);
        } else if (containsProfanity(username)) {
          setUsernameError(profanityErrorText);
        }
      }, checkUsernameWaitSeconds * 1000);
      return () => clearTimeout(timer);
    } else setUsernameOk(false);
  }, [username]);

  useEffect(() => {
    setShowBackButton(!profileCreated);
  }, [profileCreated, setShowBackButton]);

  const onBioChange = (event) => {
    const newBio = event.target.value;
    setBio(newBio);
    if (containsProfanity(newBio)) setBioOk(false);
    else setBioOk(true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRegistering(true);
    const result = await writeUserProfileData({
      userId: currentUser.uid,
      username: username.toLowerCase(),
      bio,
      email: emailAddress.toLowerCase(),
      pfp: profilePic,
    });
    if (result) {
      setProfileCreated(true);
      mixpanel.track("Completed Profile Creation", {
        email: emailAddress.toLocaleLowerCase(),
      });
    } else {
      setIsRegistering(false);
      message.error("Account could not be created");
    }
  };

  return (
    <>
      {!profileCreated ? (
        <Container>
          <Header>Create a public profile</Header>
          <EditProfilePic
            onClick={() => {
              setIsImageSheetOpen(true);
            }}
          >
            <UserProfileImage src={profilePic} />
            <ProfileImgOverlay>
              <CameraIcon src="/icons/camera.svg" alt="" />
            </ProfileImgOverlay>
          </EditProfilePic>
          <form onSubmit={onSubmit}>
            <InputHeader>Username</InputHeader>
            <InputContainer>
              <InputField
                className="transition-colors"
                placeholder={`Must be at least ${usernameLengthThreshold} characters`}
                value={username}
                maxLength={40}
                onChange={onUsernameChange}
                type="text"
              />
              {showFeedback && usernameOk && username !== "" ? (
                <Checkmark src="/icons/checkmark.svg" />
              ) : showFeedback && username !== "" ? (
                <ErrorIcon src="/icons/x.svg" />
              ) : null}
            </InputContainer>
            {showFeedback && !usernameOk && username !== "" && (
              <ErrorText>{usernameError}</ErrorText>
            )}
            <InputHeader>Bio (optional)</InputHeader>
            <BioEditor
              value={bio}
              placeholder={"Write something about yourself"}
              onChange={onBioChange}
              maxLength={150}
            />
            {!bioOk && bio !== "" && (
              <ErrorText>{profanityErrorText}</ErrorText>
            )}
            <ButtonContainer>
              {!isRegistering ? (
                usernameOk && bioOk ? (
                  <CreateAccountButton type="submit">
                    Create profile
                  </CreateAccountButton>
                ) : (
                  <CreateAccountButtonDisabled disabled>
                    Create profile
                  </CreateAccountButtonDisabled>
                )
              ) : (
                <IsRegisteringButton disabled>
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                </IsRegisteringButton>
              )}
            </ButtonContainer>
          </form>
        </Container>
      ) : (
        <NewUserCarousel email={emailAddress} />
      )}
    </>
  );
}

const Container = styled.div`
  width: 90%;
  margin: auto;
`;

const Checkmark = styled.img`
  right: 20px !important;
  top: 35%;
  width: 17px;
  height: 17px;
  position: absolute;
`;

const ErrorIcon = styled.img`
  right: 20px !important;
  top: 35%;
  width: 16px;
  height: 16px;
  position: absolute;
`;

const InputContainer = styled.div`
  position: relative;
`;

const Header = styled.div`
  letter-spacing: 0em;
  color: var(--lavender);
  font-size: 30px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
`;

const ButtonContainer = styled.div`
  margin: auto;
  margin-top: 40px;
  width: 100%;
`;

const IsRegisteringButton = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 38px;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const CreateAccountButton = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  margin-top: 78px;
  color: var(--color-cream);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  line-height: 16px;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
`;

const CreateAccountButtonDisabled = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 78px;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  height: 45px;
  border-radius: 90px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  line-height: 16px;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
`;

const InputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
  margin: auto;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const BioEditor = styled.textarea`
  display: block;
  position: relative !important;
  width: 100%;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  resize: none;
  margin-top: -2px;
  margin-left: -2px;
  background: var(--color-gray-300);
  height: 100px;
  padding: 10px 5%;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  border-color: transparent;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const InputHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-align: left;
  margin: 24px 0px 12px 0px;
  color: #f8d5d7;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const UserProfileImage = styled.img`
  object-fit: cover;
  height: 120px;
  width: 120px;
  border-radius: 100px;
`;

const EditProfilePic = styled.button`
  border: 0;
  background: none;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
`;

const ErrorText = styled.div`
  font-size: 14px;
  color: #ff8282;
  text-align: left;
  margin-top: 10px;
`;

const ProfileImgOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;
  background: var(--lightPurple);
  border-radius: 32px;
`;

const CameraIcon = styled.img`
  padding: 10px;
`;
