import React, { useState } from "react";
import styled from "styled-components";
import { currentUser } from "../../helpers/authentication";
import UploadModelImages from "../UploadModelImages";
import UploadMoreModelImages from "../UploadMoreModelImages";
import mixpanel from "mixpanel-browser";
import TrainingImage from "./TrainingImage";
import { Modal, Statistic, message } from "antd";
import { expirationDayCount } from "../../helpers/constants";
import { addDays } from "../../helpers/general";
import { trainNewModel } from "../../helpers/genAI";
import { CheckCircleOutlined } from "@ant-design/icons";
import { generateRandomId } from "../../helpers/firebase";

const { Countdown } = Statistic;

const minimumImages = 5;

export default function CreateMai({
  setImageUrl,
  hasUserModel,
  setHasUserModel,
  imageFilenames,
  setImageFilenames,
  hasUploadedImages,
  setHasUploadedImages,
  isModelGenerating,
  setModelGenerating,
  currentMai = null,
  checkingMai,
}) {
  const [imageBlobs, setImageBlobs] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLearnMoreModalOpen, setLearnMoreModalOpen] = useState(false);

  if (checkingMai) return;

  return (
    <>
      <Modal
        open={isLearnMoreModalOpen}
        onCancel={(event) => {
          setLearnMoreModalOpen(false);
          event.stopPropagation();
        }}
        closable={false}
        footer={null}
      >
        <InnerModalContainer>
          <Header>Best Practices</Header>
          <ModalSubheader align="left">
            With better images, you can create better content for engaging with
            your fans. If your best look is a certain kind of makeup, only
            upload images with that makeup.
          </ModalSubheader>
          <BottomSpacer />
          <Image src={"/images/gen-ai/training.png"} alt="" />
          <BottomSpacer />
        </InnerModalContainer>
      </Modal>

      <Container>
        {isModelGenerating !== null && !isModelGenerating && !currentMai && (
          <>
            <Header>Build Your Mai</Header>
            <Subheader>
              Upload images of yourself to build your Mai and start creating
              content
            </Subheader>
            <Disclaimer>
              By uploading images to build your Mai, you confirm that you own
              the rights to use the images and the likenesses within them, and
              that they are at least 18 years old
            </Disclaimer>
            {imageUrls.length > 0 ? (
              <>
                <ImageContainer>
                  {imageUrls.map((obj) => (
                    <TrainingImage
                      trainingObjects={imageUrls}
                      setTrainingObjects={setImageUrls}
                      trainingObj={obj}
                    />
                  ))}
                </ImageContainer>
                <UploadMoreModelImages
                  maxFileSizeInMb={5}
                  imageUrls={imageUrls}
                  setImageUrls={setImageUrls}
                  setImageBlobs={setImageBlobs}
                  setImageFilenames={setImageFilenames}
                  setHasUploadedImages={setHasUploadedImages}
                  byPassRes={true}
                />
              </>
            ) : (
              <UploadModelImages
                maxFileSizeInMb={5}
                imageUrls={imageUrls}
                setImageUrls={setImageUrls}
                setImageBlobs={setImageBlobs}
                setImageFilenames={setImageFilenames}
                setHasUploadedImages={setHasUploadedImages}
                byPassRes={true}
              />
            )}
            <SubmitButtonContainer>
              <SubmitButton
                disabled={
                  imageUrls.length < minimumImages ||
                  imageUrls.filter(function (e) {
                    return e.caption === "";
                  }).length > 0
                }
                onClick={async (e) => {
                  setModelGenerating(true);
                  mixpanel.track("Clicked train model button", {
                    imageAmount: imageUrls.length,
                  });
                  const groupId = `${currentUser.uid}-${generateRandomId()}`;
                  console.log(imageUrls);
                  console.log(groupId);
                  console.log("Calling model training");
                  message.success(
                    "Building Mai, please check back in 5-10 minutes"
                  );
                  await trainNewModel({ imageUrls, groupId });
                }}
              >
                Build
              </SubmitButton>
              <Disclaimer style={{ marginBottom: 10 }}>
                Minimum of 5 images required, 20-50 images recommended. Your Mai
                can be rebuilt every {expirationDayCount} days.{" "}
              </Disclaimer>
              <Disclaimer style={{ marginTop: 0 }}>
                <LearnMore
                  onClick={(e) => {
                    setLearnMoreModalOpen(true);
                  }}
                >
                  Learn how to build your best Mai
                </LearnMore>
              </Disclaimer>
            </SubmitButtonContainer>
            <UseDefaultButton
              onClick={async (e) => {
                setHasUserModel(true);
              }}
            >
              I don't want unique content, use the default
            </UseDefaultButton>
          </>
        )}

        {isModelGenerating && (
          <>
            <Header>Your Mai is Building</Header>
            <Subheader>
              Check back soon to start creating content with your personalized
              Mai
            </Subheader>
            <SpinnerContainer>
              <Spinner src="/icons/general/loading-black.svg" alt="" />
            </SpinnerContainer>
            <UseDefaultButton
              onClick={async (e) => {
                setHasUserModel(true);
              }}
            >
              Start creating content with the default Mai
            </UseDefaultButton>
          </>
        )}

        {currentMai &&
          addDays(currentMai.createdAt, 0) <
            addDays(currentMai.createdAt, expirationDayCount) &&
          !isModelGenerating && (
            <>
              <Header>Congratulations!</Header>
              <Subheader align="center">
                With your Mai, you can create custom content for your fans. Tap
                the button below to get started!
              </Subheader>
              <CheckmarkContainer>
                <CheckCircleOutlined
                  fill={"var(--color-green-base)"}
                  style={{ fontSize: "100px" }}
                />
              </CheckmarkContainer>

              <Subheader>
                You can build a new Mai in{" "}
                <Countdown
                  value={addDays(currentMai.createdAt, expirationDayCount)}
                  format="HH:mm:ss"
                />
              </Subheader>
              <CreateWithMaiButton
                onClick={async (e) => {
                  setHasUserModel(true);
                }}
              >
                Create content with your Mai
              </CreateWithMaiButton>
            </>
          )}
      </Container>
    </>
  );
}

const Container = styled.span`
  .contributor-sheet-container {
    background: #f5f5f5 !important;
  }

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }

  .contributor-sheet-content {
    overflow-y: scroll;
  }
`;

const Header = styled.div`
  color: black;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 14px;
  bottom-bottom: 1px solid lightgray;
`;

const Subheader = styled.div`
  color: black;
  margin: auto 18px 14px 18px;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  margin-top: 12px;
  text-align: ${(props) => (props.align ? props.align : "center")};

  .ant-statistic {
    display: inline-flex;
  }

  .ant-statistic-content-value {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
  }
`;

const ModalSubheader = styled.div`
  color: black;
  margin: auto auto 14px auto;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  margin-top: 12px;
  text-align: ${(props) => (props.align ? props.align : "center")};

  .ant-statistic {
    display: inline-flex;
  }

  .ant-statistic-content-value {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
  }
`;

const ImageContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: block;
  text-align: center;

  span {
    margin-right: 10px;
  }
`;

const Spinner = styled.img`
  width: 70px;
  height: auto;
  margin: 10px auto;
  animation: rotation 3s infinite linear;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
`;

const CreateWithMaiButton = styled.div`
  font-size: 15px;
  display: block;
  font-weight: 600;
  width: 100%;
  margin: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 40px;
  text-align: center;
  color: white;
  margin-top: 34px;
  margin-bottom: 24px;
  text-align: center;
  background: var(--color-main-blue);
`;

const UseDefaultButton = styled.div`
  font-size: 12px;
  display: block;
  font-weight: normal;
  width: 100%;
  text-align: center;
  color: var(--color-gray-100);
  margin-top: 34px;
  margin-bottom: 24px;
  text-align: center;
`;

const Disclaimer = styled.div`
  font-size: 12px;
  display: block;
  font-weight: 400;
  color: black;
  width: 100%;
  margin-top: 12px;
  text-align: left;
  color: var(--color-gray-300);
  margin-bottom: 24px;
  text-align: center;
`;

const SubmitButtonContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 30px;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: var(--blueHeader);
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #fef6f3;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }

  .ant-statistic-content-value {
    color: white;
    font-size: 20px;
    font-weight: 600 !important;
  }
`;

const CheckmarkContainer = styled.div`
  width: 100%;
  margin: 32px auto 26px auto;
  text-align: center;
  color: var(--color-green-base);
`;

const LearnMore = styled.span`
  width: 100%;
  margin: 32px auto 26px auto;
  text-align: center;
  color: var(--color-main-blue);
`;

const InnerModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: contents;
  text-align: center;
`;

const BottomSpacer = styled.div`
  height: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 4px;
`;
