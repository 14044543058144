import React from "react";
import Cta from "../sections/Cta";
import Features from "../sections/Features";
import Hero from "../sections/Hero";
import Stories from "../sections/Stories";

export default function Home() {
  return (
    <>
      <Hero />
      <Features />
      {/*
			<Stories />
			*/}
      <Cta />
    </>
  );
}
