import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUserInfoByUserId } from "../../helpers/firebase";
import { getDaysLeft } from "../../helpers/general";
import pluralize from "pluralize";
import { Link } from "react-router-dom";

export default function NotificationElement({ notification }) {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userInfo = await getUserInfoByUserId(notification.creator);
      setUserInfo(userInfo);
      /*
      if (currentUser && !employeeIds.includes(currentUser.uid)) {
        mixpanel.track("Visited User Profile");
      }
      */
    }

    fetchData();
  }, [notification]);

  return (
    userInfo &&
    notification && (
      <Container>
        <Link to={`/user/${userInfo.username}`} style={{ display: "flex" }}>
          <NotificationIcon src="/icons/information.svg" alt="" />
          <Header>
            Subscription to {userInfo.username} expires in{" "}
            {`${pluralize("day", getDaysLeft(notification.expiresAt), true)}`}
          </Header>
        </Link>
      </Container>
    )
  );
}

// box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);

const Container = styled.div`
  height: 100px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  background: white !important;
  display: inline-block;
  padding: 38px 20px 0px 26px;

  .contributor-sheet-container {
    background: #f5f5f5 !important;
  }

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }

  .contributor-sheet-content {
    overflow-y: scroll;
  }
`;

const Header = styled.div`
  color: black;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 1px;
  display: inline-flex;
  vertical-align: super;
`;

const NotificationIcon = styled.img`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  height: 20px;
  width: 20px;
  opacity: 0.8;
`;
