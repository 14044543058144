import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { containsProfanity, getComments, isOniOS } from "../../helpers/general";
import CommentSection from "./CommentSection";
import { message } from "antd";
import {
  commentLengthMax,
  commentLengthThreshold,
} from "../../helpers/constants";
import { editPostedComment, submitComment } from "../../helpers/firebase";
import userModel from "../../lib/firebase/userModel";
import TextareaAutosize from "react-textarea-autosize";

const postButtonWidth = 70;
const postButtonLeftMargin = 10;

export default function SubmissionCommentSection({
  comments,
  setComments,
  nextEpisode = null,
  backLocations,
  setBackLocations,
  isFocused,
  setIsFocused,
  showSort,
  subjectId,
  isOpen,
}) {
  const [isSending, setIsSending] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [replyToUsername, setReplyToUsername] = useState("");
  const [editComment, setEditComment] = useState(null);
  const [comment, setComment] = useState("");
  const [commentOk, setCommentOk] = useState(false);

  let commentInputRef = React.createRef();

  if ("visualViewport" in window) {
    window.visualViewport.addEventListener("resize", function (event) {
      if (event.target.height + 30 < document.scrollElement.clientHeight) {
        console.log("keyboard up?");
        setIsFocused(true);
      } else {
        console.log("keyboard down?");
        setIsFocused(false);
      }
    });
  }

  useEffect(() => {
    if (editComment) {
      console.log(editComment);
      setComment(editComment.comment);
      setReplyTo(null);
      setReplyToUsername("");
    }
  }, [editComment]);

  useEffect(() => {
    setEditComment(null);
    setComment("");
  }, [comments]);

  useEffect(() => {
    if (isOpen) {
      setEditComment(null);
      setComment("");
      setReplyTo(null);
      setReplyToUsername("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (comment.length >= commentLengthThreshold && !containsProfanity(comment))
      setCommentOk(true);
    else setCommentOk(false);
  }, [comment]);

  useEffect(() => {
    async function fetchData() {
      console.log(replyTo);
      if (replyTo) {
        const replyToUser = await userModel.getOneById(replyTo.creator);
        console.log(replyToUser);
        setReplyToUsername(replyToUser.username);
        setComment("");
      }
    }

    fetchData();
  }, [replyTo, comment.creator]);

  const startCommentSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsSending(true);
    let result;
    if (replyTo) {
      const newComment = comment.replace(`@${replyToUsername} `, "");
      result = await submitComment({ comment: newComment, subjectId, replyTo });
      setReplyTo(null);
      setReplyToUsername("");
      setComment("");
    } else {
      result = await submitComment({ comment, subjectId, replyTo });
    }
    if (result) {
      message.success("Comment submitted. Thank you!");
      setReplyTo(null);
      setReplyToUsername("");
      setComment("");
      console.log(result);
      setComments(await getComments(subjectId));
    } else message.error("Comment was not submitted. Please try again.");
    setIsSending(false);
    setIsFocused(false);
  };

  const startEditCommentSubmit = async () => {
    setIsSending(true);
    console.log(editComment);
    const result = await editPostedComment({
      editedComment: editComment,
      comment,
    });
    if (result) {
      message.success("Edited comment successfully");
      setComment("");
      console.log(result);
      setComments(await getComments(subjectId));
    } else message.error("Comment was not edited. Please try again.");
    setIsSending(false);
    setIsFocused(false);
  };

  return (
    <Container>
      <AddCommentContainer
        isFocused={isFocused}
        commentInputRef={commentInputRef}
      >
        <AddCommentInnerContainer isFocused={isFocused}>
          {editComment && (
            <EditCommentSection className="editing-comment-section">
              <StopEditButton
                src="/icons/social/stop-edit.svg"
                alt=""
                onClick={(e) => {
                  setEditComment(null);
                  setComment("");
                }}
                className="stop-edit-button"
              />
              Editing comment
            </EditCommentSection>
          )}
          <AddCommentInnerInnerContainer isFocused={isFocused}>
            <TextareaAutosize
              maxRows="4"
              className="autosize-textarea"
              placeholder="Add a comment"
              maxLength={commentLengthMax}
              ref={commentInputRef}
              value={`${
                replyTo !== null && replyToUsername !== ""
                  ? `@${replyToUsername} `
                  : ""
              }${comment}`}
              onChange={(e) => {
                let newComment = e.target.value;
                newComment = newComment.replace(`@${replyToUsername} `, "");
                setComment(newComment);
              }}
              onFocus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsFocused(true);
              }}
              onBlur={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsFocused(false);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              /*
              onBlurCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsFocused(false);
              }}
              */
            />
            <PostButton
              disabled={!commentOk || isSending}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (editComment) {
                  startEditCommentSubmit();
                } else {
                  startCommentSubmit(e);
                }
              }}
              className="submission-comment-post-button"
            >
              Post
            </PostButton>
          </AddCommentInnerInnerContainer>
        </AddCommentInnerContainer>
      </AddCommentContainer>
      <CommentsOuterContainer isFocused={isFocused}>
        {comments.length > 0 ? (
          <CommentsContainer>
            <CommentSection
              comments={comments}
              setComments={setComments}
              backLocations={backLocations}
              setBackLocations={setBackLocations}
              setEditComment={setEditComment}
              commentInputRef={commentInputRef}
              setReplyTo={setReplyTo}
              showSort={showSort}
            />
          </CommentsContainer>
        ) : (
          <EmptyCommentsContainer isFocused={isFocused}>
            <CarrotIconSection>
              <CarrotIcon src="/icons/social/carrot-gray.svg" alt="" />
            </CarrotIconSection>
            <EmptyHeader>No comments yet.</EmptyHeader>
            <EmptySubheader>Be the first to leave a kudos!</EmptySubheader>
          </EmptyCommentsContainer>
        )}
      </CommentsOuterContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  position: relative;

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }

  .comment-container {
    padding: 0px;
  }

  .sort-dropdown {
    margin-bottom: 10px;
  }
`;

const EmptyCommentsContainer = styled.div`
  width: 100%;
  margin-top: 34px;
  margin-bottom: 50px;
  pointer-events: ${(props) => props.isFocused && isOniOS() && "none"};
`;

const AddCommentInnerInnerContainer = styled.div`
  width: 100%;
  padding: 0px 12px;
  position: relative;
  margin-top: ${(props) => (props.isFocused && isOniOS() ? "10" : "0")}px;

  .autosize-textarea {
    width: calc(100% - ${postButtonWidth}px - ${postButtonLeftMargin}px);
    border-radius: 20px;
    background: #0b0b0c;
    padding: 10px 20px;
    border: 0px !important;
    outline: 0px;
    resize: none;
    color: var(--color-cream-base);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;

    &::placeholder {
      color: var(--color-gray);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
    }

    &::focus-visible {
      outline: 0px;
      margin-bottom: calc(
        100% + 500px
      ) !important; /* add padding for keyboard */
    }

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    &::placeholder {
      opacity: 0.5;
      color: var(--color-gray-100);
    }
  }
`;

const AddCommentContainer = styled.div`
  bottom: 0px !important;
  width: 100%;
  position: ${(props) =>
    props.isFocused && isOniOS() ? "fixed !important" : "static"};
  height: ${(props) => props.isFocused && isOniOS() && 500}px;
  background: var(--color-gray-600);
  margin: auto !important;
`;

const AddCommentInnerContainer = styled.div`
  width: ${(props) => (props.isFocused && isOniOS() ? "100%" : "100%")};
  margin-top: ${(props) => (props.isFocused && isOniOS() ? "10" : "0")}px;
  z-index: ${(props) => props.isFocused && isOniOS() && "10000"};
`;

const AddCommentInput = styled.input`
  width: calc(100% - ${postButtonWidth}px - ${postButtonLeftMargin}px);
  height: 44px;
  border-radius: 20px;
  background: #0b0b0c;
  padding: 0px 20px;
  border: 0px !important;
  outline: 0px;

  &::placeholder {
    color: var(--color-gray);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  &::focus-visible {
    outline: 0px;
  }
`;

const EmptyHeader = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
`;

const EmptySubheader = styled.div`
  color: var(--color-gray);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 6px;
`;

const CarrotIcon = styled.img`
  color: var(--color-gray);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: auto;
  text-align: center;
`;

const CarrotIconSection = styled.div`
  margin: auto;
  text-align: center;
`;

const PostButton = styled.button`
  width: ${postButtonWidth}px;
  height: 36px;
  border-radius: 20px;
  border: 0px !important;
  outline: 0px;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? "var(--color-gray-300)" : "var(--blurple)"};
  color: ${(props) =>
    props.disabled ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  margin-left: ${postButtonLeftMargin}px};

  
  &::placeholder {
    color: var(--color-gray);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  &::focus-visible {
    outline: 0px;
  }
`;

const CommentsContainer = styled.div`
  text-align: left;
  margin-top: 10px;
  padding: 0px 20px;
`;

const EditCommentSection = styled.div`
  margin: auto;
  text-align: left;
  background: var(--color-gray-700);
  color: var(--color-gray-50);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  border-bottom: ${(props) =>
    props.isFocused && isOniOS() ? "0px" : "0.5px solid #35383f"};
  padding: 10px 20px;
`;

const StopEditButton = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const CommentsOuterContainer = styled.div``;

// pointer-events: ${(props) => props.isFocused && isOniOS() && "none"};
