import styled from "styled-components";

export default function ComingSoon() {
  return <Container>Coming Soon</Container>;
}

const Container = styled.div`
  width: 100%;
  background: white;
  padding: 20px 0px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid var(--color-light-gray);
`;
