import "./index.css";
import "./index-website.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "./App";
import { _queryClient } from "./lib/client";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={_queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ReactQueryDevtools position="bottom-right" />
  </QueryClientProvider>
);
