import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import styled from "styled-components";
import { getAuth } from "firebase/auth";
import userModel from "../lib/firebase/userModel";
import { useQuery } from "@tanstack/react-query";

export default function UserDisplay({
  userId,
  showName,
  isCurrentUser,
  isVh = false,
  size = 40,
  isAuthor = false,
  isWinner = false,
  toLocation = "user",
  backLocation,
  backLocations,
  setBackLocations,
}) {
  const userQuery = useQuery({
    queryKey: ["user", userId],
    queryFn: () => {
      const currentUser = getAuth().currentUser;
      if (!currentUser) return null;
      return userModel.getOneById(userId);
    },
  });

  if (!userQuery.data || userQuery.status !== "success") return null;

  return (
    <Container className="user-display-master">
      {isCurrentUser &&
      userQuery.data.pfp !== null &&
      !userQuery.data.deleted ? (
        <Link
          className={`user-display-button ${
            isAuthor ? "user-display-button-storyworld" : ""
          }`}
          to={`/${toLocation}`}
          onClick={() => {
            const newLocations = backLocations;
            newLocations.push(backLocation);
            setBackLocations(newLocations);
          }}
        >
          <Avatar
            size={size}
            icon={<UserProfileImage src={userQuery.data.pfp} />}
          />
          {showName ? (
            <UsernameTitleContainer
              className={`${
                isAuthor || isWinner ? "user-display-div-storyworld" : ""
              }`}
            >
              <UsernameTitle
                className={`vision-holder-name ${
                  isAuthor
                    ? "vision-holder-name-storyworld"
                    : isWinner
                    ? "winner-user-display-text"
                    : ""
                }`}
              >
                {userQuery.data.username && userQuery.data.username !== ""
                  ? userQuery.data.username
                  : ""}
              </UsernameTitle>
              {isVh ? (
                <VisionHolderTitle>⚘ Vision Holder</VisionHolderTitle>
              ) : null}
            </UsernameTitleContainer>
          ) : (
            ``
          )}
        </Link>
      ) : !userQuery.data.deleted ? (
        <Link
          className={`user-display-button ${
            isAuthor ? "user-display-button-storyworld" : ""
          }`}
          to={`/${toLocation}/${userQuery.data.username}`}
          onClick={() => {
            if (backLocations) {
              const newLocations = backLocations;
              newLocations.push(backLocation);
              setBackLocations(newLocations);
            }
          }}
        >
          <Avatar
            size={size}
            icon={<UserProfileImage src={userQuery.data.pfp} />}
          />
          {showName ? (
            <UsernameTitleContainer
              className={`${
                isAuthor || isWinner ? "user-display-div-storyworld" : ""
              }`}
            >
              <UsernameTitle
                className={`username ${
                  isAuthor
                    ? "vision-holder-name-storyworld"
                    : isWinner
                    ? "winner-user-display-text"
                    : ""
                }`}
              >
                {userQuery.data.username && userQuery.data.username !== ""
                  ? userQuery.data.username
                  : ""}
              </UsernameTitle>
              {isVh ? (
                <VisionHolderTitle>⚘ Vision Holder</VisionHolderTitle>
              ) : null}
            </UsernameTitleContainer>
          ) : (
            ``
          )}
        </Link>
      ) : (
        <AvatarNoLink
          className={`user-display-button ${
            isAuthor ? "user-display-button-storyworld" : ""
          }`}
        >
          <Avatar
            size={size}
            icon={<UserProfileImage src={userQuery.data.pfp} />}
          />
          {showName ? (
            <UsernameTitleContainer
              className={`username ${
                isAuthor || isWinner ? "user-display-div-storyworld" : ""
              }`}
            >
              <UsernameTitle
                className={`vision-holder-name ${
                  isAuthor
                    ? "vision-holder-name-storyworld"
                    : isWinner
                    ? "winner-user-display-text"
                    : ""
                }`}
              >
                {userQuery.data.username && userQuery.data.username !== ""
                  ? userQuery.data.username
                  : ""}
              </UsernameTitle>
              {isVh ? (
                <VisionHolderTitle>⚘ Vision Holder</VisionHolderTitle>
              ) : null}
            </UsernameTitleContainer>
          ) : (
            ``
          )}
        </AvatarNoLink>
      )}
    </Container>
  );
}

const Container = styled.div`
  .username {
    margin-left: 6px !important;
  }
`;

const UserProfileImage = styled.img`
  image-size: contain;
`;

const AvatarNoLink = styled.div`
  pointer-events: none;
`;

const VisionHolderTitle = styled.div`
  color: var(--visionHolderColor);
  font-size: 10px;
  letter-spacing: 0em;
  text-align: left;
`;

const UsernameTitleContainer = styled.div`
  display: inline-flex;
`;

const UsernameTitle = styled.div`
  color: var(--color-green-base) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
`;
