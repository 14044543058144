import React from "react";
import styled from "styled-components";

export default function Comment({ comment, backLocations, setBackLocations }) {
  return (
    <Container>
      <TopHeader>
        <BoldedTopHeader>Comments</BoldedTopHeader>
      </TopHeader>
      <Header>Comments</Header>
      <Footer></Footer>
    </Container>
  );
}

const Container = styled.span`
  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }
`;

const TopHeader = styled.div`
  color: var(--color-pink);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

const BoldedTopHeader = styled.span`
  color: var(--color-pink);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const Header = styled.div`
  color: var(--cream);
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 4px;
  margin-bottom: -8px;
  text-transform: uppercase;
`;

const Footer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;
