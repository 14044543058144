import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PostedComment from "./PostedComment";
import SortDropdownButton from "./SortDropdownButton";
import likeModel from "../../lib/firebase/likeModel";

export default function CommentSection({
  comments,
  setComments,
  backLocations,
  setBackLocations,
  setEditComment,
  commentInputRef,
  setReplyTo,
  showSort = true,
}) {
  const [sortedComments, setSortedComments] = useState([]);
  const [sortType, setSortType] = useState("newest");

  useEffect(() => {
    async function sort() {
      await Promise.all(
        comments.map(async (c) => {
          const likeCount = await likeModel.count(["submissionId", "==", c.id]);
          c.likeCount = likeCount;
        })
      );
      const sorted = comments.sort(function (a, b) {
        return b.likeCount - a.likeCount || b.createdAt - a.createdAt;
      });
      setSortedComments([...sorted]);
    }
    if (sortType === "top") {
      sort();
    } else if (sortType === "newest") {
      const sorted = comments.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
      setSortedComments([...sorted]);
    }
  }, [sortType, comments]);

  return (
    <Container className="comment-container">
      {/*
      {showSort && (
        <SortSection>
          Sort by:{" "}
          <SortDropdownButton
            setSortType={setSortType}
            sortType={sortType}
            comments={comments}
            setSortedComments={setSortedComments}
          />
        </SortSection>
      )}
      */}
      <InnerContainer>
        {sortedComments.map(
          (comment, index) =>
            comment.replyTo === null && (
              <PostedComment
                comment={comment}
                key={index}
                backLocations={backLocations}
                setBackLocations={setBackLocations}
                setEditComment={setEditComment}
                setComments={setComments}
                commentInputRef={commentInputRef}
                setReplyTo={setReplyTo}
                children={comments.filter(function (c) {
                  return c.replyTo === comment.id;
                })}
              />
            )
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 0px;
  padding: 0px 20px;

  &::-webkit-scrollbar {
    display: none !important;
    background: transparent !important;
    opacity: 0 !important;
  }
`;

const InnerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  position: relative;
  user-select: none;
  touch-action: pan-y;
  margin-bottom: 50px;
`;

const SortSection = styled.div`
  margin-top: 10px;
  color: var(--color-cream-base);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: -10px;
`;

const Direction = styled.span`
  color: var(--color-pink-normal);
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.5px;
`;
