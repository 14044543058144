import { Row, Col, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  currentUser,
  firebaseAuth,
  registerAccount,
  validateEmail,
} from "../../helpers/authentication";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { appName, mobileWidthThreshold } from "../../helpers/constants";
import { writeUserProfileData } from "../../helpers/firebase";
import {
  removeIllegalCharactersForNameInput,
  sleep,
} from "../../helpers/general";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const passwordLowerThreshold = 6;
const cta = "Sign up now to start creating and browsing";

export default function Register({ setIsInRegistration }) {
  const history = useHistory();

  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [registered, setRegistered] = useState(false);

  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true);
  const [isRegistering, setIsRegistering] = useState(false);

  useEffect(() => {
    async function waitForLogin() {
      await sleep(1);
      if (currentUser && currentUser.emailVerified) history.push("/home");
    }
    waitForLogin();
  }, [history]);

  useEffect(() => {
    if (
      validateEmail(emailAddress) &&
      password.length >= passwordLowerThreshold
    ) {
      setRegisterButtonDisabled(false);
    } else setRegisterButtonDisabled(true);
  }, [emailAddress, password]);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRegistering(true);
    try {
      const registered = await registerAccount({
        email: emailAddress,
        password,
      });
      const result = await writeUserProfileData({
        userId: currentUser.uid,
        name,
        email: emailAddress.toLowerCase(),
      });
      if (result) {
        mixpanel.track("User registered account", {
          email: emailAddress.toLocaleLowerCase(),
        });
      } else {
        setIsRegistering(false);
        message.error("Account could not be created");
      }
      if (!registered.code) {
        // setRegistered(true);
        await firebaseAuth.signOut();
        history.push("/login");
        message.success(
          "Verification email sent. Please check your spam folder.",
          [0]
        );
      } else if (registered.code === "auth/email-already-in-use") {
        message.error("Email already in use");
      }
    } catch (error) {
      setIsRegistering(false);
    }
  };

  return (
    <Container>
      <MobileContainer>
        {registered ? (
          <>
            <RegisteredMessage>Verification email sent</RegisteredMessage>
            <RegisteredMessage>Please check your spam folder</RegisteredMessage>
          </>
        ) : (
          <form onSubmit={onSubmit} style={{ position: "relative", zIndex: 1 }}>
            <InnerHeader onClick={() => history.push("/")}>
              <Logo src="/android-chrome-512x512.png" alt="" />
              <HeaderText>{appName}</HeaderText>
            </InnerHeader>
            <InnerSubheader>{cta} </InnerSubheader>
            <Row style={{ marginBottom: "-3px" }}>
              <Col span={12}>
                <Image src="/images/models/00.png" alt="" />
              </Col>
              <Col span={12}>
                <Image src="/images/models/02.png" alt="" />
              </Col>
            </Row>
            <Row style={{ marginBottom: "0px" }}>
              <Col span={12}>
                <Image src="/images/models/04.png" alt="" />
              </Col>
              <Col span={12}>
                <Image src="/images/models/03.png" alt="" />
              </Col>
            </Row>
            <InputHeader>Name</InputHeader>
            <Input
              placeholder="Enter Name"
              maxLength={40}
              type={"text"}
              value={name}
              onChange={(e) => {
                setName(removeIllegalCharactersForNameInput(e.target.value));
              }}
            />
            <InputHeader>Email</InputHeader>
            <Input
              placeholder="Enter Email"
              maxLength={40}
              type={"text"}
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
            />
            <InputHeader>Password</InputHeader>
            <Input
              placeholder="Enter Password"
              maxLength={40}
              value={password}
              type={"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <SubmitButton
              type="submit"
              disabled={registerButtonDisabled || isRegistering}
            >
              SIGN UP
            </SubmitButton>
            <Disclaimer>
              By signing up for and using {appName} you agree to our{" "}
              <Link to="/terms">Terms of Service</Link> and{" "}
              <Link to="/privacy">Privacy Policy</Link>, and confirm that you
              are at least 18 years old.
            </Disclaimer>
            <GoToLogin>
              Already have an account? <Link to="/login">Log In</Link>
            </GoToLogin>
          </form>
        )}
      </MobileContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  text-align: left;
`;

const DesktopContainer = styled.div`
  width: 100%;
  text-align: left;
  height: 100%;

  @media screen and (max-width: ${mobileWidthThreshold}px) {
    display: none;
  }

  .left-container {
    background: lightblue;
    padding-top: 40px;
  }
`;

const MobileContainer = styled.div`
  width: 90%;
  text-align: left;
  margin: auto;
  margin-top: 50px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
`;

const InnerHeader = styled.span`
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;
  vertical-align: super;
  display: flex;
  cursor: pointer;
  width: fit-content;
`;

const HeaderText = styled.div`
  font-size: 34px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 12px;
  vertical-align: super;
`;

const InnerSubheader = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

const LeftContainer = styled.div`
  height: 100px;
  padding: 0px 20px;
`;

const RightContainer = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const Subheader = styled.h4`
  font-size: 12px;
  color: white;
`;

const InputHeader = styled.div`
  font-size: 12px;
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 4px;
`;

const InputField = styled.input`
  font-size: 12px;
  display: block;
  color: black;
  width: 100%;
`;

const SubmitButton = styled.button`
  font-size: 12px;
  display: block;
  font-weight: bold;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  border-radius: 24px;
  border: 0;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  color: ${(props) => (props.disabled ? "black" : "white")};
  background: ${(props) =>
    props.disabled ? "var(--color-gray-50)" : "var(--lightPurple)"};
`;

const GoToLogin = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
  width: 100%;
  margin-top: 22px;
  text-align: center;
  margin-bottom: 100px;
`;

const RegisteredMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

const Disclaimer = styled.div`
  font-size: 12px;
  display: block;
  font-weight: 400;
  color: black;
  width: 100%;
  margin-top: 12px;
  text-align: left;
  color: var(--color-gray-100);
`;

const Image = styled.img`
  height: auto;
  width: 100%;
`;
