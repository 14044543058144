import {
  bookmarksTable,
  constantsTable,
  episodesTable,
  firebaseConfig,
  galleryPageLimit,
  likesTable,
  pollTable,
  questTable,
  quotasTable,
  storyworldTable,
  submissionsTable,
  subscriptionsTable,
  userTable,
  votesTable,
  allowListTable,
  prodFirebaseConfig,
  createdAccountsTable,
  modelTable,
  defaultProfileImage,
  rendersTable,
  postsTable,
  commentsTable,
} from "./constants";
import {
  collection,
  deleteDoc,
  getFirestore,
  query,
  setDoc,
  addDoc,
  where,
  updateDoc,
  arrayUnion,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
  documentId,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { doc, getDoc } from "firebase/firestore";
import { getDocs } from "firebase/firestore";
import { currentUser, getRandomProfilePic } from "./authentication";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { getWinningSubmissionsFromList } from "./general";
import { leonardoModels } from "./genAI";
import mixpanel from "mixpanel-browser";
import { employeeIds } from "./employees";
import userModel from "../lib/firebase/userModel";
import submissionModel from "../lib/firebase/submissionModel";
import voteModel from "../lib/firebase/voteModel";
import subscriptionModel from "../lib/firebase/subscriptionModel";
import { getAuth } from "firebase/auth";

const app = initializeApp(
  process.env.REACT_APP_DEPLOYMENT === "prod"
    ? prodFirebaseConfig
    : firebaseConfig
);

export const db = getFirestore(app);
export const auth = getAuth(app);

const storage = getStorage();

export function getResultsFromSnapshot(querySnapshot) {
  const results = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    data.id = doc.id;
    results.push(data);
  });
  return results;
}

export function generateRandomId() {
  return Math.floor(Math.random() * 999999999999);
}

export function timestampToDate(timestamp) {
  return new Date(timestamp.seconds * 1000);
}

export async function writeUserProfileData({ userId, name, email }) {
  let newUsername = name.replace(/ /g, "");
  newUsername = `${newUsername.toLocaleLowerCase()}${generateRandomId()}`;
  await setDoc(doc(db, userTable, userId), {
    name,
    email: email.toLowerCase(),
    username: newUsername,
    isModelProcessing: false,
    modelId: null,
    pfp: defaultProfileImage,
  });
  return true;
}

export async function getUserInfo(userId) {
  if (!userId) throw new Error("No userId provided");

  const q = query(doc(db, userTable, userId));
  const querySnapshot = await getDoc(q);
  const userData = querySnapshot.data();
  if (!userData) return null;

  userData.id = userId;
  userData.createdAt = timestampToDate(
    querySnapshot._document.createTime.timestamp
  );

  return userData;
}

export async function getUserInfoByUserId(id) {
  if (!id) throw new Error("No id provided");
  const docRef = doc(db, userTable, id);
  const docSnap = await getDoc(docRef);
  const docData = docSnap.data();
  docData.id = id;
  return docData;
}

export async function getUserInfoByUsername(username) {
  if (!username) throw new Error("No username provided");

  const q = query(collection(db, userTable), where("username", "==", username));
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  if (!results.length === 0) return null;

  return results[0];
}

export async function getUserInfoCountByEmail(emailAddress) {
  const q = query(
    collection(db, userTable),
    where("email", "==", emailAddress.toLowerCase())
  );
  const userInfoCount = await getCountFromServer(q);
  const count = userInfoCount.data().count;
  return count;
}

export async function deleteUser() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const userId = currentUser.uid;
      const docRef = doc(db, userTable, userId);
      await updateDoc(docRef, {
        email: "",
        username: "deleted",
        deleted: true,
        pfp: getRandomProfilePic(),
      });
      currentUser
        .delete()
        .then(async () => {
          console.log("Deleted user successfully");
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          resolve(false);
        });
    }
  });
}

export async function getUserPolls() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, pollTable),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      resolve(results);
    }
  });
}

export async function getOpenPolls() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, pollTable),
        where("endTimestamp", ">", new Date()),
        where("isDraft", "==", false)
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      resolve(results);
    }
  });
}

export async function getOpenQuests() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, questTable),
        where("endTimestamp", ">", new Date()),
        where("isDraft", "==", false)
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        const newData = doc.data();
        if (newData.startTimestamp.toDate() <= new Date()) {
          newData.id = doc.id;
          results.push(newData);
        }
      });
      resolve(results);
    }
  });
}

export async function getCompletedQuests() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, questTable),
        where("endTimestamp", "<=", new Date()),
        where("isDraft", "==", false)
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        const newData = doc.data();
        // if (newData.startTimestamp.toDate() <= new Date()) {
        newData.id = doc.id;
        results.push(newData);
        // }
      });
      resolve(results);
    }
  });
}

export async function getCompletedPolls() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, pollTable),
        where("endTimestamp", "<", new Date()),
        where("confirmed", "==", true),
        where("isDraft", "==", false),
        orderBy("endTimestamp", "desc")
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      // console.log("completed polls");
      // console.log(results);
      resolve(results);
    }
  });
}

export async function getOpenPollsByStoryworld(id) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, questTable),
        where("endTimestamp", ">", new Date()),
        where("storyworld", "==", id)
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        const newData = doc.data();
        if (newData.isDraft === false) {
          newData.id = doc.id;
          results.push(newData);
        }
      });
      resolve(results);
    }
  });
}

export async function getOpenQuestsByStoryworld(id) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, questTable),
        where("endTimestamp", ">", new Date()),
        where("storyworld", "==", id)
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const newData = doc.data();
        if (newData.isDraft === false) {
          newData.id = doc.id;
          results.push(newData);
        }
      });
      resolve(results);
    }
  });
}

export async function uploadImage(folder, image) {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(
      storage,
      `${folder}/${new Date()} -- ${generateRandomId()}`
    );
    uploadBytes(storageRef, image).then((snapshot) => {
      if (snapshot) {
        const imageUrl = getDownloadURL(storageRef);
        resolve(imageUrl);
      } else reject("error uploading image");
    });
  });
}

export async function getLikesForSubmission(submission) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else if (!submission) {
      reject("There is no submission");
    } else {
      const q = query(
        collection(db, likesTable),
        where("submissionId", "==", submission.id)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      resolve(results);
    }
  });
}

export async function getCurrentUserLikeForSubmission(submission) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else if (!submission) {
      reject("There is no submission");
    } else {
      const q = query(
        collection(db, likesTable),
        where("submissionId", "==", submission.id),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      resolve(results);
    }
  });
}

export async function likeSubmission(submission) {
  return new Promise(async (resolve, reject) => {
    const result = await addDoc(collection(db, likesTable), {
      creator: currentUser.uid,
      submissionId: submission.id,
      createdAt: new Date(),
    });
    resolve(result.id);
  });
}

// unlike a previously-liked submission, fails if no like is found
export async function unlikeSubmission({ submission, likeId }) {
  if (!likeId && submission) {
    const currentUserLikesForSubmission =
      await getCurrentUserLikeForSubmission(submission);
    // console.log(currentUserLikesForSubmission[0]);
    likeId = currentUserLikesForSubmission[0].id;
  }
  await deleteDoc(doc(db, likesTable, likeId));
}

// Add a new Quest submission with a generated id
export async function createQuestSubmission({
  quest,
  imageUrl,
  text,
  prompt = "",
  usedNegativePrompt,
  usedStarterPrompt = false,
  startTimestamp = new Date(),
  usedSeedImage = false,
  usedTypedUrl = false,
  storyworld,
}) {
  return new Promise(async (resolve, reject) => {
    await addDoc(collection(db, submissionsTable), {
      imageUrl,
      text,
      prompt,
      creator: currentUser.uid,
      questId: quest.id,
      createdAt: new Date(),
      lastUpdated: new Date(),
      rejected: false,
    });
    if (currentUser && !employeeIds.includes(currentUser.uid)) {
      mixpanel.track("Created Quest Submission", {
        quest_id: quest.id,
        quest_name: quest.title,
        storyworld_id: quest.storyworld,
        storyworld_name: storyworld.title,
        title: `${storyworld.title}: ${quest.title}`,
        used_starter_prompt: usedStarterPrompt,
        used_negative_prompt: usedNegativePrompt,
        used_seed_image: usedSeedImage,
        used_typed_url: usedTypedUrl,
        time_spent_seconds: Math.abs(new Date() - startTimestamp) / 1000,
      });
    }
    resolve("Successfully wrote quest submission");
  });
}

export async function getQuestSubmissions(quest) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, submissionsTable),
        where("questId", "==", quest.id)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      resolve(results);
    }
  });
}

export async function getConfirmedQuestSubmissions(quest) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const results = [];
      const questSubmissionsPromise = await getQuestSubmissions(quest);
      for (const submission of questSubmissionsPromise) {
        if (!submission.rejected) results.push(submission);
      }
      resolve(results);
    }
  });
}

export async function getWinningQuestSubmissions(quest) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const results = [];
      const questSubmissionsPromise = await getConfirmedQuestSubmissions(quest);
      for (const sub of questSubmissionsPromise) {
        sub.voteCount = await getVoteCountForSubmission(sub);
        results.push(sub);
      }
      resolve(getWinningSubmissionsFromList(results));
    }
  });
}

/*
export async function getUserQuestSubmission(quest) {
  const path = `${questTable}/${quest.id}/submissions`;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const docRef = doc(db, path, currentUser.uid);
      const docSnap = await getDoc(docRef);
      console.log("get user quest submission");
      console.log(docSnap.data());
      resolve(docSnap.data());
    }
  });
}
*/

export async function getUserPollVote(poll) {
  if (!poll) return;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, votesTable),
        where("creator", "==", currentUser.uid),
        where("pollId", "==", poll.id)
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        const newData = doc.data();
        results.push(newData);
      });
      if (results.length > 0) {
        resolve(results[0]);
      } else {
        reject("No vote found for this user");
      }
    }
  });
}

export async function submitComment({ comment, subjectId, replyTo }) {
  return new Promise(async (resolve, reject) => {
    await addDoc(collection(db, commentsTable), {
      comment,
      replyTo: replyTo ? replyTo.id : null,
      subjectId,
      creator: currentUser.uid,
      createdAt: new Date(),
      lastUpdated: new Date(),
      edited: false,
    });

    resolve(true);
  });
}

export async function editPostedComment({ editedComment, comment }) {
  console.log(editedComment);
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, commentsTable, editedComment.id);
    await updateDoc(docRef, {
      comment,
      edited: true,
      lastUpdated: new Date(),
    });
    resolve(true);
  });
}

export async function submitPollVote({ quest, voteIds, storyworld }) {
  return new Promise(async (resolve, reject) => {
    await addDoc(collection(db, votesTable), {
      voteIds,
      pollId: quest.poll.id,
      questId: quest.id,
      creator: currentUser.uid,
      createdAt: new Date(),
      lastUpdated: new Date(),
    });
    if (currentUser && !employeeIds.includes(currentUser.uid)) {
      mixpanel.track("User Voted", {
        quest_id: quest.id,
        quest_name: quest.title,
        poll_id: quest.poll.id,
        storyworld_id: quest.storyworld,
        storyworld_name: storyworld.title,
        title: `${storyworld.title}: ${quest.title}`,
        vote_amount: voteIds.length,
      });
    }
    resolve("Successfully wrote user vote data");
  });
}

export async function getLastPublishedEpisodeByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, episodesTable),
        where("storyworldId", "==", storyworldId),
        where("publishedAt", "<=", new Date()),
        orderBy("publishedAt", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      if (results.length > 0) resolve(results[0]);
      else resolve(null);
    }
  });
}

export async function getLastTwoCompletedPolls() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, pollTable),
        where("endTimestamp", "<", new Date()),
        where("confirmed", "==", true),
        where("isDraft", "==", false),
        orderBy("endTimestamp", "desc"),
        limit(2)
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      resolve(results);
    }
  });
}

export async function getFirstPageOfGallery(questId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, submissionsTable),
        where("questId", "==", questId),
        where("rejected", "==", false),
        orderBy("rejected"),
        orderBy("createdAt"),
        limit(galleryPageLimit)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const endResults = {
        results: results,
        documentSnapshots: querySnapshot,
      };
      resolve(endResults);
    }
  });
}

export async function getNextPageOfGallery({ questId, documentSnapshots }) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      const q = query(
        collection(db, submissionsTable),
        where("questId", "==", questId),
        where("rejected", "==", false),
        orderBy("rejected"),
        orderBy("createdAt"),
        startAfter(lastVisible),
        limit(galleryPageLimit)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const endResults = { results, documentSnapshots: querySnapshot };
      resolve(endResults);
    }
  });
}

export async function getFirstPageOfPosts(questId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, submissionsTable),
        where("questId", "==", questId),
        where("rejected", "==", false),
        orderBy("rejected"),
        orderBy("createdAt"),
        limit(galleryPageLimit)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const endResults = {
        results: results,
        documentSnapshots: querySnapshot,
      };
      resolve(endResults);
    }
  });
}

export async function getNextPageOfPosts({ questId, documentSnapshots }) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      const q = query(
        collection(db, submissionsTable),
        where("creator", "==", currentUser.uid),
        where("rejected", "==", false),
        orderBy("createdAt"),
        startAfter(lastVisible),
        limit(galleryPageLimit)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const endResults = { results, documentSnapshots: querySnapshot };
      resolve(endResults);
    }
  });
}

export async function getConfirmedGalleryCount(questId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, submissionsTable),
        where("questId", "==", questId),
        where("rejected", "!=", true)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function getVoteCountForSubmission(submission) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, votesTable),
        where("voteIds", "array-contains", submission.id)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function getQuestVoters(poll) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, votesTable),
        where("pollId", "==", poll.id)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      resolve(results);
    }
  });
}

export async function getBookmarkByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, bookmarksTable),
        where("storyworldId", "==", storyworldId),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const finalResults = results.sort(function (a, b) {
        return b.createdAt.toDate() - a.createdAt.toDate();
      });
      resolve(finalResults.length > 0 ? finalResults[0] : null);
    }
  });
}

export async function getPublishedEpisodesByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, episodesTable),
        where("storyworldId", "==", storyworldId),
        where("publishedAt", "<=", new Date())
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        if (!data.isDraft) results.push(data);
      });
      const finalResults = results.sort(function (a, b) {
        return b.publishedAt.toDate() - a.publishedAt.toDate();
      });
      for (let i = 0; i < finalResults.length; i++) {
        finalResults[i].number = i + 1;
      }
      resolve(finalResults);
    }
  });
}

export async function getEpisodesForAllStoryworlds() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(collection(db, episodesTable));
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const finalResults = results.sort(function (a, b) {
        return a.publishedAt.toDate() - b.publishedAt.toDate();
      });
      resolve(finalResults);
    }
  });
}

export async function getEpisodesByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, episodesTable),
        where("storyworldId", "==", storyworldId)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const finalResults = results.sort(function (a, b) {
        return a.publishedAt.toDate() - b.publishedAt.toDate();
      });
      for (let i = 0; i < finalResults.length; i++) {
        finalResults[i].number = i + 1;
      }
      resolve(finalResults);
    }
  });
}

export async function getStoryworldViewCountById(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, episodesTable),
        where("storyworldId", "==", storyworldId)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);
      let count = 0;
      for (let i = 0; i < results.length; i++) {
        count += results[i].views;
      }
      resolve(count);
    }
  });
}

export async function getEpisodeCountByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, episodesTable),
        where("storyworldId", "==", storyworldId)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function getQuestCountByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, questTable),
        where("storyworld", "==", storyworldId)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function getPostCountByUserId(userId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, postsTable),
        where("creator", "==", userId)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function getPublishedEpisodeCountByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, episodesTable),
        where("storyworldId", "==", storyworldId),
        where("publishedAt", "<=", new Date()),
        where("isDraft", "==", false)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function getSubscriberCountByStoryworldId(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, subscriptionsTable),
        where("storyworldId", "==", storyworldId)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count);
    }
  });
}

export async function isSubscribedToStoryworld(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, subscriptionsTable),
        where("storyworldId", "==", storyworldId),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getCountFromServer(q);
      resolve(querySnapshot.data().count > 0);
    }
  });
}

export async function getStoryworldSubscriptionForCurrentUser(storyworldId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const results = [];
      const q = query(
        collection(db, subscriptionsTable),
        where("storyworldId", "==", storyworldId),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        results.push(data);
      });
      resolve(results);
    }
  });
}

export async function subscribeToStoryworld(storyworldId) {
  return new Promise(async (resolve, reject) => {
    await addDoc(collection(db, subscriptionsTable), {
      creator: currentUser.uid,
      storyworldId,
      createdAt: new Date(),
    }).then(function (docRef) {
      resolve(docRef.id);
    });
    reject("Error subscribing to storyworld");
  });
}

export async function subscribeToCreator(userId) {
  let newExpirationDate = new Date();
  newExpirationDate.setDate(newExpirationDate.getDate() + 30);
  console.log(newExpirationDate.toISOString());

  return new Promise(async (resolve, reject) => {
    await addDoc(collection(db, subscriptionsTable), {
      subscriber: currentUser.uid,
      creator: userId,
      createdAt: new Date(),
      expiresAt: newExpirationDate,
    }).then(function (docRef) {
      resolve(docRef.id);
    });
    reject("Error subscribing to creator");
  });
}

export async function unsubscribeFromCreator(creator) {
  const subscriptions = await subscriptionModel.getMany(
    ["limit", "1"],
    ["creator", "==", creator.id],
    ["subscriber", "==", currentUser.uid]
  );
  if (subscriptions.length > 0 && subscriptions[0]) {
    await deleteDoc(doc(db, subscriptionsTable, subscriptions[0].id));
  }
}

export async function addToReadingList(episodeId) {
  return new Promise(async (resolve, reject) => {
    const readingListRef = doc(db, userTable, currentUser.uid);
    const readingListUnion = await updateDoc(readingListRef, {
      readingList: arrayUnion(episodeId),
    });
    if (readingListUnion) resolve(readingListUnion);
    else reject("Already added to reading list");
  });
}

export async function increaseGenerationQuota(questId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      let quotaData = await getUserGenerationQuotaByQuestId(questId);
      const genLimit = await getGeneratorLimit();
      if (
        (quotaData && quotaData.amount && quotaData.amount < genLimit) ||
        !quotaData
      ) {
        if (!quotaData) {
          const docRef = await addDoc(collection(db, quotasTable), {
            questId,
            userId: currentUser.uid,
          });
          const docSnap = await getDoc(docRef);
          quotaData = docSnap.data();
        }
        await increaseUserGenerationQuotaByOne(quotaData);
        resolve(true);
      }
    }
    resolve(false);
  });
}

export async function increaseUserGenerationQuotaByOne(quotaData) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else if (!quotaData) {
      reject("There is no quota data");
    } else {
      const q = query(
        collection(db, quotasTable),
        where("questId", "==", quotaData.questId),
        where("userId", "==", currentUser.uid),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      const docData = results[0];
      await updateDoc(doc(db, quotasTable, docData.id), {
        amount: docData.amount ? docData.amount + 1 : 1,
      });
      resolve(true);
    }
  });
}

export async function getUserGenerationQuotaByQuestId(questId) {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const userId = currentUser.uid;
      const q = query(
        collection(db, quotasTable),
        where("questId", "==", questId),
        where("userId", "==", userId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);
      if (results.length > 0) resolve(results[0]);
      else resolve(null);
    }
  });
}

export async function getGeneratorLimit() {
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(collection(db, constantsTable), limit(1));
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);
      if (results.length > 0) resolve(results[0].generatorLimit);
      else resolve(0);
    }
  });
}

export async function isEmailOnAllowList(emailAddress) {
  const q = query(
    collection(db, allowListTable),
    where("email", "==", emailAddress.toLowerCase()),
    limit(1)
  );
  const countQuery = await getCountFromServer(q);
  const count = countQuery.data().count;
  return count > 0;
}

export async function getAllowListInfoByEmail(emailAddress) {
  const q = query(
    collection(db, allowListTable),
    where("email", "==", emailAddress.toLowerCase()),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  return results[0];
}

export async function setEnteredEmailFirstTime(userInfo) {
  const docRef = doc(db, allowListTable, userInfo.id);
  await updateDoc(docRef, {
    enteredEmailFirstTime: true,
  });
  return true;
}

export async function usernameExists(desiredUsername) {
  const q = query(
    collection(db, userTable),
    where("username", "==", desiredUsername.toLowerCase()),
    limit(1)
  );
  const countQuery = await getCountFromServer(q);
  const count = countQuery.data().count;
  return count > 0;
}

export async function userModelExists(user) {
  console.log(user);
  const q = query(collection(db, modelTable), where("creator", "==", user.uid));
  const countQuery = await getCountFromServer(q);
  const count = countQuery.data().count;
  return count > 0;
}

export async function acceptTos(email) {
  if (currentUser === undefined) {
    return "There is no logged in user";
  } else {
    const userId = currentUser.uid;
    const docRef = doc(db, userTable, userId);
    await updateDoc(docRef, {
      acceptedTos: true,
    });
    mixpanel.track("Accepted ToS", {
      email: email.toLocaleLowerCase(),
    });
    return true;
  }
}

export async function getEmailsUsedForAccountCreation() {
  const q = query(collection(db, createdAccountsTable));
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  const finalResults = [];
  results.forEach((res) => {
    if (res.email) finalResults.push(res.email);
  });
  return finalResults;
}

export async function getUserTrackingData() {
  const finalData = [];
  const users = await userModel.getMany();
  users.forEach(async (user) => {
    const questSubmissions = await submissionModel.getManyByUserId(user.id);
    const pollVotes = await voteModel.getManyByUserId(user.id);
    const userData = {
      id: user.id,
      email: user.email,
      username: user.username,
      questSubmissions: questSubmissions.length,
      pollVotes: pollVotes.length,
    };
    finalData.push(userData);
  });
  return finalData;
}

export async function getRenderCount() {
  const q = query(
    collection(db, rendersTable),
    where("creator", "==", currentUser.uid)
  );
  const userRendersCount = await getCountFromServer(q);
  const count = userRendersCount.data().count;
  return count;
}

export async function getRenderById(renderId) {
  console.log(`Getting render by ID: ${renderId}`);
  const docRef = doc(db, rendersTable, renderId);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function createPost({ text, imageUrl, isPublic }) {
  if (text === "" && imageUrl === "") return null;

  return new Promise(async (resolve, reject) => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, postsTable), {
      text,
      imageUrl,
      creator: currentUser.uid,
      createdAt: new Date(),
      lastUpdated: new Date(),
      public: isPublic,
    });
    if (docRef.id !== undefined && docRef.id !== null) resolve(true);
    else reject(false);
  });
}
