import React, { useEffect, useState } from "react";
import UserDisplay from "./UserDisplay";
import { shuffleArray } from "../helpers/general";
import { NumberCircle } from "../views";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const maxUserDisplay = 7;

function UserDisplayGroup({ users = [], backLocations, setBackLocations }) {
  const location = useLocation();

  const [uniqueUsers, setUniqueUsers] = useState([]);

  useEffect(() => {
    const newUsers = [...new Set(users)];
    const newUsersShuffled = shuffleArray(newUsers);
    setUniqueUsers(newUsersShuffled);
  }, [users]);

  return (
    <span className="user-display-group-master">
      <span className="user-display-group-span">
        {uniqueUsers.map((id, index) =>
          index < maxUserDisplay ? (
            <UserDisplay
              key={index}
              userId={id}
              size={36}
              backLocation={location.pathname}
              backLocations={backLocations}
              setBackLocations={setBackLocations}
            />
          ) : null
        )}
        {uniqueUsers.length > maxUserDisplay ? (
          <NumberCircle
            number={uniqueUsers.length - maxUserDisplay}
            showPlus={true}
            size={38}
          />
        ) : null}
      </span>
    </span>
  );
}

export default UserDisplayGroup;
