import React, { useEffect } from "react";
import styled from "styled-components";
import { adminList } from "../../helpers/constants";
import {
  currentUser,
  firebaseAuth,
  isUserLoggedIn,
} from "../../helpers/authentication";
import { isOnMobile, sleep } from "../../helpers/general";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function LoginStart({ setInSignIn }) {
  const history = useHistory();

  useEffect(() => {
    async function waitForLogin() {
      await sleep(1);
      const userLoggedIn = await isUserLoggedIn();
      const isAdminUser = currentUser && adminList.includes(currentUser.email);
      const onMobile = isOnMobile();
      if (userLoggedIn && isAdminUser && !onMobile) {
        history.push("/editStoryworlds");
      } else if (userLoggedIn && isOnMobile()) {
        history.push("/stories");
      } else if (userLoggedIn && !isAdminUser && !onMobile) {
        try {
          await firebaseAuth.signOut();
          window.location.href = "/";
        } catch (error) {
          console.error("Sign out Error:", error);
        }
      }
    }
    waitForLogin();
  }, [history]);

  return (
    <Container>
      <StorygroundsLogo src="/images/general/storygrounds-login-logo.svg" />
      <Header>Build webcomics together.</Header>
      <ActionButton type="signin" onClick={() => setInSignIn(true)}>
        Sign in
      </ActionButton>
      <WaitlistHeader>Need to sign up?</WaitlistHeader>
      <ActionButton onClick={() => window.open(null)}>
        Join the waitlist
      </ActionButton>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const StorygroundsLogo = styled.img`
  width: 85%;
  height: auto;
  margin: auto;
  display: block;
  max-width: 300px;
  position: relative;
  z-index: 3;
`;

const Header = styled.div`
  color: var(--color-green-base);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  margin-top: 22px;
  position: relative;
  z-index: 3;
`;

const WaitlistHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 140px;
  margin-bottom: 14px;
  color: #fcfcfd;
`;

const ActionButton = styled.button`
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  cursor: pointer;
  background: none;
  width: 192px;
  height: 48px;
  border-radius: 90px;
  border: 1px solid white;
  color: #fcfcfd;
  position: relative;
  z-index: 3;
  margin-top: ${(props) => (props.type === "signin" ? "110" : "0")}px;
`;
