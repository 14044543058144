import React, { useState } from "react";
import styled from "styled-components";
import {
  getAllowListInfoByEmail,
  isEmailOnAllowList,
  setEnteredEmailFirstTime,
} from "../../helpers/firebase";
import {
  doesUserAuthExistByEmail,
  validateEmail,
} from "../../helpers/authentication";
import mixpanel from "mixpanel-browser";

export default function AllowListCheck({
  emailAddress,
  setEmailAddress,
  setUserExists,
  setIsOnAllowList,
  setHasCreatedAccount,
}) {
  const [isSending, setIsSending] = useState(false);
  const [isEmailValidated, setEmailValidated] = useState(false);

  const onEmailChange = (event) => {
    const email = event.target.value;
    const emailValid = validateEmail(email);
    setEmailValidated(emailValid);
    if (emailValid) setEmailAddress(email);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);
    emailAddress = emailAddress.toLocaleLowerCase();
    const onAllowList = await isEmailOnAllowList(emailAddress);
    if (onAllowList) {
      const allowListInfo = await getAllowListInfoByEmail(emailAddress);
      if (!allowListInfo.enteredEmailFirstTime) {
        mixpanel.track("First Time Email Entry on Welcome Page", {
          email: emailAddress,
        });
        await setEnteredEmailFirstTime(allowListInfo);
      }
    }
    const userAuthExist = await doesUserAuthExistByEmail(emailAddress);
    setIsOnAllowList(onAllowList);
    setHasCreatedAccount(userAuthExist);
  };

  return (
    <Container>
      <WelcomeImage src="/images/login/welcome-image.svg" alt="" />
      <Subheader>
        Enter the email you used during pre-registration to sign in
      </Subheader>
      <form onSubmit={onSubmit} style={{ position: "relative", zIndex: 1 }}>
        <EmailHeader>Email</EmailHeader>
        <EmailInput
          className="transition-colors"
          placeholder="email@email.com"
          onChange={onEmailChange}
        />
        {!isSending ? (
          isEmailValidated && emailAddress !== "" ? (
            <SignInButton>Enter</SignInButton>
          ) : (
            <SignInButtonDisabled disabled>Enter</SignInButtonDisabled>
          )
        ) : (
          <SendingButton disabled>
            <Spinner alt="" src="/icons/loading-spheres.svg" />
          </SendingButton>
        )}
      </form>
      <Diamond src="/images/diamond.svg" alt="" />
      <Clouds src="/images/clouds.svg" alt="" />
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
  width: 90%;
  text-align: left;
`;

const WelcomeImage = styled.img`
  width: 72.5%;
  height: auto;
  margin: auto;
`;

const Subheader = styled.div`
  margin-top: 16px;
  color: var(--color-cream-base);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const SignInButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  cursor: pointer;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-cream, #fef6f3);
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 88.889% */
`;

const SignInButtonDisabled = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const SendingButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  text-align: center;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const EmailInput = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: auto auto 32px auto !important;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const EmailHeader = styled.div`
  margin: 30px 0px 12px 0px;
  font-family: Poppins;
  color: var(--color-gray-50);
  font-family: Hoss Sharp;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const Diamond = styled.img`
  position: fixed;
  bottom: 50px;
  width: 18%;
  height: auto;
  z-index: 0;
`;

const Clouds = styled.img`
  position: fixed;
  bottom: 0px;
  width: 90%;
  height: auto;
  right: 0px;
  z-index: 0;
`;
