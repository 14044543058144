import React from "react";
import { styled } from "styled-components";
import mixpanel from "mixpanel-browser";
import { useQuery } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import { getUserInfo } from "../helpers/firebase";
import { currentUser } from "../helpers/authentication";
import { employeeIds } from "../helpers/employees";
import { ShareAltOutlined } from "@ant-design/icons";
import { profileStub } from "../helpers/constants";

export default function ShareButton({
  title = "AllmaiFans Share",
  message = "Check out my content",
  imageUrl = "",
}) {
  const userQuery = useQuery({
    queryKey: [`user-${currentUser.uid}`],
    queryFn: () => {
      const currentUser = getAuth().currentUser;
      if (!currentUser) return null;
      return getUserInfo(currentUser.uid);
    },
  });

  const shareMessage = async () => {
    if (currentUser && !employeeIds.includes(currentUser.uid)) {
      mixpanel.track("Clicked Share Button");
    }
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const files = [
      new File([blob], "meme.jpg", {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      }),
    ];
    const profileUrl = `${profileStub}/${
      userQuery.status === "success" && userQuery.data.username
    }`;
    const shareData = {
      title,
      text: message,
      url: profileUrl,
      files,
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
          if (currentUser && !employeeIds.includes(currentUser.uid)) {
            mixpanel.track("Completed Sharing Content");
          }
        })
        .catch((error) => console.log("Error sharing", error));
    }
  };

  return (
    <Container>
      <Button onClick={shareMessage} className="share-button-full">
        <ShareAltOutlined />
      </Button>
    </Container>
  );
}

const Container = styled.span`
  text-align: right;
  height: 100%;

  .share-button-full {
    background: none;
    margin: auto;
  }
`;

const Button = styled.button`
  border: 0;
  width: auto;
  height: 100%;
  font-size: 18px;
  background: none;
  margin: auto;
  color: var(--color-gray-100);
`;
