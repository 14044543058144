import { likesTable } from "../../helpers/constants";
import { count, queryMany, queryOne } from "./all";

const table = likesTable;

const likeModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getManyByStoryworldId: async (storyworldId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["storyworldId", "==", storyworldId], ...args],
    });

    return data;
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default likeModel;
