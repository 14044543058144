import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { registerAccount } from "../../helpers/authentication";
import { message } from "antd";

const passwordLowerThreshold = 6;
const passwordUpperThreshold = 15;

export default function RegisterAccount({
  emailAddress,
  setHasCreatedAccount,
  setJustCreatedAccount,
}) {
  const [password, setPassword] = useState("");
  const [isPasswordValidated, setPasswordValidated] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [revealPassword, setRevealPassword] = useState(false);
  const [reavealConfirmedPassword, setReavealConfirmedPassword] =
    useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  let password1Ref = React.createRef();
  let password2Ref = React.createRef();

  useEffect(() => {
    const passwordsMatch = password === confirmedPassword;
    const passwordLengthValid =
      password.length >= passwordLowerThreshold &&
      password.length <= passwordUpperThreshold;
    setPasswordValidated(passwordLengthValid && passwordsMatch);
    if (!passwordsMatch) setPasswordError("Passwords do not match");
    else if (!passwordLengthValid && password !== "") {
      setPasswordError(
        `Must be between ${passwordLowerThreshold} and ${passwordUpperThreshold} characters.`
      );
    } else setPasswordError("");
  }, [password, confirmedPassword]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsRegistering(true);
    const registered = await registerAccount({
      email: emailAddress,
      password,
    });
    setHasCreatedAccount(registered);
    setIsRegistering(false);
    if (registered) setJustCreatedAccount(true);
    else message.error("An account with this email already exists");
  };

  return (
    <Container>
      <WelcomeImage src="/images/login/welcome-image.svg" alt="" />
      <Subheader>
        Enter a password to create your Storygrounds account
      </Subheader>
      <form onSubmit={onSubmit}>
        <InputHeader>Email</InputHeader>
        <InputContainer>
          <InputFieldDisabled
            className="transition-colors"
            placeholder="email@email.com"
            value={emailAddress}
            disabled
          />
          <OverlayIcon src="/icons/checkmark.svg" />
        </InputContainer>

        <InputHeader>Password</InputHeader>
        <InputContainer>
          <InputField
            className="transition-colors"
            placeholder={`Must be at least ${passwordLowerThreshold} characters`}
            value={password}
            maxLength={40}
            onChange={(e) => setPassword(e.target.value)}
            type={revealPassword ? "text" : "password"}
            ref={password1Ref}
          />
          <span
            class="flex justify-around items-center"
            onClick={() => {
              setRevealPassword(!revealPassword);
              password1Ref.current.focus();
            }}
          >
            {revealPassword ? (
              <HidePasswordIcon src="/images/general/password-hide.svg" />
            ) : (
              <RevealPasswordIcon src="/images/general/password-reveal.svg" />
            )}
          </span>
        </InputContainer>
        <InputHeader>Confirm password</InputHeader>
        <InputContainer>
          <InputField
            className="transition-colors"
            placeholder="Type your password again"
            value={confirmedPassword}
            maxLength={40}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            type={reavealConfirmedPassword ? "text" : "password"}
            ref={password2Ref}
          />
          <span
            class="flex justify-around items-center"
            onClick={() => {
              setReavealConfirmedPassword(!reavealConfirmedPassword);
              password2Ref.current.focus();
            }}
          >
            {reavealConfirmedPassword ? (
              <HidePasswordIcon src="/images/general/password-hide.svg" />
            ) : (
              <RevealPasswordIcon src="/images/general/password-reveal.svg" />
            )}
          </span>
        </InputContainer>
        {passwordError !== "" && <PasswordError>{passwordError}</PasswordError>}

        {!isRegistering ? (
          isPasswordValidated ? (
            <CreateAccountButton type="submit">
              Create Account
            </CreateAccountButton>
          ) : (
            <CreateAccountButtonDisabled disabled>
              Create Account
            </CreateAccountButtonDisabled>
          )
        ) : (
          <IsRegisteringButton disabled>
            <Spinner alt="" src="/icons/loading-spheres.svg" />
          </IsRegisteringButton>
        )}
      </form>
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
  width: 90%;
  text-align: left;
`;

const WelcomeImage = styled.img`
  width: 72.5%;
  height: auto;
  margin: auto;
`;

const OverlayIcon = styled.img`
  right: 14px !important;
  top: 35%;
  height: 12px;
  position: absolute;
`;

const InputContainer = styled.div`
  position: relative;
`;

const Subheader = styled.div`
  margin-bottom: 30px;
  margin-top: 16px;
  color: var(--color-cream-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const IsRegisteringButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 32px;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const CreateAccountButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  margin-top: 32px;
  color: var(--cream);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const CreateAccountButtonDisabled = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 32px;
  color: var(--disabled-button-text);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const InputFieldDisabled = styled.input`
  width: 100%;
  height: 50px;
  font-size: 14px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--seafoam);
  margin: auto;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const InputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
  margin: auto;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const InputHeader = styled.div`
  margin: 24px 0px 10px 0px;
  color: var(--color-gray-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.2px;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const PasswordError = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 12px;
  color: var(--color-red);
  letter-spacing: 0.2px;
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;
