import React from "react";
import styled from "styled-components";
import { Dropdown, Space } from "antd";
import postsModel from "../../lib/firebase/postsModel";
import mixpanel from "mixpanel-browser";
import { currentUser } from "../../helpers/authentication";

export default function PostDropdown({ post, setPosts, setOpen }) {
  let items;

  if (currentUser.uid === post.creator) {
    items = [
      {
        label: (
          <Header
            onClick={async (e) => {
              console.log("delete comment");
              console.log(post);
              const result = await postsModel.delete(post.id);
              console.log(result);
              await mixpanel.track("Deleted Post", {
                url: post.imageUrl ? post.imageUrl : null,
                text: post.text,
              });
              window.location.reload();
            }}
          >
            Delete post
          </Header>
        ),
        key: "1",
      },
    ];
  } else {
    items = [
      {
        label: (
          <DeleteHeader
            onClick={async (e) => {
              // console.log("report post");
              // console.log(post);
              setOpen(true);
              // const result = await postsModel.delete(post.id);
              // console.log(result);
            }}
          >
            Report
          </DeleteHeader>
        ),
        key: "1",
      },
    ];
  }

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button onClick={(e) => e.preventDefault()}>
        <Space>
          <Dots src="/icons/social/ellipsis.svg" alt="" />
        </Space>
      </Button>
    </Dropdown>
  );
}

const Button = styled.button`
  background: transparent;
  border: 0;
  font-size: 22px;
`;

const Header = styled.button`
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  color: var(--color-gray-200);
  border: 0;
  background: transparent;
`;

const Dots = styled.img`
  height: 20px;
  width: auto;
  padding-top: 6px;
  opacity: 0.5;
`;

const DeleteHeader = styled.div`
  color: red;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.6px */
  margin-top: 0px !important;
  width: 100%;
  height: 100%;
  background: none;
`;
