import React, { useEffect } from "react";
import styled from "styled-components";

export default function SubmitGeneration({ setShowBackground }) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setShowBackground(true);
  }, [setShowBackground]);

  return (
    <SubmittingSection>
      <X src="/images/nux/x-bordered-purple.svg" alt="" />
      <HollowCirclePurple src="/images/nux/hollow-circle-purple.svg" alt="" />
      <HollowCirclePink src="/images/nux/hollow-circle-pink.svg" alt="" />
      <HollowStar src="/images/nux/star-hollow.svg" alt="" />
      <Flair src={"/icons/gen-ai/portrait-flair.svg"} alt="" />
      <Pencil src="/icons/gen-ai/pencil-spiral-no-flair.png" alt="" />
      <BunnyImage src={"/images/nux/bunny-painting.svg"} alt="" />
      <SubmittingText>Submitting your story to the quest...</SubmittingText>
      <Spiral src="/icons/gen-ai/spiral-image-gen.svg" alt="" />
      <LongX src="/images/nux/x-bordered.svg" alt="" />
      <Carrot src="/icons/gen-ai/carrot-hollow.svg" alt="" />
      <Sword src="/icons/gen-ai/sword-hollow.svg" alt="" />
      <RoundedStar src="/images/nux/star-white-bordered.svg" alt="" />
    </SubmittingSection>
  );
}

const SubmittingSection = styled.div`
  margin-top: 5%;
  text-align: center !important;
  width: 100%;
  position: relative;
`;

const BunnyImage = styled.img`
  width: 50%;
  height: auto;
  margin-top: 25%;
`;

const Flair = styled.img`
  position: absolute;
  width: 15%;
  height: auto;
  margin-top: 25%;
  top: -8%;
  right: 14.5%;
`;

const SubmittingText = styled.h3`
  color: var(--color-cream);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.176px;
  margin: auto;
  margin-top: 28px;
  width: 80%;
`;

const X = styled.img`
  position: absolute;
  width: 8%;
  height: auto;
  left: 34px;
  top: 8px;
`;

const LongX = styled.img`
  position: absolute;
  width: 14%;
  height: auto;
  left: 38%;
  top: 134%;
`;

const HollowCirclePurple = styled.img`
  position: absolute;
  width: 8%;
  height: auto;
  right: 8%;
  top: 30%;
`;

const HollowCirclePink = styled.img`
  position: absolute;
  width: 8%;
  height: auto;
  left: 12%;
  top: 68%;
`;

const HollowStar = styled.img`
  position: absolute;
  width: 10%;
  height: auto;
  right: 38%;
  top: 10px;
`;

const Pencil = styled.img`
  position: absolute;
  width: 30%;
  height: auto;
  left: -8%;
  top: 40px;
`;

const Spiral = styled.img`
  position: absolute;
  width: 16%;
  height: auto;
  right: 27%;
  top: 110%;
`;

const Carrot = styled.img`
  position: absolute;
  width: 33%;
  height: auto;
  right: -2%;
  top: 127%;
`;

const Sword = styled.img`
  position: absolute;
  width: 29%;
  height: auto;
  left: 4%;
  top: 109%;
`;

const RoundedStar = styled.img`
  position: absolute;
  width: 6%;
  height: auto;
  right: 13%;
  top: 72.5%;
`;
