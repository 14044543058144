import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row, Switch, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";

export default function PostEditor({
  text,
  setText,
  setOpen,
  imageUrl,
  editorRef,
  isPublic,
  setPublic,
}) {
  return (
    <Container>
      <Editor
        onChange={(e) => setText(e.target.value)}
        rows={5}
        ref={editorRef}
        value={text}
        placeholder="Write a message to engage with your fans..."
      />
      {imageUrl && <ContentImage src={imageUrl} alt="" />}
      <ButtonSection>
        <Row>
          <Col span={12}>
            <CreateContentSection
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Content
              <ImageIcon alt="" src="/icons/general/image.svg" />
            </CreateContentSection>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <SwitchTooltip>
              <Tooltip
                title={`Non-subscribers can see this content on your Profile. It will also be viewable in New Posts on the Home Page.`}
              >
                Public <InfoCircleOutlined />
              </Tooltip>
            </SwitchTooltip>
            <Switch
              defaultChecked={isPublic}
              onChange={(e) => {
                setPublic(e);
              }}
            />
          </Col>
        </Row>
      </ButtonSection>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  color: white;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);
`;

const Editor = styled.textarea`
  color: black;
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  border: 0px;
  font-size: 14px;
  resize: none;

  &:focus-visible {
    outline: 0;
  }
`;

const ContentImage = styled.img`
  color: black;
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  border: 0px;
  background: white;

  &:focus-visible {
    outline: 0;
  }
`;

const ButtonSection = styled.div`
  height: fit-content;
  display: block;
  background: white;
  color: black;
  padding: 12px 14px 8px 14px;
  border-bottom: 1px solid lightgray;
`;

const CreateContentSection = styled.span`
  padding-top: 4px;
  vertical-align: middle;
  color: var(--color-gray-200);
  display: inline-flex;
  cursor: pointer;
`;

const ImageIcon = styled.img`
  height: 24px;
  width: auto;
  margin-top: auto;
  margin-left: 6px;
  padding-bottom: 8px;
  display: block;
  opacity: 0.5;
`;

const SwitchTooltip = styled.span`
  margin-right: 8px;
  padding-top: 4px;
  vertical-align: middle;
  color: var(--color-gray-200);
`;
