import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LoginCheck, LoginStart } from "../components/login";

export default function PreRegLogin({
  setLocationTitle,
  setShowLoginBackground,
}) {
  const [inSignIn, setInSignIn] = useState(false);

  useEffect(() => {
    if (inSignIn) setLocationTitle("");
    else setLocationTitle("login");
  }, [setLocationTitle, inSignIn]);

  useEffect(() => {
    setShowLoginBackground(inSignIn);
  }, [inSignIn, setShowLoginBackground]);

  return (
    <>
      {inSignIn ? (
        <CheckContainer>
          <LoginCheck setInSignIn={setInSignIn} />
        </CheckContainer>
      ) : (
        <StartContainer>
          <LoginStart setInSignIn={setInSignIn} />
        </StartContainer>
      )}
    </>
  );
}

const StartContainer = styled.div`
  text-align: center;
  margin: 48px auto auto auto;
  width: 90%;
`;

const CheckContainer = styled.div`
  text-align: center;
  margin: 30px auto 140px auto;
  width: 100%;
`;
