// ----- Components Import -----
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

const tikTokLogo = "/icons/tiktok.svg";
const instagramLogo = "/icons/instagram.svg";
const twitterLogo = "/icons/twitter.svg";

export default function Footer() {
  const location = useLocation();

  return (
    <FooterWrapper>
      <SocialContainer>
        <Link to="/">
          <Logo src="/logo512.png" alt="" />
        </Link>
      </SocialContainer>
      <OtherContainer>
        <FooterLinks>
          <a href={`mailto:${null}`} rel="noreferrer" target="_blank">
            Feedback
          </a>{" "}
          |{" "}
          <a href={`mailto:${null}`} rel="noreferrer" target="_blank">
            Help
          </a>{" "}
          |{" "}
          <Link
            to="/terms"
            className={location.pathname.includes("/terms") ? "selected" : ""}
          >
            Terms
          </Link>{" "}
          |{" "}
          <Link
            to="/privacy"
            className={location.pathname.includes("/privacy") ? "selected" : ""}
          >
            Privacy
          </Link>
          {/*
		  {" "}
          |{" "}
          <Link
            to="/content-and-community-guidelines"
            className={
              location.pathname.includes("/content-and-community-guidelines")
                ? "selected"
                : ""
            }
          >
            Community Guidelines
          </Link>
		*/}
        </FooterLinks>
        <CopyrightText>© AllmaiFans</CopyrightText>
      </OtherContainer>
    </FooterWrapper>
  );
}

{
  /*
		<FooterWrapper>
			<Container>
				<FooterWidgets>
					<SocialWidget>
						<Logo>
							<img className="logo-lg" src={sgFooterLogo} alt="" />
							<img className="logo-sm" src={sgFooterLogoSm} alt="" />
						</Logo>
						<SocialsInfo>
							<h3>Want to connect?</h3>
							{/*
							<SocialIcons>
								{socialLinksData.map((item) => (
									<li key={item.id}>
										<a href={item.url} target="_blank">
											{<item.icon />}
										</a>
									</li>
								))}
							</SocialIcons>
              */
}
{
  /*
						</SocialsInfo>
					</SocialWidget>
				</FooterWidgets>
			</Container>
		</FooterWrapper>
								*/
}

// ----- Styles -----
const FooterWrapper = styled.footer`
  background-color: var(--color-primary);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4rem 0;

  @media (max-width: 47.9375rem) {
    padding: 3.125rem 0;
  }
`;

const FooterWidgets = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 47.9375rem) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 20rem;
    margin: auto;
  }

  .logo-lg {
    @media (max-width: 47.9375rem) {
      display: none;
    }
  }

  .logo-sm {
    display: none;

    @media (max-width: 47.9375rem) {
      display: block;
    }
  }
`;

const SocialWidget = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 47.9375rem) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h3 {
    font-size: 3rem;
    line-height: 52px;
    color: var(--color-tertiary);
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 0.5rem;

    @media (max-width: 35.9375rem) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }

    br {
      @media (max-width: 47.9375rem) {
        display: none;
      }
    }
  }

  p {
    font-family: var(--heading-font);
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 700;
    color: var(--color-white);
    margin-bottom: 1rem;

    @media (max-width: 35.9375rem) {
      font-size: 1.125rem;
      line-height: 0.75rem;
      margin-bottom: 2rem;
    }
  }

  ul {
    gap: 1.25rem;

    li a {
      font-size: 1.8rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

const SocialsInfo = styled.div`
  ul {
    @media (max-width: 47.9375rem) {
      justify-content: center;
      flex-wrap: wrap;
    }
    a {
      transition: 0.3s;

      &:hover {
        box-shadow: 4px 4px var(--color-tertiary);
        transition: 0.3s;
      }
    }
  }
`;

const MenuWidget = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 47.9375rem) {
    flex-direction: row;
    column-gap: 2.25rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  li {
    text-align: right;
  }

  a {
    font-size: 1.25rem;
    color: var(--color-white);
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    transition: 0.3s;

    @media (max-width: 47.9375rem) {
      line-height: 1.25rem;
    }

    &:hover {
      color: var(--color-tertiary);
      transition: 0.3s;
    }
  }
`;

/*
const Footer = styled.div`
	background: var(--color-main-blue);
	flex-direction: column;
	gap: 20px;
	width: 100%;
	padding: 20px 0px;
`;
*/

const FooterLinks = styled.div`
  font-size: 1rem;
  text-align: center;
  font-style: normal;
  color: white;
  font-weight: 400;
  line-height: 32.5px;
  letter-spacing: 0.4px;
  display: block;
  background: none;

  a {
    color: white !important;
    font-size: 1rem;
  }

  @media (max-width: 47.9375rem) {
    font-size: 0.6rem;

    a {
      font-size: 0.6rem;
    }
  }

  .selected {
    color: black;
    font-weight: 600;
  }
`;

const Logo = styled.img`
  height: 40px;
  margin: auto;
`;

const SocialContainer = styled.div`
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  text-align: center;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: -12px;
  color: black;
`;

const OtherContainer = styled.div`
  text-align: center;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const CopyrightText = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #ede5e2;
  line-height: 32px;
  font-style: normal;
  line-height: 32.5px;
  margin-top: 0px;
  color: white;
`;
