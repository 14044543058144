import React, { useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "antd";
import ConsistencyCarouselOne from "./ConsistencyCarouselOne";
import ConsistencyCarouselTwo from "./ConsistencyCarouselTwo";
import ConsistencyCarouselThree from "./ConsistencyCarouselThree";

export default function ConsistencyCarousel() {
  const carouselRef = React.createRef();

  useEffect(() => {
    carouselRef.current.goTo(0, true);
  }, [carouselRef]);

  return (
    <Container>
      <Header>Consistent Content</Header>

      <Carousel ref={carouselRef}>
        <CarouselSection>
          <ConsistencyCarouselOne />
        </CarouselSection>
        <CarouselSection>
          <ConsistencyCarouselTwo />
        </CarouselSection>
        <CarouselSection>
          <ConsistencyCarouselThree />
        </CarouselSection>
      </Carousel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  .invisible {
    opacity: 0;
    pointer-events: none;
  }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background: var(--color-gray-50);
    }

    .slick-active {
      width: 32px;
      height: 8px;
      border: 0;
      background: var(--color-main-blue);
    }

    button {
      opacity: 0 !important;
    }
  }
`;

const CarouselSection = styled.div`
  margin: 0;
  width: 100%;
  height: 420px;
  max-height: 370px;
  overflow: hidden;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: black;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 14px;
`;
