import { message, Upload } from "antd";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { generateRandomId } from "../helpers/firebase";
import { useState } from "react";
import styled from "styled-components";

export default function UploadImageGenAi({
  setImageBlob,
  setImageUrl,
  setHasUploadedImage,
  setImageFilename,
  maxFileSizeInMb,
  maxWidth,
  maxHeight,
  minWidth,
  fileBucket,
  byPassRes = false,
  isDisabled = false,
}) {
  const { Dragger } = Upload;
  const storage = getStorage();

  const [isUploading, setIsUploading] = useState(false);

  const beforeUpload = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("Please upload a JPG/PNG/JPEG/WEBP image");
    }
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeInMb;
    if (!isLt2M) {
      message.error(`Image must be smaller than ${maxFileSizeInMb}MB`);
    }
    const isRightResolution = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          var goodRes;
          if (minWidth) {
            goodRes = width >= minWidth;
          } else if (maxWidth && maxHeight) {
            goodRes = width === maxWidth && height === maxHeight;
          } else if (byPassRes) {
            goodRes = true;
          }
          if (!goodRes) {
            message.error(`Image must be the correct resolution`);
            resolve(false);
          }
          resolve(true);
        });
      });
    });
    return isJpgOrPng && isLt2M && isRightResolution;
  };

  const props = {
    name: "image",
    multiple: false,
    maxCount: 1,
    accept: ".jpeg,.jpg,.png,.webp",
    beforeUpload,
    action: async (file) => {
      setIsUploading(true);
      setHasUploadedImage(true);

      const storageRef = ref(
        storage,
        `${fileBucket}/${new Date()} -- ${generateRandomId()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // handle progress state changes here
        },
        (error) => {
          console.error(error);
          message.error(`${file.name} file upload failed.`);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setImageBlob(file);
            setImageUrl(downloadURL);
            setImageFilename(file.name);
            return downloadURL;
          } catch (error) {
            console.error(error);
          }
          setIsUploading(false);
        }
      );
    },
  };

  return (
    <Container>
      {isUploading ? (
        <LoadingContainer>
          <Spinner src="/icons/loading-spheres.svg" />
        </LoadingContainer>
      ) : (
        <InnerContainer>
          <Dragger {...props} disabled={isDisabled}>
            <span
              className="ant-upload-drag-icon"
              style={{ marginBottom: 8, marginRight: 4 }}
            >
              <UploadIcon src="/icons/gen-ai/upload-image.svg" alt="" />
            </span>
            <UploadHint style={{ marginLeft: 6, marginTop: 6 }}>
              Tap to upload an image
            </UploadHint>
          </Dragger>
        </InnerContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: fit-content;
`;

const InnerContainer = styled.div`
  background: var(--color-gray-300);
  border-radius: 8px;
  border-radius: 8px;
  border: 1.5px dashed var(--color-gray-100);
  margin-top: 6px;

  .ant-upload.ant-upload-drag {
    height: 47px !important;
  }

  .ant-upload {
    padding: 0px !important;
    text-align: left;
    padding-left: 6px !important;
  }

  .ant-upload.ant-upload-drag {
    border: 0 !important;
  }
`;

const UploadHint = styled.span`
  color: #747887;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px 
  letter-spacing: 0.2px;
`;

const UploadIcon = styled.img`
  width: 16.391px;
  height: 17px;
  padding: 0px 0px 0px 1px;
  margin-bottom: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const LoadingContainer = styled.div`
  display: block;
  text-align: center;
`;

const Spinner = styled.img`
  height: 50px;
  width: auto;
  margin: auto;
  display: inline-flex;
  margintop: 40px;
  animation: rotation 2s infinite linear;
`;
