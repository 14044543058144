import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

const gutterSize = 0;
const leftColumnSpan = 10;
const middleColumnSpan = 2;
const rightColumnSpan = 12;
const paddingSize = 10;

export default function PrivacyPolicyPersonalDataTable() {
  return (
    <Container>
      <Row
        gutter={gutterSize}
        style={{
          backgroundColor: "#d9d9d9",
          padding: `0px ${paddingSize}px`,
        }}
      >
        <Col span={leftColumnSpan} className="header">
          Category of personal data
        </Col>
        <Col span={middleColumnSpan} />
        <Col
          span={rightColumnSpan}
          className="header"
          style={{ marginTop: 12 }}
        >
          Description
        </Col>
      </Row>
      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          User Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>
            Creators and Content Collaborators
          </ExtraText>
          <UnorderedList>
            <ListItem>full name*</ListItem>
            <ListItem>alias, if applicable</ListItem>
            <ListItem>residential address</ListItem>
            <ListItem>country of residence*</ListItem>
            <ListItem>email address</ListItem>
            <ListItem>telephone number</ListItem>
            <ListItem>
              a copy of the government identity document that you provide to us*
            </ListItem>
            <ListItem>
              a "selfie" of you holding your government identity document*
            </ListItem>
            <ListItem>
              third-party social media handle / personal website address (used
              to further verify your age and identity and to help us better
              understand the content which you are likely to share on our
              Website)
            </ListItem>
            <ListItem>
              signature on release forms if you feature in another Creator's
              content*
            </ListItem>
          </UnorderedList>
          <ExtraText>
            Please note: Items marked with * will be requested for Content
            Collaborators that are not existing Creators on the Website, via a
            release form.
          </ExtraText>
          <ExtraText>Fans</ExtraText>
          <UnorderedList>
            <ListItem>email address</ListItem>
            <ListItem>telephone number</ListItem>
          </UnorderedList>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Third-Party Onboarding Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>
            The following types of personal data are collected directly by our
            third-party providers during onboarding:
          </ExtraText>
          <ExtraText>Creators</ExtraText>
          <UnorderedList>
            <ListItem>
              a copy of the government identity document that you provide to our
              third-party providers
            </ListItem>
            <ListItem>
              a short .gif, taken from a “selfie” that you provide to our
              third-party providers
            </ListItem>
            <ListItem>
              the results of the third-party age and identity verification
              process (pass / fail and reason for failing)
            </ListItem>
            <ListItem>
              metadata associated with the third-party age and identity
              verification process (e.g. start and finish time)
            </ListItem>
          </UnorderedList>
          <ExtraText>Fans</ExtraText>
          <UnorderedList>
            <ListItem>
              for locations where we conduct third-party age and identity
              verification of Fans, a copy of the government identity document
              that you provide to our third-party providers
            </ListItem>
            <ListItem>
              for locations where we carry out third-party age estimation, or
              third-party age and identity verification of Fans, a short .gif,
              taken from a "selfie" that you provide to our third-party
              providers
            </ListItem>
            <ListItem>
              the results of the third-party age estimation process or
              third-party age and identity verification process (pass / fail and
              reason for failing)
            </ListItem>
            <ListItem>
              metadata associated with the third-party age estimation process or
              third-party age and identity verification process (e.g. user start
              and finish time)
            </ListItem>
          </UnorderedList>
          <ExtraText>
            Please see Section 10 (our onboarding processes), below, for further
            information. Third-Party Onboarding Data and Technical Data does not
            include Face Recognition Data, as set out below.
          </ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Account Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>Creators</ExtraText>
          <UnorderedList>
            <ListItem>profile name </ListItem>
            <ListItem>password</ListItem>
            <ListItem>avatars and headers of your Creator account</ListItem>
            <ListItem>your subscriptions, subscribers and referrals</ListItem>
            <ListItem>
              posts that you have made to your Creator account
            </ListItem>
            <ListItem>
              comments on posts made from your Creator account
            </ListItem>
            <ListItem>chat messages between you and other users</ListItem>
            <ListItem>customer support queries that you submit to us</ListItem>
          </UnorderedList>
          <ExtraText>Fans</ExtraText>
          <UnorderedList>
            <ListItem>profile name </ListItem>
            <ListItem>password</ListItem>
            <ListItem>avatars and headers of your Creator account</ListItem>
            <ListItem>your subscriptions</ListItem>
            <ListItem>comments on posts made from your Fan account</ListItem>
            <ListItem>chat messages between you and other users</ListItem>
            <ListItem>customer support queries that you submit to us</ListItem>
          </UnorderedList>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Financial Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>Creators</ExtraText>
          <UnorderedList>
            <ListItem>payment card details*</ListItem>
            <ListItem>billing address</ListItem>
            <ListItem>funds added to your wallet</ListItem>
            <ListItem>bank account information</ListItem>
            <ListItem>pay-out country</ListItem>
            <ListItem>
              corporate or business entity if registered for tax purposes
            </ListItem>
            <ListItem>
              social security number (for US Creators only) or other relevant
              tax information
            </ListItem>
            <ListItem>W-9 form (for US Creators only)</ListItem>
            <ListItem>1099-MISC form (for US Creators only)</ListItem>
            <ListItem>1099-NEC form (for US Creators only)</ListItem>
          </UnorderedList>
          <ExtraText>Fans</ExtraText>
          <UnorderedList>
            <ListItem>payment card details*</ListItem>
            <ListItem>billing address</ListItem>
            <ListItem>funds added to your wallet </ListItem>
          </UnorderedList>
          <ExtraText>
            * Please note: Any payments made to view the content of Creators are
            processed by our third-party payment providers. We do not receive
            your full payment card number, payment card expiration date, or the
            security code. Instead, the payment provider provides us with a
            "token" that represents your account, your payment card's expiration
            date, payment card type and the first six and last four digits of
            your payment card number.
          </ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Transaction Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>Creators</ExtraText>
          <UnorderedList>
            <ListItem>earnings</ListItem>
            <ListItem>pay-out requests</ListItem>
            <ListItem>payments made to your Creator account</ListItem>
            <ListItem>
              payments made from your Creator account to other Creators
            </ListItem>
            <ListItem>any failed payments</ListItem>
          </UnorderedList>
          <ExtraText>Fans</ExtraText>
          <UnorderedList>
            <ListItem>payments made from your Fan account to Creators</ListItem>
            <ListItem>any failed payments</ListItem>
          </UnorderedList>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Technical Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>Creators and Fans</ExtraText>
          <ExtraText>
            Internet or other electronic network activity information,
            including:
          </ExtraText>
          <UnorderedList>
            <ListItem>
              internet protocol (IP) address (and associated location data)
            </ListItem>
            <ListItem>Internet Service Provider (ISP)</ListItem>
            <ListItem>device and type </ListItem>
            <ListItem>name and version of browser</ListItem>
          </UnorderedList>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Usage Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>Creators and Fans</ExtraText>
          <ExtraText>
            We use cookies where necessary (to allow you to browse the Services
            and access certain pages of the Website) and, with your consent we
            will use cookies:{" "}
          </ExtraText>
          <UnorderedList>
            <ListItem>
              for performance on the Website (e.g. to analyse how users interact
              with the Website to improve the Services and, where you are a
              Creator, so that we can recognise that you have referred another
              Creator through your unique referral code)
            </ListItem>
            <ListItem>
              for Website functionality (e.g. saving your logged-in status)
            </ListItem>
          </UnorderedList>
          <ExtraText>
            More information on our use of cookies, including how to delete or
            block cookies, can be found in our Cookie Notice. In some cases,
            data collected from cookies will be in a deidentified, aggregated or
            anonymised format.
          </ExtraText>
          <ExtraText>
            We currently do not use any cross-site tracking technologies and we
            do not sell personal data collected about you, or share personal
            data collected about you for cross-context behavioural advertising.
          </ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan} className="header">
          Face Recognition Data
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan}>
          <ExtraText style={{ marginTop: 0 }}>
            Creators (and Fans in certain locations)
          </ExtraText>
          <UnorderedList>
            <ListItem>
              As described in more detail below in Section 10 (our onboarding
              processes), during onboarding, our third-party providers may use
              face recognition technology, so they can digitally verify you.
            </ListItem>
            <ListItem>
              The Face Recognition Data remains with our third-party provider
              and we do not ourselves collect, receive, possess, or have access
              to Face Recognition Data at any time.
            </ListItem>
          </UnorderedList>
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 14px;
  margin-bottom: 30px;

  ul {
    margin: 0;
    margin-left: 12px;
    padding: 0;
    list-style-type: square;
  }

  .header {
    font-weight: 500;
    vertical-align: middle;
  }

  .ant-row {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .info {
    padding: 0px ${paddingSize}px;
    padding-bottom: 22px !important;
    border: 0.5px solid #d9d9d9;
  }
`;

const UnorderedList = styled.ul`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const ListItem = styled.li`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const ExtraText = styled.div`
  margin-top: 20px;
`;
