import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { sleep } from "../helpers/general";
import SubscriptionPosts from "./SubscriptionPosts";

export default function NotificationPosts() {
  const [waited, setWaited] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await sleep(1);
      setWaited(true);
    }
    fetchData();
  }, []);

  return (
    <Container>
      {waited ? (
        <HomePostsContainer>
          <HeaderContainer>
            <RecentPostsHeader>
              <Stars src="/images/general/stars.svg" alt="" />
              Recent posts
            </RecentPostsHeader>
            <Subheader>Catch up with your content creators</Subheader>
          </HeaderContainer>
          <SubscriptionPosts />
        </HomePostsContainer>
      ) : (
        <SpinnerContainer>
          <Spinner src="/icons/general/loading-black.svg" alt="" />
        </SpinnerContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-right: none !important;
  text-align: left;
`;

const HomePostsContainer = styled.div`
  margin-top: 40px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
`;

const Spinner = styled.img`
  width: 36px;
  height: auto;
  animation: rotation 2s infinite linear;
`;

const RecentPostsHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  font-weight: 500;
`;

const Stars = styled.img`
  width: 18px;
  height: auto;
  margin-right: 6px;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-right: 10px;
  margin-top: 10px;
`;

const HeaderContainer = styled.div`
  margin-left: 24px;
  margin-bottom: 50px;
`;
