import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import ConsistencyCarousel from "./ConsistencyCarousel";

export default function ConsistencyModal({ isOpen, setOpen }) {
  return (
    <Container>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[750]}
        style={{
          pointerEvents: isOpen ? "auto" : "none",
          zIndex: 999,
        }}
        onCancel={(event) => {
          setOpen(false);
          event.stopPropagation();
        }}
        closable={false}
        footer={null}
      >
        <InnerContainer>
          <ConsistencyCarousel />
        </InnerContainer>
      </Modal>
    </Container>
  );
}

const Container = styled.div`
  .ant-modal-content {
    padding: 0px !important;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 0px;
  height: 400px;
`;
