import React, { useState } from "react";
import styled from "styled-components";
import { Image, Row, Col } from "antd";
import { useQuery } from "@tanstack/react-query";
import userModel from "../../lib/firebase/userModel";
import { Link, useLocation } from "react-router-dom";
import PostDropdown from "./PostDropdown";
import { currentUser } from "../../helpers/authentication";
import LikeButton from "../LikeButton";
import { ShareButton } from "../../views";
import CommentButton from "../CommentButton";
import CommentSheet from "../social/CommentSheet";
import ContentReportSheet from "../social/ContentReportSheet";

const horizontalPadding = 16;

const dayOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export default function SinglePost({
  post,
  setPosts,
  isSubscribed,
  setIsSubscribed,
}) {
  const location = useLocation();
  const [subscribing, setSubscribing] = useState(false);
  const [open, setOpen] = useState(false);

  const userQuery = useQuery({
    queryKey: ["user", post.creator],
    queryFn: () => userModel.getOneById(post.creator),
  });

  return (
    userQuery.status === "success" && (
      <>
        <Container>
          <InnerContainer>
            <PostHeader>
              <Link to={`/user/${userQuery.data && userQuery.data.username}`}>
                <ProfileImg
                  alt="profile image"
                  src={
                    userQuery.data && userQuery.data.pfp
                      ? userQuery.data.pfp
                      : null
                  }
                />
              </Link>
              <UsernameInfoContainer>
                <ProfileTitle>{userQuery.data.name}</ProfileTitle>
                <UsernameTitle>@{userQuery.data.username}</UsernameTitle>
              </UsernameInfoContainer>
              <DateInfoContainer>
                <DateInfo>
                  {post.createdAt.toLocaleDateString("en-US", dayOptions)}
                </DateInfo>
              </DateInfoContainer>
            </PostHeader>
            {post.text && <Text>{post.text}</Text>}
            {post.imageUrl && isSubscribed && (
              <Image preview="true" src={post.imageUrl} />
            )}
            {post.imageUrl && !isSubscribed && (
              <SubscribeContainer>
                <SubscribeLock src="/icons/general/lock.svg" alt="" />
                <SubscribeButton
                  disabled={subscribing}
                  onClick={async (e) => {
                    window.scroll(0, 0);
                  }}
                >
                  Subscribe to see user's posts
                </SubscribeButton>
              </SubscribeContainer>
            )}
            <PostFooter>
              <Row>
                <Col span={12}>
                  <LikeButton likeSubject={post ? post : null} />
                  <CommentButton post={post} />
                </Col>
                <Col span={12} style={{ textAlign: "right", display: "block" }}>
                  {currentUser && (
                    <>
                      {location.pathname === "/profile" &&
                        currentUser.uid === post.creator && (
                          <ShareButton
                            message="Check out my content on AllmaiFans!"
                            title={"Test"}
                            imageUrl={post.imageUrl ? post.imageUrl : null}
                          />
                        )}
                      <DropdownContainer>
                        <PostDropdown
                          post={post}
                          setPosts={setPosts}
                          setOpen={setOpen}
                        />
                      </DropdownContainer>
                    </>
                  )}
                </Col>
              </Row>
            </PostFooter>
          </InnerContainer>
        </Container>
        <ContentReportSheet isOpen={open} setOpen={setOpen} post={post} />
      </>
    )
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;
  margin-bottom: 42px;
`;

const InnerContainer = styled.div`
  width: 100%;
  border-bottom: 0.5px solid var(--color-gray-50);
`;

const PostFooter = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 4px;
`;

const UsernameInfoContainer = styled.div`
  text-align: left;
  margin-top: 1px;
  margin-left: 10px;
  width: -webkit-fill-available;
`;

const DateInfoContainer = styled.div`
  text-align: right;
  margin-top: 1px;
  margin-left: 10px;
  width: 100%;
`;

const DropdownContainer = styled.span`
  text-align: right;
  margin-top: 1px;
  margin-left: 10px;
  width: 100%;
`;

const PostHeader = styled.div`
  width: 100%;
  padding: 0px ${horizontalPadding}px;
  margin: auto;
  display: flex;
  margin-bottom: 6px;
`;

const Text = styled.p`
  width: 100%;
  padding: 0px ${horizontalPadding + 4}px;
  margin: auto;
  text-align: left;
  margin-bottom: 12px;
`;

const ProfileImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
`;

const ProfileTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: black;
  width: 150px;
`;

const UsernameTitle = styled.div`
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0px;
  color: gray;
`;

const DateInfo = styled.div`
  font-weight: 400;
  font-size: 13px;
  margin-top: 2px;
  color: gray;
`;

const SubscribeContainer = styled.div`
  font-weight: 400;
  font-size: 13px;
  margin-top: 2px;
  padding: 20px 0px 14px 0px;
  color: gray;
  background: lightgray;
  text-align: center;
`;

const SubscribeLock = styled.img`
  font-weight: 400;
  font-size: 13px;
  width: 50px;
  height: auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const SubscribeButton = styled.button`
  border: 0;
  width: 90%;
  display: inline-block;
  margin: auto 5%;
  background: var(--lightPurple);
  border-radius: 50px;
  padding: ${(props) => (props.disabled ? "9.5px" : "14px 20px")};
  margin-top: 10px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;
