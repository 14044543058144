import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { sleep } from "../../helpers/general";
import { message } from "antd";
import React from "react";

const passwordLowerThreshold = 6;
const passwordUpperThreshold = 15;

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [reavealConfirmedPassword, setReavealConfirmedPassword] =
    useState(false);

  let password1Ref = React.createRef();
  let password2Ref = React.createRef();

  useEffect(() => {
    setValidPassword(
      password === confirmedPassword &&
        password.length >= passwordLowerThreshold &&
        password.length <= passwordUpperThreshold
    );
  }, [password, confirmedPassword]);

  async function handleResetPassword() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get("mode");
      const actionCode = urlParams.get("oobCode");
      const auth = getAuth();

      if (mode === "resetPassword") {
        await verifyPasswordResetCode(auth, actionCode);
        await confirmPasswordReset(auth, actionCode, password);
        message.success("Password updated successfully");
        await sleep(2);
        window.location = "/login";
      }
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    handleResetPassword();
  };

  return (
    <Container>
      <Header>Reset your password</Header>
      <form onSubmit={onSubmit}>
        <InputHeader>New password</InputHeader>
        <InputContainer>
          <InputField
            className="transition-colors"
            placeholder={`Must be between ${passwordLowerThreshold} and ${passwordUpperThreshold} characters`}
            value={password}
            maxLength={40}
            onChange={(e) => setPassword(e.target.value)}
            type={revealPassword ? "text" : "password"}
            ref={password1Ref}
          />
          <span
            class="flex justify-around items-center"
            onClick={() => {
              setRevealPassword(!revealPassword);
              password1Ref.current.focus();
            }}
          >
            {revealPassword ? (
              <HidePasswordIcon src="/images/general/password-hide.svg" />
            ) : (
              <RevealPasswordIcon src="/images/general/password-reveal.svg" />
            )}
          </span>
        </InputContainer>
        <InputHeader>Confirm new password</InputHeader>
        <InputContainer>
          <InputField
            className="transition-colors"
            placeholder="Type your new password again"
            value={confirmedPassword}
            maxLength={40}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            type={reavealConfirmedPassword ? "text" : "password"}
            ref={password2Ref}
          />
          <span
            class="flex justify-around items-center"
            onClick={() => {
              setReavealConfirmedPassword(!reavealConfirmedPassword);
              password2Ref.current.focus();
            }}
          >
            {reavealConfirmedPassword ? (
              <HidePasswordIcon src="/images/general/password-hide.svg" />
            ) : (
              <RevealPasswordIcon src="/images/general/password-reveal.svg" />
            )}
          </span>
        </InputContainer>
        <ButtonContainer>
          {!isSaving ? (
            validPassword ? (
              <CreateAccountButton type="submit">Save</CreateAccountButton>
            ) : (
              <CreateAccountButtonDisabled disabled>
                Save
              </CreateAccountButtonDisabled>
            )
          ) : (
            <IsSavingButton disabled>
              <Spinner alt="" src="/icons/loading-spheres.svg" />
            </IsSavingButton>
          )}
        </ButtonContainer>
      </form>
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 30px;
  width: 90%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Header = styled.div`
  letter-spacing: 0em;
  color: var(--lavender);
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
`;

const InputHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-align: left;
  color: var(--color-gray-50);
  margin-bottom: 12px;
  margin-top: 24px;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const InputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding-left: 18px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
  width: 100%;
  margin: auto;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const CreateAccountButton = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  margin-top: 32px;
  color: var(--cream);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const CreateAccountButtonDisabled = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 32px;
  color: var(--disabled-button-text);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const IsSavingButton = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 32px;
  color: var(--disabled-button-text);
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const InputContainer = styled.div`
  position: relative;
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;
