import React from "react";
import styled from "styled-components";
import { Collapse, Statistic, Tooltip, message } from "antd";
import ImageGrid from "../ImageGrid";
import CategorySelector from "./CategorySelector";
import { InfoCircleOutlined } from "@ant-design/icons";
import { sfwLoras, nsfwLoras } from "../../helpers/constants";
import BaseImageSelector from "./BaseImageSelector";

const { Countdown } = Statistic;
const Panel = Collapse.Panel;

const maxPromptLength = 600;

export default function ImageGenerator({
  isGenerating,
  genAiImages,
  selectedImage,
  setSelectedImage,
  buttonEnabled,
  isOpen,
  setOpen,
  setImageUrl,
  handleChange,
  createGeneration,
  setHasUserModel,
  setGenAiImages,
  countdownDate,
  prompt,
  loras,
  setLoras,
  baseImageUrl,
  setBaseImageUrl,
}) {
  return (
    <>
      <Header>Create Content</Header>
      {isGenerating ||
        (genAiImages.length > 0 && (
          <ImageGrid
            genAiImages={genAiImages}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setImageUrl={setImageUrl}
            isButtonEnabled={buttonEnabled}
            setOpen={setOpen}
          />
        ))}
      <ExampleSubheader>
        Describe the content you want to post, more detailed descriptions will
        create more specific content
      </ExampleSubheader>
      <ChildrenContainer noBottom={true}>
        <Collapse expandIconPosition="start">
          <Panel header={<CategoryHeader>Consistency</CategoryHeader>} key="1">
            <BaseImageSelector
              baseImageUrl={baseImageUrl}
              setBaseImageUrl={setBaseImageUrl}
            />
          </Panel>
        </Collapse>
      </ChildrenContainer>
      {/*
      <ChildrenContainer noBottom={true}>
        <Collapse expandIconPosition="start">
          <Panel
            header={
              <>
                <CategoryHeader>General Categories</CategoryHeader>
                {/*
                <Tooltip
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  title={`May produce unintended results, mix and match to see what works best`}
                >
                  <InfoCircleOutlined />
                </Tooltip>
                */}
      {/*
              </>
            }
            key="1"
          >
            <CategorySelector
              loras={loras}
              setLoras={setLoras}
              categories={sfwLoras}
            />
          </Panel>
        </Collapse>
      </ChildrenContainer>
      */}
      <ChildrenContainer>
        <Collapse expandIconPosition="start">
          <Panel
            header={
              <>
                <CategoryHeader>NSFW Categories</CategoryHeader>
                {/*
                <Tooltip
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  title={`May produce unintended results, mix and match to see what works best`}
                >
                  <InfoCircleOutlined />
                </Tooltip>
                */}
              </>
            }
            key="1"
          >
            <Hint>Mix and match categories for new kinds of content</Hint>
            <CategorySelector
              loras={loras}
              setLoras={setLoras}
              categories={nsfwLoras}
            />
          </Panel>
        </Collapse>
      </ChildrenContainer>

      <TextArea
        className="transition-colors"
        value={prompt}
        placeholder="Add what you want to see, separated by commas (ex: beautiful woman, curvy figure, full body image, messy hair...)"
        maxLength={maxPromptLength}
        onChange={handleChange}
      />
      <Footer>
        <SubmitButton
          disabled={!buttonEnabled || isGenerating}
          onClick={async () => {
            if (prompt !== "") {
              console.log("generate things");
              createGeneration();
            }
          }}
        >
          {isGenerating ? (
            <>
              {countdownDate < new Date() || !countdownDate ? (
                <Spinner
                  src="/icons/general/loading.svg"
                  alt=""
                  valueStyle={{ fontSize: "1", fontWeight: "300" }}
                />
              ) : (
                <Countdown
                  value={countdownDate}
                  format="m:ss"
                  onFinish={() => {
                    message.success(
                      "Please wait, taking longer than expected",
                      [5]
                    );
                  }}
                />
              )}
            </>
          ) : (
            "Create"
          )}
        </SubmitButton>
        {/*
        <UseDefaultButton
          onClick={async (e) => {
            setHasUserModel(false);
            setGenAiImages([]);
          }}
        >
          Create a new Mai to make custom content
        </UseDefaultButton>
        */}
      </Footer>
    </>
  );
}

const ChildrenContainer = styled.div`
  .ant-collapse-header {
    padding-bottom: ${(props) => props.noBottom && "0px !important"};
  }

  .ant-collapse-content-box {
    padding-bottom: ${(props) => props.noBottom && "0px !important"};
  }

  .ant-collapse {
    background: transparent;
    border: 0;
  }

  .ant-collapse-arrow {
    color: var(--disabled-button-text) !important;
  }

  .ant-collapse-content {
    border: 0;
    background: transparent;
    outline: 0;
    margin-top: ${(props) => props.noBottom && "8px !important"};
  }

  .ant-collapse-header-text {
    color: var(--disabled-button-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .ant-collapse-item {
    border: 0;
  }
`;

const Header = styled.div`
  color: black;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 14px;
  bottom-bottom: 1px solid lightgray;
`;

const CategoryHeader = styled.span`
  margin-right: 6px;
`;

const ExampleSubheader = styled.div`
  color: black;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 6px;
  color: var(--color-gray-300);
=`;

const Footer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const TextArea = styled.textarea`
  background: white;
  resize: none;
  width: -webkit-fill-available;
  min-height: 138px;
  font-size: 14px;
  color: black;
  border: 0px;
  padding: 10px 14px;
  border: 1px solid black;
  border-radius: 10px;
  line-height: 20px;

  &:focus-visible {
    outline-color: var(--blueHeader);
  }

  &::placeholder {
    color: #7d818e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    color: var(--inputFieldText) !important;
  }
`;

const Spinner = styled.img`
  width: 30px;
  height: auto;
  margin: 10px auto;
  animation: rotation 2s infinite linear;
`;

const UseDefaultButton = styled.div`
  font-size: 12px;
  display: block;
  font-weight: normal;
  width: 100%;
  text-align: center;
  color: var(--color-gray-100);
  margin-top: 34px;
  margin-bottom: 24px;
  text-align: center;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: var(--blueHeader);
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #fef6f3;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }

  .ant-statistic-content-value {
    color: white;
    font-size: 20px;
    font-weight: 600 !important;
  }
`;

const Subheader = styled.div`
  font-size: 14px;
  text-align: left;
  margin-bottom: 14px;
`;

const Hint = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 18px;
  margin-top: -10px;
  color: var(--color-gray-300);
`;
