import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

const gutterSize = 0;
const leftColumnSpan = 13;
const middleColumnSpan = 2;
const rightColumnSpan = 9;
const paddingSize = 10;

export default function PrivacyPolicyPersonalDataUseTable() {
  return (
    <Container>
      <Row
        gutter={gutterSize}
        style={{
          backgroundColor: "#d9d9d9",
          padding: `0px ${paddingSize}px`,
        }}
      >
        <Col span={leftColumnSpan} className="header">
          Purpose / activity
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Lawful basis for processing
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Account creation (both Creators and Fans).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Creator age and identity verification and where applicable, subsequent
          authentication (specifically in relation to the processing of Face
          Recognition Data).
          <ExtraText>
            Please see Section 10 (our onboarding processes), above, for further
            information.
          </ExtraText>
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Consent
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Fan age and identity verification and where applicable, subsequent
          authentication, in certain locations (specifically in relation to the
          processing of Face Recognition Data).
          <ExtraText>
            Please see Section 10 (our onboarding processes), above, for further
            information.
          </ExtraText>
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Consent
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Fan age estimation, in certain locations (specifically in relation to
          the processing of the age estimation capture, which may involve the
          use of Face Recognition Data).
          <ExtraText>
            Please see Section 10 (our onboarding processes), above, for further
            information.
          </ExtraText>
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Consent
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Fan age verification (to the extent we are able to do so without
          third-party age and identity verification or third-party age
          estimation).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Government identity document validity check, and maintaining a record
          of the age and identity verification process (for Creators, and Fans
          in certain locations).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Maintaining a record of the age estimation process (for Fans in
          certain locations).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Providing the Services, including the hosting of Creator content, the
          fulfilment of transactions between Fans and Creators and processing
          Creator earnings.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Providing technical support to Fans and Creators.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Communicating with you about the Services, responding to support
          requests or, sharing information about the Services (e.g. providing
          you with updates to our Terms of Service or this Policy).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Ensuring compliance with, and enforcing, our Terms of Service and
          other usage policies (e.g. our Acceptable Use Policy).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Moderation and filtration:
          <UnorderedList style={{ marginLeft: 14 }}>
            <ListItem>text and content uploaded to the Website</ListItem>
            <ListItem>livestreaming on the Website</ListItem>
            <ListItem>content sent in chat messages on the Website</ListItem>
          </UnorderedList>
          <ExtraText>
            to monitor and investigate violations of our Terms of Service.{" "}
          </ExtraText>
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Filtration of text sent in direct messages on the Website to
          investigate violations of our Terms of Service.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Removal from the Services of text and content uploaded by users that
          is identified as illegal, and suspending or deactivating those user
          accounts.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Compliance with legal obligations
          <ExtraText>Performance of a contract</ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Removal from the Services of text and content uploaded by users that
          is identified as violating our Terms of Service and where appropriate,
          suspending or deactivating user accounts.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Maintaining a record of banned users, to prevent further access to the
          Website.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Legitimate interests
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Reporting illegal activity to relevant law enforcement authorities,
          other governmental agencies and non-governmental organisations.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Legitimate interests
          <ExtraText>Task carried out in the public interest</ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Preservation and sharing of personal data in the context of legal
          proceedings (e.g. litigation).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Legitimate interests
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Complying with applicable laws, rules and regulations.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Compliance with legal obligations
          <ExtraText>Legitimate interests</ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Monitoring transactions and company network, systems, applications,
          and data, to: (i) detect malicious, deceptive, fraudulent, or illegal
          activity in order to protect information security and integrity, and
          user safety; and (ii) respond to / investigate incidents where
          appropriate.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Legitimate interests
          <ExtraText>Task carried out in the public interest</ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          As necessary or appropriate to protect the rights and property of our
          users, us, and other third parties.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Legitimate interests
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Data analysis and testing, system maintenance, reporting and hosting
          of data, to maintain, develop and improve the provision of the
          Services (e.g. safety, performance and functionality).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Consent (involving Usage Data, where this is personal data, collected
          via cookies)
          <ExtraText>Legitimate interests</ExtraText>
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          As necessary in the context of a possible sale, merger, acquisition,
          business reorganisation or group restructuring exercise.
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Legitimate interests
        </Col>
      </Row>

      <Row gutter={gutterSize} className="info">
        <Col span={leftColumnSpan}>
          Processing of personal data in connection with sponsorships, and our
          relationship with service providers, professional advisers and other
          third parties for business purposes (e.g. business contact information
          and correspondence).
        </Col>
        <Col span={middleColumnSpan} />
        <Col span={rightColumnSpan} className="header">
          Performance of a contract
          <ExtraText>Legitimate interests</ExtraText>
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 14px;
  margin-bottom: 30px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: square;
  }

  .header {
    font-weight: 500;
    vertical-align: middle;
  }

  .ant-row {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .info {
    padding: 0px ${paddingSize}px;
    padding-bottom: 22px !important;
    border: 0.5px solid #d9d9d9;
  }
`;

const Header = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 24px;
`;

const List = styled.ol`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const UnorderedList = styled.ul`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const ListHeader = styled.li`
  color: var(--grayscale);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const ListItem = styled.li`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const ExtraText = styled.div`
  margin-top: 20px;
`;

const TextSection = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;
