import React from "react";
import styled from "styled-components";
import { TermsOfUseForCreators } from "../terms";

export default function TermsOfUse({ mustAcceptTos = false }) {
  return (
    <Container mustAcceptTos={mustAcceptTos}>
      <TermsOfUseForCreators />
    </Container>
  );
}

const Container = styled.div`
  color: var(--grayscale);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 90%;
  margin: auto;
  margin-bottom: ${(props) => (props.mustAcceptTos ? "0" : "40")}px;
`;
