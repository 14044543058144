import React from "react";
import styled from "styled-components";

export default function DefaultImageIcon({
  imageUrl,
  profilePic,
  setProfilePic,
}) {
  return (
    <Container>
      {profilePic === imageUrl ? (
        <>
          <OutlinedProfileImage src={imageUrl} alt="" disabled />
          <CheckBadge>
            <Checkmark src="/icons/checkmark-white.svg" />
          </CheckBadge>
        </>
      ) : (
        <ProfileImage
          onClick={() => setProfilePic(imageUrl)}
          src={imageUrl}
          alt=""
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: inline-flex;
  width: 22.5%;
  margin: 1.25%;
  position: relative;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 100px;
`;

const OutlinedProfileImage = styled.img`
  width: 100%;
  height: auto;
  border: 4px solid var(--nextPreviewButton);
  border-radius: 100px;
`;

const CheckBadge = styled.span`
  right: 0;
  top: -4px;
  height: 25px;
  width: 25px;
  position: absolute;
  pointer-events: none;
  background: var(--nextPreviewButton);
  padding: 2px;
  border-radius: 50px;
`;

const Checkmark = styled.img`
  display: block;
  width: 12px;
  margin: auto;
  height: 100%;
`;
