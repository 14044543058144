import "./App.css";
import "./Chat.css";
import "./fonts.css";
import "./colors.css";
import "./quill-theme.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { currentUser } from "./helpers/authentication";
import { isOnMobile, sleep } from "./helpers/general";
import { NavMenu } from "./components/footer";
import styled from "styled-components";
import { Main } from "./views";
import { MobileHeader } from "./components/general";
import { mobileWidthThreshold } from "./helpers/constants";

export default function App(props) {
  const location = useLocation();
  const history = useHistory();

  const [waited, setWaited] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      await sleep(1);
      setWaited(true);
      if (currentUser && currentUser.emailVerified) {
        //setIsEmailVerified(true);
      } else if (
        location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/terms" &&
        location.pathname !== "/privacy" &&
        location.pathname !== "/content-and-community-guidelines"
      ) {
        history.push("/login");
      }
    }
    fetchData();
  }, [location]);

  return (
    <Container>
      <MobileHeader />
      <Main />
      <NavMenu />
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  padding-left: calc(100vw - 100%);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center;
  background-color: white;
  height: 100%;
`;
