import styled from "styled-components";

export const Container = styled.div`
	max-width: 81.25rem;
	margin: 0 auto;
	padding: 0 1.25rem;
`;

export const SectionHeading = styled.h2`
	font-size: ${(props) =>
		props.lg ? "4.68rem" : props.secondary ? "3.3rem" : "4.37rem"};
	line-height: ${(props) => (props.lg ? "4.87rem" : "4.62rem")};
	font-weight: 800;
	color: ${(props) =>
		props.primary
			? "var(--color-primary)"
			: props.secondary
			? "var(--color-secondary)"
			: "var(--color-tertiary)"};

	@media (max-width: 47.9375rem) {
		font-size: 3.75rem;
		line-height: 4.375rem;
	}

	@media (max-width: 35.9375rem) {
		font-size: 2.375rem;
		line-height: 2.75rem;
	}
`;

export const BodyText = styled.p`
	font-size: 1.5rem;
	line-height: 2.25rem;
	color: var(--color-primary);
	font-weight: 700;
`;

export const Button = styled.a`
	font-family: var(--heading-font);
	background-color: ${(props) =>
		props.primary
			? "var(--color-primary)"
			: props.secondary
			? "var(--color-secondary)"
			: "var(--color-tertiary)"};
	padding: 0.7rem 1.5rem;
	display: inline-block;
	border-radius: 0.5rem;
	font-size: 1.25rem;
	font-weight: 400;
	text-decoration: none;
	color: var(--color-white) !important;
	transition: 0.3s;
	border: 0px !important;
`;

export const SocialIconsWrapper = styled.div`
	position: relative;
`;

export const SocialIcons = styled.ul`
	display: flex;
	flex-direction: ${(props) => (props.vertical ? "column" : "row")};
	gap: 0.6rem;

	li {
		a {
			font-size: 1.3rem;
			background-color: ${(props) =>
				props.primary ? "var(--color-primary)" : "var(--color-secondary)"};
			color: ${(props) =>
				props.primary ? "var(--color-white)" : "var(--color-primary)"};
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2.1rem;
			height: 2.1rem;
			border-radius: 2px;
			transtion: all 0.3s ease;

			&:hover {
				box-shadow: 4px 4px var(--color-secondary);
				transition: 0.3s;
			}
		}
	}
`;
