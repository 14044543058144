import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown, Space, message } from "antd";
import { adminEmails } from "../../helpers/adminEmails";
import { currentUser } from "../../helpers/authentication";
import CommentReportSheet from "./CommentReportSheet";
import { getComments } from "../../helpers/general";
import commentsModel from "../../lib/firebase/commentsModel";

export default function CommentDropdownButton({
  isCurrentUser,
  setEditComment,
  comment,
  children,
  commentInputRef,
  setComments,
}) {
  const [sheetOpen, setSheetOpen] = useState(false);

  const startDeletingComment = async () => {
    await commentsModel.delete(comment.id);
    children.forEach(async (c) => {
      await commentsModel.delete(c.id);
    });
    message.success("Comment deleted");
    setComments(await getComments(comment.subjectId));
  };

  let items;

  if (isCurrentUser) {
    items = [
      {
        label: (
          <EditHeader
            onClick={(e) => {
              setEditComment(comment);
              commentInputRef.current.focus();
            }}
          >
            {/*
            <DropdownIcon src="/icons/social/edit.svg" alt="" />
            */}
            Edit
          </EditHeader>
        ),
        key: "1",
      },
      {
        label: (
          <DeleteHeader onClick={async () => await startDeletingComment()}>
            {/*
               <DropdownIcon src="/icons/social/delete.svg" alt="" />
            */}
            Delete
          </DeleteHeader>
        ),
        key: "2",
      },
    ];
  } else {
    items = [
      {
        label: (
          <DeleteHeader onClick={async () => setSheetOpen(true)}>
            Report
          </DeleteHeader>
        ),
        key: "2",
      },
      currentUser &&
        adminEmails.includes(currentUser.email) && {
          label: (
            <DeleteHeader onClick={async () => await startDeletingComment()}>
              Admin Delete
            </DeleteHeader>
          ),
          key: "3",
        },
    ];
  }

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button onClick={(e) => e.preventDefault()}>
          <Space>
            <MoreIcon src="/icons/social/ellipsis.svg" alt="" />
          </Space>
        </Button>
      </Dropdown>
      <CommentReportSheet
        comment={comment}
        isOpen={sheetOpen}
        setOpen={setSheetOpen}
        setComments={setComments}
      />
    </>
  );
}

const Button = styled.button`
  background: none;
  border: 0;
  vertical-align: sub;
`;

const MoreIcon = styled.img`
  height: 20px;
  width: auto;
  margin-right: 0px;
  opacity: 0.5;
`;

const DropdownIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

const EditHeader = styled.span`
  color: black;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.6px */
`;

const DeleteHeader = styled.div`
  color: red;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.6px */
  margin-top: 0px !important;
`;
