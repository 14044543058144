import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PostedComment from "./PostedComment";
import { Col, Row } from "antd";
import likeModel from "../../lib/firebase/likeModel";

export default function EpisodeCommentSection({
  comments,
  setComments,
  nextEpisode,
  backLocations,
  setBackLocations,
  loadNextEpisode,
  commentsOpen,
  setCommentsOpen,
}) {
  const [topThreeComments, setTopThreeComments] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await Promise.all(
        comments.map(async (c) => {
          const likeCount = await likeModel.count(["submissionId", "==", c.id]);
          c.likeCount = likeCount;
        })
      );
      const sortedComments = comments.sort(function (a, b) {
        return b.likeCount - a.likeCount || b.createdAt - a.createdAt;
      });
      setTopThreeComments(sortedComments.slice(0, 3));
    }

    fetchData();
  }, [comments]);

  return (
    <Container>
      <Row>
        <Col span={18}>
          <Header>
            Comments {comments.length > 0 && `(${comments.length})`}
          </Header>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          {comments.length > 0 && (
            <ViewAll
              onClick={(e) => {
                setCommentsOpen(true);
              }}
            >
              View all
            </ViewAll>
          )}
        </Col>
      </Row>
      <Break />
      {comments.length > 0 ? (
        <CommentsContainer>
          {topThreeComments.map((comment, index) => (
            <PostedComment comment={comment} key={index} showReply={false} />
          ))}
        </CommentsContainer>
      ) : (
        <EmptyCommentsContainer>
          <CarrotIconSection>
            <CarrotIcon src="/icons/social/carrot-gray.svg" alt="" />
          </CarrotIconSection>
          <EmptyHeader>No comments yet.</EmptyHeader>
          <EmptySubheader
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setCommentsOpen(true);
            }}
          >
            Leave a comment
          </EmptySubheader>
        </EmptyCommentsContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  padding: 0px 20px;

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }
`;

const CommentsContainer = styled.div`
  text-align: left;
  margin-top: 10px;
`;

const Header = styled.div`
  color: var(--color-cream-base);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
`;

const EmptyCommentsContainer = styled.div`
  width: 100%;
  margin-top: 34px;
  margin-bottom: 50px;
`;

const EmptyHeader = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
`;

const EmptySubheader = styled.div`
  color: var(--color-pink);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 6px;
`;

const CarrotIcon = styled.img`
  color: var(--color-gray);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: auto;
  text-align: center;
`;

const CarrotIconSection = styled.div`
  margin: auto;
  text-align: center;
`;

const Break = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #35383f;
`;

const ViewAll = styled.button`
  color: var(--color-pink-normal);
  text-align: right;
  /* Small Body Text (Default) */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.5px; /* 189.286% */
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
`;
