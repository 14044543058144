import styled from "styled-components";

const InputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid black;
  padding-left: 18px;
  border-radius: 12px;s
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: black;
  margin: auto;

  &:focus {
    border-color: var(--blueHeader);
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  &:disabled {
    background: var(--color-gray-50);
    pointer-events: none;
    user-select: none;
    color: #65676d;
  }
`;

export default InputField;
