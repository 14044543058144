import React from "react";
import { Avatar, Button, Col, Image, Row, Statistic, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import date from "date-and-time";
import { getUserInfo } from "../helpers/firebase";
import { datePattern } from "../helpers/constants";
import { getAdjustedEpisodeText } from "../helpers/general";

function AdminEpisodeDisplay({
  episode,
  setSelectedEpisode,
  setIsNewEpisode,
  winningSubmission,
}) {
  const [publishedDate, setPublishedDate] = useState("");
  const [questStatus, setQuestStatus] = useState(null);
  const [winningUserInfo, setWinningUserInfo] = useState(null);
  const [leftPollColumn, setLeftPollColumn] = useState(null);
  const [rightPollColumn, setRightPollColumn] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userDatePromise = await getUserInfo(winningSubmission.creator);
      setWinningUserInfo(userDatePromise);
    }
    if (winningSubmission) fetchData();
  }, [winningSubmission]);

  useEffect(() => {
    setPublishedDate(
      date.format(
        episode.publishedAt ? episode.publishedAt.toDate() : new Date(),
        datePattern
      )
    );
    if (episode.isDraft)
      setQuestStatus(
        <h5 className="quest-display-status-draft">
          <img style={{ marginRight: 4 }} src="icons/calendar.svg" alt="" />{" "}
          Draft Saved
        </h5>
      );
  }, [episode]);

  return (
    <Button
      className="admin-quest-display admin-episode-display"
      onClick={async () => {
        setSelectedEpisode(episode);
        setIsNewEpisode(false);
      }}
      key={episode.id}
      style={{ minHeight: 110 }}
    >
      <Row gutter={36}>
        <Col span={13} style={{ display: "inline-block" }}>
          <Tooltip>
            <h2 className="quest-display-title">
              {getAdjustedEpisodeText({
                number: episode.number,
                showEpText: false,
              })}
            </h2>
          </Tooltip>
          <Tooltip title={episode.title} placement="bottomLeft">
            <p className="quest-display-text" style={{ marginTop: "-4px" }}>
              {episode.title}
            </p>
          </Tooltip>
          {questStatus}
        </Col>
        <Col span={4} style={{ margin: "auto" }} className="center">
          <Tooltip title={publishedDate}>
            <h4 className="quest-display-time-text">{publishedDate}</h4>
          </Tooltip>
        </Col>
        <Col span={4} style={{ margin: "auto" }} className="center">
          {winningSubmission ? (
            <h4 className="quest-display-time-text center">
              <div>
                <div
                  className="winning-submission-container"
                  style={{ pointerEvents: "none !important" }}
                >
                  <Image
                    className="admin-quest-display-poll-image"
                    src={winningSubmission ? winningSubmission.imageUrl : null}
                    alt=""
                    preview={false}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                {winningSubmission.creator ? (
                  <span
                    style={{
                      textAlign: "center",
                      margin: "auto",
                      display: "inline-flex",
                    }}
                    className="admin-winner-display"
                  >
                    <Avatar
                      size="small"
                      icon={
                        <Image
                          preview={false}
                          src={winningUserInfo ? winningUserInfo.pfp : null}
                        />
                      }
                      className="small-profile-icon"
                      style={{ marginLeft: 0 }}
                    />
                    <span className="quest-display-text quest-winner">
                      {winningUserInfo ? winningUserInfo.username : null}
                    </span>
                  </span>
                ) : null}
              </div>
            </h4>
          ) : (
            <h4 className="quest-display-text" style={{ marginLeft: "10%" }}>
              No winner
            </h4>
          )}
        </Col>
        <Col span={3} style={{ margin: "auto" }} className="poll-column center">
          {episode.views ? episode.views.toLocaleString() : 0}
        </Col>
      </Row>
    </Button>
  );
}

export default AdminEpisodeDisplay;
