// ----- Components Import -----
import { styled } from "styled-components";
import { Col, Row } from "antd";
import { Button, Container } from "../styles/CommonElements";

// ----- Assets Import -----
import heroMockup from "../../assets/home/hero.svg";

export default function Hero() {
  return (
    <HeroWrapper>
      <Container>
        <Row>
          <Col xs={24} lg={12}>
            <HeroInfo>
              <h1>Create your dream model and engage with fans</h1>
              <HeroAction>
                <Button primary href="/register">
                  Sign up now
                </Button>
              </HeroAction>
            </HeroInfo>
          </Col>
          <Col span="12">
            <HeroMockup>
              <img src={heroMockup} alt="Mockup" />
            </HeroMockup>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

// ----- Styles -----
const HeroWrapper = styled.section`
  padding: 7rem 0 12rem 0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  @media (max-width: 80.9375rem) {
    background-image: unset;
  }

  @media (max-width: 61.9375rem) {
    padding: 6rem 0 12rem 0;
    position: relative;
    overflow: hidden;
  }

  @media (max-width: 47.9375rem) {
    padding: 3.75rem 0 7.125rem;
    background-size: cover;
    background-position: center;
  }
`;

const HeroInfo = styled.div`
  position: relative;
  text-align: left;

  &:before {
    content: "";
    width: 100%;
    height: 180%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: -5rem;
    top: -7rem;

    @media (max-width: 61.9375rem) {
      width: 8rem;
      height: 42%;
      top: unset;
      left: 5rem;
      bottom: 5.125rem;
    }
  }

  h1 {
    font-size: 4.37rem;
    line-height: 4.81rem;
    color: black;
    font-weight: 800;
    margin-bottom: 2rem;

    @media (max-width: 61.9375rem) {
      padding-bottom: 5rem;
    }

    @media (max-width: 47.9375rem) {
      max-width: 21.25rem;
      font-size: 2.5rem;
      line-height: 2.8125rem;
    }
  }

  a {
    font-size: 1.5rem;
    color: #00231c;
    border: 1px solid #00231c;
    position: relative;
    z-index: 999;

    @media (max-width: 61.9375rem) {
      margin-top: 8rem;
    }

    @media (max-width: 47.9375rem) {
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      max-width: 15rem;
      margin: 8rem auto 0 auto;
    }
  }
`;

const HeroMockup = styled.div`
  position: relative;

  @media (max-width: 80.9375rem) {
    position: absolute;
    bottom: -12rem;
  }

  @media (max-width: 61.9375rem) {
    display: none;
  }

  img {
    position: absolute;
    top: -2.3rem;
    right: -5rem;

    display: block;
    max-width: 100%;

    @media (max-width: 80.9375rem) {
      position: unset;
    }
  }
`;

const HeroBottomPattern = styled.div`
  position: absolute;
  left: 14rem;
  bottom: 0;

  @media (max-width: 61.9375rem) {
    left: 50%;
    transform: translateX(-50%);
    bottom: -100px;
  }
`;

const HeroAction = styled.div`
  max-width: 15rem;
  position: relative;
  z-index: 5;

  @media (max-width: 47.9375rem) {
    margin: auto;
  }
`;
