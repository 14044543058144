import React from "react";
import { Avatar, Button, Col, Image, Row, Statistic } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import date from "date-and-time";
import {
  downloadImage,
  isPollCompleted,
  isTimePassed,
  removeTags,
} from "../helpers/general";
import {
  getQuestSubmissions,
  getQuestVoters,
  getUserInfo,
  getWinningQuestSubmissions,
} from "../helpers/firebase";
import pluralize from "pluralize";
import { datePattern } from "../helpers/constants";
import { Link } from "react-router-dom";

const { Countdown } = Statistic;

const bookIcon = "../../icons/book.svg";

function AdminQuestDisplay({
  quest,
  setSelectedQuest,
  setIsNewQuest,
  setSelectedPoll,
  setIsNewPoll,
  setQuestInfo,
  setSelectedEpisode,
  episodes,
}) {
  const [startDate, setStartDate] = useState("StartDate");
  const [startTime, setStartTime] = useState("StartTime");
  const [endDate, setEndDate] = useState("EndDate");
  const [endTime, setEndTime] = useState("EndTime");
  const [questStatus, setQuestStatus] = useState("EndTime");
  const [subAmount, setSubAmount] = useState(0);
  const [voterAmount, setVoterAmount] = useState(0);
  const [winningSubmission, setWinningSubmission] = useState(null);
  const [winningUserInfo, setWinningUserInfo] = useState(null);

  const [leftPollColumn, setLeftPollColumn] = useState(null);
  const [rightPollColumn, setRightPollColumn] = useState(null);

  useEffect(() => {
    const startDate = quest.startTimestamp.toDate();
    const endDate = quest.endTimestamp.toDate();
    setStartDate(date.format(startDate, datePattern));
    setEndDate(date.format(endDate, datePattern));
    const timePattern = date.compile("hh:mm A");
    setStartTime(date.format(startDate, timePattern));
    setEndTime(date.format(endDate, timePattern));
    if (quest.episode) {
      setQuestStatus(
        <h5 className="quest-display-status-published">Episode Published</h5>
      );
    } else if (isTimePassed(quest.endTimestamp.toDate())) {
      setQuestStatus(
        <h5 className="quest-display-status-completed">
          <img style={{ marginRight: 4 }} src="icons/checkmark.svg" alt="" />{" "}
          Completed
        </h5>
      );
    } else {
      if (quest.isDraft)
        setQuestStatus(
          <h5 className="quest-display-status-draft">
            <img style={{ marginRight: 4 }} src="icons/calendar.svg" alt="" />{" "}
            Draft Saved
          </h5>
        );
      else {
        setQuestStatus(
          <h5 className="quest-display-status-live">
            Live
            {quest.startTimestamp.toDate() > new Date() ? (
              <span>
                {" "}
                in{" "}
                <Countdown
                  style={{ display: "inline-flex" }}
                  className="quest-display-status-live"
                  value={quest.startTimestamp.toDate()}
                />
              </span>
            ) : (
              ``
            )}
          </h5>
        );
      }
    }

    if (!quest.poll) {
      // quest hasn't been created: show submissions and create button
      setLeftPollColumn(
        <h4 className="poll-submissions-text">
          {`${pluralize("Submission", subAmount, true)}`}
        </h4>
      );
      setRightPollColumn(
        <Button
          className="new-poll-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const poll = { title: "" };
            setSelectedPoll(poll);
            setIsNewPoll(true);
            setQuestInfo(quest);
          }}
        >
          Create Poll
        </Button>
      );
    } else if (
      quest.poll &&
      !isTimePassed(quest.poll.startTimestamp.toDate())
    ) {
      // quest has been created and it's before the start time: show Not Started thing and review button
      setLeftPollColumn(
        <div className="not-started">
          <h6
            className="quest-display-text"
            style={{ textAlign: "center", marginTop: 14 }}
          >
            Not Started
          </h6>
        </div>
      );
      setRightPollColumn(
        <Button
          className="review-poll-button close-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedPoll(quest.poll);
            setIsNewPoll(false);
            setQuestInfo(quest);
          }}
        >
          Review poll
        </Button>
      );
    } else if (quest.poll && isTimePassed(quest.poll.endTimestamp.toDate())) {
      // quest has been created and ended: show winning submission and quest winner name + text
      setRightPollColumn(
        <Button
          className="review-poll-button close-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedPoll(quest.poll);
            setIsNewPoll(false);
            setQuestInfo(quest);
          }}
          style={{ padding: 0 }}
        >
          Show results
        </Button>
      );
    } else if (
      quest.poll &&
      isTimePassed(quest.poll.startTimestamp.toDate()) &&
      !isTimePassed(quest.poll.endTimestamp.toDate()) &&
      !quest.poll.isDraft
    ) {
      // quest has been created and started: show submissions, voters (if any) and countdown timer until it ends and the review button
      setLeftPollColumn(
        <>
          <h4 className="poll-submissions-text">
            {`${pluralize("Submission", subAmount, true)}`}
          </h4>
          <h4 className="quest-display-text" style={{ textAlign: "center" }}>
            {`${pluralize("Voter", voterAmount, true)}`}
          </h4>
        </>
      );
      setRightPollColumn(
        <>
          <div className="admin-quest-display-poll-countdown-div">
            <img src="./icons/wifi.svg" alt="" className="wifi-icon" />
            <Countdown
              style={{ display: "inline-flex" }}
              className="admin-quest-display-poll-countdown"
              value={quest.poll.endTimestamp.toDate()}
            />
          </div>
          <Button
            className="review-poll-button close-button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedPoll(quest.poll);
              setIsNewPoll(false);
              setQuestInfo(quest);
            }}
          >
            Review poll
          </Button>
        </>
      );
    } else {
      setLeftPollColumn(
        <div className="not-started">
          <h6
            className="quest-display-text"
            style={{ textAlign: "center", marginTop: 14 }}
          >
            Not Started
          </h6>
        </div>
      );
      setRightPollColumn(
        <Button
          className="review-poll-button close-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedPoll(quest.poll);
            setIsNewPoll(false);
            setQuestInfo(quest);
          }}
        >
          Publish poll
        </Button>
      );
    }

    if (quest.poll && quest.poll.isDraft) {
      setRightPollColumn(
        <Button
          className="review-poll-button close-button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedPoll(quest.poll);
            setIsNewPoll(false);
            setQuestInfo(quest);
          }}
        >
          Publish poll
        </Button>
      );
    }
  }, [
    quest,
    setIsNewPoll,
    setQuestInfo,
    setSelectedPoll,
    subAmount,
    voterAmount,
    winningSubmission,
    winningUserInfo,
  ]);

  useEffect(() => {
    async function fetchData() {
      const userDatePromise = await getUserInfo(winningSubmission.creator);
      setWinningUserInfo(userDatePromise);
    }

    if (winningSubmission !== null) fetchData();
  }, [winningSubmission]);

  useEffect(() => {
    async function fetchData() {
      const questSubmissionPromise = await getQuestSubmissions(quest);
      setSubAmount(questSubmissionPromise.length);
      if (quest.poll) {
        const questVoterPromise = await getQuestVoters(quest.poll);
        setVoterAmount(questVoterPromise.length);
      }
      if (quest.poll && isPollCompleted(quest.poll)) {
        const winningQuestSubmissionsPromise = await getWinningQuestSubmissions(
          quest
        );
        if (winningQuestSubmissionsPromise.length > 0) {
          setWinningSubmission(winningQuestSubmissionsPromise[0]);
          setLeftPollColumn(
            <>
              <div
                className="winning-submission-container"
                style={{ pointerEvents: "none !important" }}
              >
                <Image
                  className="admin-quest-display-poll-image"
                  src={winningSubmission ? winningSubmission.imageUrl : null}
                  alt=""
                  preview={false}
                  style={{ pointerEvents: "none" }}
                />
                <div className="middle-section-winning-submission">
                  <Row className="inner-content">
                    <Col span={12} className="winning-submission-col">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={winningSubmission.imageUrl}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          downloadImage(winningSubmission.imageUrl);
                        }}
                        style={{
                          marginLeft: !quest.episode ? "15%" : "0%",
                        }}
                      >
                        <Image
                          preview={false}
                          style={{
                            fontSize: 30,
                            margin: "auto",
                          }}
                          src="../../icons/download.svg"
                        />
                      </a>
                    </Col>
                    {!quest.episode ? (
                      <Col span={12} className="winning-submission-col">
                        <Link
                          to="/publishedEpisodes"
                          onClick={(event) => {
                            //event.preventDefault();
                            //event.stopPropagation();
                            const ep = {
                              title: "",
                              number: 1,
                              winningSubmission,
                              isNewEpisode: true,
                            };
                            setSelectedEpisode(ep);
                          }}
                          style={{
                            marginRight: "15%",
                            zIndex: 10,
                          }}
                        >
                          <Image
                            preview={false}
                            style={{
                              fontSize: 30,
                              margin: "auto",
                            }}
                            src={bookIcon}
                          />
                        </Link>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </div>
              <span
                style={{
                  textAlign: "center",
                  margin: "auto",
                  display: "flex",
                }}
                className="admin-winner-display"
              >
                <Avatar
                  size="small"
                  icon={
                    <Image
                      preview={false}
                      src={winningUserInfo ? winningUserInfo.pfp : null}
                    />
                  }
                  className="small-profile-icon"
                />
                <span className="quest-display-text quest-winner">
                  {winningSubmission ? winningUserInfo.username : null}
                </span>
              </span>
            </>
          );
        } else {
          setWinningSubmission(null);
          setLeftPollColumn(
            <span
              style={{
                textAlign: "center !important",
                margin: "auto",
              }}
            >
              <h5 className="quest-display-text">No winner</h5>
            </span>
          );
        }
      }
    }

    fetchData();
  }, [quest, winningUserInfo, winningSubmission]);

  return (
    <Button
      className="admin-quest-display"
      onClick={async () => {
        setSelectedQuest(quest);
        setIsNewQuest(false);
      }}
      key={quest.id}
    >
      <Row gutter={48}>
        <Col span={11} style={{ display: "inline-block" }}>
          <h2 className="quest-display-title">{quest.title}</h2>
          <p className="quest-display-text">{removeTags(quest.description)}</p>
          {questStatus}
        </Col>
        <Col span={3}>
          <h4 className="quest-display-time-text">{startDate}</h4>
          <h4 style={{ marginTop: 12 }} className="quest-display-text">
            {startTime}
          </h4>
        </Col>
        <Col span={3}>
          <h4 className="quest-display-time-text">{endDate}</h4>
          <h4 style={{ marginTop: 12 }} className="quest-display-text">
            {endTime}
          </h4>
        </Col>
        <Col span={7} style={{ paddingLeft: 0 }}>
          <Row style={{ height: "100%" }} gutter={12}>
            <Col span={12} className="poll-column">
              {leftPollColumn}
            </Col>
            <Col span={12} className="poll-column">
              <div className="poll-column-right">{rightPollColumn}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Button>
  );
}

export default AdminQuestDisplay;
