import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { unsubscribeFromCreator } from "../../helpers/firebase";
import SubscriptionButton from "./SubscriptionButton";

export default function SubscriptionSection({
  creator,
  setIsSubscribed,
  isSubscribed,
  subscriptionQuery,
}) {
  const [subscribing, setSubscribing] = useState(null);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container>
      <Header>Subscription</Header>

      {subscriptionQuery.status !== "success" && (
        <SpinnerContainer>
          <Spinner src="/icons/general/loading-black.svg" alt="" />
        </SpinnerContainer>
      )}

      {subscriptionQuery.status === "success" && isSubscribed && (
        <Button
          disabled={subscribing}
          onClick={async (e) => {
            setSubscribing(true);
            await unsubscribeFromCreator(creator);
            setIsSubscribed(false);
            setSubscribing(false);
          }}
        >
          {subscribing ? (
            <SubscribingIcon alt="" src="/icons/general/loading.svg" />
          ) : (
            <SubscribedHeader>Subscribed</SubscribedHeader>
          )}
        </Button>
      )}

      {subscriptionQuery.status === "success" && !isSubscribed && (
        <>
          <SubscriptionButton months={1} price={"9.99"} creator={creator} />
          <SubscriptionButton months={2} price={"17.49"} creator={creator} />
          <SubscriptionButton months={3} price={"22.49"} creator={creator} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px;
  text-align: left;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
`;

const Header = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-gray-100);
`;

const Button = styled.button`
  border: 0;
  width: 100%;
  display: inline-block;
  background: var(--lightPurple);
  border-radius: 50px;
  padding: ${(props) => (props.disabled ? "9.5px" : "14px 20px")};
  margin-top: 10px;
  color: white;
  font-size: 12px;
  font-weight: 600;
`;

const SubscribedHeader = styled.div`
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
`;

const SubscribingIcon = styled.img`
  height: 20px;
  width: 20px;
  animation: rotation 2s infinite linear;
  margin: auto;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
`;

const Spinner = styled.img`
  width: 36px;
  height: auto;
  animation: rotation 2s infinite linear;
`;
