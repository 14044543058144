import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { removeItemOnce } from "../../helpers/general";

export default function CategorySelector({ loras, setLoras, categories }) {
  const [sortedLoras, setSortedLoras] = useState([]);

  useEffect(() => {
    const sorted = categories.sort(function (a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setSortedLoras(sorted);
  }, [categories]);

  useEffect(() => {
    console.log(loras);
  }, [loras]);

  return (
    <Container>
      {sortedLoras.map((lora, index) => (
        <Category
          className="category"
          active={
            loras.filter(function (e) {
              return e.label === lora.label;
            }).length > 0
          }
          onClick={() => {
            const filter = loras.filter(function (e) {
              return e.label === lora.label;
            });
            if (filter.length > 0) {
              const newThing = [...loras];
              const newArray = removeItemOnce(newThing, lora);
              setLoras(newArray);
            } else {
              setLoras((loras) => [...loras, lora]);
            }
          }}
        >
          {lora.label}
        </Category>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: block;
  margin-bottom: 10px;
  text-align: center;

  .category {
    margin-right: 4px;
  }
`;

const Category = styled.button`
  width: fit-content;
  border-radius: 50px;
  border: 0;
  font-size: 10px;
  text-transform: capitalize;
  margin-top: 6px;
  padding: ${(props) => (props.active ? "6px 14px" : "6px 14px")};
  color: ${(props) => (props.active ? "white" : "var(--color-gray-200)")};
  font-weight: ${(props) => (props.active ? "500" : "300")};
  background-color: ${(props) =>
    props.active ? "var(--color-main-blue)" : "var(--color-gray-50)"};
`;
