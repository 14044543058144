import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { count, queryMany, queryOne } from "./all";
import { conversationsTable } from "../../helpers/constants";

const table = conversationsTable;

const conversationsModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });
    return data;
  },
  getManyByUserId: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["creator", "==", userId], ...args],
    });

    return data;
  },
  update: async ({ id, data }) => {
    const userRef = doc(getFirestore(), table, id);
    await updateDoc(userRef, data);
    return await queryOne({ table, id });
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default conversationsModel;
