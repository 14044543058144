import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../helpers/authentication";
import paymentModel from "../lib/firebase/paymentModel";
import subscriptionModel from "../lib/firebase/subscriptionModel";
import { TabItem } from "../components/TabItem";
import Statements from "../components/statistics/Statements";
import { ComingSoon } from "../components";
import { Overview, Subscribers } from "../components/statistics";

export default function ProfileStatistics() {
  const [payments, setPayments] = useState([]);
  const [unpaidPayments, setUnpaidPayments] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalUnpaidRevenue, setTotalUnpaidRevenue] = useState(0);
  const [currentSubscriberCount, setCurrentSubscriberCount] = useState(0);
  const [tab, setTab] = useState("statements");

  const paymentsQuery = useQuery({
    queryKey: ["payments"],
    queryFn: () => paymentModel.getMany(["seller", "==", currentUser.uid]),
  });

  const subscriberCountQuery = useQuery({
    queryKey: ["subscriber-count"],
    queryFn: () =>
      subscriptionModel.count(
        ["creator", "==", currentUser.uid],
        ["expiresAt", ">", new Date()]
      ),
  });

  useEffect(() => {
    if (
      subscriberCountQuery.status === "success" &&
      subscriberCountQuery.data
    ) {
      setCurrentSubscriberCount(subscriberCountQuery.data);
    } else {
      setCurrentSubscriberCount(0);
    }
  }, [subscriberCountQuery.status, subscriberCountQuery.data]);

  useEffect(() => {
    if (paymentsQuery.status === "success") {
      const totalPayments = paymentsQuery.data;
      let total = 0;
      let totalUnpaid = 0;
      const totalUnpaidPayments = [];
      totalPayments.forEach((p) => {
        total += p.sellerRevenue;
        if (p.paidOutToSeller === false) {
          totalUnpaid += p.sellerRevenue;
          totalUnpaidPayments.push(p);
        }
      });
      setPayments(totalPayments);
      setUnpaidPayments(totalUnpaidPayments);
      setTotalRevenue(total.toFixed(2));
      setTotalUnpaidRevenue(totalUnpaid.toFixed(2));
    }
  }, [paymentsQuery.status, paymentsQuery.data]);

  if (!paymentsQuery.data || paymentsQuery.status !== "success") {
    return null;
  }

  return (
    <>
      <TabContainer>
        <TabItem
          layoutId="statements"
          isActive={tab === "statements"}
          onClick={() => setTab("statements")}
        >
          Statements
        </TabItem>
        <TabItem
          layoutId="overview"
          isActive={tab === "overview"}
          onClick={() => setTab("overview")}
        >
          Overview
        </TabItem>
        <TabItem
          layoutId="engagement"
          isActive={tab === "engagement"}
          onClick={() => setTab("engagement")}
        >
          Engagement
        </TabItem>
        <TabItem
          layoutId="subscribers"
          isActive={tab === "subscribers"}
          onClick={() => setTab("subscribers")}
        >
          Subscribers
        </TabItem>
      </TabContainer>

      <MainContainer>
        {tab === "statements" && (
          <Statements
            totalUnpaidRevenue={totalUnpaidRevenue}
            currentSubscriberCount={currentSubscriberCount}
          />
        )}
        {tab === "overview" && (
          <Overview
            payments={payments}
            unpaidPayments={unpaidPayments}
            totalRevenue={totalRevenue}
            totalUnpaidRevenue={totalUnpaidRevenue}
            currentSubscriberCount={currentSubscriberCount}
          />
        )}
        {tab === "engagement" && <ComingSoon />}
        {tab === "subscribers" && <Subscribers />}
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;

  .tab-item-header {
    font-size: 12px;
    padding-top: 16px;
  }
`;
