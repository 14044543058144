import React from "react";
import styled from "styled-components";
import {
  appName,
  contactEmailAddress,
  discordLink,
  mobileWidthThreshold,
  urlName,
} from "../helpers/constants";

export default function DesktopBounce() {
  return (
    <Container>
      <InnerContainer>
        <div>
          <Logo src="/android-chrome-512x512.png" alt="" />{" "}
        </div>
        <UpperTextContainer>
          <Text>
            <Bold>
              {appName} is currently available on your phone at {urlName}
            </Bold>
          </Text>
          <Text>
            <Highlighted>Log in on mobile</Highlighted> to support your favorite
            creators
          </Text>
        </UpperTextContainer>

        <DiscordButton href={discordLink} target="_blank">
          <DiscordIcon src="/icons/discord.svg" alt="" />
          {appName} Discord
        </DiscordButton>

        <LowerTextContainer>
          <EmailText>
            <Bold>
              <Link href={`mailto:${contactEmailAddress}`} target="_blank">
                <EmailIcon src="/icons/general/email.svg" alt="" />{" "}
                <EmailInnerText>Want to work with us?</EmailInnerText>
              </Link>
            </Bold>
          </EmailText>
        </LowerTextContainer>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  background: white;

  a {
    text-decoration: none;
  }
  &.ant-image {
    width: 100%;
  }

  @media screen and (max-width: ${mobileWidthThreshold}) {
    display: none;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  top: 15%;
  margin: auto;
`;

const Logo = styled.img`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  width: 12%;
  height: auto;

  &p {
    margin-bottom: 6px;
  }
`;

const UpperTextContainer = styled.div`
  margin-top: 3%;
`;

const LowerTextContainer = styled.div`
  margin-top: 1.5%;
`;

const Bold = styled.span`
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
`;

const Highlighted = styled.span`
  color: var(--color-main-blue);
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
`;

const Link = styled.a`
  color: black;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  padding-bottom: 1px;

  &:hover {
    color: black;
  }
`;

const Text = styled.div`
  color: black;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
`;

const EmailText = styled.div`
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

const EmailInnerText = styled.span`
  color: black;
`;

const EmailIcon = styled.img`
  width: 25px;
  height: auto;
  margin-right: 4px;
  margin-bottom: -5px;
`;

const DiscordIcon = styled.img`
  width: 25px;
  height: auto;
  margin-right: 10px;
  margin-bottom: -3px;
`;

const DiscordButton = styled.a`
  padding: 16px 32px;
  border-radius: 90px;
  background: var(--color-main-blue);
  color: white !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  display: inline-block;
  margin-top: 100px;
  text-align: center;
  position: relative;
  border: 0px;

  &:hover {
    color: white !important;
  }
`;
