// ----- Components Import -----
import { styled } from "styled-components";
import { Button, Container, SectionHeading } from "../styles/CommonElements";
import { FaDiscord } from "react-icons/fa";
import { appName } from "../../helpers/constants";

export default function Cta() {
  return (
    <CtaWrapper>
      <Container>
        <CtaCharacters>
          <CtaAction>
            <SectionHeading secondary>
              Join the {appName} community
            </SectionHeading>
            <Button
              primary
              target="_blank"
              href="https://discord.gg/p9yyZn4Nrd"
            >
              <span>
                <FaDiscord />
              </span>
              Join our Discord
            </Button>
          </CtaAction>
        </CtaCharacters>
      </Container>
    </CtaWrapper>
  );
}

// ----- Styles -----
const CtaWrapper = styled.section`
  padding: 3rem 0 5.625rem 0 ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .characters-sm-wrapper {
    display: none;

    @media (max-width: 34.8125rem) {
      display: block;
    }
  }

  .characters-sm {
    display flex;
    flex-wrap: nowrap;
    overflow-x: scroll;


    .ant-row {
      width: 500px;
    }

    &::-webkit-scrollbar {
      display: none;
      height: 0;
    }


  }

`;

const CtaCharacters = styled.div`
  p {
    color: #d1c6c1;
    font-size: 1.12rem;
    font-weight: 600;
    text-align: center;
    margin-top: 1.8rem;
  }

  .ant-row {
    @media (max-width: 35.9375rem) {
      display: none;
    }
  }
`;

const Character = styled.div`
  max-width: 150px;
`;

const CtaAction = styled.div`
  h2 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 2rem 0 1.5rem 0;

    img {
      object-fit: contain;
    }
  }

  a {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    max-width: max-content;
    margin: 0 auto;
  }
`;
