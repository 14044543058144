import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CreateAccount from "./CreateAccount";
import { Authentication } from "../../views";
import AllowListCheck from "./AllowListCheck";
import ForgotPassword from "./ForgotPassword";

export default function LoginCheck({ setInSignIn }) {
  const [emailAddress, setEmailAddress] = useState("");
  const [isOnAllowList, setIsOnAllowList] = useState(null);
  const [forgotPw, setForgotPw] = useState(false);
  const [hasCreatedAccount, setHasCreatedAccount] = useState(false);
  const [backButtonText, setBackButtonText] = useState("Storygrounds");
  const [goToProfileCreation, setGoToProfileCreation] = useState(false);
  const [justCreatedAccount, setJustCreatedAccount] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [profileCreated, setProfileCreated] = useState(false);

  useEffect(() => {
    if (hasCreatedAccount) setBackButtonText("Create profile");
    else setBackButtonText("Storygrounds");
  }, [hasCreatedAccount]);

  return (
    <>
      {showBackButton && (
        <BackLink onClick={() => setInSignIn(false)}>
          <LeftCarat src="/icons/login/chevron-left-lavender.svg" />{" "}
        </BackLink>
      )}
      <>
        {isOnAllowList &&
        hasCreatedAccount &&
        !goToProfileCreation &&
        !justCreatedAccount ? (
          !forgotPw ? (
            <Authentication
              email={emailAddress}
              setForgotPw={setForgotPw}
              setGoToProfileCreation={setGoToProfileCreation}
              setProfileCreated={setProfileCreated}
              profileCreated={profileCreated}
            />
          ) : (
            <ForgotPassword email={emailAddress} />
          )
        ) : isOnAllowList === null || !isOnAllowList ? (
          <AllowListCheck
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            setIsOnAllowList={setIsOnAllowList}
            setHasCreatedAccount={setHasCreatedAccount}
          />
        ) : isOnAllowList ? (
          <CreateAccount
            emailAddress={emailAddress}
            hasCreatedAccount={hasCreatedAccount}
            goToProfileCreation={goToProfileCreation}
            setHasCreatedAccount={setHasCreatedAccount}
            setJustCreatedAccount={setJustCreatedAccount}
            setShowBackButton={setShowBackButton}
            setProfileCreated={setProfileCreated}
            profileCreated={profileCreated}
          />
        ) : null}
      </>
    </>
  );
}

const LeftCarat = styled.img`
  height: 24px;
  margin: auto 8px 2px auto;
`;

const BackLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  display: block;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  color: var(--blurple);

  &:hover {
    color: white;
  }
`;
