import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { removeItemOnce } from "../../helpers/general";
import { Modal } from "antd";

export default function TrainingImage({
  trainingObjects,
  setTrainingObjects,
  trainingObj,
}) {
  const [isCaptionModalOpen, setCaptionModalOpen] = useState(false);
  const [caption, setCaption] = useState("");

  useEffect(() => {
    setCaption(trainingObj.caption);
  }, [trainingObj]);

  return (
    <>
      <Modal
        open={isCaptionModalOpen}
        onCancel={(event) => {
          setCaptionModalOpen(false);
          event.stopPropagation();
        }}
        closable={false}
        footer={null}
        style={{
          top: 40,
        }}
      >
        <InnerModalContainer>
          <ModalImage src={trainingObj.url} alt="" />
          <Editor
            onChange={(e) => {
              setCaption(e.target.value);
            }}
            rows={5}
            placeholder="Describe what is in this image..."
            value={caption}
            maxLength="300"
          />
          <Spacer />
          <SubmitButton
            disabled={false}
            onClick={async () => {
              const newObj = { url: trainingObj.url, caption };
              const newArray = removeItemOnce(trainingObjects, trainingObj);
              setTrainingObjects((newArray) => [...newArray, newObj]);
              setCaptionModalOpen(false);
            }}
          >
            Update
          </SubmitButton>
          <BottomSpacer />
        </InnerModalContainer>
      </Modal>

      <UploadedImageContainer>
        <DeleteButton
          src="/icons/x-icon-gray.svg"
          alt=""
          onClick={(e) => {
            const newThing = [...trainingObjects];
            const newArray = removeItemOnce(newThing, trainingObj);
            setTrainingObjects(newArray);
            setCaption("");
          }}
        />
        <UploadedImage
          src={trainingObj.url}
          alt=""
          onClick={(e) => {
            setCaptionModalOpen(true);
          }}
        />
        {trainingObj.caption !== "" && (
          <>
            <DescriptionContainer>
              <DescriptionInnerContainer>
                {trainingObj.caption}
              </DescriptionInnerContainer>
            </DescriptionContainer>
          </>
        )}
      </UploadedImageContainer>
    </>
  );
}

const UploadedImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
  background: lightgray;
  border-radius: 10px;
`;

const DeleteButton = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  border: 0px;
  right: 0px;
  padding: 4px;
  background: var(--color-gray-200);
  border-radius: 0px 8px;
  opacity: 0.5;
`;

const UploadedImageContainer = styled.span`
  width: fit-content;
  height: auto;
  position: relative;
  display: inline-flex;
`;

const InnerModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: contents;
  text-align: center;
`;

const ModalImage = styled.img`
  width: auto;
  height: auto;
  display: block;
  max-height: 350px;
  margin: auto;
  margin-bottom: 4px;
`;

const Editor = styled.textarea`
  color: black;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  font-size: 14px;
  border: 0;

  &:focus-visible {
    outline: 0;
  }
`;

const DescriptionContainer = styled.div`
  display: block;
  height: 30px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
  margin: auto;
  text-align: center;
  pointer-events: none;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(21, 23, 31, 0) 0%,
    var(--color-gray-300) 100%
  );
`;

const DescriptionInnerContainer = styled.div`
  font: unset;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: white;
  padding: 0px !important;
  margin: 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 5px;
  width: 75%;
  text-align: center;
  margin-left: 12.5%;

  .ant-image {
    width: 20px !important;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: var(--blueHeader);
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #fef6f3;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }
`;

const Spacer = styled.div`
  height: 20px;
`;

const BottomSpacer = styled.div`
  height: 10px;
`;
