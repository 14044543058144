import React, { useEffect, useState } from "react";
import { currentUser } from "../helpers/authentication";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import commentsModel from "../lib/firebase/commentsModel";
import { CommentSheet } from "./social";

export default function CommentButton({ post }) {
  const [isOpen, setOpen] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const newComments = await commentsModel.getMany([
        "subjectId",
        "==",
        post.id,
      ]);
      setComments(newComments);
    }
    fetchData();
  }, [post.id]);

  return (
    <>
      <ClickButton
        onClick={() => {
          console.log("open comment sheet");
          setOpen(true);
          // releaseParticles(disabledButtonRef);
        }}
      >
        <CommentIcon src="/icons/comment.svg" alt="" />
        <Amount>{comments.length}</Amount>
      </ClickButton>
      <CommentSheet
        isOpen={isOpen}
        setOpen={setOpen}
        comments={comments}
        setComments={setComments}
        subjectId={post.id}
      />
    </>
  );
}

const ClickButton = styled.button`
  background: transparent;
  color: var(--color-cream-base);
  text-align: center;
  border: 0;
  vertical-align: sub;

  &:disabled {
    background: transparent;
  }

  &:active {
    background: transparent;
  }

  &:hover {
    background: transparent;
  }

  &::selection {
    background: transparent;
  }

  &:focus {
    background: transparent;
  }
`;

const Amount = styled.span`
  margin-left: 4px;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-gray-100);
  vertical-align: top;
  line-height: 21px;
`;

const CommentIcon = styled.img`
  width: 19px;
  height: 19px;
  margin-left: 2px;
  font-weight: ${(props) => (props.isLiked ? 600 : 400)};
  color: var(--color-gray-100);
  padding-top: 2px;
`;
