import { v4 as uuidv4 } from "uuid";

import modelIcon from "../assets/icons/model.svg";
import createIcon from "../assets/icons/image.svg";
import fanIcon from "../assets/icons/fan.svg";
import { appName } from "../helpers/constants";

const featuresData = [
  {
    id: uuidv4(),
    icon: modelIcon,
    title: "Design Your Model",
    desc: "Create your perfect influencer that only YOU have access to",
  },

  {
    id: uuidv4(),
    icon: createIcon,
    title: "Create Exclusive Content",
    desc: `with ${appName} tools and share your content`,
  },

  {
    id: uuidv4(),
    icon: fanIcon,
    title: "Engage Your Fans",
    desc: "Bring fans into your world with exclusive content",
  },
];

export default featuresData;
