import React, { useEffect, useState } from "react";
import DragCard from "./DragCard";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

function DragComics({
  comicEntries,
  removeComicEntry,
  updateComicOrdering,
  setWinningComic,
  winningComic,
}) {
  const [comicEntriesNumbered, setComicsEntriesNumbered] =
    useState(comicEntries);

  useEffect(() => {
    const newEntries = [];
    for (let i = 0; i < comicEntries.length; i += 1) {
      comicEntries[i].pageNumber = i + 1;
      comicEntries[i].id = comicEntries[i].fileName + "-" + new Date();
      newEntries.push(comicEntries[i]);
    }
    setComicsEntriesNumbered(newEntries);
  }, [comicEntries]);

  return (
    <div className="flex-container create-episode-drag">
      <div className="row">
        <DragDropContext onDragEnd={updateComicOrdering}>
          <Droppable droppableId="drag-drop-list" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className="drag-drop-list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {comicEntriesNumbered.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        className="item-card"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DragCard
                          comicEntry={item}
                          className="drag-card flex-item"
                          removeComicEntry={removeComicEntry}
                          setWinningComic={setWinningComic}
                          isWinningComic={
                            winningComic && item.id === winningComic.id
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default DragComics;
