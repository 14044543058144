import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  subscribeToCreator,
  unsubscribeFromCreator,
} from "../../helpers/firebase";
import subscriptionModel from "../../lib/firebase/subscriptionModel";
import { currentUser } from "../../helpers/authentication";
import pluralize from "pluralize";
import { gumroadLinkRoot } from "../../helpers/constants";
import mixpanel from "mixpanel-browser";

export default function SubscriptionButton({ price, months, creator }) {
  const [subscribing, setSubscribing] = useState(false);

  /*
  const subscribeToUser = async () => {
    setSubscribing(true);
    await subscribeToCreator(creator.id);
    console.log(creator);
    const subscriptions = await subscriptionModel.getMany(
      ["limit", "1"],
      ["creator", "==", creator.id],
      ["subscriber", "==", currentUser.uid]
    );
    if (subscriptions.length > 0 && subscriptions[0]) {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }
    setSubscribing(false);
  };
  */

  return (
    <Button
      disabled={subscribing}
      onClick={async (e) => {
        mixpanel.track("Clicked subscription button", {
          creator: creator.id,
          price,
        });
        window.open(
          gumroadLinkRoot +
            months +
            `month?creator=${creator.id}&subscriber=${currentUser.uid}`
        );
      }}
    >
      <Row>
        <Col span={18}>
          <SubscribeButtonHeader>
            Subscribe for {pluralize("month", months, true)}
          </SubscribeButtonHeader>
        </Col>
        <Col span={6}>
          <PriceHeader>${price}</PriceHeader>
        </Col>
      </Row>
    </Button>
  );
}

const SubscribeButtonHeader = styled.div`
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
`;

const PriceHeader = styled.div`
  font-size: 12px;
  text-align: right;
`;

const Button = styled.button`
  border: 0;
  width: 100%;
  display: inline-block;
  background: var(--lightPurple);
  border-radius: 50px;
  padding: ${(props) => (props.disabled ? "9.5px" : "14px 20px")};
  margin-top: 10px;
  color: white;
  font-size: 12px;
  font-weight: 600;
`;
