// ----- Components Import -----
import { useState } from "react";
import { styled } from "styled-components";
import { Link, NavLink } from "react-router-dom";

// ----- Assets Import -----
import SGLogo from "../../assets/home/logo.svg";
import burgerIcon from "../../assets/icons/burger-icon.svg";
import MobileMenu from "../sections/MobileMenu";

export default function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const mobileMenuHandler = () => {
    console.log("click");
    setMobileMenu(!mobileMenu);
  };

  return (
    <HeaderWrapper>
      <nav>
        <NavContent>
          <button className="mobile-burger" onClick={mobileMenuHandler}>
            <img src={burgerIcon} alt="Burger" />
          </button>
          <Link to="/" className="logo">
            <Logo src={SGLogo} alt="" />
          </Link>
          <NavItems className="show">
            <li>
              <NavLink to="/login">Log In</NavLink>
            </li>
          </NavItems>
        </NavContent>
      </nav>
      <MobileMenu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
    </HeaderWrapper>
  );
}

// ----- Styles -----
const HeaderWrapper = styled.header`
  background-color: white;
  padding: 1.5rem 3.125rem;

  @media (max-width: 61.9375rem) {
    padding: 1.1rem 1.5625rem;
  }

  @media (max-width: 35.9375rem) {
    padding: 1.1rem 1.125rem;
  }
`;

const Logo = styled.img`
  height: 70px;
  width: auto;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: 61.9375rem) {
    justify-content: start;
    gap: 0.8rem;
  }

  .logo {
    @media (max-width: 35.9375rem) {
      max-width: 14.375rem;
    }
  }

  .mobile-burger {
    display: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: 61.9375rem) {
      display: block;
    }
  }
`;

const NavItems = styled.ul`
  display: flex;
  align-items: center;
  gap: 5rem;

  @media (max-width: 61.9375rem) {
    display: none;
  }

  li {
    list-style: none;

    a {
      font-family: var(--heading-font);
      color: var(--color-dark);
      font-size: 1.5rem;
      font-weight: 700;
      text-decoration: none;
      transition: all 0.3s ease;

      &.active,
      &:hover {
        color: var(--color-primary);
      }
    }
  }
`;
