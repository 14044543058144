import axios from "axios";
import { addDays, sleep, timeout } from "./general";
import mixpanel from "mixpanel-browser";
import { employeeIds } from "./employees";
import { currentUser } from "./authentication";
import { getGeneratorLimit, getRenderById } from "./firebase";
import { message } from "antd";
import rendersModel from "../lib/firebase/rendersModel";

const pollInterval = 3;
const secondsWaitedThreshold = 600;

export const leonardoModels = [
  {
    label: "DreamShaper V7",
    value: "ac614f96-1082-45bf-be9d-757f2d31c174",
  },
  {
    label: "Leonardo Creative",
    value: "6bef9f1b-29cb-40c7-b9df-32b51c1f67d3",
  },
  {
    label: "Leonardo Select",
    value: "cd2b2a15-9760-4174-a5ff-4d2925057376",
  },
  {
    label: "Leonardo Signature",
    value: "291be633-cb24-434f-898f-e662799936ad",
  },
];

export async function callGenAi({
  quest,
  prompt,
  modelId,
  initImageId,
  negativePrompt,
  storyworld,
  usedSeedImage,
  usedTypedUrl,
  usedStarterPrompt,
  includesCharacters,
}) {
  const leonardoPromiseId = await generateImages({
    prompt,
    modelId,
    negativePrompt,
    initImageId,
    includesCharacters,
  });

  if (leonardoPromiseId === null) {
    return "Image generation failed. Please try again.";
  }

  try {
    const start = Date.now();
    const results = await timeout({
      asyncFn: async () => await getGeneratedImages(leonardoPromiseId),
      pollInterval,
      maxWaitTime: secondsWaitedThreshold,
      validateFn: (results) => results?.length > 0,
    });
    console.log("Got results in", (Date.now() - start) / 1000, "seconds");
    if (currentUser && !employeeIds.includes(currentUser.uid)) {
      mixpanel.track("Generated Art", {
        quest_id: quest.id,
        quest_name: quest.title,
        storyworld_id: quest.storyworld,
        storyworld_name: storyworld.title,
        title: `${storyworld.title}: ${quest.title}`,
        used_negative_prompt: negativePrompt !== "",
        used_starter_prompt: usedStarterPrompt,
        used_seed_image: usedSeedImage,
        used_typed_url: usedTypedUrl,
        time_spent_seconds: (Date.now() - start) / 1000,
      });
    }
    return results;
  } catch (error) {
    return "Retrieving generated images failed";
  }
}

export async function retrieveGraydientImagesFromFirebase(sessionId) {
  console.log("Retrieving generated images");
  if (sessionId === null) {
    return "No session ID. Please try again.";
  }

  try {
    const start = Date.now();
    const results = await timeout({
      asyncFn: async () => await getRenderById(sessionId),
      pollInterval,
      maxWaitTime: secondsWaitedThreshold,
      validateFn: (results) => results?.images.length > 0,
    });
    console.log("Results retrieved in", (Date.now() - start) / 1000, "seconds");
    message.success(
      `Generated in ${Math.floor((Date.now() - start) / 1000)} seconds`,
      [5]
    );
    if (currentUser) mixpanel.track("Generated content");

    return results;
  } catch (error) {
    return "Retrieving generated images failed";
  }
}

export async function invokeGenAi({
  prompt,
  sessionId,
  loras,
  weights,
  facepushUrl,
}) {
  // console.log("about to generate images");
  const genLimit = await getGeneratorLimit();
  const generationsOverThePastDay = await rendersModel.count(
    ["creator", "==", currentUser.uid],
    ["createdAt", ">=", addDays(new Date(), -1)]
  );
  // console.log("gen limit", genLimit);
  // console.log("daily gens", generationsOverThePastDay);
  if (
    genLimit <= generationsOverThePastDay &&
    !employeeIds.includes(currentUser.uid)
  ) {
    message.error("Daily creation limit reached, please return later", [5]);
    return null;
  }
  const imageGenPromise = await generateNewImages({
    prompt,
    sessionId,
    loras,
    weights,
    facepushUrl,
  });

  if (imageGenPromise === null) {
    return "Image generation failed. Please try again.";
  } else {
    return imageGenPromise;
  }
}

async function retrieveGraydientImages(renderHash) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/generations/${renderHash}`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          prompt,
        }),
      }
    );
    const data = await response.json();
    console.log(data);
    return data.has_been_rendered;
  } catch (error) {
    console.log("Error generating images:", error);
    throw error;
  }
}

async function generateContentImages({ prompt }) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/generations`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          prompt,
        }),
      }
    );
    const data = await response.json();
    return data.sdGenerationJob.generationId;
  } catch (error) {
    console.log("Error generating images:", error);
    throw error;
  }
}

async function generateNewImages({
  prompt,
  sessionId,
  loras,
  weights,
  facepushUrl,
}) {
  console.log(prompt);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/generations`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          prompt,
          session_id: sessionId,
          loras,
          weights,
          facepushUrl,
        }),
      }
    );

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log("Error generating images:", error);
    throw error;
  }
}

export async function trainNewModel({ imageUrls, groupId }) {
  console.log(imageUrls);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/trainModel`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          groupId,
          imageUrls,
          creator: currentUser.uid,
        }),
      }
    );

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log("Error training Mai:", error);
    throw error;
  }
}

async function generateImages({
  prompt,
  modelId,
  negativePrompt,
  initImageId,
  includesCharacters,
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/generations`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          prompt,
          modelId,
          initImageId,
          negativePrompt,
          includesCharacters,
        }),
      }
    );

    const data = await response.json();
    return data.sdGenerationJob.generationId;
  } catch (error) {
    console.log("Error generating images:", error);
    throw error;
  }
}

async function getGeneratedImages(id) {
  if (!id) return null;
  const response = await axios.request({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/generations/${id}`,
    headers: {
      accept: "application/json",
    },
  });
  const entryArray = response.data.generations_by_pk.generated_images;
  return entryArray.map((image) => image.url);
}

export async function initImage(file) {
  const fileExtension = file.type.split("/").pop();
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ extension: fileExtension }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/init-image`,
    options
  );
  const data = await response.json();
  await uploadInitImage(file, data);
  await sleep(3);
  return data.uploadInitImage.id;
}

async function uploadInitImage(file, response) {
  const rawFields = response.uploadInitImage.fields;
  const fields = JSON.parse(rawFields);
  const url = response.uploadInitImage.url;

  const formData = new FormData();

  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const request = new XMLHttpRequest();
  request.open("POST", url);

  request.send(formData);
}
