import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import {
  getUsernameFromUrl,
  isOnMobile,
  showHeaderUi,
  sleep,
} from "../../helpers/general";
import { mobileWidthThreshold } from "../../helpers/constants";

const headerHeight = 50;

export default function MobileHeader({ setBackLocations }) {
  const location = useLocation();
  const history = useHistory();

  const [waited, setWaited] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    async function fetchData() {
      await sleep(1);
      setWaited(true);
    }
    fetchData();
  }, []);

  useEffect(() => {
    let title;
    if (location.pathname === "/post") {
      title = "New Post";
    } else if (location.pathname.includes("/messages")) {
      title = "Messages";
    } else if (location.pathname === "/message") {
      title = "New Post";
    } else if (location.pathname === "/notifications") {
      title = "Notifications";
    } else if (location.pathname.includes("/user/")) {
      title = getUsernameFromUrl();
    } else if (location.pathname === "/post") {
      title = "New Post";
    } else if (location.pathname === "/privacy") {
      title = "Privacy Policy";
    } else if (location.pathname === "/profile") {
      title = "Profile";
    } else if (location.pathname === "/terms") {
      title = "Terms of Use";
    } else {
      title = "Home";
    }
    setHeaderTitle(title);
  }, [location]);

  return (
    waited &&
    showHeaderUi(location) && (
      <>
        <Container>
          <Header>
            {location.pathname !== "/home" && (
              <LeftArrow
                onClick={(e) => {
                  history.push("/home");
                }}
                src="/icons/chevron-left.svg"
                alt=""
              />
            )}
            <HeaderText>{headerTitle}</HeaderText>
          </Header>
        </Container>
        <Spacer />
      </>
    )
  );
}

const Container = styled.div`
  position: relative;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid lightgray;
  height: ${headerHeight}px;
  text-align: left;
  background: white;

  @media (min-width: ${mobileWidthThreshold}) {
    display: none;
  }
`;

const Header = styled.div`
  display: inline-flex;
  padding-left: 20px;
  height: ${headerHeight}px;
  margin-top: 12px;
`;

const HeaderText = styled.span`
  font-size: 22px;
  font-weight: bold;
`;

const Spacer = styled.div`
  height: ${headerHeight}px;
`;

const LeftArrow = styled.img`
  height: 24px;
  width: auto;
  margin-top: 2px;
  margin-right: 10px;
`;
