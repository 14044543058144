import React from "react";
import { Button, Col, Image, Row, Typography } from "antd";
import { questCardDatePattern } from "../helpers/constants";
import date from "date-and-time";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const { Paragraph } = Typography;
const bookmarkIcon = "./../icons/bookmark.svg";

export default function EpisodeListEntry({
  storyworldId,
  episode,
  bookmarked = false,
  read = false,
}) {
  const readEpisode = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Button onClick={readEpisode} className="episode-list-entry">
      {bookmarked ? (
        <img className="bookmark-icon" alt="" src={bookmarkIcon} />
      ) : null}
      <Link to={`/stories/${storyworldId}/episode/${episode.number}`}>
        <Row gutter={18}>
          <Col span={9} className="left">
            <Image
              src={
                episode &&
                episode.winningSubmission &&
                episode.winningSubmission.imageUrl
                  ? episode.winningSubmission.imageUrl
                  : "/images/default-storyworld.svg"
              }
              preview={false}
              className="episode-list-image"
            />
          </Col>
          <Col span={15} className="left">
            <EpisodeText>
              Episode {episode.number} ·{" "}
              {date.format(episode.publishedAt.toDate(), questCardDatePattern)}
            </EpisodeText>
            <div>
              <Paragraph
                ellipsis={{
                  rows: 2,
                }}
                className={`episode-list-entry-title ${
                  read || bookmarked ? "episode-read" : null
                }`}
              >
                {episode.title}
              </Paragraph>
            </div>
          </Col>
        </Row>
      </Link>
    </Button>
  );
}

const EpisodeText = styled.span`
  font-size: 10px;
  font-weight: 400;
  margin-left: 0px;
  color: var(--color-gray-50);
`;
