import React, { useState } from "react";
import { currentUser } from "../../helpers/authentication";
import styled from "styled-components";
import { deleteUser } from "../../helpers/firebase";
import { Modal, message } from "antd";
import { UserDisplay } from "..";
import { sleep } from "../../helpers/general";

export default function DeleteUserModal({
  userName,
  isDeleteOpen,
  setIsDeleteOpen,
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteThisUser = async () => {
    await deleteUser();
    setIsDeleting(false);
    setIsDeleteOpen(false);
    message.success("Account deleted successfully");
    await sleep(2);
    window.location.reload();
  };

  return (
    <Modal
      open={isDeleteOpen}
      onCancel={(event) => {
        setIsDeleteOpen(false);
        event.stopPropagation();
      }}
      closable={false}
      footer={null}
    >
      <InnerModalContainer>
        <UserContainer>
          <UserDisplay userId={currentUser.uid} size={33} />
          <Username>{userName}</Username>{" "}
        </UserContainer>
        <DeleteText>Are you sure you want to delete your account?</DeleteText>
        <DeleteText>
          This action cannot be undone and will remove all of your subscriptions
          and content.
        </DeleteText>
        <Spacer />
        <ButtonContainer>
          {isDeleting ? (
            <DeleteUserButtonDeleting>
              <Spinner alt="" src="/icons/loading-spheres.svg" />
            </DeleteUserButtonDeleting>
          ) : (
            <DeleteUserButton
              onClick={async (e) => {
                setIsDeleting(true);
                await deleteThisUser();
              }}
            >
              Delete account
            </DeleteUserButton>
          )}
        </ButtonContainer>

        <ButtonContainer>
          <CancelDeleteUserButton
            onClick={(e) => {
              setIsDeleteOpen(false);
            }}
          >
            Cancel
          </CancelDeleteUserButton>
        </ButtonContainer>
      </InnerModalContainer>
    </Modal>
  );
}

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const UserContainer = styled.div`
  display: inline-flex;
  text-align: center;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  justify-content: center;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: auto;
  margin: 0px 3px 4px 0px;
`;

const DeleteUserButton = styled.button`
  color: var(--lightText);
  background: var(--deleteColor);
  border: 0;
  height: 45px;
  border-radius: 24px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 0px;
`;

const DeleteUserButtonDeleting = styled.button`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background: var(--color-gray-300);
  border: 0;
  height: 45px;
  border-radius: 24px;
  cursor: pointer;
  width: 100%;
`;

const CancelDeleteUserButton = styled.button`
  color: black;
  width: 100%;
  border: 0;
  margin-top: 6px;
  background: none;
  height: 45px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const InnerModalContainer = styled.div`
  height: 100%;
  display: contents;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 14px 0px 0px 0px;
`;

const DeleteText = styled.h3`
  margin: 10px auto 20px auto;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;

const Username = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: center;
  color: black;
  margin-top: 0px;
  margin-left: 8px;
`;

const Spacer = styled.div`
  height: 10px;
`;
