import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import styled from "styled-components";
import subscriptionModel from "../lib/firebase/subscriptionModel";
import { NotificationElement } from "../components/notifications";
import { getAuth } from "firebase/auth";
import NotificationPosts from "./NotificationPosts";

export default function Notifications() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const notificationQuery = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const currentUser = getAuth().currentUser;
      if (!currentUser) return null;
      const subscriptions = await subscriptionModel.getMany([
        "subscriber",
        "==",
        currentUser.uid,
      ]);
      return subscriptions;
    },
  });

  return (
    <Container>
      {notificationQuery.status === "success" &&
        notificationQuery.data &&
        notificationQuery.data.map((notification, index) => (
          <NotificationElement notification={notification} />
        ))}
      {notificationQuery.status === "success" &&
        notificationQuery.data &&
        notificationQuery.data.length === 0 && (
          <NoNotifications>
            Subscribe to creators to get notifications
          </NoNotifications>
        )}

      <NotificationPosts />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  text-align: left;
`;

const NoNotifications = styled.div`
  height: 100px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  background: white !important;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 38px 20px 0px 26px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }
`;
