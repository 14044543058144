import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  generateRandomId,
  getUserInfo,
  getUserInfoCountByEmail,
  writeUserProfileData,
} from "./firebase";
import {
  defaultProfileImages,
  adminList,
  passwordErrorText,
} from "./constants";
import { isOnMobile } from "./general";
import mixpanel from "mixpanel-browser";

export const firebaseAuth = getAuth();

export let currentUser;

firebaseAuth.onAuthStateChanged(async (user) => {
  console.log(user);
  if (user) {
    currentUser = user;
    mixpanel.identify(currentUser.uid);
  } else {
    console.log("User is logged out!");
  }
});

export async function registerAccount({ email, password }) {
  try {
    // const profileCount = await getUserInfoCountByEmail(email);
    // if (profileCount >= 1) return false;
    await createUserWithEmailAndPassword(firebaseAuth, email, password).then(
      async (cred) => await sendEmailVerification(cred.user)
    );
    return true;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function registerUser({
  username,
  bio,
  email,
  password,
  imageUrl,
}) {
  try {
    const userCredential = await registerAccount({ email, password });
    await writeUserProfileData({
      userId: userCredential.user.uid,
      bio,
      username,
      email: email.toLowerCase(),
      pfp: imageUrl,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function loginUser(email, password) {
  try {
    const credentials = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    return credentials;
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

export function validateEmail(email) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

export function moveToPageAfterLogin(user = currentUser) {
  const onMobile = isOnMobile();
  if (onMobile) {
    window.location = "/stories";
  } else if (adminList.includes(user.email) && !onMobile) {
    window.location = "/editStoryworlds";
  }
}

export async function isUserLoggedIn() {
  if (firebaseAuth.currentUser) {
    const userInfo = await getUserInfo(currentUser.uid);
    if (userInfo && userInfo.acceptedTos) return true;
  }
  return false;
}

export function isUsernameTaken() {
  // check firebase for existing username -- if it exists return true, else false
  if (firebaseAuth.currentUser) return true;
  else return false;
}

export function isTodayPastDate(date) {
  if (date > new Date()) {
    return true;
  }
  return false;
}

export function getRandomProfilePic() {
  return null;
}

export async function doesUserAuthExistByEmail(email) {
  console.log(firebaseAuth);
  console.log(email);
  try {
    await signInWithEmailAndPassword(firebaseAuth, email, generateRandomId());
    return true;
  } catch (error) {
    console.log(error.code);
    return (
      error.code === "auth/wrong-password" ||
      error.code === "auth/email-already-in-use"
    );
  }
}

export function getLoginError(error) {
  console.log(error);
  let response = "";
  switch (error) {
    case "auth/wrong-password":
      response = passwordErrorText;
      break;
    case "auth/invalid-login-credentials":
      response = "Invalid login credentials";
      break;
    case "auth/too-many-requests":
      response =
        "You have tried to log in too many times. Try again in a few minutes.";
      break;
    default:
      response = "You have encountered an error. Try again in a few minutes.";
      break;
  }
  return response;
}
