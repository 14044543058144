import { getAuth } from "firebase/auth";
import { reportTable } from "../../helpers/constants";
import { count, queryMany, queryOne } from "./all";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const table = reportTable;

const reportModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getManyByUserId: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["creator", "==", userId], ...args],
    });

    return data;
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
  create: async (params) => {
    const { subjectId } = params;
    console.log("trying to create report");
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const ref = await addDoc(collection(getFirestore(), table), {
      subjectId,
      creator: user.uid,
      createdAt: new Date(),
    });
    const doc = await queryOne({ table, id: ref.id });
    if (!doc) throw new Error("Report not created");
    return doc;
  },
};

export default reportModel;
