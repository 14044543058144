import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../helpers/authentication";
import conversationsModel from "../../lib/firebase/conversationsModel";
import { Convo } from ".";

export default function Conversations() {
  const conversationsQuery = useQuery({
    queryKey: [`conversations-${currentUser.uid}`],
    queryFn: async () => {
      if (!currentUser) return null;
      let conversations = [];
      const creatorConversations = await conversationsModel.getMany([
        "creator",
        "==",
        currentUser.uid,
      ]);
      const subscriberConversations = await conversationsModel.getMany([
        "subscriber",
        "==",
        currentUser.uid,
      ]);
      conversations = creatorConversations.concat(subscriberConversations);
      return conversations;
    },
  });

  if (conversationsQuery.status !== "success") return;

  return (
    <Container>
      {conversationsQuery.data &&
        conversationsQuery.data.map((convo, index) => <Convo convo={convo} />)}
      {conversationsQuery.data && conversationsQuery.data.length === 0 && (
        <NoSubscribers>
          Connect with users and creators to start messaging
        </NoSubscribers>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const NoSubscribers = styled.div`
  width: 100%;
  background: white;
  padding: 20px 0px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid var(--color-light-gray);
`;
