import date from "date-and-time";

export const companyName = "Junoware LLC";
export const appName = "AllmaiFans";
export const urlName = `${appName.toLocaleLowerCase()}.com`;

const junowareUrl = "junoware.com";
export const contactEmailAddress = `contact@${junowareUrl}`;
export const helpEmailAddress = `help@${junowareUrl}`;
export const feedbackEmailAddress = `feedback@${junowareUrl}`;
export const supportEmailAddress = `support@${junowareUrl}`;
export const privacyEmailAddress = `privacy@${junowareUrl}`;
/*
export const contactEmailAddress = `contact@${urlName}`;
export const helpEmailAddress = `help@${urlName}`;
export const feedbackEmailAddress = `feedback@${urlName}`;
export const supportEmailAddress = `support@${urlName}`;
export const privacyEmailAddress = `privacy@${urlName}`;
*/

export const gumroadLinkRoot = `https://allmaifans.gumroad.com/l/`;

export const commentLengthThreshold = 1;
export const commentLengthMax = 500;

export const galleryPageLimit = 12;
export const spinnerIcon = "/icons/spinner.svg";

export const tikTokLink = "";
export const instagramLink = "";
export const twitterLink = "https://twitter.com/allmaifans";
export const discordLink = "https://discord.com/invite/aSFCqNVk4P";

export const datePattern = date.compile("ddd, MMM DD");
export const questCardDatePattern = date.compile("MMMM DD, YYYY");

export const noHeaderUiPaths = [
  "/login",
  "/register",
  "/privacy",
  "/terms",
  "/reset-password",
  "/content-and-community-guidelines",
];

export const desktopWidthPaths = [
  "/privacy",
  "/terms",
  "/content-and-community-guidelines",
];

export const noFooterUiPaths = ["/login", "/register", "/reset-password", "/"];

export const profanityErrorText = "Please edit and try again";
export const usernameTakenErrorText = "Username taken";
export const passwordErrorText = "Wrong password";

export const mobileWidthThreshold = 768;

export const forbiddenUsernames = ["admin", "allmaifans", "test"];

export const profileStub = "/user";
export const defaultProfileImage =
  "https://firebasestorage.googleapis.com/v0/b/allmaifans.appspot.com/o/defaultImage%2Fprofile.svg?alt=media&token=5587e574-b10c-4a55-abf3-8e06dcb993d3";

export const sfwLoras = [
  { label: "realistic", tag: "realism-ce", weight: 0.7 },
  { label: "femboy", tag: "femboi", weight: 0.7 },
  { label: "missionary pov", tag: "missionary-pov", weight: 0.7 },
  { label: "cum", tag: "cum", weight: 0.7 },
  { label: "cum 2", tag: "cum2", weight: 0.7 },
  { label: "blowjob pov", tag: "bj-pov", weight: 0.7 },
  { label: "doggystyle", tag: "sex-from-behind", weight: 0.7 },
  { label: "bimbo", tag: "cgm-bimbo", weight: 0.9 },
  { label: "realistic 2", tag: "photon", weight: 0.7 },
  { label: "trans", tag: "penis-real", weight: 1 },
  { label: "missionary panties", tag: "panties-toside2", weight: 0.7 },
];

export const nsfwLoras = [
  { label: "realistic", tag: "realism-ce", weight: 0.7 },
  { label: "femboy", tag: "femboi", weight: 0.7 },
  // { label: "missionary pov", tag: "missionary-pov", weight: 0.7 },
  { label: "cum", tag: "cum", weight: 0.7 },
  // { label: "cum 2", tag: "cum2", weight: 0.7 },
  { label: "blowjob pov", tag: "bj-pov", weight: 0.7 },
  { label: "doggystyle", tag: "sex-from-behind", weight: 0.7 },
  // { label: "bimbo", tag: "cgm-bimbo", weight: 0.9 },
  // { label: "realistic 2", tag: "photon", weight: 0.7 },
  { label: "trans", tag: "penis-real", weight: 1 },
  // { label: "missionary panties", tag: "panties-toside2", weight: 0.7 },
  // { label: "riding", tag: "riding-sex", weight: 0.7 },
  // { label: "femboy 2", tag: "femboi2", weight: 0.7 },
  // { label: "high definition", tag: "hd-porn", weight: 0.7 },
  { label: "futa", tag: "futanari-factor", weight: 1 },
  // { label: "futa 2", tag: "futa2", weight: 0.7 },
  { label: "pussy", tag: "pussyfat", weight: 0.7 },
  //  { label: "chill", tag: "chillout-nsfw", weight: 0.7 },
  { label: "ass", tag: "ass-combat", weight: 0.7 },
  { label: "penis", tag: "pov-penis", weight: 0.7 },
  { label: "creampie", tag: "closeup-vaginal-creampie", weight: 0.7 },
  // { label: "FFM", tag: "ffm-sex", weight: 0.7 },
  // { label: "futa on male", tag: "futa-on-male", weight: 0.7 },
  { label: "straddle", tag: "front-reverse-straddle", weight: 0.7 },
  { label: "gay", tag: "gay-anal-sex75", weight: 0.8 },
  // { label: "dildo futa", tag: "dildo-riding-futa", weight: 0.7 },
  { label: "elf", tag: "duskfall-portal", weight: 0.7 },
  // { label: "realistic 3", tag: "sexytoon-real2", weight: 0.7 },
  { label: "anime", tag: "bb-anime3", weight: 0.7 },
  { label: "hentai", tag: "anyhentai2", weight: 0.7 },
];

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyByrUdjEHDOO8ZodWTtnBi31ekmXtX88bE",
  authDomain: "foxxy-78cf6.firebaseapp.com",
  projectId: "foxxy-78cf6",
  storageBucket: "foxxy-78cf6.appspot.com",
  messagingSenderId: "409968715732",
  appId: "1:409968715732:web:fe8f3d375843ef3ee0d841",
  measurementId: "G-59DVJVB1QF",
};

/*
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const prodFirebaseConfig = {
  apiKey: "AIzaSyALUwyTazIqpeutcjNKM6oya_R6eOFyg_Q",
  authDomain: "foxxyai.firebaseapp.com",
  projectId: "foxxyai",
  storageBucket: "foxxyai.appspot.com",
  messagingSenderId: "855929597285",
  appId: "1:855929597285:web:ff13c42615811ed4cc058c",
  measurementId: "G-LVEFH5XYBC",
};
*/

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const prodFirebaseConfig = {
  apiKey: "AIzaSyDV7jCuQROBAtChoPBOe5No3RYM2bAzbHs",
  authDomain: "allmaifans.firebaseapp.com",
  projectId: "allmaifans",
  storageBucket: "allmaifans.appspot.com",
  messagingSenderId: "274905313552",
  appId: "1:274905313552:web:4441946b3dbb053ffff595",
  measurementId: "G-VPE7Y7DDPG",
};

export const allowListTable = "allowList";
export const pollTable = "polls";
export const userTable = "users";
export const storyworldTable = "storyworlds";
export const questTable = "quests";
export const submissionsTable = "submissions";
export const votesTable = "votes";
export const likesTable = "likes";
export const episodesTable = "episodes";
export const subscriptionsTable = "subscriptions";
export const bookmarksTable = "bookmarks";
export const quotasTable = "generationQuotas";
export const constantsTable = "constants";
export const createdAccountsTable = "createdAccounts";
export const messageTable = "messages";
export const modelTable = "models";
export const modelPromiseTable = "modelPromises";
export const rendersTable = "renders";
export const postsTable = "posts";
export const paymentsTable = "payments";
export const maiFavoriteTable = "maiFavorite";
export const conversationsTable = "convos";
export const commentsTable = "comments";
export const reportTable = "reports";

export const adminList = ["contact@junoware.com"];

export const discordApiInfo = {
  clientId: "1078797737513984020",
  clientSecret: "N2KYALWkwaudNBrmOLjYX1SeW2v3V2Bd",
  publicKey: "1dbfd1e9bbd1152919918b5c357deb69c1c5ccea1d96186aba0a0f720b63f86b",
  apiTokenEndpoint: "https://discord.com/api/oauth2/token",
  redirectUrl:
    "https://discord.com/api/oauth2/authorize?client_id=1078797737513984020&redirect_uri=https%3A%2F%2Fstrider-62efc1.netlify.app%2F&response_type=code&scope=identify",
};

let newExpirationDate = new Date();
export const expirationDayCount = 14;
export const maiExpiration = newExpirationDate.setDate(
  newExpirationDate.getDate() - expirationDayCount
);

export const createQuestQuillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
        ],
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageCompress: {
    quality: 0.5,
    maxWidth: 10000, // default
    maxHeight: 10000, // default
    // imageType: "image/jpeg", // default
    debug: false, // default
    suppressErrorLogging: false, // default
    insertIntoEditor: undefined, // default
    /* 
    insertIntoEditor: async (imageBase64URL, imageBlob) => {
      const downloadUrl = await uploadImage("questImages", imageBlob);
      return imageBlob;
    },
    */
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
    handleStyles: {
      backgroundColor: "white",
      border: "none",
      color: "white",
    },
  },
};

export const createPollQuillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
        ],
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageCompress: {
    quality: 0.8,
    maxWidth: 1000,
    maxHeight: 1000,
    imageType: "image/jpeg",
    debug: false,
    suppressErrorLogging: false,
    insertIntoEditor: undefined,
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
    handleStyles: {
      backgroundColor: "white",
      border: "none",
      color: "white",
    },
  },
};
