import { deleteDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import { count, queryMany, queryOne } from "./all";
import { commentsTable } from "../../helpers/constants";
import { getAuth } from "@firebase/auth";

const table = commentsTable;

const commentsModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });
    return data;
  },
  getManyByUserId: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["creator", "==", userId], ...args],
    });

    return data;
  },
  update: async ({ id, data }) => {
    const userRef = doc(getFirestore(), table, id);
    await updateDoc(userRef, data);
    return await queryOne({ table, id });
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");
    await deleteDoc(doc(getFirestore(), table, id));
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default commentsModel;
