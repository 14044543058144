import React from "react";
import styled from "styled-components";
import { appName } from "../../helpers/constants";

export default function ConsistencyCarouselOne({ email }) {
  return (
    <Container>
      <InnerContainer>
        <Subheader>
          With {appName}, you can create your perfect model. Our platform helps
          you make them consistent across all your exclusive content.
        </Subheader>
        <Image src={"/images/consistency/consistency.svg"} alt="" />
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const InnerContainer = styled.div`
  margin-bottom: 10px;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 14px;
`;

const Image = styled.img`
  max-width: 240px;
  height: auto;
  text-align: center;
  margin: auto;
`;
