import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Carousel } from "antd";
import TermsOfUse from "./TermsOfUse";
import { firebaseAuth } from "../../helpers/authentication";
import CreateVoteConnect from "./CreateVoteConnect";
import { acceptTos } from "../../helpers/firebase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const finalSlideNum = 2;

export default function NewUserCarousel({ email }) {
  const history = useHistory();

  const [finalSlide, setFinalSlide] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  let carouselRef = React.createRef();

  const afterChange = (currentSlide) => {
    if (currentSlide === finalSlideNum) setFinalSlide(true);
    else setFinalSlide(false);
  };

  const acceptTermsOfService = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsAccepting(true);
    await acceptTos(email);
    history.push("/stories");
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container>
      <SkipButton
        className={`${finalSlide ? "invisible" : ""}`}
        onClick={() => {
          carouselRef.current.goTo(finalSlideNum);
          setFinalSlide(true);
        }}
      >
        Skip <RightCarat src="/images/nux/right-carat-pink.svg" />
      </SkipButton>
      <InnerContainer>
        <Carousel afterChange={afterChange} ref={carouselRef}>
          <CarouselSection>Test</CarouselSection>
          <CarouselSection>
            <CreateVoteConnect />
          </CarouselSection>
          <CarouselSection>
            <TermsOfUse mustAcceptTos={true} />
          </CarouselSection>
        </Carousel>
        <ButtonSection>
          {!finalSlide ? (
            <NextButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.scroll(0, 0);
                carouselRef.current.next();
              }}
            >
              Next
            </NextButton>
          ) : (
            <>
              {!isAccepting ? (
                <>
                  <NextButton onClick={acceptTermsOfService}>
                    I Accept
                  </NextButton>
                  <DeclineButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      firebaseAuth.signOut().then(
                        function () {
                          window.location.href = "/login";
                        },
                        function (error) {
                          console.error("Sign Out Error", error);
                        }
                      );
                    }}
                  >
                    Decline
                  </DeclineButton>
                </>
              ) : (
                <IsAcceptingButton disabled>
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                </IsAcceptingButton>
              )}
            </>
          )}
        </ButtonSection>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  .invisible {
    opacity: 0;
    pointer-events: none;
  }
`;

const InnerContainer = styled.div`
  height: auto;
  width: 100%;

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background: #35383f;
    }

    .slick-active {
      width: 32px;
      height: 8px;
      border: 0;
      background: linear-gradient(286deg, #525cf5 0%, #f49090 100%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    button {
      opacity: 0 !important;
    }
  }
`;

const CarouselSection = styled.div`
  margin: 0;
  height: 650px;
  max-height: 650px;
  width: 100%;
  color: white;
  overflow: hidden;

  @media screen and (max-height: 825px) {
    height: 550px;
    max-height: 550px;
  }
`;

const ButtonSection = styled.div`
  margin: auto;
  margin-top: 0px;
  width: 90%;
`;

const NextButton = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 90px;
  background: var(--blurple);
  border: 0;
  color: var(--cream);
  cursor: pointer;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const DeclineButton = styled.button`
  width: fit-content;
  height: 45px;
  background: none;
  border: 0;
  margin-top: 14px;
  color: white;
  cursor: pointer;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const RightCarat = styled.img`
  height: 12px;
  margin: auto auto 2px 8px;
`;

const SkipButton = styled.a`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: white;
  display: block;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  color: var(--color-pink) !important;

  &:hover {
    color: white;
  }
`;

const IsAcceptingButton = styled.button`
  background: var(--color-gray-300);
  color: var(--disabled-button-text);
  width: 100%;
  height: 45px;
  border-radius: 90px;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;
